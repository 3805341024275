/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from "prop-types";
import { useIsWithinBreakpoints } from '../../../../src/services';
import { EuiHeaderLink, EuiHeaderSectionItemButton } from '../../../../src/components/header';
import { EuiToolTip } from '../../../../src/components/tool_tip';
import { EuiIcon } from '../../../../src/components/icon';
import logoFigma from '../../images/logo-figma.svg';
import { ThemeContext } from '../with_theme';
export var GuideFigmaLink = function GuideFigmaLink() {
  return ___EmotionJSX(ThemeContext.Consumer, null, function (context) {
    return ___EmotionJSX(GuideFigmaLinkComponent, {
      context: context
    });
  });
};

// @ts-ignore Context has no type
GuideFigmaLink.propTypes = {
  context: PropTypes.any
};
var GuideFigmaLinkComponent = () => {
  const isMobileSize = useIsWithinBreakpoints(['xs', 's']);
  const href = 'https://www.figma.com/community/file/964536385682658129';
  const label = 'EUI Figma Design Library';
  return isMobileSize ? <EuiHeaderLink color="primary" iconType={logoFigma} href={href}>
      {label}
    </EuiHeaderLink> : <EuiToolTip title={label} content="The Figma Elastic UI framework (EUI) is a design library in use at Elastic to build internal products that need to share our aesthetics.">
      <EuiHeaderSectionItemButton notificationColor="subdued" aria-label={label} href={href}>
        <EuiIcon type={logoFigma} aria-hidden="true" />
      </EuiHeaderSectionItemButton>
    </EuiToolTip>;
};
GuideFigmaLinkComponent.propTypes = {
  context: PropTypes.any
};