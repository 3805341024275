/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useLayoutEffect, useState } from 'react';
import PropTypes from "prop-types";
import { logicalStyle, useEuiPaddingCSS } from '../../../global_styling';
import { useEuiTheme, useIsWithinBreakpoints } from '../../../services';
import { euiPageSidebarStyles } from './page_sidebar.styles';
export var EuiPageSidebar = ({
  children,
  className,
  sticky = false,
  paddingSize = 'none',
  minWidth = 248,
  responsive = ['xs', 's'],
  hasEmbellish = false,
  style,
  ...rest
}) => {
  const themeContext = useEuiTheme();
  const styles = euiPageSidebarStyles(themeContext);
  const isResponding = useIsWithinBreakpoints(responsive);
  const cssStyles = [styles.euiPageSidebar, !isResponding && sticky && styles.sticky, hasEmbellish && styles.embellish, useEuiPaddingCSS()[paddingSize]];

  // Inline styles for setting up width and sticky offsets
  const [inlineStyles, setInlineStyles] = useState({
    ...style,
    ...logicalStyle('min-width', isResponding ? '100%' : minWidth)
  });
  useLayoutEffect(() => {
    let updatedStyles = {
      ...style,
      ...logicalStyle('min-width', isResponding ? '100%' : minWidth)
    };
    if (sticky) {
      const offset = typeof sticky === 'object' ? `${sticky?.offset}px` : 'var(--euiFixedHeadersOffset, 0)';
      updatedStyles = {
        ...updatedStyles,
        ...logicalStyle('top', offset),
        ...logicalStyle('max-height', `calc(100vh - ${offset})`)
      };
    }
    setInlineStyles(updatedStyles);
  }, [style, sticky, themeContext.euiTheme.base, isResponding, minWidth]);
  return <div className={className} css={cssStyles} style={inlineStyles} {...rest}>
      {children}
    </div>;
};
EuiPageSidebar.propTypes = {
  /**
     * Adjust the padding.
     * When using this setting it's best to be consistent throughout all similar usages.
     */
  paddingSize: PropTypes.any,
  /**
     * Renders a fancy little visual in the top left corner of the side bar
     */
  hasEmbellish: PropTypes.bool,
  /**
     * Adds `position: sticky` and affords for any fixed position headers.
     */
  sticky: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    /**
             * To account for any fixed elements like headers,
             * pass in the value of the total height of those fixed elements.
             */
    offset: PropTypes.number
  }).isRequired]),
  /**
     * A minimum width is necessary to maintain size.
     * Be sure to take `paddingSize` into account.
     */
  minWidth: PropTypes.any,
  /**
     * Sets the `minWidth` to 100% when within these breakpoints.
     */
  responsive: PropTypes.arrayOf(PropTypes.any.isRequired),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiPageSidebar.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/page/page_sidebar/page_sidebar.tsx',
    description: '',
    displayName: 'EuiPageSidebar',
    methods: [],
    props: {
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adjust the padding.\n' + "When using this setting it's best to be consistent throughout all similar usages.",
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      hasEmbellish: {
        defaultValue: {
          value: 'false'
        },
        description: 'Renders a fancy little visual in the top left corner of the side bar',
        name: 'hasEmbellish',
        parent: {
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      sticky: {
        defaultValue: {
          value: 'false'
        },
        description: 'Adds `position: sticky` and affords for any fixed position headers.',
        name: 'sticky',
        parent: {
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        }],
        required: false,
        type: {
          name: 'boolean | { offset?: number; }'
        }
      },
      minWidth: {
        defaultValue: {
          value: '248'
        },
        description: 'A minimum width is necessary to maintain size.\n' + 'Be sure to take `paddingSize` into account.',
        name: 'minWidth',
        parent: {
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        }],
        required: false,
        type: {
          name: 'Width<string | number>'
        }
      },
      responsive: {
        defaultValue: {
          value: "['xs', 's']"
        },
        description: 'Sets the `minWidth` to 100% when within these breakpoints.',
        name: 'responsive',
        parent: {
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_sidebar/page_sidebar.tsx',
          name: 'EuiPageSidebarProps'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiPageSidebarProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}