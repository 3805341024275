/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { forwardRef, useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, useMouseMove, useEuiMemoizedStyles, useGeneratedHtmlId } from '../../services';
import { isNil } from '../../services/predicate';
import { logicalStyles } from '../../global_styling';
import { useEuiI18n } from '../i18n';
import { getEventPosition } from './utils';
import { euiSaturationStyles } from './saturation.styles';
var colorDefaultValue = [1, 0, 0];
export var EuiSaturation = forwardRef(({
  className,
  color = colorDefaultValue,
  'data-test-subj': dataTestSubj = 'euiSaturation',
  hex,
  id: _id,
  onChange,
  onKeyDown,
  ...rest
}, ref) => {
  const classes = classNames('euiSaturation', className);
  const styles = useEuiMemoizedStyles(euiSaturationStyles);
  const id = useGeneratedHtmlId({
    conditionalId: _id
  });
  const instructionsId = `${id}-instructions`;
  const indicatorId = `${id}-saturationIndicator`;
  const [roleDescString, instructionsString] = useEuiI18n(['euiSaturation.ariaLabel', 'euiSaturation.screenReaderInstructions'], ['HSV color mode saturation and value 2-axis slider', "Arrow keys to navigate the square color gradient. Coordinates will be used to calculate HSV color mode 'saturation' and 'value' numbers, in the range of 0 to 1. Left and right to change the saturation. Up and down change the value."]);
  const [indicator, setIndicator] = useState({
    left: 0,
    top: 0
  });
  const [lastColor, setLastColor] = useState([]);
  const boxRef = useRef(null);
  useEffect(() => {
    // Mimics `componentDidMount` and `componentDidUpdate`
    const [, s, v] = color;
    if (!isNil(boxRef.current) && lastColor.join() !== color.join()) {
      const {
        height,
        width
      } = boxRef.current.getBoundingClientRect();
      setIndicator({
        left: s * width,
        top: (1 - v) * height
      });
    }
  }, [color, lastColor]);
  const calculateColor = useCallback(({
    top,
    height,
    left,
    width
  }) => {
    const [h] = color;
    const s = left / width;
    const v = 1 - top / height;
    return [h, s, v];
  }, [color]);
  const handleUpdate = useCallback(box => {
    const {
      left,
      top
    } = box;
    setIndicator({
      left,
      top
    });
    const newColor = calculateColor(box);
    setLastColor(newColor);
    onChange(newColor);
  }, [calculateColor, onChange]);
  const handleChange = useCallback(location => {
    if (isNil(boxRef?.current)) return;
    const box = getEventPosition(location, boxRef.current);
    handleUpdate(box);
  }, [handleUpdate]);
  const [handleMouseDown, handleInteraction] = useMouseMove(handleChange, boxRef.current);
  const handleKeyDown = useCallback(event => {
    onKeyDown?.(event);
    if (isNil(boxRef?.current)) return;
    const {
      height,
      width
    } = boxRef.current.getBoundingClientRect();
    const {
      left,
      top
    } = indicator;
    const heightScale = height / 100;
    const widthScale = width / 100;
    let newLeft = left;
    let newTop = top;
    switch (event.key) {
      case keys.ARROW_DOWN:
        event.preventDefault();
        newTop = top < height ? top + heightScale : height;
        break;
      case keys.ARROW_LEFT:
        event.preventDefault();
        newLeft = left > 0 ? left - widthScale : 0;
        break;
      case keys.ARROW_UP:
        event.preventDefault();
        newTop = top > 0 ? top - heightScale : 0;
        break;
      case keys.ARROW_RIGHT:
        event.preventDefault();
        newLeft = left < width ? left + widthScale : width;
        break;
      default:
        return;
    }
    const newPosition = {
      left: newLeft,
      top: newTop
    };
    setIndicator(newPosition);
    const newColor = calculateColor({
      width,
      height,
      ...newPosition
    });
    onChange(newColor);
  }, [calculateColor, indicator, onChange, onKeyDown]);
  return <div onMouseDown={handleMouseDown} onTouchStart={handleInteraction} onTouchMove={handleInteraction} onKeyDown={handleKeyDown} ref={ref} css={styles.euiSaturation} className={classes} data-test-subj={dataTestSubj} style={{
    background: `hsl(${color[0]}, 100%, 50%)`
  }} tabIndex={-1} {...rest}>
        <div css={styles.euiSaturation__lightness} className="euiSaturation__lightness" ref={boxRef}>
          <div css={styles.euiSaturation__saturation} className="euiSaturation__saturation" />
        </div>
        <button id={indicatorId} css={styles.euiSaturation__indicator} className="euiSaturation__indicator" style={logicalStyles(indicator)} aria-roledescription={roleDescString} aria-label={hex} aria-describedby={instructionsId} />
        <span hidden aria-live="assertive">
          {hex}
        </span>
        <span hidden id={instructionsId}>
          {instructionsString}
        </span>
      </div>;
});
EuiSaturation.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  color: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  hex: PropTypes.string
};
EuiSaturation.displayName = 'EuiSaturation';
try {
  EuiSaturation.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/color_picker/saturation.tsx',
    description: '',
    displayName: 'EuiSaturation',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      color: {
        defaultValue: {
          value: '[1, 0, 0]'
        },
        description: '',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: false,
        type: {
          name: '[number, number, number]'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/saturation.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: true,
        type: {
          name: '(color: [number, number, number]) => void'
        }
      },
      hex: {
        defaultValue: null,
        description: '',
        name: 'hex',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/color_picker/saturation.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'HTMLDivElementOverrides', 'Attributes']
  };
} catch (e) {}