/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { isWithinRange } from '../../../services/number';
import { EuiInputPopover } from '../../popover';
import { htmlIdGenerator, withEuiTheme } from '../../../services/';
import { FormContext } from '../eui_form_context';
import { getLevelColor } from './range_levels_colors';
import { EuiRangeHighlight } from './range_highlight';
import { EuiRangeInput } from './range_input';
import { EuiRangeLabel } from './range_label';
import { EuiRangeSlider } from './range_slider';
import { EuiRangeTooltip } from './range_tooltip';
import { EuiRangeTrack } from './range_track';
import { EuiRangeWrapper } from './range_wrapper';
import { euiRangeStyles } from './range.styles';
import { EuiI18n } from '../../i18n';
export class EuiRangeClass extends Component {
  static contextType = FormContext;
  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    compressed: false,
    isLoading: false,
    showLabels: false,
    showInput: false,
    showRange: false,
    showTicks: false,
    showValue: false,
    levels: []
  };
  preventPopoverClose = false;
  state = {
    id: this.props.id || htmlIdGenerator()(),
    isPopoverOpen: false,
    trackWidth: 0
  };
  handleOnChange = e => {
    const isValid = isWithinRange(this.props.min, this.props.max, e.currentTarget.value);
    if (this.props.onChange) {
      this.props.onChange(e, isValid);
    }
  };
  get isValid() {
    return isWithinRange(this.props.min, this.props.max, this.props.value || '');
  }
  setTrackWidth = ({
    width
  }) => {
    this.setState({
      trackWidth: width
    });
  };
  onInputFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    this.setState({
      isPopoverOpen: true
    });
  };
  onInputBlur = e => setTimeout(() => {
    // Safari does not recognize any focus-related eventing for input[type=range]
    // making it impossible to capture its state using active/focus/relatedTarget
    // Instead, a prevention flag is set on mousedown, with a waiting period here.
    // Mousedown is viable because in the popover case, it is inaccessible via keyboard (intentionally)
    if (this.preventPopoverClose) {
      this.preventPopoverClose = false;
      return;
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    this.closePopover();
  }, 200);
  closePopover = () => {
    this.preventPopoverClose = false;
    this.setState({
      isPopoverOpen: false
    });
  };
  handleAriaValueText = (ticks, currentVal) => {
    const target = ticks.find(tick => tick.value.toString() === currentVal.toString());
    if (target) {
      return target.accessibleLabel ? `${target.value}, (${target.accessibleLabel})` : typeof target.label === 'string' // Fall back to the label if it's a usable string
      ? `${target.value}, (${target.label})` : undefined;
    }
  };
  render() {
    const {
      defaultFullWidth
    } = this.context;
    const {
      className,
      compressed,
      disabled,
      fullWidth = defaultFullWidth,
      isLoading,
      readOnly,
      id: propsId,
      max,
      min,
      name,
      step,
      showLabels,
      showInput,
      inputPopoverProps,
      showTicks,
      tickInterval,
      ticks,
      levels,
      showRange,
      showValue,
      valueAppend,
      valuePrepend,
      onBlur,
      onChange,
      onFocus,
      value,
      tabIndex,
      isInvalid,
      theme,
      ...rest
    } = this.props;
    const {
      id
    } = this.state;
    const showInputOnly = showInput === 'inputWithPopover';
    const canShowDropdown = showInputOnly && !readOnly && !disabled;
    const theInput = !!showInput ? <EuiRangeInput id={id} min={min} max={max} step={step} value={value} readOnly={readOnly} disabled={disabled} compressed={compressed} onChange={this.handleOnChange} name={name} onFocus={canShowDropdown ? this.onInputFocus : onFocus} onBlur={canShowDropdown ? this.onInputBlur : onBlur} fullWidth={showInputOnly && fullWidth} isLoading={showInputOnly && isLoading} isInvalid={isInvalid} autoSize={!showInputOnly} {...rest} /> : null;
    const classes = classNames('euiRange', className);
    const styles = euiRangeStyles(theme);
    const cssStyles = [styles.euiRange, showInput && styles.hasInput];
    const thumbColor = levels && getLevelColor(levels, Number(value));
    const sliderScreenReaderInstructions = <EuiI18n token="euiRange.sliderScreenReaderInstructions" default="You are in a custom range slider. Use the Up and Down arrow keys to change the value." />;
    const theRange = <EuiRangeWrapper className={classes} css={cssStyles} fullWidth={fullWidth} compressed={compressed}>
        {showLabels && <EuiRangeLabel side="min" disabled={disabled}>
            {min}
          </EuiRangeLabel>}
        <EuiRangeTrack trackWidth={this.state.trackWidth} disabled={disabled} compressed={compressed} max={max} min={min} step={step} showTicks={showTicks} tickInterval={tickInterval} ticks={ticks} levels={levels} onChange={this.handleOnChange} value={value} aria-hidden={!!showInput} showRange={showRange}>
          <EuiRangeSlider ariaValueText={ticks ? this.handleAriaValueText(ticks, value) : undefined} id={showInput ? undefined : id} // Attach id only to the input if there is one
        name={name} min={min} max={max} step={step} value={value} disabled={disabled} onChange={this.handleOnChange} showTicks={showTicks} showRange={showRange} tabIndex={showInput ? -1 : tabIndex} onMouseDown={showInputOnly ? () => this.preventPopoverClose = true : undefined} onFocus={showInput === true ? undefined : onFocus} onBlur={showInputOnly ? this.onInputBlur : onBlur} aria-hidden={!!showInput} thumbColor={thumbColor} {...rest} onResize={this.setTrackWidth} />

          {showRange && this.isValid && <EuiRangeHighlight showTicks={showTicks} min={Number(min)} max={Number(max)} lowerValue={Number(min)} upperValue={Number(value)} levels={levels} trackWidth={this.state.trackWidth} />}

          {showValue && !!String(value).length && <EuiRangeTooltip value={value} max={max} min={min} name={name} showTicks={showTicks} valuePrepend={valuePrepend} valueAppend={valueAppend} />}
        </EuiRangeTrack>
        {showLabels && <EuiRangeLabel side="max" disabled={disabled}>
            {max}
          </EuiRangeLabel>}
        {showInput && !showInputOnly && <>
            <div className={showTicks || ticks ? 'euiRange__slimHorizontalSpacer' : 'euiRange__horizontalSpacer'} css={showTicks || ticks ? styles.euiRange__slimHorizontalSpacer : styles.euiRange__horizontalSpacer} />
            {theInput}
          </>}
      </EuiRangeWrapper>;
    const thePopover = showInputOnly ? <EuiInputPopover {...inputPopoverProps} className={classNames('euiRange__popover', inputPopoverProps?.className)} input={theInput} // `showInputOnly` confirms existence
    fullWidth={fullWidth} isOpen={this.state.isPopoverOpen} closePopover={this.closePopover} disableFocusTrap={true} popoverScreenReaderText={sliderScreenReaderInstructions}>
        {theRange}
      </EuiInputPopover> : undefined;
    return thePopover ? thePopover : theRange;
  }
}
EuiRangeClass.propTypes = {
  /**
     * Shows a tooltip styled value
     */
  showValue: PropTypes.bool,
  /**
     * Appends to the tooltip
     */
  valueAppend: PropTypes.node,
  /**
     * Prepends to the tooltip
     */
  valuePrepend: PropTypes.node,
  /**
     * Only impacts the input rendered by the `showInput` prop.
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
     * Only impacts the input rendered by the `showInput` prop.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  onChange: PropTypes.func,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  /**
     * The number to increment or decrement between each interval
     * @default 1
     */
  step: PropTypes.number,
  /**
     * Specified ticks at specified values
     */
  ticks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.node.isRequired,
    accessibleLabel: PropTypes.string
  }).isRequired),
  /**
     * Modifies the number of tick marks and at what interval
     */
  tickInterval: PropTypes.number,
  /**
     * Create colored indicators for certain intervals.
     * An array of #EuiRangeLevel objects
     */
  levels: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Accepts one of `["primary", "success", "warning", "danger"]` or a valid CSS color value.
       */
    color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired),
  /**
     * Pass `true` to displays an extra input control for direct manipulation.
     * Pass `"inputWithPopover"` to only show the input but show the range in a dropdown.
     */
  showInput: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["inputWithPopover"])]),
  /**
     * Shows static min/max labels on the sides of the range slider
     */
  showLabels: PropTypes.bool,
  /**
     * Shows a thick line from min to value
     */
  showRange: PropTypes.bool,
  /**
     * Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)
     */
  showTicks: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  /**
     * Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.
     */
  compressed: PropTypes.bool,
  /**
     * Only impacts inputs rendered by the `showInput` prop.
     * The range slider itself remains interactable unless `disabled` is applied.
     */
  readOnly: PropTypes.bool,
  /**
     * Disables both the range track and any input(s)
     */
  disabled: PropTypes.bool,
  /**
     * Expand to fill 100% of the parent.
     * Defaults to `fullWidth` prop of `<EuiForm>`.
     * @default false
     */
  fullWidth: PropTypes.bool,
  /**
     * Only impacts inputs rendered by the `showInput` prop
     */
  isInvalid: PropTypes.bool,
  /**
     * Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`
     */
  isLoading: PropTypes.bool,
  /**
     * Only impacts input popovers rendered when the `showInput` prop is set to `"inputWithPopover"`
     *
     * Allows customizing the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element),
     * except for props controlled by the range component
     */
  inputPopoverProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    /**
       * Alignment of the popover relative to the input
       */
    anchorPosition: PropTypes.oneOf(["downLeft", "downRight", "downCenter"]),
    /**
       * Allows automatically closing the input popover on page scroll
       */
    closeOnScroll: PropTypes.bool,
    inputRef: PropTypes.any,
    onPanelResize: PropTypes.func,
    /**
       * By default, **EuiInputPopovers** inherit the same width as the passed input element.
       * However, if the input width is too small, you can pass a minimum panel width
       * (that should be based on the popover content).
       */
    panelMinWidth: PropTypes.number
  })
};
export const EuiRange = withEuiTheme(EuiRangeClass);
try {
  EuiRangeClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range.tsx',
    description: '',
    displayName: 'EuiRangeClass',
    methods: [],
    props: {
      showValue: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows a tooltip styled value',
        name: 'showValue',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      valueAppend: {
        defaultValue: null,
        description: 'Appends to the tooltip',
        name: 'valueAppend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      valuePrepend: {
        defaultValue: null,
        description: 'Prepends to the tooltip',
        name: 'valuePrepend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Only impacts the input rendered by the `showInput` prop.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Only impacts the input rendered by the `showInput` prop.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        }],
        required: false,
        type: {
          name: '(event: _SingleRangeChangeEvent, isValid: boolean) => void'
        }
      },
      max: {
        defaultValue: {
          value: '100'
        },
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: {
          value: '0'
        },
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeTick[]'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      levels: {
        defaultValue: {
          value: '[]'
        },
        description: 'Create colored indicators for certain intervals.\n' + 'An array of #EuiRangeLevel objects',
        name: 'levels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeLevel[]'
        }
      },
      showInput: {
        defaultValue: {
          value: 'false'
        },
        description: 'Pass `true` to displays an extra input control for direct manipulation.\n' + 'Pass `"inputWithPopover"` to only show the input but show the range in a dropdown.',
        name: 'showInput',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean | "inputWithPopover"'
        }
      },
      showLabels: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows static min/max labels on the sides of the range slider',
        name: 'showLabels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showRange: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: 'Only impacts inputs rendered by the `showInput` prop',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputPopoverProps: {
        defaultValue: null,
        description: 'Only impacts input popovers rendered when the `showInput` prop is set to `"inputWithPopover"`\n' + '\n' + 'Allows customizing the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element),\n' + 'except for props controlled by the range component',
        name: 'inputPopoverProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'Omit<EuiInputPopoverProps, "input" | "fullWidth" | "isOpen" | "closePopover" | "popoverScreenReaderText" | "disableFocusTrap">'
        }
      },
      theme: {
        defaultValue: null,
        description: '',
        name: 'theme',
        parent: {
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        }],
        required: true,
        type: {
          name: 'UseEuiTheme<{}>'
        }
      }
    },
    extendedInterfaces: ['EuiRangeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', '_SingleRangeValue', '_SharedRangeValuesProps', '_SharedRangeDataStructures', '_SharedRangeVisualConfiguration', '_SharedRangeInputProps', 'WithEuiThemeProps', 'Attributes']
  };
} catch (e) {}
try {
  EuiRange.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range.tsx',
    description: '',
    displayName: 'EuiRange',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        }],
        required: false,
        type: {
          name: '(event: _SingleRangeChangeEvent, isValid: boolean) => void'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      append: {
        defaultValue: null,
        description: 'Only impacts the input rendered by the `showInput` prop.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SingleRangeValue'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Only impacts the input rendered by the `showInput` prop.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      levels: {
        defaultValue: null,
        description: 'Create colored indicators for certain intervals.\n' + 'An array of #EuiRangeLevel objects',
        name: 'levels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeLevel[]'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: 'Only impacts inputs rendered by the `showInput` prop',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputPopoverProps: {
        defaultValue: null,
        description: 'Only impacts input popovers rendered when the `showInput` prop is set to `"inputWithPopover"`\n' + '\n' + 'Allows customizing the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element),\n' + 'except for props controlled by the range component',
        name: 'inputPopoverProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'Omit<EuiInputPopoverProps, "input" | "fullWidth" | "isOpen" | "closePopover" | "popoverScreenReaderText" | "disableFocusTrap">'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeTick[]'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      showInput: {
        defaultValue: null,
        description: 'Pass `true` to displays an extra input control for direct manipulation.\n' + 'Pass `"inputWithPopover"` to only show the input but show the range in a dropdown.',
        name: 'showInput',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean | "inputWithPopover"'
        }
      },
      showLabels: {
        defaultValue: null,
        description: 'Shows static min/max labels on the sides of the range slider',
        name: 'showLabels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showRange: {
        defaultValue: null,
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showValue: {
        defaultValue: null,
        description: 'Shows a tooltip styled value',
        name: 'showValue',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      valueAppend: {
        defaultValue: null,
        description: 'Appends to the tooltip',
        name: 'valueAppend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      valuePrepend: {
        defaultValue: null,
        description: 'Prepends to the tooltip',
        name: 'valuePrepend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiRangeProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['EuiRangeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', '_SingleRangeValue', '_SharedRangeValuesProps', '_SharedRangeDataStructures', '_SharedRangeVisualConfiguration', '_SharedRangeInputProps', 'WithEuiThemeProps', 'Attributes']
  };
} catch (e) {}