import React from 'react';
// Used to generate a "component" that is parsed for its types
// and used to generate a prop table
export var EuiComponentDefaultsProps = () => <></>;

// Used by both getting started and EuiProvider component documentation pages
// Exported in one place for DRYness
export const euiProviderComponentDefaultsSnippet = `<EuiProvider
  componentDefaults={{
    EuiTable: { responsiveBreakpoint: 's', },
    EuiTablePagination: { itemsPerPage: 20, },
    EuiFocusTrap: { crossFrame: true },
    EuiPortal: { insert },
  }}
>
  <App />
</EuiProvider>
`;
try {
  EuiComponentDefaultsProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/provider/provider_component_defaults.tsx',
    description: '',
    displayName: 'EuiComponentDefaultsProps',
    methods: [],
    props: {
      EuiPortal: {
        defaultValue: null,
        description: "Provide a global configuration for EuiPortal's default insertion position.",
        name: 'EuiPortal',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/provider/component_defaults/component_defaults.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Pick<EuiPortalProps, "insert">'
        }
      },
      EuiFocusTrap: {
        defaultValue: null,
        description: "Provide a global configuration for EuiFocusTrap's `gapMode` and `crossFrame` props",
        name: 'EuiFocusTrap',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/provider/component_defaults/component_defaults.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Pick<EuiFocusTrapProps, "gapMode" | "crossFrame">'
        }
      },
      EuiTablePagination: {
        defaultValue: null,
        description: "Provide global settings for EuiTablePagination's props that affect page size\n" + '/ the rows per page selection.\n' + '\n' + 'These defaults will be inherited all table and grid components that utilize EuiTablePagination.',
        name: 'EuiTablePagination',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/provider/component_defaults/component_defaults.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Pick<EuiTablePaginationProps, "showPerPageOptions" | "itemsPerPage" | "itemsPerPageOptions">'
        }
      },
      EuiTable: {
        defaultValue: null,
        description: "Provide a global configuration for EuiTable's `responsiveBreakpoint` prop. Defaults to `'s'`.\n" + '\n' + 'Defaults will be inherited by all `EuiBasicTable`s and `EuiInMemoryTable`s.',
        name: 'EuiTable',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/provider/component_defaults/component_defaults.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Pick<EuiTableProps, "responsiveBreakpoint">'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}