/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import classNames from 'classnames';
import PropTypes from "prop-types";
import React, { createElement } from 'react';
import { EuiTitle } from '../title';
import { EuiStepNumber } from './step_number';
import { useEuiTheme } from '../../services';
import { euiStepStyles, euiStepContentStyles, euiStepTitleStyles } from './step.styles';
export var EuiStep = ({
  className,
  children,
  headingElement = 'p',
  step = 1,
  title,
  titleSize = 's',
  status,
  ...rest
}) => {
  const classes = classNames('euiStep', className);
  const euiTheme = useEuiTheme();
  const styles = euiStepStyles(euiTheme);
  const cssStyles = [styles.euiStep, styles[titleSize]];
  const contentStyles = euiStepContentStyles(euiTheme);
  const cssContentStyles = [contentStyles.euiStep__content, contentStyles[titleSize]];
  const titleStyles = euiStepTitleStyles(euiTheme);
  const cssStepTitleStyles = [titleStyles.euiStep__title, status === 'disabled' && titleStyles.isDisabled, titleStyles[titleSize]];
  const cssTitleWrapperStyles = titleStyles.euiStep__titleWrapper;
  return <div className={classes} css={cssStyles} {...rest}>
      <div className="euiStep__titleWrapper" css={cssTitleWrapperStyles}>
        <EuiStepNumber number={step} status={status} titleSize={titleSize === 'xxs' ? 'none' : titleSize} />
        <EuiTitle size={titleSize === 'xxs' ? 'xs' : titleSize} className="euiStep__title" css={cssStepTitleStyles}>
          {createElement(headingElement, null, title)}
        </EuiTitle>
      </div>

      <div className="euiStep__content" css={cssContentStyles}>
        {children}
      </div>
    </div>;
};
EuiStep.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  /**
     * The HTML tag used for the title
     */
  headingElement: PropTypes.string,
  /**
     * The number of the step in the list of steps
     */
  step: PropTypes.number,
  title: PropTypes.string.isRequired,
  /**
     * May replace the number provided in props.step with alternate styling.
     */
  status: PropTypes.any,
  /**
     * Title sizing equivalent to **EuiTitle**, but only `m`, `s`, `xs` font sizes.
     * The `xxs` size reduces the size of the accompanying step indicator, but not the title itself.
     * @default s
     */
  titleSize: PropTypes.any
};
try {
  EuiStep.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/steps/step.tsx',
    description: '',
    displayName: 'EuiStep',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      headingElement: {
        defaultValue: {
          value: 'p'
        },
        description: 'The HTML tag used for the title',
        name: 'headingElement',
        parent: {
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number of the step in the list of steps',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      status: {
        defaultValue: null,
        description: 'May replace the number provided in props.step with alternate styling.',
        name: 'status',
        parent: {
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"warning" | "danger" | "disabled" | "current" | "loading" | "incomplete" | "complete"',
          value: [{
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"disabled"'
          }, {
            value: '"current"'
          }, {
            value: '"loading"'
          }, {
            value: '"incomplete"'
          }, {
            value: '"complete"'
          }]
        }
      },
      titleSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Title sizing equivalent to **EuiTitle**, but only `m`, `s`, `xs` font sizes.\n' + 'The `xxs` size reduces the size of the accompanying step indicator, but not the title itself.',
        name: 'titleSize',
        parent: {
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step.tsx',
          name: 'EuiStepInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"xxs"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiStepInterface']
  };
} catch (e) {}