const _excluded = ["children", "className", "type", "truncate"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { EuiBreadcrumbContent } from './_breadcrumb_content';
import { euiBreadcrumbStyles } from './breadcrumb.styles';
export var EuiBreadcrumb = function EuiBreadcrumb(_ref) {
  var children = _ref.children,
    className = _ref.className,
    type = _ref.type,
    truncate = _ref.truncate,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiBreadcrumb', className);
  var styles = useEuiMemoizedStyles(euiBreadcrumbStyles);
  var cssStyles = [styles.euiBreadcrumb, styles[type], truncate && styles.isTruncated];
  return ___EmotionJSX("li", _extends({
    className: classes,
    css: cssStyles,
    "data-test-subj": "euiBreadcrumb"
  }, rest), children);
};
export var EuiBreadcrumbCollapsed = ({
  children,
  isFirstBreadcrumb,
  type
}) => {
  const styles = useEuiMemoizedStyles(euiBreadcrumbStyles);
  const cssStyles = [styles.isCollapsed];
  const ariaLabel = useEuiI18n('euiBreadcrumb.collapsedBadge.ariaLabel', 'See collapsed breadcrumbs');
  return <EuiBreadcrumb css={cssStyles} type={type}>
      <EuiBreadcrumbContent popoverContent={children} text={<span aria-label={ariaLabel}>&hellip;</span>} title={ariaLabel} truncate={false} isFirstBreadcrumb={isFirstBreadcrumb} type={type} />
    </EuiBreadcrumb>;
};
try {
  EuiBreadcrumb.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/breadcrumbs/breadcrumb.tsx',
    description: '',
    displayName: 'EuiBreadcrumb',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"application" | "page"',
          value: [{
            value: '"application"'
          }, {
            value: '"page"'
          }]
        }
      },
      truncate: {
        defaultValue: null,
        description: 'Force a max-width on the breadcrumb text',
        name: 'truncate',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}
try {
  EuiBreadcrumbCollapsed.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/breadcrumbs/breadcrumb.tsx',
    description: '',
    displayName: 'EuiBreadcrumbCollapsed',
    methods: [],
    props: {
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"application" | "page"',
          value: [{
            value: '"application"'
          }, {
            value: '"page"'
          }]
        }
      },
      isFirstBreadcrumb: {
        defaultValue: null,
        description: '',
        name: 'isFirstBreadcrumb',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}