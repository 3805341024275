/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import moment from 'moment';
import dateMath from '@elastic/datemath';
import { htmlIdGenerator } from '../../../../services';
import { EuiI18n } from '../../../i18n';
import { EuiScreenReaderOnly } from '../../../accessibility';
import { EuiButton, EuiButtonIcon } from '../../../button';
import { EuiFlexGroup, EuiFlexItem } from '../../../flex';
import { EuiSelect, EuiFieldNumber } from '../../../form';
import { EuiToolTip } from '../../../tool_tip';
import { NEXT } from '../time_options';
import { parseTimeParts } from './quick_select_utils';
import { EuiQuickSelectPanel } from './quick_select_panel';
export class EuiQuickSelect extends Component {
  constructor(props) {
    super(props);
    const {
      timeTense: timeTenseDefault,
      timeUnits: timeUnitsDefault,
      timeValue: timeValueDefault
    } = parseTimeParts(props.start, props.end);
    this.state = {
      timeTense: props.prevQuickSelect && props.prevQuickSelect.timeTense ? props.prevQuickSelect.timeTense : timeTenseDefault,
      timeValue: props.prevQuickSelect && props.prevQuickSelect.timeValue ? props.prevQuickSelect.timeValue : timeValueDefault,
      timeUnits: props.prevQuickSelect && props.prevQuickSelect.timeUnits ? props.prevQuickSelect.timeUnits : timeUnitsDefault
    };
  }
  generateId = htmlIdGenerator();
  timeSelectionId = this.generateId();
  legendId = this.generateId();
  onTimeTenseChange = event => {
    this.setState({
      timeTense: event.target.value
    });
  };
  onTimeValueChange = event => {
    const sanitizedValue = parseInt(event.target.value, 10);
    this.setState({
      timeValue: isNaN(sanitizedValue) ? 0 : sanitizedValue
    });
  };
  onTimeUnitsChange = event => {
    this.setState({
      timeUnits: event.target.value
    });
  };
  handleKeyDown = ({
    key
  }) => {
    if (key === 'Enter') {
      this.applyQuickSelect();
    }
  };
  applyQuickSelect = () => {
    const {
      timeTense,
      timeValue,
      timeUnits
    } = this.state;
    if (timeTense === NEXT) {
      this.props.applyTime({
        start: 'now',
        end: `now+${timeValue}${timeUnits}`,
        quickSelect: {
          ...this.state
        }
      });
      return;
    }
    this.props.applyTime({
      start: `now-${timeValue}${timeUnits}`,
      end: 'now',
      quickSelect: {
        ...this.state
      }
    });
  };
  getBounds = () => {
    const startMoment = dateMath.parse(this.props.start);
    const endMoment = dateMath.parse(this.props.end, {
      roundUp: true
    });
    return {
      min: startMoment && startMoment.isValid() ? startMoment : moment().subtract(15, 'minute'),
      max: endMoment && endMoment.isValid() ? endMoment : moment()
    };
  };
  stepForward = () => {
    const {
      min,
      max
    } = this.getBounds();
    const diff = max.diff(min);
    this.props.applyTime({
      start: moment(max).add(1, 'ms').toISOString(),
      end: moment(max).add(diff + 1, 'ms').toISOString(),
      keepPopoverOpen: true
    });
  };
  stepBackward = () => {
    const {
      min,
      max
    } = this.getBounds();
    const diff = max.diff(min);
    this.props.applyTime({
      start: moment(min).subtract(diff + 1, 'ms').toISOString(),
      end: moment(min).subtract(1, 'ms').toISOString(),
      keepPopoverOpen: true
    });
  };
  render() {
    const {
      timeTense,
      timeValue,
      timeUnits
    } = this.state;
    const {
      timeTenseOptions,
      timeUnitsOptions
    } = this.props.timeOptions;
    const matchedTimeUnit = timeUnitsOptions.find(({
      value
    }) => value === timeUnits);
    const timeUnit = matchedTimeUnit ? matchedTimeUnit.text : '';
    return <EuiQuickSelectPanel component="fieldset" title={<EuiI18n token="euiQuickSelect.quickSelectTitle" default="Quick select" />} titleId={this.legendId} aria-describedby={this.timeSelectionId} css={{
      '> div': {
        position: 'relative',
        overflow: 'visible'
      }
    }}>
        {/* Absolutely position the prev/next arrows in the top right hand corner */}
        <EuiFlexGroup css={{
        position: 'absolute',
        right: '0',
        bottom: '100%',
        transform: 'translateY(-33%)'
      }} alignItems="center" gutterSize="s" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiI18n token="euiQuickSelect.previousLabel" default="Previous time window">
              {previousLabel => <EuiToolTip content={previousLabel}>
                  <EuiButtonIcon aria-label={previousLabel} iconType="arrowLeft" onClick={this.stepBackward} />
                </EuiToolTip>}
            </EuiI18n>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiI18n token="euiQuickSelect.nextLabel" default="Next time window">
              {nextLabel => <EuiToolTip content={nextLabel}>
                  <EuiButtonIcon aria-label={nextLabel} iconType="arrowRight" onClick={this.stepForward} />
                </EuiToolTip>}
            </EuiI18n>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup gutterSize="s" responsive={false}>
          <EuiFlexItem>
            <EuiI18n token="euiQuickSelect.tenseLabel" default="Time tense">
              {tenseLabel => <EuiSelect compressed onKeyDown={this.handleKeyDown} aria-label={tenseLabel} aria-describedby={`${this.timeSelectionId} ${this.legendId}`} value={timeTense} options={timeTenseOptions} onChange={this.onTimeTenseChange} />}
            </EuiI18n>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiI18n token="euiQuickSelect.valueLabel" default="Time value">
              {valueLabel => <EuiFieldNumber compressed onKeyDown={this.handleKeyDown} aria-describedby={`${this.timeSelectionId} ${this.legendId}`} aria-label={valueLabel} value={timeValue} onChange={this.onTimeValueChange} />}
            </EuiI18n>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiI18n token="euiQuickSelect.unitLabel" default="Time unit">
              {unitLabel => <EuiSelect compressed onKeyDown={this.handleKeyDown} aria-label={unitLabel} aria-describedby={`${this.timeSelectionId} ${this.legendId}`} value={timeUnits} options={timeUnitsOptions} onChange={this.onTimeUnitsChange} />}
            </EuiI18n>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton aria-describedby={`${this.timeSelectionId} ${this.legendId}`} data-test-subj="superDatePickerQuickSelectApplyButton" minWidth={0} // Allow the button to shrink
          size="s" onClick={this.applyQuickSelect} disabled={timeValue <= 0}>
              <EuiI18n token="euiQuickSelect.applyButton" default="Apply" />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiScreenReaderOnly>
          <p id={this.timeSelectionId}>
            <EuiI18n token="euiQuickSelect.fullDescription" default="Currently set to {timeTense} {timeValue} {timeUnit}." values={{
            timeTense,
            timeValue,
            timeUnit
          }} />
          </p>
        </EuiScreenReaderOnly>
      </EuiQuickSelectPanel>;
  }
}
EuiQuickSelect.propTypes = {
  applyTime: PropTypes.func.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  prevQuickSelect: PropTypes.shape({
    timeTense: PropTypes.string.isRequired,
    timeValue: PropTypes.number.isRequired,
    timeUnits: PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired
  }),
  timeOptions: PropTypes.shape({
    timeTenseOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    timeUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    relativeOptions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired, PropTypes.oneOf(["s+", "m+", "h+", "d+", "w+", "M+", "y+"]).isRequired]).isRequired
    }).isRequired).isRequired,
    relativeRoundingLabels: PropTypes.any.isRequired,
    refreshUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    commonDurationRanges: PropTypes.arrayOf(PropTypes.shape({
      end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
      label: PropTypes.string,
      start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
    }).isRequired).isRequired
  }).isRequired
};
try {
  EuiQuickSelect.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
    description: '',
    displayName: 'EuiQuickSelect',
    methods: [],
    props: {
      applyTime: {
        defaultValue: null,
        description: '',
        name: 'applyTime',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        }],
        required: true,
        type: {
          name: 'ApplyTime'
        }
      },
      start: {
        defaultValue: null,
        description: '',
        name: 'start',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      end: {
        defaultValue: null,
        description: '',
        name: 'end',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      prevQuickSelect: {
        defaultValue: null,
        description: '',
        name: 'prevQuickSelect',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        }],
        required: false,
        type: {
          name: 'QuickSelect'
        }
      },
      timeOptions: {
        defaultValue: null,
        description: '',
        name: 'timeOptions',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select.tsx',
          name: 'EuiQuickSelectProps'
        }],
        required: true,
        type: {
          name: 'TimeOptions'
        }
      }
    },
    extendedInterfaces: ['EuiQuickSelectProps']
  };
} catch (e) {}