/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { getSecureRelForTarget, useEuiMemoizedStyles } from '../../services';
import { validateHref } from '../../services/security/href_validator';
import { EuiExternalLinkIcon } from './external_link_icon';
import { euiLinkStyles } from './link.styles';
export var COLORS = ['primary', 'subdued', 'success', 'accent', 'danger', 'warning', 'text', 'ghost'];
var EuiLink = forwardRef(({
  children,
  color = 'primary',
  className,
  href,
  external,
  target,
  rel,
  type = 'button',
  onClick,
  disabled: _disabled,
  ...rest
}, ref) => {
  const styles = useEuiMemoizedStyles(euiLinkStyles);
  const cssStyles = [styles.euiLink];
  const isHrefValid = !href || validateHref(href);
  const disabled = _disabled || !isHrefValid;
  if (href === undefined || !isHrefValid) {
    const buttonProps = {
      className: classNames('euiLink', className),
      css: [cssStyles, disabled ? [styles.disabled] : styles[color]],
      type,
      onClick,
      disabled,
      ...rest
    };
    return <button ref={ref} {...buttonProps}>
          {children}
        </button>;
  }
  const secureRel = getSecureRelForTarget({
    href,
    target,
    rel
  });
  const anchorProps = {
    className: classNames('euiLink', className),
    css: [cssStyles, styles[color]],
    href,
    target,
    rel: secureRel,
    onClick,
    ...rest
  };
  return <a ref={ref} {...anchorProps}>
        {children}
        <EuiExternalLinkIcon external={external} target={target} />
      </a>;
});
EuiLink.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  type: PropTypes.oneOf(["button", "reset", "submit"]),
  /**
     * Any of our named colors.
     */
  /**
     * Any of our named colors.
     */
  color: PropTypes.any,
  onClick: PropTypes.func,
  /**
     * Set to true to show an icon indicating that it is an external link;
     * Defaults to true if `target="_blank"`
     */
  external: PropTypes.bool
};
EuiLink.displayName = 'EuiLink';
export { EuiLink };
try {
  EuiLink.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/link/link.tsx',
    description: '',
    displayName: 'EuiLink',
    methods: [],
    props: {
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'eui/src/components/link/link.tsx',
          name: 'EuiLinkAnchorProps'
        },
        declarations: [{
          fileName: 'eui/src/components/link/link.tsx',
          name: 'EuiLinkAnchorProps'
        }, {
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkButtonProps'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLAnchorElement> | MouseEventHandler<HTMLButtonElement>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      type: {
        defaultValue: {
          value: 'button'
        },
        description: '',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        },
        declarations: [{
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        }, {
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkButtonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiLinkType',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'primary'
        },
        description: 'Any of our named colors.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        },
        declarations: [{
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        }, {
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkButtonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "ghost"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      external: {
        defaultValue: null,
        description: 'Set to true to show an icon indicating that it is an external link;\n' + 'Defaults to true if `target="_blank"`',
        name: 'external',
        parent: {
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        },
        declarations: [{
          fileName: 'eui/src/components/link/link.tsx',
          name: 'LinkAnchorProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLAnchorElement | HTMLButtonElement>'
        }
      }
    },
    extendedInterfaces: ['ButtonHTMLAttributes', 'EuiLinkAnchorProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'AnchorHTMLAttributes', 'LinkAnchorProps', 'Attributes']
  };
} catch (e) {}