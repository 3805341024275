/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiTableIsResponsive } from './mobile/responsive_context';
import { euiTableCellCheckboxStyles } from './table_cells_shared.styles';
export var EuiTableRowCellCheckbox = ({
  children,
  className,
  ...rest
}) => {
  const isResponsive = useEuiTableIsResponsive();
  const styles = useEuiMemoizedStyles(euiTableCellCheckboxStyles);
  const cssStyles = [styles.euiTableRowCellCheckbox, isResponsive ? styles.mobile : styles.desktop];
  const classes = classNames('euiTableRowCellCheckbox', className);
  return <td css={cssStyles} className={classes} {...rest}>
      <div className="euiTableCellContent">{children}</div>
    </td>;
};
EuiTableRowCellCheckbox.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTableRowCellCheckbox.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/table_row_cell_checkbox.tsx',
    description: '',
    displayName: 'EuiTableRowCellCheckbox',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'TdHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}