/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, htmlIdGenerator, withEuiTheme } from '../../../services';
import { isWithinRange } from '../../../services/number';
import { logicalStyles } from '../../../global_styling';
import { EuiInputPopover } from '../../popover';
import { EuiFormControlLayoutDelimited } from '../form_control_layout';
import { FormContext } from '../eui_form_context';
import { EuiRangeDraggable } from './range_draggable';
import { EuiRangeHighlight } from './range_highlight';
import { EuiRangeInput } from './range_input';
import { EuiRangeLabel } from './range_label';
import { getLevelColor, euiRangeLevelColor } from './range_levels_colors';
import { EuiRangeSlider } from './range_slider';
import { EuiRangeThumb } from './range_thumb';
import { EuiRangeTrack } from './range_track';
import { EuiRangeWrapper } from './range_wrapper';
import { calculateThumbPosition } from './utils';
import { euiRangeStyles } from './range.styles';
import { euiDualRangeStyles } from './dual_range.styles';
import { EuiI18n } from '../../i18n';
export class EuiDualRangeClass extends Component {
  static contextType = FormContext;
  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    compressed: false,
    isLoading: false,
    showLabels: false,
    showInput: false,
    showRange: true,
    showTicks: false,
    levels: []
  };
  state = {
    id: this.props.id || htmlIdGenerator()(),
    isPopoverOpen: false,
    rangeWidth: 0
  };
  get isInPopover() {
    return this.props.showInput === 'inputWithPopover';
  }
  preventPopoverClose = false;
  leftPosition = 0;
  dragAcc = 0;
  get lowerValue() {
    return this.props.value ? this.props.value[0] : this.props.min;
  }
  get upperValue() {
    return this.props.value ? this.props.value[1] : this.props.max;
  }
  get lowerValueIsValid() {
    return isWithinRange(this.props.min, this.upperValue, this.lowerValue);
  }
  get upperValueIsValid() {
    return isWithinRange(this.lowerValue, this.props.max, this.upperValue);
  }
  get isValid() {
    return this.lowerValueIsValid && this.upperValueIsValid;
  }
  _determineInvalidThumbMovement = (newVal, lower, upper, e) => {
    // If the values are invalid, find whether the new value is in the upper
    // or lower half and move the appropriate handle to the new value,
    // while the other handle gets moved to the opposite bound (if invalid)
    const min = this.props.min;
    const max = this.props.max;
    const lowerHalf = Math.abs(max - min) / 2 + min;
    const newValIsLow = isWithinRange(min, lowerHalf, newVal);
    if (newValIsLow) {
      lower = newVal;
      upper = !this.upperValueIsValid ? max : upper;
    } else {
      lower = !this.lowerValueIsValid ? min : lower;
      upper = newVal;
    }
    this._handleOnChange(lower, upper, e);
  };
  _determineValidThumbMovement = (newVal, lower, upper, e) => {
    // Lower thumb targeted or right-moving swap has occurred
    if (Math.abs(lower - newVal) < Math.abs(upper - newVal)) {
      lower = newVal;
    }
    // Upper thumb targeted or left-moving swap has occurred
    else {
      upper = newVal;
    }
    this._handleOnChange(lower, upper, e);
  };
  _determineThumbMovement = (newVal, e) => {
    // Determine thumb movement based on slider interaction
    if (!this.isValid) {
      // Non-standard positioning follows
      this._determineInvalidThumbMovement(newVal, this.lowerValue, this.upperValue, e);
    } else {
      // Standard positioning based on click event proximity to thumb locations
      this._determineValidThumbMovement(newVal, this.lowerValue, this.upperValue, e);
    }
  };
  _handleOnChange = (lower, upper, e) => {
    const isValid = isWithinRange(this.props.min, upper, lower) && isWithinRange(lower, this.props.max, upper);
    this.props.onChange([lower, upper], isValid, e);
  };
  handleSliderChange = e => {
    this._determineThumbMovement(Number(e.currentTarget.value), e);
  };
  _resetToRangeEnds = e => {
    // Arbitrary decision to pass `min` instead of `max`. Result is the same.
    this._determineInvalidThumbMovement(this.props.min, this.lowerValue, this.upperValue, e);
  };
  _isDirectionalKeyPress = event => {
    return [keys.ARROW_UP, keys.ARROW_RIGHT, keys.ARROW_DOWN, keys.ARROW_LEFT].indexOf(event.key) > -1;
  };
  handleInputKeyDown = e => {
    // Relevant only when initial values are both `''` and `showInput` is set
    if (this._isDirectionalKeyPress(e) && !this.isValid) {
      e.preventDefault();
      this._resetToRangeEnds(e);
    }
  };
  handleLowerInputChange = e => {
    this._handleOnChange(e.target.value, this.upperValue, e);
  };
  handleUpperInputChange = e => {
    this._handleOnChange(this.lowerValue, e.target.value, e);
  };
  _handleKeyDown = (value, event) => {
    let newVal = Number(value);
    let stepRemainder = 0;
    const step = this.props.step || 1;
    const min = this.props.min;
    switch (event.key) {
      case keys.ARROW_UP:
      case keys.ARROW_RIGHT:
        event.preventDefault();
        newVal += step;
        stepRemainder = (newVal - min) % step;
        if (step !== 1 && stepRemainder > 0) {
          newVal = newVal - stepRemainder;
        }
        break;
      case keys.ARROW_DOWN:
      case keys.ARROW_LEFT:
        event.preventDefault();
        newVal -= step;
        stepRemainder = (newVal - min) % step;
        if (step !== 1 && stepRemainder > 0) {
          newVal = newVal + (step - stepRemainder);
        }
        break;
    }
    return newVal;
  };
  handleLowerKeyDown = event => {
    let lower = this.lowerValue;
    switch (event.key) {
      case keys.TAB:
        return;
      default:
        if (!this.lowerValueIsValid) {
          // Relevant only when initial value is `''` and `showInput` is not set
          event.preventDefault();
          this._resetToRangeEnds(event);
          return;
        }
        lower = this._handleKeyDown(lower, event);
    }
    if (lower >= Number(this.upperValue) || lower < this.props.min) return;
    this._handleOnChange(lower, this.upperValue, event);
  };
  handleUpperKeyDown = event => {
    let upper = this.upperValue;
    switch (event.key) {
      case keys.TAB:
        return;
      default:
        if (!this.upperValueIsValid) {
          // Relevant only when initial value is `''` and `showInput` is not set
          event.preventDefault();
          this._resetToRangeEnds(event);
          return;
        }
        upper = this._handleKeyDown(upper, event);
    }
    if (upper <= Number(this.lowerValue) || upper > this.props.max) return;
    this._handleOnChange(this.lowerValue, upper, event);
  };
  handleDraggableKeyDown = event => {
    let lower = this.lowerValue;
    let upper = this.upperValue;
    switch (event.key) {
      case keys.TAB:
        return;
      default:
        lower = this._handleKeyDown(lower, event);
        upper = this._handleKeyDown(upper, event);
    }
    if (lower >= Number(this.upperValue) || lower < this.props.min) return;
    if (upper <= Number(this.lowerValue) || upper > this.props.max) return;
    this._handleOnChange(lower, upper, event);
  };
  calculateThumbPositionStyle = (value, width) => {
    const trackWidth = this.isInPopover && !!width ? width : this.state.rangeWidth;
    const position = calculateThumbPosition(value, this.props.min, this.props.max, trackWidth);
    return {
      left: `${position}%`
    };
  };
  onThumbFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };
  onThumbBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };
  onInputFocus = e => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    this.preventPopoverClose = true;
    this.setState({
      isPopoverOpen: true
    });
  };
  onInputBlur = e => setTimeout(() => {
    // Safari does not recognize any focus-related eventing for input[type=range]
    // making it impossible to capture its state using active/focus/relatedTarget
    // Instead, a prevention flag is set on mousedown, with a waiting period here.
    // Mousedown is viable because in the popover case, it is inaccessible via keyboard (intentionally)
    if (this.preventPopoverClose) {
      this.preventPopoverClose = false;
      return;
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    this.closePopover();
  }, 200);
  closePopover = () => {
    this.preventPopoverClose = false;
    this.setState({
      isPopoverOpen: false
    });
  };
  setRangeWidth = ({
    width
  }) => {
    this.setState({
      rangeWidth: width
    });
  };
  getNearestStep = value => {
    const min = this.props.min;
    const max = this.props.max;
    const steps = (this.props.max - this.props.min) / this.props.step;
    const approx = Math.round((value - min) * steps / (max - min)) / steps;
    const bound = Math.min(Math.max(approx, 0), 1);
    const nearest = bound * (max - min) + min;
    return Number(nearest.toPrecision(10)) * 100 / 100;
  };
  handleDrag = (x, isFirstInteraction) => {
    if (isFirstInteraction) {
      this.leftPosition = x;
      this.dragAcc = 0;
    }
    const min = this.props.min;
    const max = this.props.max;
    const lowerValue = Number(this.lowerValue);
    const upperValue = Number(this.upperValue);
    const delta = this.leftPosition - x;
    this.leftPosition = x;
    this.dragAcc = this.dragAcc + delta;
    const percentageOfArea = this.dragAcc / this.state.rangeWidth;
    const percentageOfRange = percentageOfArea * (max - min);
    const newLower = this.getNearestStep(lowerValue - percentageOfRange);
    const newUpper = this.getNearestStep(upperValue - percentageOfRange);
    const noMovement = newLower === lowerValue;
    const isMin = min === lowerValue && min === newLower;
    const isMax = max === upperValue && max === newUpper;
    const isOutOfRange = newLower < min || newUpper > max;
    if (noMovement || isMin || isMax || isOutOfRange) return;
    this._handleOnChange(newLower, newUpper);
    this.dragAcc = 0;
  };
  render() {
    const {
      defaultFullWidth
    } = this.context;
    const {
      className,
      css: customCss,
      compressed,
      disabled,
      fullWidth = defaultFullWidth,
      isLoading,
      readOnly,
      id: propsId,
      max: maxProps,
      min: minProps,
      name,
      step,
      showLabels,
      showInput,
      showTicks,
      tickInterval,
      ticks,
      levels,
      onBlur,
      onChange,
      onFocus,
      showRange,
      value,
      isInvalid,
      append,
      prepend,
      minInputProps,
      maxInputProps,
      inputPopoverProps,
      isDraggable,
      theme,
      ...rest
    } = this.props;
    const min = minProps;
    const max = maxProps;
    const {
      id
    } = this.state;
    const showInputOnly = this.isInPopover;
    const canShowDropdown = showInputOnly && !readOnly && !disabled;
    const rangeStyles = euiRangeStyles(theme);
    const minInput = !!showInput ? <EuiRangeInput
    // Overridable props
    aria-describedby={this.props['aria-describedby']} aria-label={this.props['aria-label']} disabled={disabled} isInvalid={isInvalid} name={`${name}-minValue`} value={this.lowerValue} readOnly={readOnly} {...minInputProps}
    // Non-overridable props
    side="min" min={min} max={this.upperValue === '' ? max : Number(this.upperValue)} step={step} compressed={compressed} autoSize={!showInputOnly} fullWidth={!!showInputOnly && fullWidth} controlOnly={showInputOnly} onChange={event => {
      this.handleLowerInputChange(event);
      minInputProps?.onChange?.(event);
    }} onKeyDown={event => {
      this.handleInputKeyDown(event);
      minInputProps?.onKeyDown?.(event);
    }} onFocus={event => {
      if (canShowDropdown) {
        this.onInputFocus(event);
      } else {
        onFocus?.(event);
      }
      minInputProps?.onFocus?.(event);
    }} onBlur={event => {
      if (canShowDropdown) {
        this.onInputBlur(event);
      } else {
        onBlur?.(event);
      }
      minInputProps?.onBlur?.(event);
    }} onMouseDown={event => {
      if (showInputOnly) {
        this.preventPopoverClose = true;
      }
      minInputProps?.onMouseDown?.(event);
    }} /> : undefined;
    const maxInput = !!showInput ? <EuiRangeInput
    // Overridable props
    aria-describedby={this.props['aria-describedby']} aria-label={this.props['aria-label']} disabled={disabled} isInvalid={isInvalid} name={`${name}-maxValue`} value={this.upperValue} readOnly={readOnly} {...maxInputProps}
    // Non-overridable props
    side="max" min={this.lowerValue === '' ? min : Number(this.lowerValue)} max={max} step={step} compressed={compressed} autoSize={!showInputOnly} fullWidth={!!showInputOnly && fullWidth} controlOnly={showInputOnly} onChange={event => {
      this.handleUpperInputChange(event);
      maxInputProps?.onChange?.(event);
    }} onKeyDown={event => {
      this.handleInputKeyDown(event);
      maxInputProps?.onKeyDown?.(event);
    }} onFocus={event => {
      if (canShowDropdown) {
        this.onInputFocus(event);
      } else {
        onFocus?.(event);
      }
      maxInputProps?.onFocus?.(event);
    }} onBlur={event => {
      if (canShowDropdown) {
        this.onInputBlur(event);
      } else {
        onBlur?.(event);
      }
      maxInputProps?.onBlur?.(event);
    }} onMouseDown={event => {
      if (showInputOnly) {
        this.preventPopoverClose = true;
      }
      maxInputProps?.onMouseDown?.(event);
    }} /> : undefined;
    const classes = classNames('euiDualRange', className);
    const dualRangeStyles = euiDualRangeStyles();
    const cssStyles = [dualRangeStyles.euiDualRange, customCss];
    const leftThumbPosition = this.state.rangeWidth ? this.calculateThumbPositionStyle(Number(this.lowerValue) || min, this.state.rangeWidth) : {
      left: '0'
    };
    const rightThumbPosition = this.state.rangeWidth ? this.calculateThumbPositionStyle(Number(this.upperValue) || max, this.state.rangeWidth) : {
      left: '0'
    };
    const leftThumbColor = levels && getLevelColor(levels, Number(this.lowerValue));
    const rightThumbColor = levels && getLevelColor(levels, Number(this.upperValue));
    const leftThumbStyles = leftThumbColor ? {
      ...leftThumbPosition,
      backgroundColor: euiRangeLevelColor(leftThumbColor, theme.euiTheme)
    } : leftThumbPosition;
    const rightThumbStyles = rightThumbColor ? {
      ...rightThumbPosition,
      backgroundColor: euiRangeLevelColor(rightThumbColor, theme.euiTheme)
    } : rightThumbPosition;
    const dualSliderScreenReaderInstructions = <EuiI18n token="euiDualRange.sliderScreenReaderInstructions" default="You are in a custom range slider. Use the Up and Down arrow keys to change the minimum value. Press Tab to interact with the maximum value." />;
    const theRange = <EuiRangeWrapper css={cssStyles} className={classes} fullWidth={fullWidth} compressed={compressed}>
        {showInput && !showInputOnly && <>
            {minInput}
            <div className={showTicks || ticks ? 'euiRange__slimHorizontalSpacer' : 'euiRange__horizontalSpacer'} css={showTicks || ticks ? rangeStyles.euiRange__slimHorizontalSpacer : rangeStyles.euiRange__horizontalSpacer} />
          </>}
        {showLabels && <EuiRangeLabel side="min" disabled={disabled}>
            {min}
          </EuiRangeLabel>}
        <EuiRangeTrack trackWidth={this.state.rangeWidth} compressed={compressed} disabled={disabled} max={max} min={min} step={step} showTicks={showTicks} tickInterval={tickInterval} ticks={ticks} levels={levels} onChange={this.handleSliderChange} value={value} aria-hidden={showInput === true} showRange={showRange}>
          <EuiRangeSlider className="euiDualRange__slider" css={dualRangeStyles.euiDualRange__slider} id={id} name={name} min={min} max={max} step={step} disabled={disabled} onChange={this.handleSliderChange} showTicks={showTicks} aria-hidden={true} tabIndex={-1} showRange={showRange} onFocus={onFocus} onBlur={onBlur} {...rest} onResize={this.setRangeWidth} />

          {isDraggable && this.isValid && <EuiRangeDraggable min={min} max={max} value={[this.lowerValue, this.upperValue]} disabled={disabled} lowerPosition={leftThumbPosition.left} upperPosition={rightThumbPosition.left} showTicks={showTicks} onChange={this.handleDrag} onFocus={this.onThumbFocus} onBlur={this.onThumbBlur} onKeyDown={this.handleDraggableKeyDown} aria-describedby={showInputOnly ? undefined : this.props['aria-describedby']} aria-label={showInputOnly ? undefined : this.props['aria-label']} />}

          <EuiRangeThumb min={min} max={Number(this.upperValue)} value={this.lowerValue} disabled={disabled} showTicks={showTicks} showInput={!!showInput} onKeyDown={this.handleLowerKeyDown} onFocus={this.onThumbFocus} onBlur={this.onThumbBlur} style={logicalStyles(leftThumbStyles)} aria-describedby={showInputOnly ? undefined : this.props['aria-describedby']} aria-label={showInputOnly ? undefined : this.props['aria-label']} />

          <EuiRangeThumb min={Number(this.lowerValue)} max={max} value={this.upperValue} disabled={disabled} showTicks={showTicks} showInput={!!showInput} onKeyDown={this.handleUpperKeyDown} onFocus={this.onThumbFocus} onBlur={this.onThumbBlur} style={logicalStyles(rightThumbStyles)} aria-describedby={showInputOnly ? undefined : this.props['aria-describedby']} aria-label={showInputOnly ? undefined : this.props['aria-label']} />

          {showRange && this.isValid && <EuiRangeHighlight showTicks={showTicks} min={Number(min)} max={Number(max)} lowerValue={Number(this.lowerValue)} upperValue={Number(this.upperValue)} levels={levels} trackWidth={this.state.rangeWidth} />}
        </EuiRangeTrack>
        {showLabels && <EuiRangeLabel disabled={disabled}>{max}</EuiRangeLabel>}
        {showInput && !showInputOnly && <>
            <div className={showTicks || ticks ? 'euiRange__slimHorizontalSpacer' : 'euiRange__horizontalSpacer'} css={showTicks || ticks ? rangeStyles.euiRange__slimHorizontalSpacer : rangeStyles.euiRange__horizontalSpacer} />
            {maxInput}
          </>}
      </EuiRangeWrapper>;
    const thePopover = showInputOnly ? <EuiInputPopover {...inputPopoverProps} className={classNames('euiDualRange__popover', inputPopoverProps?.className)} input={<EuiFormControlLayoutDelimited startControl={minInput} endControl={maxInput} isDisabled={disabled} fullWidth={fullWidth} compressed={compressed} readOnly={readOnly} append={append} prepend={prepend} isLoading={isLoading} isInvalid={isInvalid} />} fullWidth={fullWidth} isOpen={this.state.isPopoverOpen} closePopover={this.closePopover} disableFocusTrap={true} popoverScreenReaderText={dualSliderScreenReaderInstructions}>
        {theRange}
      </EuiInputPopover> : undefined;
    return thePopover || theRange;
  }
}
EuiDualRangeClass.propTypes = {
  /**
     * Creates a draggble highlighted range area
     */
  isDraggable: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**
     * Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`.
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
     * Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
     * Intended to be used with aria attributes. Some attributes may be overwritten.
     */
  minInputProps: PropTypes.any,
  /**
     *  Intended to be used with aria attributes. Some attributes may be overwritten.
     */
  maxInputProps: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  /**
     * The number to increment or decrement between each interval
     * @default 1
     */
  step: PropTypes.number,
  /**
     * Specified ticks at specified values
     */
  ticks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.node.isRequired,
    accessibleLabel: PropTypes.string
  }).isRequired),
  /**
     * Modifies the number of tick marks and at what interval
     */
  tickInterval: PropTypes.number,
  /**
     * Create colored indicators for certain intervals.
     * An array of #EuiRangeLevel objects
     */
  levels: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Accepts one of `["primary", "success", "warning", "danger"]` or a valid CSS color value.
       */
    color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired),
  /**
     * Pass `true` to displays an extra input control for direct manipulation.
     * Pass `"inputWithPopover"` to only show the input but show the range in a dropdown.
     */
  showInput: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["inputWithPopover"])]),
  /**
     * Shows static min/max labels on the sides of the range slider
     */
  showLabels: PropTypes.bool,
  /**
     * Shows a thick line from min to value
     */
  showRange: PropTypes.bool,
  /**
     * Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)
     */
  showTicks: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  /**
     * Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.
     */
  compressed: PropTypes.bool,
  /**
     * Only impacts inputs rendered by the `showInput` prop.
     * The range slider itself remains interactable unless `disabled` is applied.
     */
  readOnly: PropTypes.bool,
  /**
     * Disables both the range track and any input(s)
     */
  disabled: PropTypes.bool,
  /**
     * Expand to fill 100% of the parent.
     * Defaults to `fullWidth` prop of `<EuiForm>`.
     * @default false
     */
  fullWidth: PropTypes.bool,
  /**
     * Only impacts inputs rendered by the `showInput` prop
     */
  isInvalid: PropTypes.bool,
  /**
     * Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`
     */
  isLoading: PropTypes.bool,
  /**
     * Only impacts input popovers rendered when the `showInput` prop is set to `"inputWithPopover"`
     *
     * Allows customizing the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element),
     * except for props controlled by the range component
     */
  inputPopoverProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    /**
       * Alignment of the popover relative to the input
       */
    anchorPosition: PropTypes.oneOf(["downLeft", "downRight", "downCenter"]),
    /**
       * Allows automatically closing the input popover on page scroll
       */
    closeOnScroll: PropTypes.bool,
    inputRef: PropTypes.any,
    onPanelResize: PropTypes.func,
    /**
       * By default, **EuiInputPopovers** inherit the same width as the passed input element.
       * However, if the input width is too small, you can pass a minimum panel width
       * (that should be based on the popover content).
       */
    panelMinWidth: PropTypes.number
  })
};
export const EuiDualRange = withEuiTheme(EuiDualRangeClass);
try {
  EuiDualRangeClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/dual_range.tsx',
    description: '',
    displayName: 'EuiDualRangeClass',
    methods: [],
    props: {
      isDraggable: {
        defaultValue: null,
        description: 'Creates a draggble highlighted range area',
        name: 'isDraggable',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: '(event: FocusEvent<HTMLInputElement, Element> | FocusEvent<HTMLDivElement, Element>) => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: '(event: FocusEvent<HTMLInputElement, Element> | FocusEvent<HTMLDivElement, Element>) => void'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      minInputProps: {
        defaultValue: null,
        description: 'Intended to be used with aria attributes. Some attributes may be overwritten.',
        name: 'minInputProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiRangeInputProps, "fullWidth" | "step" | "max" | "min" | "compressed" | "controlOnly" | "autoSize">>'
        }
      },
      maxInputProps: {
        defaultValue: null,
        description: 'Intended to be used with aria attributes. Some attributes may be overwritten.',
        name: 'maxInputProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiRangeInputProps, "fullWidth" | "step" | "max" | "min" | "compressed" | "controlOnly" | "autoSize">>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        }],
        required: true,
        type: {
          name: '[string | number, string | number]'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        }],
        required: true,
        type: {
          name: '(values: [string | number, string | number], isValid: boolean, event?: _DualRangeChangeEvent) => void'
        }
      },
      max: {
        defaultValue: {
          value: '100'
        },
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: {
          value: '0'
        },
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeTick[]'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      levels: {
        defaultValue: {
          value: '[]'
        },
        description: 'Create colored indicators for certain intervals.\n' + 'An array of #EuiRangeLevel objects',
        name: 'levels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeLevel[]'
        }
      },
      showInput: {
        defaultValue: {
          value: 'false'
        },
        description: 'Pass `true` to displays an extra input control for direct manipulation.\n' + 'Pass `"inputWithPopover"` to only show the input but show the range in a dropdown.',
        name: 'showInput',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean | "inputWithPopover"'
        }
      },
      showLabels: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows static min/max labels on the sides of the range slider',
        name: 'showLabels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showRange: {
        defaultValue: {
          value: 'true'
        },
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: {
          value: 'false'
        },
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: 'Only impacts inputs rendered by the `showInput` prop',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputPopoverProps: {
        defaultValue: null,
        description: 'Only impacts input popovers rendered when the `showInput` prop is set to `"inputWithPopover"`\n' + '\n' + 'Allows customizing the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element),\n' + 'except for props controlled by the range component',
        name: 'inputPopoverProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'Omit<EuiInputPopoverProps, "input" | "fullWidth" | "isOpen" | "closePopover" | "popoverScreenReaderText" | "disableFocusTrap">'
        }
      },
      theme: {
        defaultValue: null,
        description: '',
        name: 'theme',
        parent: {
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        }],
        required: true,
        type: {
          name: 'UseEuiTheme<{}>'
        }
      }
    },
    extendedInterfaces: ['EuiDualRangeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', '_DualRangeValue', '_SharedRangeValuesProps', '_SharedRangeDataStructures', '_SharedRangeVisualConfiguration', '_SharedRangeInputProps', 'WithEuiThemeProps', 'Attributes']
  };
} catch (e) {}
try {
  EuiDualRange.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/dual_range.tsx',
    description: '',
    displayName: 'EuiDualRange',
    methods: [],
    props: {
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        }],
        required: true,
        type: {
          name: '[string | number, string | number]'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: '(event: FocusEvent<HTMLInputElement, Element> | FocusEvent<HTMLDivElement, Element>) => void'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: '(event: FocusEvent<HTMLInputElement, Element> | FocusEvent<HTMLDivElement, Element>) => void'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        }],
        required: true,
        type: {
          name: '(values: [string | number, string | number], isValid: boolean, event?: _DualRangeChangeEvent) => void'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      append: {
        defaultValue: null,
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      levels: {
        defaultValue: null,
        description: 'Create colored indicators for certain intervals.\n' + 'An array of #EuiRangeLevel objects',
        name: 'levels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeLevel[]'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: 'Only impacts inputs rendered by the `showInput` prop',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputPopoverProps: {
        defaultValue: null,
        description: 'Only impacts input popovers rendered when the `showInput` prop is set to `"inputWithPopover"`\n' + '\n' + 'Allows customizing the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element),\n' + 'except for props controlled by the range component',
        name: 'inputPopoverProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'Omit<EuiInputPopoverProps, "input" | "fullWidth" | "isOpen" | "closePopover" | "popoverScreenReaderText" | "disableFocusTrap">'
        }
      },
      isDraggable: {
        defaultValue: null,
        description: 'Creates a draggble highlighted range area',
        name: 'isDraggable',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minInputProps: {
        defaultValue: null,
        description: 'Intended to be used with aria attributes. Some attributes may be overwritten.',
        name: 'minInputProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiRangeInputProps, "fullWidth" | "step" | "max" | "min" | "compressed" | "controlOnly" | "autoSize">>'
        }
      },
      maxInputProps: {
        defaultValue: null,
        description: 'Intended to be used with aria attributes. Some attributes may be overwritten.',
        name: 'maxInputProps',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'EuiDualRangeProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiRangeInputProps, "fullWidth" | "step" | "max" | "min" | "compressed" | "controlOnly" | "autoSize">>'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeTick[]'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      showInput: {
        defaultValue: null,
        description: 'Pass `true` to displays an extra input control for direct manipulation.\n' + 'Pass `"inputWithPopover"` to only show the input but show the range in a dropdown.',
        name: 'showInput',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean | "inputWithPopover"'
        }
      },
      showLabels: {
        defaultValue: null,
        description: 'Shows static min/max labels on the sides of the range slider',
        name: 'showLabels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showRange: {
        defaultValue: null,
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['EuiDualRangeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', '_DualRangeValue', '_SharedRangeValuesProps', '_SharedRangeDataStructures', '_SharedRangeVisualConfiguration', '_SharedRangeInputProps', 'WithEuiThemeProps', 'Attributes']
  };
} catch (e) {}