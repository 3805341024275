import React from 'react';
// Loading `Pagination` directly via !prop-loader doesn't correctly inherit @defaults
export var Pagination = () => <div />;
try {
  Pagination.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/tables/paginated/_props.tsx',
    description: '',
    displayName: 'Pagination',
    methods: [],
    props: {
      pageIndex: {
        defaultValue: null,
        description: 'The current page (zero-based) index',
        name: 'pageIndex',
        parent: {
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        },
        declarations: [{
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      pageSize: {
        defaultValue: {
          value: '10'
        },
        description: 'The maximum number of items that can be shown in a single page.\n' + 'Pass `0` to display the selected "Show all" option and hide the pagination.',
        name: 'pageSize',
        parent: {
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        },
        declarations: [{
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      totalItemCount: {
        defaultValue: null,
        description: 'The total number of items the page is "sliced" of',
        name: 'totalItemCount',
        parent: {
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        },
        declarations: [{
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      pageSizeOptions: {
        defaultValue: {
          value: '[10, 25, 50]'
        },
        description: 'Configures the page size dropdown options.\n' + 'Pass `0` as one of the options to create a "Show all" option.',
        name: 'pageSizeOptions',
        parent: {
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        },
        declarations: [{
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        }],
        required: false,
        type: {
          name: 'number[]'
        }
      },
      showPerPageOptions: {
        defaultValue: {
          value: 'true'
        },
        description: 'Set to false to hide the page size dropdown',
        name: 'showPerPageOptions',
        parent: {
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        },
        declarations: [{
          fileName: 'eui/src/components/basic_table/pagination_bar.tsx',
          name: 'Pagination'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['Pagination']
  };
} catch (e) {}