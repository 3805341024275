import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiTabs, EuiTab } from '../../../../../src/components/tabs';
import { EuiErrorBoundary } from '../../../../../src/components/error_boundary';
import { EuiHorizontalRule } from '../../../../../src/components/horizontal_rule';
import { GuideSectionSnippets } from './guide_section_snippets';
import { GuideSectionExampleCode } from './guide_section_code';
import { GuideSectionPropsTable } from './guide_section_props_table';
import { EuiFlexGroup, EuiFlexItem } from '../../../../../src/components/flex';
export var GuideSectionExampleTabs = ({
  tabs,
  rightSideControl
}) => {
  const [selectedTabId, setSelectedTabId] = useState('');
  const onSelectedTabChanged = id => {
    if (id === selectedTabId) {
      setSelectedTabId('');
    } else {
      setSelectedTabId(id);
    }
  };
  const tabClasses = classNames('guideSectionTabs', {
    'guideSectionTabs--open': selectedTabId
  });
  const renderTabs = () => {
    return <EuiTabs size="s" bottomBorder={false}>
        {tabs.map((tab, index) => {
        const {
          displayName,
          code,
          type,
          name,
          props,
          snippets,
          ...rest
        } = tab;
        return <EuiTab {...rest} className="guideSectionTabs__tab" name={name} onClick={() => onSelectedTabChanged(name)} isSelected={name === selectedTabId} key={index}>
              {tab.displayName}
            </EuiTab>;
      })}
      </EuiTabs>;
  };
  const renderContent = () => {
    if (!selectedTabId) return null;
    const selectedTab = tabs.find(tab => tab.name === selectedTabId);

    // SNIPPET
    if (selectedTab && selectedTab.snippets) {
      return <EuiErrorBoundary>
          <EuiHorizontalRule margin="none" />
          <GuideSectionSnippets snippets={selectedTab.snippets} />
        </EuiErrorBoundary>;
      // SOURCE CODE BLOCK
    } else if (selectedTab && selectedTab.code) {
      return <EuiErrorBoundary>
          <EuiHorizontalRule margin="none" />
          <GuideSectionExampleCode code={selectedTab.code} type={selectedTab.type} />
        </EuiErrorBoundary>;
      // PROPS TABLE
    } else if (selectedTab && selectedTab.props) {
      const components = Object.keys(selectedTab.props);
      return components.map(component => <EuiErrorBoundary key={component}>
          <EuiHorizontalRule margin="none" />
          <GuideSectionPropsTable key={component} componentName={component} component={selectedTab.props[component]} />
        </EuiErrorBoundary>);
    }
  };
  return <>
      <EuiFlexGroup className={tabClasses} responsive={false} wrap gutterSize="none" alignItems="center">
        <EuiFlexItem>{renderTabs()}</EuiFlexItem>
        <EuiFlexItem grow={false}>{rightSideControl}</EuiFlexItem>
      </EuiFlexGroup>
      {selectedTabId && renderContent()}
    </>;
};
GuideSectionExampleTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    /**
       * Places content before the tab content/children.
       * Will be excluded from interactive effects.
       */
    prepend: PropTypes.node,
    /**
       * Places content after the tab content/children.
       * Will be excluded from interactive effects.
       */
    append: PropTypes.node,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    code: PropTypes.any,
    type: PropTypes.string,
    snippets: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]),
    props: PropTypes.any,
    displayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired).isRequired,
  /** Renders any content to the right of the tabs (playground toggle) */rightSideControl: PropTypes.node
};
try {
  GuideSectionExampleTabs.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_tabs.tsx',
    description: '',
    displayName: 'GuideSectionExampleTabs',
    methods: [],
    props: {
      tabs: {
        defaultValue: null,
        description: '',
        name: 'tabs',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'GuideSectionExampleTabType[]'
        }
      },
      rightSideControl: {
        defaultValue: null,
        description: 'Renders any content to the right of the tabs (playground toggle)',
        name: 'rightSideControl',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}