/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useIsEuiTableResponsive, EuiTableIsResponsiveContext } from './mobile/responsive_context';
import { euiTableStyles } from './table.styles';
export var EuiTable = ({
  children,
  className,
  compressed,
  tableLayout = 'fixed',
  responsiveBreakpoint,
  // Default handled by `useIsEuiTableResponsive`
  ...rest
}) => {
  const isResponsive = useIsEuiTableResponsive(responsiveBreakpoint);
  const classes = classNames('euiTable', className);
  const styles = useEuiMemoizedStyles(euiTableStyles);
  const cssStyles = [styles.euiTable, styles.layout[tableLayout], (!compressed || isResponsive) && styles.uncompressed, compressed && !isResponsive && styles.compressed, isResponsive ? styles.mobile : styles.desktop];
  return <table tabIndex={-1} css={cssStyles} className={classes} {...rest}>
      <EuiTableIsResponsiveContext.Provider value={isResponsive}>
        {children}
      </EuiTableIsResponsiveContext.Provider>
    </table>;
};
EuiTable.propTypes = {
  compressed: PropTypes.bool,
  /**
     * Named breakpoint. Below this size, the table will collapse
     * into responsive cards.
     *
     * Pass `false` to never collapse to a mobile view, or inversely,
     * `true` to always render mobile-friendly cards.
     *
     * @default m
     */
  responsiveBreakpoint: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.bool.isRequired]),
  /**
     * Sets the table-layout CSS property
     */
  tableLayout: PropTypes.oneOf(["fixed", "auto"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTable.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/table.tsx',
    description: '',
    displayName: 'EuiTable',
    methods: [],
    props: {
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      responsiveBreakpoint: {
        defaultValue: {
          value: 'm'
        },
        description: 'Named breakpoint. Below this size, the table will collapse\n' + 'into responsive cards.\n' + '\n' + 'Pass `false` to never collapse to a mobile view, or inversely,\n' + '`true` to always render mobile-friendly cards.',
        name: 'responsiveBreakpoint',
        parent: {
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        }],
        required: false,
        type: {
          name: 'string | boolean'
        }
      },
      tableLayout: {
        defaultValue: {
          value: 'fixed'
        },
        description: 'Sets the table-layout CSS property',
        name: 'tableLayout',
        parent: {
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "auto"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"auto"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiTableProps', 'CommonProps', 'TableHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}