/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { cloneElement, forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { euiToolTipAnchorStyles } from './tool_tip.styles';
export var EuiToolTipAnchor = forwardRef(({
  onBlur,
  onFocus,
  onMouseOver,
  onMouseOut,
  id,
  className,
  children,
  display,
  isVisible,
  ...rest
}, ref) => {
  const anchorCss = euiToolTipAnchorStyles();
  const cssStyles = [anchorCss.euiToolTipAnchor, anchorCss[display]];
  const classes = classNames('euiToolTipAnchor', className);
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <span ref={ref} css={cssStyles} {...rest} className={classes} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        {/**
         * Re: jsx-a11y/mouse-events-have-key-events
         * We apply onFocus, onBlur, etc to the children element because that's the element
         * the user will be interacting with, as opposed to the enclosing anchor element.
         * For example, if the inner component is a button and the user tabs to it, we want
         * the enter key to trigger the button. That won't work if the enclosing anchor
         * element has focus.
         */}
        {cloneElement(children, {
        onFocus: e => {
          onFocus();
          children.props.onFocus && children.props.onFocus(e);
        },
        onBlur: e => {
          onBlur();
          children.props.onBlur && children.props.onBlur(e);
        },
        'aria-describedby': isVisible ? classNames(id, children.props['aria-describedby']) : children.props['aria-describedby']
      })}
      </span>
  );
});
EuiToolTipAnchor.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
};
EuiToolTipAnchor.displayName = 'EuiToolTipAnchor';
try {
  EuiToolTipAnchor.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tool_tip/tool_tip_anchor.tsx',
    description: '',
    displayName: 'EuiToolTipAnchor',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: 'The in-view trigger for your tooltip.',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      display: {
        defaultValue: null,
        description: 'Common display alternatives for the anchor wrapper',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_anchor.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_anchor.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      isVisible: {
        defaultValue: null,
        description: '',
        name: 'isVisible',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_anchor.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLSpanElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiToolTipProps', 'Attributes']
  };
} catch (e) {}