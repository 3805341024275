const _excluded = ["text", "truncate", "type", "href", "rel", "onClick", "popoverContent", "popoverProps", "className", "color", "isFirstBreadcrumb", "isLastBreadcrumb", "isOnlyBreadcrumb", "highlightLastBreadcrumb", "truncateLastBreadcrumb"],
  _excluded2 = ["css"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
import "core-js/modules/es.array.concat.js";
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useCallback, forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiInnerText } from '../inner_text';
import { EuiLink } from '../link';
import { EuiPopover } from '../popover';
import { EuiIcon } from '../icon';
import { useEuiI18n } from '../i18n';
import { euiBreadcrumbContentStyles, euiBreadcrumbPopoverStyles } from './_breadcrumb_content.styles';
export var EuiBreadcrumbContent = function EuiBreadcrumbContent(_ref) {
  var text = _ref.text,
    truncate = _ref.truncate,
    type = _ref.type,
    href = _ref.href,
    rel = _ref.rel,
    onClick = _ref.onClick,
    popoverContent = _ref.popoverContent,
    popoverProps = _ref.popoverProps,
    className = _ref.className,
    color = _ref.color,
    isFirstBreadcrumb = _ref.isFirstBreadcrumb,
    isLastBreadcrumb = _ref.isLastBreadcrumb,
    isOnlyBreadcrumb = _ref.isOnlyBreadcrumb,
    highlightLastBreadcrumb = _ref.highlightLastBreadcrumb,
    truncateLastBreadcrumb = _ref.truncateLastBreadcrumb,
    rest = _objectWithoutProperties(_ref, _excluded);
  var isApplication = type === 'application';
  var classes = classNames('euiBreadcrumb__content', className);
  var styles = useEuiMemoizedStyles(euiBreadcrumbContentStyles);
  var cssStyles = [styles.euiBreadcrumb__content, styles[type]];
  if (isApplication) {
    if (isOnlyBreadcrumb) {
      cssStyles.push(styles.applicationStyles.onlyChild);
    } else if (isFirstBreadcrumb) {
      cssStyles.push(styles.applicationStyles.firstChild);
    } else if (isLastBreadcrumb) {
      cssStyles.push(styles.applicationStyles.lastChild);
    }
  }
  var truncationStyles = [truncate && !truncateLastBreadcrumb && styles.isTruncated, truncateLastBreadcrumb && styles.isTruncatedLast];
  var isBreadcrumbWithPopover = !!popoverContent;
  var isInteractiveBreadcrumb = href || onClick;
  var linkColor = color || 'subdued';
  var ariaCurrent = highlightLastBreadcrumb ? 'page' : undefined;
  var interactionStyles = (isInteractiveBreadcrumb || isBreadcrumbWithPopover) && !isApplication && styles.isInteractive;
  return ___EmotionJSX(EuiInnerText, null, function (ref, innerText) {
    var title = innerText === '' ? undefined : innerText;
    var baseProps = {
      ref: ref,
      title: title,
      'aria-current': ariaCurrent,
      className: classes,
      css: [].concat(cssStyles, truncationStyles, [interactionStyles])
    };
    if (isBreadcrumbWithPopover) {
      var _ = baseProps.css,
        popoverButtonProps = _objectWithoutProperties(baseProps, _excluded2);
      return ___EmotionJSX(EuiBreadcrumbPopover, _extends({}, popoverButtonProps, {
        breadcrumbCss: [].concat(cssStyles, [interactionStyles]),
        truncationCss: truncationStyles,
        isLastBreadcrumb: isLastBreadcrumb,
        type: type,
        color: linkColor,
        popoverContent: popoverContent,
        popoverProps: popoverProps
      }, rest), text);
    } else if (isInteractiveBreadcrumb) {
      return ___EmotionJSX(EuiLink, _extends({}, baseProps, {
        color: linkColor,
        onClick: onClick,
        href: href,
        rel: rel
      }, rest), text);
    } else {
      return ___EmotionJSX("span", _extends({}, baseProps, rest), text);
    }
  });
};
EuiBreadcrumbContent.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  href: PropTypes.string,
  rel: PropTypes.string,
  onClick: PropTypes.func,
  /**
       * Visible label of the breadcrumb
       */
  text: PropTypes.node.isRequired,
  /**
       * Force a max-width on the breadcrumb text
       */
  truncate: PropTypes.bool,
  /**
       * @deprecated - if a custom color is wanted, use the `css` prop to pass custom css
       */
  color: PropTypes.any,
  /**
       * Override the existing `aria-current` which defaults to `page` for the last breadcrumb
       */
  "aria-current": PropTypes.any,
  /**
       * Creates a breadcrumb that toggles a popover dialog. Takes any rendered node(s),
       * or a render function that will pass callback allowing you to close the
       * breadcrumb popover from within your popover content.
       *
       * If passed, both `href` and `onClick` will be ignored - the breadcrumb's
       * click behavior should only trigger a popover.
       */
  popoverContent: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired]),
  /**
       * Allows customizing the popover if necessary. Accepts any props that
       * [EuiPopover](/#/layout/popover) accepts, except for props that control state.
       */
  popoverProps: PropTypes.shape({
    /**
       * Alignment of the popover and arrow relative to the button
       */
    anchorPosition: PropTypes.any,
    /**
       * Style and position alteration for arrow-less attachment.
       * Intended for use with inputs as anchors, e.g. EuiInputPopover
       */
    attachToAnchor: PropTypes.bool,
    /**
       * Restrict the popover's position within this element
       */
    container: PropTypes.any,
    /**
       * CSS display type for both the popover and anchor
       */
    display: PropTypes.any,
    /**
       * Object of props passed to EuiFocusTrap
       */
    focusTrapProps: PropTypes.any,
    /**
       * Show arrow indicating to originating button
       */
    hasArrow: PropTypes.bool,
    /**
       * Specifies what element should initially have focus; Can be a DOM
       * node, or a selector string (which will be passed to
       * document.querySelector() to find the DOM node), or a function that
       * returns a DOM node.
       *
       * If not passed, initial focus defaults to the popover panel.
       */
    initialFocus: PropTypes.any,
    /**
       * Passed directly to EuiPortal for DOM positioning. Both properties are
       * required if prop is specified
       */
    insert: PropTypes.shape({
      sibling: PropTypes.any.isRequired,
      position: PropTypes.oneOf(["before", "after"]).isRequired
    }),
    /**
       * Traps tab focus within the popover contents
       */
    ownFocus: PropTypes.bool,
    /**
       * Custom class added to the EuiPanel containing the popover contents
       */
    panelClassName: PropTypes.string,
    /**
       * EuiPanel padding on all sides
       */
    panelPaddingSize: PropTypes.any,
    /**
       * Standard DOM `style` attribute. Passed to the EuiPanel
       */
    panelStyle: PropTypes.any,
    /**
       * Object of props passed to EuiPanel. See #EuiPopoverPanelProps
       */
    panelProps: PropTypes.shape({
      element: PropTypes.oneOf(["div"]),
      /**
         * Padding for all four sides
         */
      paddingSize: PropTypes.any,
      /**
         * Corner border radius
         */
      borderRadius: PropTypes.any,
      /**
         * When true the panel will grow in height to match `EuiFlexItem`
         */
      grow: PropTypes.bool,
      panelRef: PropTypes.any,
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any
    }),
    panelRef: PropTypes.any,
    /**
       * Optional screen reader instructions to announce upon popover open,
       * in addition to EUI's default popover instructions for Escape on close.
       * Useful for popovers that may have additional keyboard capabilities such as
       * arrow navigation.
       */
    popoverScreenReaderText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
    popoverRef: PropTypes.any,
    /**
       * When `true`, the popover's position is re-calculated when the user
       * scrolls, this supports having fixed-position popover anchors. When nesting
       * an `EuiPopover` in a scrollable container, `repositionOnScroll` should be `true`
       */
    repositionOnScroll: PropTypes.bool,
    /**
       * By default, popovers will attempt to position themselves along the initial
       * axis specified. If there is not enough room either vertically or horizontally
       * however, the popover will attempt to reposition itself along the secondary
       * cross axis if there is room there instead.
       *
       * If you do not not want this repositioning to occur (and it is acceptable for
       * the popover to appear offscreen), set this to false to disable this behavior.
       *
       * @default true
       */
    repositionToCrossAxis: PropTypes.bool,
    /**
       * Must be set to true if using `EuiDragDropContext` within a popover,
       * otherwise your nested drag & drop will have incorrect positioning
       *
       * @deprecated - use `usePortal` prop on children `EuiDraggable` components instead.
       */
    hasDragDrop: PropTypes.bool,
    /**
       * By default, popover content inherits the z-index of the anchor
       * component; pass `zIndex` to override
       */
    zIndex: PropTypes.number,
    /**
       * Distance away from the anchor that the popover will render
       */
    offset: PropTypes.number,
    /**
       * Minimum distance between the popover and the bounding container;
       * Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`
       * @default 16
       */
    buffer: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.any.isRequired]),
    /**
       * Element to pass as the child element of the arrow;
       * Use case is typically limited to an accompanying `EuiBeacon`
       */
    arrowChildren: PropTypes.node,
    /**
       * Provide a name to the popover panel
       */
    "aria-label": PropTypes.string,
    /**
       * Alternative option to `aria-label` that takes an `id`.
       * Usually takes the `id` of the popover title
       */
    "aria-labelledby": PropTypes.string,
    /**
       * Function callback for when the popover positon changes
       */
    onPositionChange: PropTypes.func,
    className: PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  type: PropTypes.any.isRequired,
  isFirstBreadcrumb: PropTypes.bool,
  isLastBreadcrumb: PropTypes.bool,
  isOnlyBreadcrumb: PropTypes.bool,
  highlightLastBreadcrumb: PropTypes.bool,
  truncateLastBreadcrumb: PropTypes.bool
};
var EuiBreadcrumbPopover = forwardRef(({
  popoverContent,
  popoverProps,
  color,
  type,
  title,
  'aria-current': ariaCurrent,
  className,
  isLastBreadcrumb,
  breadcrumbCss,
  truncationCss,
  children,
  ...rest
}, ref) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const closePopover = useCallback(() => setIsPopoverOpen(false), []);
  const togglePopover = useCallback(() => setIsPopoverOpen(isOpen => !isOpen), []);
  const popoverAriaLabel = useEuiI18n(
  // This component was moved into another file for organization/dev readability,
  // but we're keeping the i18n token the same as before for consumer consistency
  // eslint-disable-next-line local/i18n
  'euiBreadcrumb.popoverAriaLabel', 'Clicking this button will toggle a popover dialog.');
  const styles = useEuiMemoizedStyles(euiBreadcrumbPopoverStyles);
  const wrapperStyles = [styles.popoverWrapper.euiBreadcrumb__popoverWrapper, !isLastBreadcrumb && styles.popoverWrapper[type]];
  const buttonStyles = [styles.euiBreadcrumb__popoverButton, ...breadcrumbCss];
  const truncationStyles = [styles.euiBreadcrumb__popoverTruncation, ...truncationCss];
  return <EuiPopover {...popoverProps} isOpen={isPopoverOpen} closePopover={closePopover} css={wrapperStyles} button={<EuiLink ref={ref} title={title} aria-current={ariaCurrent} className={className} css={buttonStyles} color={color} onClick={togglePopover} {...rest}>
            <span css={truncationStyles}>{children}</span>
            <EuiIcon type="arrowDown" size="s" aria-label={` - ${popoverAriaLabel}`} />
          </EuiLink>}>
        {typeof popoverContent === 'function' ? popoverContent(closePopover) : popoverContent}
      </EuiPopover>;
});
EuiBreadcrumbPopover.propTypes = {
  breadcrumbCss: PropTypes.any.isRequired,
  truncationCss: PropTypes.any.isRequired
};
EuiBreadcrumbPopover.displayName = 'EuiBreadcrumbPopover';
try {
  EuiBreadcrumbPopover.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/breadcrumbs/_breadcrumb_content.tsx',
    description: '',
    displayName: 'EuiBreadcrumbPopover',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      text: {
        defaultValue: null,
        description: 'Visible label of the breadcrumb',
        name: 'text',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      truncate: {
        defaultValue: null,
        description: 'Force a max-width on the breadcrumb text',
        name: 'truncate',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      color: {
        defaultValue: null,
        description: '@deprecated - if a custom color is wanted, use the `css` prop to pass custom css',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "ghost"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      'aria-current': {
        defaultValue: null,
        description: 'Override the existing `aria-current` which defaults to `page` for the last breadcrumb',
        name: 'aria-current',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "time" | "true" | "false" | "page" | "step" | "location" | "date"'
        }
      },
      popoverContent: {
        defaultValue: null,
        description: 'Creates a breadcrumb that toggles a popover dialog. Takes any rendered node(s),\n' + 'or a render function that will pass callback allowing you to close the\n' + 'breadcrumb popover from within your popover content.\n' + '\n' + "If passed, both `href` and `onClick` will be ignored - the breadcrumb's\n" + 'click behavior should only trigger a popover.',
        name: 'popoverContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode | ((closePopover: () => void) => ReactNode)'
        }
      },
      popoverProps: {
        defaultValue: null,
        description: 'Allows customizing the popover if necessary. Accepts any props that\n' + '[EuiPopover](/#/layout/popover) accepts, except for props that control state.',
        name: 'popoverProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Omit<EuiPopoverProps, "button" | "isOpen" | "closePopover">'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"application" | "page"',
          value: [{
            value: '"application"'
          }, {
            value: '"page"'
          }]
        }
      },
      isFirstBreadcrumb: {
        defaultValue: null,
        description: '',
        name: 'isFirstBreadcrumb',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLastBreadcrumb: {
        defaultValue: null,
        description: '',
        name: 'isLastBreadcrumb',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isOnlyBreadcrumb: {
        defaultValue: null,
        description: '',
        name: 'isOnlyBreadcrumb',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      highlightLastBreadcrumb: {
        defaultValue: null,
        description: '',
        name: 'highlightLastBreadcrumb',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      truncateLastBreadcrumb: {
        defaultValue: null,
        description: '',
        name: 'truncateLastBreadcrumb',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}