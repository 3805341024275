/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useLoadingAriaLabel } from './_loading_strings';
import { euiLoadingChartStyles, euiLoadingChartBarStyles, BARS_COUNT } from './loading_chart.styles';
export var SIZES = ['m', 'l', 'xl'];
export var EuiLoadingChart = ({
  size = 'm',
  mono = false,
  className,
  'aria-label': ariaLabel,
  ...rest
}) => {
  const classes = classNames('euiLoadingChart', className);
  const styles = useEuiMemoizedStyles(euiLoadingChartStyles);
  const cssStyles = [styles.euiLoadingChart, styles[size]];
  const barStyles = useEuiMemoizedStyles(euiLoadingChartBarStyles);
  const barCssStyles = [barStyles.euiLoadingChart__bar, mono ? barStyles.mono : barStyles.nonmono, barStyles[size]];
  const defaultAriaLabel = useLoadingAriaLabel();
  return <span className={classes} css={cssStyles} role="progressbar" aria-label={ariaLabel || defaultAriaLabel} {...rest}>
      {Array.from({
      length: BARS_COUNT
    }, (_, index) => <span key={index} css={barCssStyles} />)}
    </span>;
};
EuiLoadingChart.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  size: PropTypes.any,
  mono: PropTypes.bool
};
try {
  EuiLoadingChart.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/loading/loading_chart.tsx',
    description: '',
    displayName: 'EuiLoadingChart',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/loading/loading_chart.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"m" | "l" | "xl"',
          value: [{
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      mono: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'mono',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/loading/loading_chart.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}