/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { RenderWithEuiTheme } from '../../../services';
import { isArray, isNil } from '../../../services/predicate';
import { EuiPopover, EuiPopoverTitle } from '../../popover';
import { EuiFilterButton } from '../../filter_group';
import { euiFilterGroupStyles } from '../../filter_group/filter_group.styles';
import { EuiSelectable } from '../../selectable';
import { Query } from '../query';
import { Operator } from '../query/ast';
var defaults = {
  config: {
    multiSelect: true,
    filterWith: 'prefix',
    searchThreshold: 10
  }
};
export class FieldValueSelectionFilter extends Component {
  constructor(props) {
    super(props);
    const {
      options
    } = props.config;
    const preloadedOptions = isArray(options) ? {
      all: options,
      shown: options
    } : null;
    this.state = {
      popoverOpen: false,
      error: null,
      options: preloadedOptions,
      activeItems: []
    };
  }
  closePopover() {
    this.setState({
      popoverOpen: false
    });
  }
  onButtonClick() {
    this.setState(prevState => {
      if (!prevState.popoverOpen) {
        // loading options updates the state, so we'll do that in the animation frame
        window.requestAnimationFrame(() => {
          this.loadOptions();
        });
      }
      return {
        options: null,
        error: null,
        popoverOpen: !prevState.popoverOpen
      };
    });
  }
  loadOptions() {
    const loader = this.resolveOptionsLoader();
    this.setState({
      options: null,
      error: null
    });
    loader().then(options => {
      const items = {
        on: [],
        off: [],
        rest: []
      };
      const {
        query,
        config
      } = this.props;
      const multiSelect = this.resolveMultiSelect();
      if (options) {
        options.forEach(op => {
          const optionField = op.field || config.field;
          if (optionField) {
            const clause = multiSelect === 'or' ? query.getOrFieldClause(optionField, op.value) : query.getSimpleFieldClause(optionField, op.value);
            const checked = this.resolveChecked(clause);
            if (!checked) {
              items.rest.push(op);
            } else if (checked === 'on') {
              items.on.push(op);
            } else {
              items.off.push(op);
            }
          }
          return;
        });
      }
      this.setState({
        error: null,
        activeItems: items.on,
        options: {
          all: options,
          shown: [...items.on, ...items.off, ...items.rest]
        }
      });
    }).catch(() => {
      this.setState({
        options: null,
        error: 'Could not load options'
      });
    });
  }
  filterOptions(q = '') {
    this.setState(prevState => {
      if (isNil(prevState.options)) {
        return {};
      }
      const predicate = this.getOptionFilter();
      return {
        ...prevState,
        options: {
          ...prevState.options,
          shown: prevState.options.all.filter((option, i, options) => {
            const name = this.resolveOptionName(option).toLowerCase();
            const query = q.toLowerCase();
            return predicate(name, query, options);
          })
        }
      };
    });
  }
  getOptionFilter() {
    const filterWith = this.props.config.filterWith || defaults.config.filterWith;
    if (typeof filterWith === 'function') {
      return filterWith;
    }
    if (filterWith === 'includes') {
      return (name, query) => name.includes(query);
    }
    return (name, query) => name.startsWith(query);
  }
  resolveOptionsLoader = () => {
    const options = this.props.config.options;
    if (isArray(options)) {
      return () => Promise.resolve(options);
    }
    return () => {
      const cachedOptions = this.state.cachedOptions;
      if (cachedOptions) {
        return Promise.resolve(cachedOptions);
      }
      return options().then(opts => {
        // If a cache time is set, populate the cache and also schedule a
        // cache reset.
        if (this.props.config.cache != null && this.props.config.cache > 0) {
          this.setState({
            cachedOptions: opts
          });
          setTimeout(() => {
            this.setState({
              cachedOptions: null
            });
          }, this.props.config.cache);
        }
        return opts;
      });
    };
  };
  resolveOptionName(option) {
    return option.name || option.value.toString();
  }
  onOptionClick(field, value, checked) {
    const multiSelect = this.resolveMultiSelect();
    const {
      config: {
        autoClose,
        operator = Operator.EQ
      }
    } = this.props;

    // If the consumer explicitly sets `autoClose`, always defer to that.
    // Otherwise, default to auto-closing for single selections and leaving the
    // popover open for multi-select (so users can continue selecting options)
    const shouldClosePopover = autoClose ?? !multiSelect;
    if (shouldClosePopover) {
      this.closePopover();
    }
    if (!multiSelect) {
      const query = checked ? this.props.query.removeSimpleFieldClauses(field).addSimpleFieldValue(field, value, true, operator) : this.props.query.removeSimpleFieldClauses(field);
      this.props.onChange(query);
    } else if (multiSelect === 'or') {
      const query = checked ? this.props.query.addOrFieldValue(field, value, true, operator) : this.props.query.removeOrFieldValue(field, value);
      this.props.onChange(query);
    } else {
      const query = checked ? this.props.query.addSimpleFieldValue(field, value, true, operator) : this.props.query.removeSimpleFieldValue(field, value);
      this.props.onChange(query);
    }
  }
  resolveMultiSelect() {
    const {
      config
    } = this.props;
    return !isNil(config.multiSelect) ? config.multiSelect : defaults.config.multiSelect;
  }
  componentDidMount() {
    if (this.props.query.text.length) this.loadOptions();
  }
  componentDidUpdate(prevProps) {
    if (this.props.query !== prevProps.query) this.loadOptions();
  }
  render() {
    const {
      query,
      config
    } = this.props;
    const multiSelect = this.resolveMultiSelect();
    const activeTop = this.isActiveField(config.field);
    const activeItem = this.state.options ? this.state.options.all.some(item => this.isActiveField(item.field)) : false;
    const activeItemsCount = this.state.activeItems.length;
    const active = (activeTop || activeItem) && activeItemsCount > 0;
    const button = <EuiFilterButton iconType="arrowDown" iconSide="right" onClick={this.onButtonClick.bind(this)} hasActiveFilters={active} numActiveFilters={active ? activeItemsCount : undefined} grow>
        {config.name}
      </EuiFilterButton>;
    const items = this.state.options ? this.state.options.shown.map(option => {
      const optionField = option.field || config.field;
      if (optionField == null) {
        throw new Error('option.field or field should be provided in <FieldValueSelectionFilter/>');
      }
      const clause = multiSelect === 'or' ? query.getOrFieldClause(optionField, option.value) : query.getSimpleFieldClause(optionField, option.value);
      const label = this.resolveOptionName(option);
      const checked = this.resolveChecked(clause);
      return {
        label,
        checked,
        data: {
          view: option.view ?? label,
          value: option.value,
          optionField
        }
      };
    }) : [];
    const threshold = config.searchThreshold || defaults.config.searchThreshold;
    const isOverSearchThreshold = this.state.options && this.state.options.all.length >= threshold;
    let searchProps = {
      searchable: false
    };
    if (isOverSearchThreshold) {
      searchProps = {
        searchable: true,
        searchProps: {
          compressed: true,
          disabled: this.state.error != null
        }
      };
    }
    return <RenderWithEuiTheme>
        {euiThemeContext => <EuiPopover button={button} isOpen={this.state.popoverOpen} closePopover={this.closePopover.bind(this)} panelPaddingSize="none" anchorPosition="downCenter" panelProps={{
        css: euiFilterGroupStyles(euiThemeContext).euiFilterGroup__popoverPanel
      }}>
            <EuiSelectable singleSelection={!multiSelect} aria-label={config.name} options={items} renderOption={option => option.view} isLoading={isNil(this.state.options)} loadingMessage={config.loadingMessage} emptyMessage={config.noOptionsMessage} errorMessage={this.state.error} noMatchesMessage={config.noOptionsMessage} listProps={{
          isVirtualized: isOverSearchThreshold || false
        }} onChange={(options, event, changedOption) => {
          if (changedOption.data) {
            this.onOptionClick(changedOption.data.optionField, changedOption.data.value, changedOption.checked);
          }
        }} {...searchProps}>
              {(list, search) => <>
                  {isOverSearchThreshold && <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>}
                  {list}
                </>}
            </EuiSelectable>
          </EuiPopover>}
      </RenderWithEuiTheme>;
  }
  resolveChecked(clause) {
    if (clause) {
      return Query.isMust(clause) ? 'on' : 'off';
    }
  }
  isActiveField(field) {
    if (typeof field !== 'string') {
      return false;
    }
    const {
      query
    } = this.props;
    const multiSelect = this.resolveMultiSelect();
    if (multiSelect === 'or') {
      return query.hasOrFieldClause(field);
    }
    return query.hasSimpleFieldClause(field);
  }
}
FieldValueSelectionFilter.propTypes = {
  index: PropTypes.number.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(["field_value_selection"]).isRequired,
    field: PropTypes.string,
    name: PropTypes.string.isRequired,
    /**
       * See #FieldValueOptionType
       */
    options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired, PropTypes.shape({
        type: PropTypes.oneOf(["date"]).isRequired,
        raw: PropTypes.any.isRequired,
        granularity: PropTypes.oneOfType([PropTypes.shape({
          es: PropTypes.oneOf(["d", "w", "M", "y"]).isRequired,
          js: PropTypes.oneOf(["day", "week", "month", "year"]).isRequired,
          isSame: PropTypes.func.isRequired,
          start: PropTypes.func.isRequired,
          startOfNext: PropTypes.func.isRequired,
          iso8601: PropTypes.func.isRequired
        }).isRequired, PropTypes.oneOf([undefined])]).isRequired,
        text: PropTypes.string.isRequired,
        resolve: PropTypes.func.isRequired
      }).isRequired]).isRequired,
      name: PropTypes.string,
      view: PropTypes.node
    }).isRequired).isRequired, PropTypes.func.isRequired]).isRequired,
    filterWith: PropTypes.oneOfType([PropTypes.oneOf(["prefix", "includes"]), PropTypes.func.isRequired]),
    cache: PropTypes.number,
    multiSelect: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["and", "or"])]),
    loadingMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    searchThreshold: PropTypes.number,
    available: PropTypes.func,
    autoClose: PropTypes.bool,
    operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
  }).isRequired,
  query: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};
try {
  FieldValueSelectionFilter.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
    description: '',
    displayName: 'FieldValueSelectionFilter',
    methods: [],
    props: {
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      config: {
        defaultValue: null,
        description: '',
        name: 'config',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: 'FieldValueSelectionFilterConfigType'
        }
      },
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: 'Query'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_selection_filter.tsx',
          name: 'FieldValueSelectionFilterProps'
        }],
        required: true,
        type: {
          name: '(query: Query) => void'
        }
      }
    },
    extendedInterfaces: ['FieldValueSelectionFilterProps']
  };
} catch (e) {}