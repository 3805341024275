/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { EuiTimelineItem } from './timeline_item';
import { euiTimelineStyles } from './timeline.styles';
export var GUTTER_SIZES = ['m', 'l', 'xl'];
export var EuiTimeline = ({
  className,
  items = [],
  children,
  gutterSize = 'xl',
  ...rest
}) => {
  const classes = classNames('euiTimeline', className);
  const euiTheme = useEuiTheme();
  const styles = euiTimelineStyles(euiTheme);
  const cssStyles = [styles.euiTimeline, styles[gutterSize]];
  return (
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ol className={classes} css={cssStyles} role="list" {...rest}>
      {items.map((item, index) => <EuiTimelineItem key={index} {...item} />)}
      {children}
    </ol>
  );
};
EuiTimeline.propTypes = {
  /**
     * List of timeline items to render. See #EuiTimelineItem
     */
  items: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Vertical alignment of the event with the icon
       */
    verticalAlign: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired),
  /**
     * Sets the size of the vertical space between each timeline item
     */
  gutterSize: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTimeline.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/timeline/timeline.tsx',
    description: '',
    displayName: 'EuiTimeline',
    methods: [],
    props: {
      items: {
        defaultValue: {
          value: '[]'
        },
        description: 'List of timeline items to render. See #EuiTimelineItem',
        name: 'items',
        parent: {
          fileName: 'eui/src/components/timeline/timeline.tsx',
          name: 'EuiTimelineProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline.tsx',
          name: 'EuiTimelineProps'
        }],
        required: false,
        type: {
          name: 'EuiTimelineItemProps[]'
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'xl'
        },
        description: 'Sets the size of the vertical space between each timeline item',
        name: 'gutterSize',
        parent: {
          fileName: 'eui/src/components/timeline/timeline.tsx',
          name: 'EuiTimelineProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline.tsx',
          name: 'EuiTimelineProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"m" | "l" | "xl"',
          value: [{
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiTimelineProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}