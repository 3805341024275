import React from 'react';
import { EuiDataGrid } from '../../../../../src/components';
import { DataGridPropsTable } from '../_props_table';
import { gridSnippets } from '../_snippets';
var gridLinks = {
  rowHeightsOptions: '/tabular-content/data-grid-style-display#row-heights-options',
  gridStyle: '/tabular-content/data-grid-style-display#grid-style',
  inMemory: '/tabular-content/data-grid-advanced#data-grid-in-memory',
  leadingControlColumns: '/tabular-content/data-grid-schema-columns#control-columns',
  trailingControlColumns: '/tabular-content/data-grid-schema-columns#control-columns',
  renderCellPopover: '/tabular-content/data-grid-cells-popovers#completely-customizing-cell-popover-rendering',
  renderFooterCellValue: '/tabular-content/data-grid-schema-columns#footer-row',
  schemaDetectors: '/tabular-content/data-grid-schema-columns#schemas',
  toolbarVisibility: '/tabular-content/data-grid-toolbar#toolbar-visibility',
  ref: '/tabular-content/data-grid-advanced#ref-methods',
  renderCustomGridBody: '/tabular-content/data-grid-advanced#custom-body-renderer',
  cellContext: '/tabular-content/data-grid-cells-popovers#cell-context'
};
export var DataGridTopProps = function DataGridTopProps() {
  return ___EmotionJSX(DataGridPropsTable, {
    component: EuiDataGrid,
    exclude: ['className', 'css', 'data-test-subj', 'aria-label', 'width', 'height', 'minSizeForControls'],
    snippets: gridSnippets,
    links: gridLinks
  });
};

// Loading `EuiDataGridPaginationProps` via !prop-loader doesn't correctly inherit @defaults
export var EuiDataGridPaginationProps = () => <div />;
try {
  EuiDataGridPaginationProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/datagrid/basics/_props.tsx',
    description: '',
    displayName: 'EuiDataGridPaginationProps',
    methods: [],
    props: {
      pageIndex: {
        defaultValue: null,
        description: 'The index of the current page, starts at 0 for the first page',
        name: 'pageIndex',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      pageSize: {
        defaultValue: {
          value: '10'
        },
        description: 'How many rows should initially be shown per page.\n' + 'Pass `0` to display the selected "Show all" option and hide the pagination.',
        name: 'pageSize',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      pageSizeOptions: {
        defaultValue: {
          value: '[10, 25, 50]'
        },
        description: 'An array of page sizes the user can select from.\n' + 'Pass `0` as one of the options to create a "Show all" option.\n' + 'Pass an empty array to hide "Rows per page" select button.',
        name: 'pageSizeOptions',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        }],
        required: false,
        type: {
          name: 'number[]'
        }
      },
      onChangeItemsPerPage: {
        defaultValue: null,
        description: 'A callback for when the user changes the page size selection',
        name: 'onChangeItemsPerPage',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        }],
        required: true,
        type: {
          name: '(itemsPerPage: number) => void'
        }
      },
      onChangePage: {
        defaultValue: null,
        description: 'A callback for when the current page index changes',
        name: 'onChangePage',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridPaginationProps'
        }],
        required: true,
        type: {
          name: '(pageIndex: number) => void'
        }
      }
    },
    extendedInterfaces: ['EuiDataGridPaginationProps']
  };
} catch (e) {}