/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { EuiToolTip } from '../../../tool_tip';
import { EuiIcon } from '../../../icon';
export var tooltipMarkdownRenderer = ({
  content,
  children
}) => {
  return <span>
      <EuiToolTip content={content}>
        <span>
          <strong>{children}</strong>
          <EuiIcon type="questionInCircle"
        // This is to offset the tooltip icon, which isn't perfectly centered
        css={{
          transform: 'translateY(-1px)'
        }} />
        </span>
      </EuiToolTip>
    </span>;
};
tooltipMarkdownRenderer.propTypes = {
  type: PropTypes.oneOf(["tooltipPlugin"]).isRequired,
  content: PropTypes.string.isRequired,
  position: PropTypes.shape({
    start: PropTypes.shape({
      line: PropTypes.number.isRequired,
      column: PropTypes.number.isRequired,
      offset: PropTypes.number.isRequired
    }).isRequired,
    end: PropTypes.shape({
      line: PropTypes.number.isRequired,
      column: PropTypes.number.isRequired,
      offset: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};
try {
  tooltipMarkdownRenderer.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/markdown_editor/plugins/markdown_tooltip/renderer.tsx',
    description: '',
    displayName: 'tooltipMarkdownRenderer',
    methods: [],
    props: {
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_tooltip/types.ts',
          name: 'TooltipNodeDetails'
        },
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_tooltip/types.ts',
          name: 'TooltipNodeDetails'
        }],
        required: true,
        type: {
          name: '"tooltipPlugin"'
        }
      },
      content: {
        defaultValue: null,
        description: '',
        name: 'content',
        parent: {
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_tooltip/types.ts',
          name: 'TooltipNodeDetails'
        },
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_tooltip/types.ts',
          name: 'TooltipNodeDetails'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      position: {
        defaultValue: null,
        description: '',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_tooltip/renderer.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'EuiMarkdownAstNodePosition'
        }
      }
    },
    extendedInterfaces: ['TooltipNodeDetails']
  };
} catch (e) {}