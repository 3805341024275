/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { EuiTimelineItemEvent } from './timeline_item_event';
import { EuiTimelineItemIcon } from './timeline_item_icon';
import { euiTimelineItemStyles } from './timeline_item.styles';
export var VERTICAL_ALIGN = ['top', 'center'];
export var EuiTimelineItem = ({
  children,
  verticalAlign = 'center',
  icon,
  iconAriaLabel,
  ...rest
}) => {
  const styles = euiTimelineItemStyles();
  const cssStyles = [styles.euiTimelineItem, styles[verticalAlign]];
  return <li css={cssStyles} {...rest}>
      <EuiTimelineItemIcon icon={icon} iconAriaLabel={iconAriaLabel} verticalAlign={verticalAlign} />

      <EuiTimelineItemEvent verticalAlign={verticalAlign}>
        {children}
      </EuiTimelineItemEvent>
    </li>;
};
EuiTimelineItem.propTypes = {
  /**
     * Vertical alignment of the event with the icon
     */
  verticalAlign: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTimelineItem.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/timeline/timeline_item.tsx',
    description: '',
    displayName: 'EuiTimelineItem',
    methods: [],
    props: {
      verticalAlign: {
        defaultValue: {
          value: 'center'
        },
        description: 'Vertical alignment of the event with the icon',
        name: 'verticalAlign',
        parent: {
          fileName: 'eui/src/components/timeline/timeline_item.tsx',
          name: 'EuiTimelineItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline_item.tsx',
          name: 'EuiTimelineItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "top"',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      icon: {
        defaultValue: null,
        description: "Any `ReactNode`, but preferably `EuiAvatar`, or a `string` as an `EuiIcon['type']`.",
        name: 'icon',
        parent: {
          fileName: 'eui/src/components/timeline/timeline_item_icon.tsx',
          name: 'EuiTimelineItemIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline_item_icon.tsx',
          name: 'EuiTimelineItemIconProps'
        }],
        required: true,
        type: {
          name: 'string | number | boolean | ReactElement | Iterable<ReactNode> | ReactPortal | ComponentClass<...> | FunctionComponent<...>'
        }
      },
      iconAriaLabel: {
        defaultValue: null,
        description: 'Specify an `aria-label` for the icon when passed as an `IconType`.\n' + 'If no `aria-label` is passed we assume the icon is purely decorative.',
        name: 'iconAriaLabel',
        parent: {
          fileName: 'eui/src/components/timeline/timeline_item_icon.tsx',
          name: 'EuiTimelineItemIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline_item_icon.tsx',
          name: 'EuiTimelineItemIconProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: 'Accepts any node. But preferably `EuiPanel`',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/timeline/timeline_item_event.tsx',
          name: 'EuiTimelineItemEventProps'
        },
        declarations: [{
          fileName: 'eui/src/components/timeline/timeline_item_event.tsx',
          name: 'EuiTimelineItemEventProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['EuiTimelineItemProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'EuiTimelineItemIconProps']
  };
} catch (e) {}