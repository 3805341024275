function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
import PropTypes from "prop-types";
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { useCallback, useEffect, useState } from 'react';
export function useInnerText(innerTextFallback) {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    ref = _useState2[0],
    setRef = _useState2[1];
  var _useState3 = useState(innerTextFallback),
    _useState4 = _slicedToArray(_useState3, 2),
    innerText = _useState4[0],
    setInnerText = _useState4[1];
  var updateInnerText = useCallback(function (node) {
    if (!node) return;
    setInnerText(
    // Check for `innerText` implementation rather than a simple OR check
    // because in real cases the result of `innerText` could correctly be `null`
    // while the result of `textContent` could correctly be non-`null` due to
    // differing reliance on browser layout calculations.
    // We prefer the result of `innerText`, if available.
    'innerText' in node ? node.innerText : node.textContent || innerTextFallback);
  }, [innerTextFallback]);
  useEffect(function () {
    var observer = new MutationObserver(function (mutationsList) {
      if (mutationsList.length) updateInnerText(ref);
    });
    if (ref) {
      updateInnerText(ref);
      observer.observe(ref, {
        characterData: true,
        subtree: true,
        childList: true
      });
    }
    return function () {
      observer.disconnect();
    };
  }, [ref, updateInnerText]);
  return [setRef, innerText];
}
export var EuiInnerText = ({
  children,
  fallback
}) => {
  const [ref, innerText] = useInnerText(fallback);
  return children(ref, innerText);
};
EuiInnerText.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.func.isRequired,
  fallback: PropTypes.string
};
try {
  useInnerText.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/inner_text/inner_text.tsx',
    description: '',
    displayName: 'useInnerText',
    methods: [],
    props: {},
    extendedInterfaces: ['String', 'RelativeIndexable', 'EuiInnerTextProps']
  };
} catch (e) {}
try {
  EuiInnerText.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/inner_text/inner_text.tsx',
    description: '',
    displayName: 'EuiInnerText',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/inner_text/inner_text.tsx',
          name: 'EuiInnerTextProps'
        },
        declarations: [{
          fileName: 'eui/src/components/inner_text/inner_text.tsx',
          name: 'EuiInnerTextProps'
        }],
        required: true,
        type: {
          name: '(ref?: (node: RefT) => void, innerText?: string) => ReactElement'
        }
      },
      fallback: {
        defaultValue: null,
        description: '',
        name: 'fallback',
        parent: {
          fileName: 'eui/src/components/inner_text/inner_text.tsx',
          name: 'EuiInnerTextProps'
        },
        declarations: [{
          fileName: 'eui/src/components/inner_text/inner_text.tsx',
          name: 'EuiInnerTextProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['String', 'RelativeIndexable', 'EuiInnerTextProps']
  };
} catch (e) {}