/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { useIsEuiTableResponsive } from './responsive_context';
import { euiTableHeaderMobileStyles } from './table_header_mobile.styles';
export var EuiTableHeaderMobile = ({
  children,
  className,
  responsiveBreakpoint,
  ...rest
}) => {
  const isResponsive = useIsEuiTableResponsive(responsiveBreakpoint);
  const styles = useEuiMemoizedStyles(euiTableHeaderMobileStyles);
  const classes = classNames('euiTableHeaderMobile', className);
  return isResponsive ? <div className={classes} css={styles.euiTableHeaderMobile} {...rest}>
      {children}
    </div> : null;
};
EuiTableHeaderMobile.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTableHeaderMobile.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/mobile/table_header_mobile.tsx',
    description: '',
    displayName: 'EuiTableHeaderMobile',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      responsiveBreakpoint: {
        defaultValue: {
          value: 'm'
        },
        description: 'Named breakpoint. Below this size, the table will collapse\n' + 'into responsive cards.\n' + '\n' + 'Pass `false` to never collapse to a mobile view, or inversely,\n' + '`true` to always render mobile-friendly cards.',
        name: 'responsiveBreakpoint',
        parent: {
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table.tsx',
          name: 'EuiTableProps'
        }],
        required: false,
        type: {
          name: 'string | boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiTableProps']
  };
} catch (e) {}