/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { EuiButtonIcon } from '../button';
import { euiFlyoutCloseButtonStyles } from './_flyout_close_button.styles';
export var EuiFlyoutCloseButton = ({
  className,
  onClick,
  onClose,
  closeButtonPosition,
  side,
  ...rest
}) => {
  const classes = classNames('euiFlyout__closeButton', className);
  const styles = useEuiMemoizedStyles(euiFlyoutCloseButtonStyles);
  const cssStyles = [styles.euiFlyout__closeButton, styles[closeButtonPosition], closeButtonPosition === 'outside' && styles.outsideSide[side]];
  const ariaLabel = useEuiI18n('euiFlyoutCloseButton.ariaLabel', 'Close this dialog');
  return <EuiButtonIcon display={closeButtonPosition === 'outside' ? 'fill' : 'empty'} iconType="cross" color="text" aria-label={ariaLabel} data-test-subj="euiFlyoutCloseButton" {...rest} className={classes} css={cssStyles} onClick={e => {
    onClose(e.nativeEvent);
    onClick?.(e);
  }} />;
};
try {
  EuiFlyoutCloseButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/flyout/_flyout_close_button.tsx',
    description: '',
    displayName: 'EuiFlyoutCloseButton',
    methods: [],
    props: {
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      iconType: {
        defaultValue: null,
        description: '',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      size: {
        defaultValue: null,
        description: 'Overall size of button.\nMatches the sizes of other EuiButtons',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      iconSize: {
        defaultValue: null,
        description: 'Size of the icon only.\n' + 'This will not affect the overall size of the button',
        name: 'iconSize',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "original" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"original"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      display: {
        defaultValue: null,
        description: 'Sets the display style for matching other EuiButton types.\n' + '`base` is equivalent to a typical EuiButton\n' + '`fill` is equivalent to a filled EuiButton\n' + '`empty` (default) is equivalent to an EuiButtonEmpty',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"base" | "fill" | "empty"',
          value: [{
            value: '"base"'
          }, {
            value: '"fill"'
          }, {
            value: '"empty"'
          }]
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: true,
        type: {
          name: '(event: MouseEvent | KeyboardEvent | TouchEvent) => void'
        }
      },
      closeButtonPosition: {
        defaultValue: {
          value: 'inside'
        },
        description: 'Position of close button.\n' + '`inside`: Floating to just inside the flyout, always top right;\n' + '`outside`: Floating just outside the flyout near the top (side dependent on `side`). Helpful when the close button may cover other interactable content.',
        name: 'closeButtonPosition',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inside" | "outside"',
          value: [{
            value: '"inside"'
          }, {
            value: '"outside"'
          }]
        }
      },
      side: {
        defaultValue: {
          value: 'right'
        },
        description: 'Which side of the window to attach to.\n' + 'The `left` option should only be used for navigation.',
        name: 'side',
        parent: {
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        },
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout.tsx',
          name: '_EuiFlyoutProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      }
    },
    extendedInterfaces: ['EuiButtonIconProps', 'CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', '_EuiFlyoutProps']
  };
} catch (e) {}