/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { EuiResizeObserver } from '../../observer/resize_observer';
import { EuiAccordionChildrenLoading } from './accordion_children_loading';
import { euiAccordionChildrenStyles, euiAccordionChildWrapperStyles } from './accordion_children.styles';
export var EuiAccordionChildren = ({
  role,
  children,
  paddingSize,
  isLoading,
  isLoadingMessage,
  isOpen,
  ...rest
}) => {
  /**
   * Children
   */
  const classes = classNames('euiAccordion__children', {
    'euiAccordion__children-isLoading': isLoading
  });
  const euiTheme = useEuiTheme();
  const styles = euiAccordionChildrenStyles(euiTheme);
  const cssStyles = [styles.euiAccordion__children, isLoading && styles.isLoading, paddingSize && paddingSize !== 'none' && styles[paddingSize]];

  /**
   * Wrapper
   */
  const wrapperStyles = euiAccordionChildWrapperStyles(euiTheme);
  const wrapperCssStyles = [wrapperStyles.euiAccordion__childWrapper, isOpen ? wrapperStyles.isOpen : wrapperStyles.isClosed];

  /**
   * Update the accordion wrapper height whenever the accordion opens, and also
   * whenever the child content updates (which will change the height)
   */
  const [contentHeight, setContentHeight] = useState(0);
  const onResize = useCallback(({
    height
  }) => setContentHeight(Math.round(height)), []);
  const heightInlineStyle = useMemo(() => ({
    blockSize: isOpen ? contentHeight : 0
  }), [isOpen, contentHeight]);
  return <div {...rest} className="euiAccordion__childWrapper" css={wrapperCssStyles} style={heightInlineStyle} role={role}
  // @ts-expect-error - inert property not yet available in React TS defs. TODO: Remove this once https://github.com/DefinitelyTyped/DefinitelyTyped/pull/60822 is merged
  inert={!isOpen ? '' : undefined} // Can't pass a boolean currently, Jest throws errors
  >
      <EuiResizeObserver onResize={onResize}>
        {resizeRef => <div ref={resizeRef} className={classes} css={cssStyles}>
            {isLoading && isLoadingMessage ? <EuiAccordionChildrenLoading isLoadingMessage={isLoadingMessage} /> : children}
          </div>}
      </EuiResizeObserver>
    </div>;
};
EuiAccordionChildren.propTypes = {
  isOpen: PropTypes.bool.isRequired
};
try {
  EuiAccordionChildren.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accordion/accordion_children/accordion_children.tsx',
    description: '',
    displayName: 'EuiAccordionChildren',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Change `extraAction` and children into a loading spinner',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: 'The padding around the exposed accordion content.',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      isLoadingMessage: {
        defaultValue: null,
        description: 'Choose whether the loading message replaces the content. Customize the message by passing a node',
        name: 'isLoadingMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      isOpen: {
        defaultValue: null,
        description: '',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion_children/accordion_children.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}