import React from 'react';
import PropTypes from "prop-types";
export var I18nShapeProps = () => <div />;
I18nShapeProps.propTypes = {
  mapping: PropTypes.shape({}),
  mappingFunc: PropTypes.func,
  /**
     * Some browsers' translation features don't work with a rendered `<Fragment>` component.
     * The `render` function allows you to pass in another component instead, e.g. `<div>`
     */
  render: PropTypes.func,
  formatNumber: PropTypes.func,
  formatDateTime: PropTypes.func,
  locale: PropTypes.string
};
try {
  I18nShapeProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/i18n/props.tsx',
    description: '',
    displayName: 'I18nShapeProps',
    methods: [],
    props: {
      mapping: {
        defaultValue: null,
        description: '',
        name: 'mapping',
        parent: {
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '{ [key: string]: Renderable<object>; }'
        }
      },
      mappingFunc: {
        defaultValue: null,
        description: '',
        name: 'mappingFunc',
        parent: {
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '(value: string) => string'
        }
      },
      render: {
        defaultValue: null,
        description: "Some browsers' translation features don't work with a rendered `<Fragment>` component.\n" + 'The `render` function allows you to pass in another component instead, e.g. `<div>`',
        name: 'render',
        parent: {
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '(children: any) => FunctionComponent<{}>'
        }
      },
      formatNumber: {
        defaultValue: null,
        description: '',
        name: 'formatNumber',
        parent: {
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '(x: number) => string'
        }
      },
      formatDateTime: {
        defaultValue: null,
        description: '',
        name: 'formatDateTime',
        parent: {
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: '(x: Date) => string'
        }
      },
      locale: {
        defaultValue: null,
        description: '',
        name: 'locale',
        parent: {
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        },
        declarations: [{
          fileName: 'eui/src/components/context/context.tsx',
          name: 'I18nShape'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['I18nShape']
  };
} catch (e) {}