/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useCurrentEuiBreakpoint } from '../../services';
export var EuiHideFor = ({
  children,
  sizes
}) => {
  const currentBreakpoint = useCurrentEuiBreakpoint();
  const isWithinBreakpointSizes = currentBreakpoint && sizes.includes(currentBreakpoint);
  if (sizes === 'all' || isWithinBreakpointSizes) {
    return null;
  }
  return <>{children}</>;
};
EuiHideFor.propTypes = {
  /**
     * Required otherwise nothing ever gets returned
     */
  children: PropTypes.node.isRequired,
  /**
     * List of all the responsive sizes to hide the children for.
     * Array of #EuiBreakpointSize
     */
  sizes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any.isRequired).isRequired, PropTypes.oneOf(["all", "none"])]).isRequired
};
try {
  EuiHideFor.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/responsive/hide_for.tsx',
    description: '',
    displayName: 'EuiHideFor',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: 'Required otherwise nothing ever gets returned',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/responsive/hide_for.tsx',
          name: 'EuiHideForProps'
        },
        declarations: [{
          fileName: 'eui/src/components/responsive/hide_for.tsx',
          name: 'EuiHideForProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      sizes: {
        defaultValue: null,
        description: 'List of all the responsive sizes to hide the children for.\n' + 'Array of #EuiBreakpointSize',
        name: 'sizes',
        parent: {
          fileName: 'eui/src/components/responsive/hide_for.tsx',
          name: 'EuiHideForProps'
        },
        declarations: [{
          fileName: 'eui/src/components/responsive/hide_for.tsx',
          name: 'EuiHideForProps'
        }],
        required: true,
        type: {
          name: 'string[] | "none" | "all"'
        }
      }
    },
    extendedInterfaces: ['EuiHideForProps']
  };
} catch (e) {}