/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiIcon } from '../icon';
import { useLoadingAriaLabel } from './_loading_strings';
import { euiLoadingElasticStyles as styles } from './loading_elastic.styles';
export var SIZES = ['m', 'l', 'xl', 'xxl'];
export var EuiLoadingElastic = ({
  size = 'm',
  className,
  'aria-label': ariaLabel,
  ...rest
}) => {
  const classes = classNames('euiLoadingElastic', className);
  const defaultLabel = useLoadingAriaLabel();
  return <span className={classes} css={styles.euiLoadingElastic} role="progressbar" aria-label={ariaLabel || defaultLabel} {...rest}>
      <EuiIcon type="logoElastic" size={size} />
    </span>;
};
EuiLoadingElastic.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  size: PropTypes.any
};
try {
  EuiLoadingElastic.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/loading/loading_elastic.tsx',
    description: '',
    displayName: 'EuiLoadingElastic',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/loading/loading_elastic.tsx',
          name: 'EuiLoadingElasticProps'
        },
        declarations: [{
          fileName: 'eui/src/components/loading/loading_elastic.tsx',
          name: 'EuiLoadingElasticProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"m" | "l" | "xl" | "xxl"',
          value: [{
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"xxl"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiLoadingElasticProps']
  };
} catch (e) {}