/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { euiFlyoutBodyStyles } from './flyout_body.styles';
export var EuiFlyoutBody = ({
  children,
  className,
  banner,
  scrollableTabIndex = 0,
  ...rest
}) => {
  const classes = classNames('euiFlyoutBody', className);
  const styles = useEuiMemoizedStyles(euiFlyoutBodyStyles);
  const overflowCssStyles = [styles.overflow.euiFlyoutBody__overflow, banner ? styles.overflow.hasBanner : styles.overflow.noBanner];
  return <div className={classes} css={styles.euiFlyoutBody} {...rest}>
      <div tabIndex={scrollableTabIndex} className="euiFlyoutBody__overflow" css={overflowCssStyles}>
        {banner && <div className="euiFlyoutBody__banner" css={styles.euiFlyoutBody__banner}>
            {banner}
          </div>}
        <div className="euiFlyoutBody__overflowContent">{children}</div>
      </div>
    </div>;
};
EuiFlyoutBody.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
         * Use to display a banner at the top of the body. It is suggested to use `EuiCallOut` for it.
         */
  banner: PropTypes.node,
  /**
         * [Scrollable regions (or their children) should be focusable](https://dequeuniversity.com/rules/axe/4.0/scrollable-region-focusable)
         * to allow keyboard users to scroll the region via arrow keys.
         *
         * By default, EuiFlyoutBody's scroll overflow wrapper sets a `tabIndex` of `0`.
         * If you know your flyout body content already contains focusable children
         * that satisfy keyboard accessibility requirements, you can use this prop
         * to override this default.
         */
  scrollableTabIndex: PropTypes.number
};
try {
  EuiFlyoutBody.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/flyout/flyout_body.tsx',
    description: '',
    displayName: 'EuiFlyoutBody',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      banner: {
        defaultValue: null,
        description: 'Use to display a banner at the top of the body. It is suggested to use `EuiCallOut` for it.',
        name: 'banner',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout_body.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      scrollableTabIndex: {
        defaultValue: {
          value: '0'
        },
        description: '[Scrollable regions (or their children) should be focusable](https://dequeuniversity.com/rules/axe/4.0/scrollable-region-focusable)\n' + 'to allow keyboard users to scroll the region via arrow keys.\n' + '\n' + "By default, EuiFlyoutBody's scroll overflow wrapper sets a `tabIndex` of `0`.\n" + 'If you know your flyout body content already contains focusable children\n' + 'that satisfy keyboard accessibility requirements, you can use this prop\n' + 'to override this default.',
        name: 'scrollableTabIndex',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flyout/flyout_body.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}