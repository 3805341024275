/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useGeneratedHtmlId } from '../../../../services';
import { useEuiI18n } from '../../../i18n';
import { EuiFlexGroup, EuiFlexItem } from '../../../flex';
import { EuiLink } from '../../../link';
import { PrettyDuration } from '../pretty_duration';
import { EuiQuickSelectPanel } from './quick_select_panel';
export var EuiRecentlyUsed = ({
  applyTime,
  commonlyUsedRanges,
  dateFormat,
  recentlyUsedRanges = []
}) => {
  const title = useEuiI18n('euiRecentlyUsed.legend', 'Recently used date ranges');
  const legendId = useGeneratedHtmlId();
  if (recentlyUsedRanges.length === 0) {
    return null;
  }
  const links = recentlyUsedRanges.map(({
    start,
    end
  }) => {
    const applyRecentlyUsed = () => {
      applyTime({
        start,
        end
      });
    };
    return <EuiFlexItem component="li" key={`${start}-${end}`}>
        <EuiLink onClick={applyRecentlyUsed}>
          <PrettyDuration timeFrom={start} timeTo={end} quickRanges={commonlyUsedRanges} dateFormat={dateFormat} />
        </EuiLink>
      </EuiFlexItem>;
  });
  return <EuiQuickSelectPanel component="fieldset" titleId={legendId} title={title}>
      <EuiFlexGroup component="ul" gutterSize="s" direction="column">
        {links}
      </EuiFlexGroup>
    </EuiQuickSelectPanel>;
};
EuiRecentlyUsed.propTypes = {
  applyTime: PropTypes.func.isRequired,
  commonlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,
  dateFormat: PropTypes.string.isRequired,
  recentlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired)
};
EuiRecentlyUsed.displayName = 'EuiRecentlyUsed';
try {
  EuiRecentlyUsed.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
    description: '',
    displayName: 'EuiRecentlyUsed',
    methods: [],
    props: {
      applyTime: {
        defaultValue: null,
        description: '',
        name: 'applyTime',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        }],
        required: true,
        type: {
          name: 'ApplyTime'
        }
      },
      commonlyUsedRanges: {
        defaultValue: null,
        description: '',
        name: 'commonlyUsedRanges',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        }],
        required: true,
        type: {
          name: 'DurationRange[]'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      recentlyUsedRanges: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'recentlyUsedRanges',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/recently_used.tsx',
          name: 'EuiRecentlyUsedProps'
        }],
        required: false,
        type: {
          name: 'DurationRange[]'
        }
      }
    },
    extendedInterfaces: ['EuiRecentlyUsedProps']
  };
} catch (e) {}