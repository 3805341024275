export var MetricProps = function MetricProps() {
  return null;
};
import PropTypes from "prop-types";
MetricProps.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired, PropTypes.any.isRequired, PropTypes.any.isRequired]).isRequired).isRequired).isRequired
};
export var MetricDatumWText = function MetricDatumWText() {
  return null;
};
export var MetricDatumWNumber = function MetricDatumWNumber() {
  return null;
};
export var MetricDatumWProgress = function MetricDatumWProgress() {
  return null;
};
export var MetricDatumWTrend = () => null;
try {
  MetricProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/metric/metric_chart_props.tsx',
    description: '',
    displayName: 'MetricProps',
    methods: [],
    props: {
      data: {
        defaultValue: null,
        description: '',
        name: 'data',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/metric/metric_chart_props.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(MetricWNumber | MetricWProgress | MetricWText | MetricWTrend)[][]'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  MetricDatumWText.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/metric/metric_chart_props.tsx',
    description: '',
    displayName: 'MetricDatumWText',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      background: {
        defaultValue: null,
        description: 'Overrides background color from `Theme.background` and `Theme.metric.emptyBackground`',
        name: 'background',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueColor: {
        defaultValue: null,
        description: '',
        name: 'valueColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueIcon: {
        defaultValue: null,
        description: '',
        name: 'valueIcon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; verticalAlign: "middle"; }>'
        }
      },
      subtitle: {
        defaultValue: null,
        description: '',
        name: 'subtitle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      extra: {
        defaultValue: null,
        description: '',
        name: 'extra',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; }>'
        }
      },
      body: {
        defaultValue: null,
        description: '',
        name: 'body',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  MetricDatumWNumber.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/metric/metric_chart_props.tsx',
    description: '',
    displayName: 'MetricDatumWNumber',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      background: {
        defaultValue: null,
        description: 'Overrides background color from `Theme.background` and `Theme.metric.emptyBackground`',
        name: 'background',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueColor: {
        defaultValue: null,
        description: '',
        name: 'valueColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueIcon: {
        defaultValue: null,
        description: '',
        name: 'valueIcon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; verticalAlign: "middle"; }>'
        }
      },
      subtitle: {
        defaultValue: null,
        description: '',
        name: 'subtitle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      extra: {
        defaultValue: null,
        description: '',
        name: 'extra',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; }>'
        }
      },
      body: {
        defaultValue: null,
        description: '',
        name: 'body',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      valueFormatter: {
        defaultValue: null,
        description: '',
        name: 'valueFormatter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ValueFormatter'
        }
      },
      targetFormatter: {
        defaultValue: null,
        description: 'Used for header display only, defaults to `valueFormatter`',
        name: 'targetFormatter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ValueFormatter'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  MetricDatumWProgress.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/metric/metric_chart_props.tsx',
    description: '',
    displayName: 'MetricDatumWProgress',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      background: {
        defaultValue: null,
        description: 'Overrides background color from `Theme.background` and `Theme.metric.emptyBackground`',
        name: 'background',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueColor: {
        defaultValue: null,
        description: '',
        name: 'valueColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueIcon: {
        defaultValue: null,
        description: '',
        name: 'valueIcon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; verticalAlign: "middle"; }>'
        }
      },
      subtitle: {
        defaultValue: null,
        description: '',
        name: 'subtitle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      extra: {
        defaultValue: null,
        description: '',
        name: 'extra',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; }>'
        }
      },
      body: {
        defaultValue: null,
        description: '',
        name: 'body',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      valueFormatter: {
        defaultValue: null,
        description: '',
        name: 'valueFormatter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ValueFormatter'
        }
      },
      targetFormatter: {
        defaultValue: null,
        description: 'Used for header display only, defaults to `valueFormatter`',
        name: 'targetFormatter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ValueFormatter'
        }
      },
      domainMax: {
        defaultValue: null,
        description: '',
        name: 'domainMax',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      progressBarDirection: {
        defaultValue: null,
        description: '',
        name: 'progressBarDirection',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'LayoutDirection',
          value: [{
            value: '"horizontal"'
          }, {
            value: '"vertical"'
          }]
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  MetricDatumWTrend.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/metric/metric_chart_props.tsx',
    description: '',
    displayName: 'MetricDatumWTrend',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      background: {
        defaultValue: null,
        description: 'Overrides background color from `Theme.background` and `Theme.metric.emptyBackground`',
        name: 'background',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueColor: {
        defaultValue: null,
        description: '',
        name: 'valueColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      valueIcon: {
        defaultValue: null,
        description: '',
        name: 'valueIcon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; verticalAlign: "middle"; }>'
        }
      },
      subtitle: {
        defaultValue: null,
        description: '',
        name: 'subtitle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      extra: {
        defaultValue: null,
        description: '',
        name: 'extra',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{ width: number; height: number; color: string; }>'
        }
      },
      body: {
        defaultValue: null,
        description: '',
        name: 'body',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      valueFormatter: {
        defaultValue: null,
        description: '',
        name: 'valueFormatter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ValueFormatter'
        }
      },
      targetFormatter: {
        defaultValue: null,
        description: 'Used for header display only, defaults to `valueFormatter`',
        name: 'targetFormatter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ValueFormatter'
        }
      },
      trend: {
        defaultValue: null,
        description: '',
        name: 'trend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '{ x: number; y: number; }[]'
        }
      },
      trendShape: {
        defaultValue: null,
        description: '',
        name: 'trendShape',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'MetricTrendShape',
          value: [{
            value: '"area"'
          }, {
            value: '"bars"'
          }]
        }
      },
      trendA11yTitle: {
        defaultValue: null,
        description: '',
        name: 'trendA11yTitle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      trendA11yDescription: {
        defaultValue: null,
        description: '',
        name: 'trendA11yDescription',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@elastic/charts/dist/chart_types/metric/specs/index.d.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}