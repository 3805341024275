/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { euiBadgeGroupStyles } from './badge_group.styles';
export var GUTTER_SIZES = ['none', 'xs', 's'];
export var EuiBadgeGroup = forwardRef(({
  children,
  className,
  gutterSize = 'xs',
  ...rest
}, ref) => {
  const classes = classNames('euiBadgeGroup', className);
  const styles = useEuiMemoizedStyles(euiBadgeGroupStyles);
  const cssStyles = [styles.euiBadgeGroup, styles[gutterSize]];
  return <div css={cssStyles} className={classes} ref={ref} {...rest}>
        {children}
      </div>;
});
EuiBadgeGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Space between badges
     */
  gutterSize: PropTypes.any,
  /**
     * Should be a list of `EuiBadge`s, but can also be any other element
     */
  children: PropTypes.node
};
EuiBadgeGroup.displayName = 'EuiBadgeGroup';
try {
  EuiBadgeGroup.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/badge/badge_group/badge_group.tsx',
    description: '',
    displayName: 'EuiBadgeGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      children: {
        defaultValue: null,
        description: 'Should be a list of `EuiBadge`s, but can also be any other element',
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/badge/badge_group/badge_group.tsx',
          name: 'EuiBadgeGroupProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'xs'
        },
        description: 'Space between badges',
        name: 'gutterSize',
        parent: {
          fileName: 'eui/src/components/badge/badge_group/badge_group.tsx',
          name: 'EuiBadgeGroupProps'
        },
        declarations: [{
          fileName: 'eui/src/components/badge/badge_group/badge_group.tsx',
          name: 'EuiBadgeGroupProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"xs"'
          }]
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiBadgeGroupProps', 'Attributes']
  };
} catch (e) {}