/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import { EuiButtonEmpty } from '../../button';
import { EuiContextMenuItem, EuiContextMenuPanel } from '../../context_menu';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
import { EuiPagination } from '../../pagination';
import { EuiPopover } from '../../popover';
import { EuiI18n } from '../../i18n';
import { usePropsWithComponentDefaults } from '../../provider/component_defaults';
import { euiTablePaginationDefaults } from './table_pagination_defaults';
export var EuiTablePagination = props => {
  const {
    activePage,
    itemsPerPage = euiTablePaginationDefaults.itemsPerPage,
    itemsPerPageOptions = euiTablePaginationDefaults.itemsPerPageOptions,
    showPerPageOptions = euiTablePaginationDefaults.showPerPageOptions,
    onChangeItemsPerPage,
    onChangePage,
    pageCount,
    ...rest
  } = usePropsWithComponentDefaults('EuiTablePagination', props);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = useCallback(() => {
    setIsPopoverOpen(isOpen => !isOpen);
  }, []);
  const closePopover = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);
  const button = <EuiButtonEmpty size="xs" color="text" iconType="arrowDown" iconSide="right" data-test-subj="tablePaginationPopoverButton" onClick={togglePopover}>
      {itemsPerPage === 0 ? <EuiI18n token="euiTablePagination.allRows" default="Showing all rows" /> : <>
          <EuiI18n token="euiTablePagination.rowsPerPage" default="Rows per page" />
          : {itemsPerPage}
        </>}
    </EuiButtonEmpty>;
  const items = useMemo(() => itemsPerPageOptions.map(itemsPerPageOption => <EuiContextMenuItem key={itemsPerPageOption} icon={itemsPerPageOption === itemsPerPage ? 'check' : 'empty'} aria-current={itemsPerPageOption === itemsPerPage ? 'true' : undefined} onClick={() => {
    closePopover();
    onChangeItemsPerPage?.(itemsPerPageOption);
  }} data-test-subj={`tablePagination-${itemsPerPageOption}-rows`}>
          {itemsPerPageOption === 0 ? <EuiI18n token="euiTablePagination.rowsPerPageOptionShowAllRows" default="Show all rows" /> : <EuiI18n token="euiTablePagination.rowsPerPageOption" values={{
      rowsPerPage: itemsPerPageOption
    }} default="{rowsPerPage} rows" />}
        </EuiContextMenuItem>), [itemsPerPageOptions, itemsPerPage, onChangeItemsPerPage, closePopover]);
  const itemsPerPagePopover = <EuiPopover button={button} isOpen={isPopoverOpen} closePopover={closePopover} panelPaddingSize="none" anchorPosition="upRight">
      <EuiContextMenuPanel items={items} data-test-subj="tablePaginationRowOptions" />
    </EuiPopover>;
  return <EuiFlexGroup justifyContent="spaceBetween" alignItems="center" responsive={false} wrap gutterSize="s" className="eui-xScroll">
      <EuiFlexItem grow={false}>
        {showPerPageOptions && itemsPerPagePopover}
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        {itemsPerPage > 0 && <EuiPagination pageCount={pageCount} activePage={activePage} onPageClick={onChangePage} {...rest} />}
      </EuiFlexItem>
    </EuiFlexGroup>;
};
EuiTablePagination.propTypes = {
  /**
     * Option to completely hide the "Rows per page" selector.
     *
     * @default true
     */
  showPerPageOptions: PropTypes.bool,
  /**
     * Current selection for "Rows per page".
     * Pass `0` to display the selected "Show all" option and hide the pagination.
     *
     * @default 10
     */
  itemsPerPage: PropTypes.number,
  /**
     * Custom array of options for "Rows per page".
     * Pass `0` as one of the options to create a "Show all" option.
     *
     * @default [10, 25, 50]
     */
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  /**
     * Click handler that passes back selected `pageSize` number
     */
  onChangeItemsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,
  /**
     * Requires the `id` of the table being controlled
     */
  "aria-controls": PropTypes.string,
  "aria-label": PropTypes.string
};
try {
  EuiTablePagination.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/table_pagination/table_pagination.tsx',
    description: '',
    displayName: 'EuiTablePagination',
    methods: [],
    props: {
      showPerPageOptions: {
        defaultValue: {
          value: 'true'
        },
        description: 'Option to completely hide the "Rows per page" selector.',
        name: 'showPerPageOptions',
        parent: {
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      itemsPerPage: {
        defaultValue: {
          value: '10'
        },
        description: 'Current selection for "Rows per page".\n' + 'Pass `0` to display the selected "Show all" option and hide the pagination.',
        name: 'itemsPerPage',
        parent: {
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      itemsPerPageOptions: {
        defaultValue: {
          value: '[10, 25, 50]'
        },
        description: 'Custom array of options for "Rows per page".\n' + 'Pass `0` as one of the options to create a "Show all" option.',
        name: 'itemsPerPageOptions',
        parent: {
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        }],
        required: false,
        type: {
          name: 'number[]'
        }
      },
      onChangeItemsPerPage: {
        defaultValue: null,
        description: 'Click handler that passes back selected `pageSize` number',
        name: 'onChangeItemsPerPage',
        parent: {
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        }],
        required: false,
        type: {
          name: 'ItemsPerPageChangeHandler'
        }
      },
      onChangePage: {
        defaultValue: null,
        description: '',
        name: 'onChangePage',
        parent: {
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        }],
        required: false,
        type: {
          name: '(pageIndex: number) => void'
        }
      },
      'aria-controls': {
        defaultValue: null,
        description: 'Requires the `id` of the table being controlled',
        name: 'aria-controls',
        parent: {
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_pagination/table_pagination.tsx',
          name: 'EuiTablePaginationProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      responsive: {
        defaultValue: null,
        description: 'Automatically reduces to the `compressed` version on smaller screens.\n' + 'Remove completely with `false` or provide your own list of responsive breakpoints.',
        name: 'responsive',
        parent: {
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        }],
        required: false,
        type: {
          name: 'false | string[]'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'If true, will only show next/prev arrows and simplified number set.',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      pageCount: {
        defaultValue: null,
        description: 'The total number of pages.\nPass `0` if total count is unknown.',
        name: 'pageCount',
        parent: {
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      activePage: {
        defaultValue: null,
        description: 'The current page using a zero based index.\n' + 'So if you set the activePage to 1, it will activate the second page.\n' + 'Pass `-1` for forcing to last page.',
        name: 'activePage',
        parent: {
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        },
        declarations: [{
          fileName: 'eui/src/components/pagination/pagination.tsx',
          name: 'EuiPaginationProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['EuiTablePaginationProps', 'EuiPaginationProps']
  };
} catch (e) {}