function _EMOTION_STRINGIFIED_CSS_ERROR__() {
  return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
}
import PropTypes from "prop-types";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { findElementBySelectorOrRef } from '../../../services';
import { EuiBottomBar } from '../../bottom_bar';
import { EuiPageSection } from '../../page/page_section';
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1y6dyt1-bar",
  styles: "overflow:hidden;flex-shrink:0;label:bar;"
} : {
  name: "1y6dyt1-bar",
  styles: "overflow:hidden;flex-shrink:0;label:bar;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var _EuiPageBottomBar = ({
  children,
  paddingSize,
  restrictWidth,
  parent,
  ...rest
}) => {
  // In order for the bottom bar to be placed at the end of the content,
  // it must know what parent element to insert into
  const [hasValidAnchor, setHasValidAnchor] = useState(false);
  const animationFrameId = useRef();
  const parentNode = useRef(null);
  useEffect(() => {
    animationFrameId.current = window.requestAnimationFrame(() => {
      parentNode.current = findElementBySelectorOrRef(parent);
      setHasValidAnchor(parentNode.current ? true : false);
    });
    return () => {
      animationFrameId.current && window.cancelAnimationFrame(animationFrameId.current);
    };
  }, [parent]);
  const bar = <EuiBottomBar paddingSize={'none'} position="sticky"
  // Hide the overflow in case of larger flex margins than padding
  css={_ref}
  // Using object here because of the possible conflict with overriding props and position `sticky`
  {...rest}>
      {/* Wrapping the contents with EuiPageContentBody allows us to match the restrictWidth to keep the contents aligned */}
      <EuiPageSection paddingSize={paddingSize} restrictWidth={restrictWidth}>
        {children}
      </EuiPageSection>
    </EuiBottomBar>;
  return hasValidAnchor && parentNode.current ? createPortal(bar, parentNode.current) : bar;
};
_EuiPageBottomBar.propTypes = {
  /**
     * The reference id of the element to insert into
     */
  parent: PropTypes.string,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired])
};
try {
  _EuiPageBottomBar.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/page_template/bottom_bar/page_bottom_bar.tsx',
    description: '',
    displayName: '_EuiPageBottomBar',
    methods: [],
    props: {
      parent: {
        defaultValue: null,
        description: 'The reference id of the element to insert into',
        name: 'parent',
        parent: {
          fileName: 'eui/src/components/page_template/bottom_bar/page_bottom_bar.tsx',
          name: '_EuiPageBottomBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page_template/bottom_bar/page_bottom_bar.tsx',
          name: '_EuiPageBottomBarProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      restrictWidth: {
        defaultValue: null,
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1200px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      left: {
        defaultValue: null,
        description: 'Starting horizontal position when `fixed` position.\n' + 'Has no affect on `static` or `sticky` positions.',
        name: 'left',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Left<string | number>'
        }
      },
      right: {
        defaultValue: null,
        description: 'Ending horizontal position when `fixed` position.\n' + 'Has no affect on `static` or `sticky` positions.',
        name: 'right',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Right<string | number>'
        }
      },
      top: {
        defaultValue: null,
        description: 'Starting vertical position when `fixed` position.\n' + 'Offset from the top of the window when `sticky` position.\n' + 'Has no affect on `static` positions.',
        name: 'top',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Top<string | number>'
        }
      },
      bottom: {
        defaultValue: null,
        description: 'Starting vertical position when `fixed` position.\n' + 'Offset from the bottom of the window when `sticky` position.\n' + 'Has no affect on `static` positions.',
        name: 'bottom',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Bottom<string | number>'
        }
      },
      position: {
        defaultValue: null,
        description: 'How to position the bottom bar against its parent.',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "static" | "sticky"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"static"'
          }, {
            value: '"sticky"'
          }]
        }
      },
      usePortal: {
        defaultValue: null,
        description: 'Whether to wrap in an EuiPortal which appends the component to the body element.\n' + 'Only works if `position` is `fixed`.',
        name: 'usePortal',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | EuiPortalProps'
        }
      },
      affordForDisplacement: {
        defaultValue: null,
        description: 'Whether the component should apply padding on the document body element to afford for its own displacement height.\n' + 'Only works if `usePortal` is true and `position` is `fixed`.',
        name: 'affordForDisplacement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      bodyClassName: {
        defaultValue: null,
        description: 'Optional class applied to the body element on mount.',
        name: 'bodyClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      landmarkHeading: {
        defaultValue: {
          value: 'Page level controls'
        },
        description: 'Customize the screen reader heading that helps users find this control.',
        name: 'landmarkHeading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/bottom_bar/bottom_bar.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['_EuiPageBottomBarProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}