/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useContext } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiDescriptionListContext } from './description_list_context';
import { euiDescriptionListDescriptionStyles } from './description_list_description.styles';

// Export required for correct inference by HOCs

export var EuiDescriptionListDescription = ({
  children,
  className,
  ...rest
}) => {
  const {
    type,
    textStyle,
    compressed,
    align
  } = useContext(EuiDescriptionListContext);
  const styles = useEuiMemoizedStyles(euiDescriptionListDescriptionStyles);
  let conditionalStyles = compressed && textStyle === 'reverse' ? [styles.fontStyles.compressed] : [styles.fontStyles[textStyle]];
  switch (type) {
    case 'inline':
      conditionalStyles = compressed ? [styles.inlineStyles.compressed] : [styles.inlineStyles.normal];
      break;
    case 'column':
      if (align === 'center') {
        conditionalStyles.push(styles.left);
      }
      break;
  }
  const cssStyles = [styles.euiDescriptionList__description, styles[type], ...conditionalStyles];
  const classes = classNames('euiDescriptionList__description', className);
  return <dd className={classes} css={cssStyles} {...rest}>
      {children}
    </dd>;
};
EuiDescriptionListDescription.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiDescriptionListDescription.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/description_list/description_list_description.tsx',
    description: '',
    displayName: 'EuiDescriptionListDescription',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}