/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { memo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiDataGridHeaderCellWrapper } from './data_grid_header_cell_wrapper';
export var EuiDataGridControlHeaderCell = memo(({
  controlColumn,
  index
}) => {
  const {
    headerCellRender: HeaderCellRender,
    headerCellProps,
    width,
    id
  } = controlColumn;
  return <EuiDataGridHeaderCellWrapper {...headerCellProps} className={classNames('euiDataGridHeaderCell--controlColumn', headerCellProps?.className)} id={id} index={index} width={width}>
        <HeaderCellRender />
      </EuiDataGridHeaderCellWrapper>;
});
EuiDataGridControlHeaderCell.propTypes = {
  index: PropTypes.number.isRequired,
  controlColumn: PropTypes.shape({
    /**
       * Used as the React `key` when rendering content
       */
    id: PropTypes.string.isRequired,
    /**
       * Width of the column, users are unable to change this
       */
    width: PropTypes.number.isRequired,
    /**
       * Component to render in the column header
       */
    headerCellRender: PropTypes.elementType.isRequired,
    /**
       * Optional props to pass to the column header cell
       */
    headerCellProps: PropTypes.any,
    /**
       * Component to render for each row in the column
       */
    rowCellRender: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.element.isRequired]).isRequired,
    /**
       * Component to render in the optional column footer
       */
    footerCellRender: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.element.isRequired]),
    /**
       * Optional props to pass to the column footer cell
       */
    footerCellProps: PropTypes.any
  }).isRequired
};
EuiDataGridControlHeaderCell.displayName = 'EuiDataGridControlHeaderCell';
try {
  EuiDataGridControlHeaderCell.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/datagrid/body/header/data_grid_control_header_cell.tsx',
    description: '',
    displayName: 'EuiDataGridControlHeaderCell',
    methods: [],
    props: {
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridControlHeaderCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridControlHeaderCellProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      controlColumn: {
        defaultValue: null,
        description: '',
        name: 'controlColumn',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridControlHeaderCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridControlHeaderCellProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridControlColumn'
        }
      }
    },
    extendedInterfaces: ['EuiDataGridControlHeaderCellProps']
  };
} catch (e) {}