/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiButtonEmpty } from '../../button/button_empty';
import { EuiPopover } from '../../popover';
import { EuiContextMenuPanel } from '../../context_menu';
import { EuiI18n } from '../../i18n';
import { EuiTableSortMobileItem } from './table_sort_mobile_item';
export class EuiTableSortMobile extends Component {
  state = {
    isPopoverOpen: false
  };
  onButtonClick = () => {
    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen
    });
  };
  closePopover = () => {
    this.setState({
      isPopoverOpen: false
    });
  };

  // Aligns the button to the right even when it's the only element present
  euiTableSortMobileStyles = {
    marginInlineStart: 'auto',
    label: 'euiTableSortMobile'
  };
  render() {
    const {
      className,
      anchorPosition,
      items,
      ...rest
    } = this.props;
    const classes = classNames('euiTableSortMobile', className);
    const mobileSortButton = <EuiButtonEmpty iconType="arrowDown" iconSide="right" onClick={this.onButtonClick.bind(this)} flush="right" size="xs">
        <EuiI18n token="euiTableSortMobile.sorting" default="Sorting" />
      </EuiButtonEmpty>;
    const mobileSortPopover = <EuiPopover button={mobileSortButton} isOpen={this.state.isPopoverOpen} closePopover={this.closePopover} anchorPosition={anchorPosition || 'downRight'} panelPaddingSize="none" {...rest}>
        <EuiContextMenuPanel style={{
        minWidth: 200
      }} items={items && items.length ? items.map(item => {
        return <EuiTableSortMobileItem key={item.key} onSort={item.onSort} isSorted={item.isSorted} isSortAscending={item.isSortAscending}>
                      {item.name}
                    </EuiTableSortMobileItem>;
      }) : undefined} />
      </EuiPopover>;
    return <div className={classes} css={this.euiTableSortMobileStyles}>
        {mobileSortPopover}
      </div>;
  }
}
EuiTableSortMobile.propTypes = {
  anchorPosition: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.node.isRequired,
    key: PropTypes.any,
    onSort: PropTypes.func,
    isSorted: PropTypes.bool,
    isSortAscending: PropTypes.bool
  }).isRequired),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTableSortMobile.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/mobile/table_sort_mobile.tsx',
    description: '',
    displayName: 'EuiTableSortMobile',
    methods: [],
    props: {
      anchorPosition: {
        defaultValue: null,
        description: '',
        name: 'anchorPosition',
        parent: {
          fileName: 'eui/src/components/table/mobile/table_sort_mobile.tsx',
          name: 'EuiTableSortMobileProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/mobile/table_sort_mobile.tsx',
          name: 'EuiTableSortMobileProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"upCenter" | "upLeft" | "upRight" | "downCenter" | "downLeft" | "downRight" | "leftCenter" | "leftUp" | "leftDown" | "rightCenter" | "rightUp" | "rightDown"',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      items: {
        defaultValue: null,
        description: '',
        name: 'items',
        parent: {
          fileName: 'eui/src/components/table/mobile/table_sort_mobile.tsx',
          name: 'EuiTableSortMobileProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/mobile/table_sort_mobile.tsx',
          name: 'EuiTableSortMobileProps'
        }],
        required: false,
        type: {
          name: 'ItemProps[]'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiTableSortMobileProps', 'CommonProps']
  };
} catch (e) {}