/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles, LEFT_ALIGNMENT } from '../../services';
import { useEuiTableIsResponsive } from './mobile/responsive_context';
import { resolveWidthAsStyle } from './utils';
import { EuiTableCellContent } from './_table_cell_content';
import { euiTableRowCellStyles } from './table_row_cell.styles';
export var EuiTableRowCell = ({
  align = LEFT_ALIGNMENT,
  children,
  className,
  truncateText,
  setScopeRow,
  textOnly = true,
  hasActions,
  isExpander,
  style,
  width,
  valign = 'middle',
  mobileOptions,
  append,
  ...rest
}) => {
  const isResponsive = useEuiTableIsResponsive();
  const styles = useEuiMemoizedStyles(euiTableRowCellStyles);
  const cssStyles = [styles.euiTableRowCell, setScopeRow && styles.rowHeader, isExpander && styles.isExpander, hasActions && styles.hasActions, styles[valign], ...(isResponsive ? [styles.mobile.mobile, mobileOptions?.enlarge && styles.mobile.enlarge, hasActions === 'custom' && styles.mobile.customActions, hasActions === true && styles.mobile.actions, isExpander && styles.mobile.expander] : [styles.desktop.desktop, hasActions && styles.desktop.actions])];
  const cellClasses = classNames('euiTableRowCell', className, {
    'euiTableRowCell--hasActions': hasActions,
    'euiTableRowCell--isExpander': isExpander
  });
  const widthValue = isResponsive ? hasActions || isExpander ? undefined // On mobile, actions are shifted to a right column via CSS
  : mobileOptions?.width : width;
  const styleObj = resolveWidthAsStyle(style, widthValue);
  const Element = setScopeRow ? 'th' : 'td';
  const sharedProps = {
    scope: setScopeRow ? 'row' : undefined,
    style: styleObj,
    css: cssStyles,
    ...rest
  };
  const sharedContentProps = {
    align,
    textOnly,
    truncateText,
    hasActions: hasActions || isExpander
  };
  if (isResponsive) {
    // Mobile view
    if (mobileOptions?.show === false) {
      return null;
    } else {
      return <Element className={cellClasses} {...sharedProps}>
          {mobileOptions?.header && <div className="euiTableRowCell__mobileHeader" css={styles.euiTableRowCell__mobileHeader}>
              {mobileOptions.header}
            </div>}
          <EuiTableCellContent {...sharedContentProps} align={mobileOptions?.align ?? 'left'} // Default to left aligned mobile cells, unless consumers specifically set an alignment for mobile
        truncateText={mobileOptions?.truncateText ?? truncateText} textOnly={mobileOptions?.textOnly ?? textOnly}>
            {mobileOptions?.render || children}
          </EuiTableCellContent>
          {append}
        </Element>;
    }
  } else {
    // Desktop view
    if (mobileOptions?.only) {
      return null;
    } else {
      return <Element className={cellClasses} {...sharedProps}>
          <EuiTableCellContent {...sharedContentProps}>
            {children}
          </EuiTableCellContent>
          {append}
        </Element>;
    }
  }
};
EuiTableRowCell.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Vertical alignment of the content in the cell
     */
  valign: PropTypes.any,
  /**
     * Indicates whether the cell should be marked as the heading for its row
     */
  setScopeRow: PropTypes.bool,
  /**
     * Indicates if the cell is dedicated to row actions
     * (used for mobile styling and desktop action hover behavior)
     */
  hasActions: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["custom"])]),
  /**
     * Indicates if the column is dedicated as the expandable row toggle
     */
  isExpander: PropTypes.bool,
  /**
     * Mobile options for displaying differently at small screens;
     * See #EuiTableRowCellMobileOptionsShape
     */
  mobileOptions: PropTypes.shape({
    /**
       * If false, will not render the cell at all for mobile
       * @default true
       */
    show: PropTypes.bool,
    /**
       * Only show for mobile? If true, will not render the column at all for desktop
       * @default false
       */
    only: PropTypes.bool,
    /**
       * Custom render/children if different from desktop
       */
    render: PropTypes.node,
    /**
       * The column's header for use in mobile view (automatically passed down
       * when using `EuiBasicTable`).
       * Or pass `false` to not show a header at all.
       */
    header: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.bool.isRequired]),
    /**
       * Increase text size compared to rest of cells
       * @default false
       */
    enlarge: PropTypes.bool,
    /**
       * Applies the value to the width of the cell in mobile view (typically 50%)
       * @default 50%
       */
    width: PropTypes.any,
    /**
       * Horizontal alignment of the text in the cell
       */
    align: PropTypes.any,
    /**
       * Creates a text wrapper around cell content that helps word break or truncate
       * long text correctly.
       * @default true
       */
    textOnly: PropTypes.bool,
    /**
       * Indicates whether this column should truncate overflowing text content.
       * - Set to `true` to enable single-line truncation.
       * - To enable multi-line truncation, use a configuration object with `lines`
       * set to a number of lines to truncate to.
       * @default false
       */
    truncateText: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
      lines: PropTypes.number.isRequired
    }).isRequired])
  }),
  /**
     * Content rendered outside the visible cell content wrapper. Useful for, e.g. screen reader text.
     *
     * Used by EuiBasicTable to render hidden copy markers
     */
  append: PropTypes.node,
  /**
     * Horizontal alignment of the text in the cell
     */
  align: PropTypes.any,
  /**
     * Creates a text wrapper around cell content that helps word break or truncate
     * long text correctly.
     * @default true
     */
  textOnly: PropTypes.bool,
  /**
     * Indicates whether this column should truncate overflowing text content.
     * - Set to `true` to enable single-line truncation.
     * - To enable multi-line truncation, use a configuration object with `lines`
     * set to a number of lines to truncate to.
     * @default false
     */
  truncateText: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    lines: PropTypes.number.isRequired
  }).isRequired]),
  width: PropTypes.any
};
try {
  EuiTableRowCell.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/table_row_cell.tsx',
    description: '',
    displayName: 'EuiTableRowCell',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      valign: {
        defaultValue: {
          value: 'middle'
        },
        description: 'Vertical alignment of the content in the cell',
        name: 'valign',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"top" | "bottom" | "baseline" | "middle"',
          value: [{
            value: '"top"'
          }, {
            value: '"bottom"'
          }, {
            value: '"baseline"'
          }, {
            value: '"middle"'
          }]
        }
      },
      setScopeRow: {
        defaultValue: null,
        description: 'Indicates whether the cell should be marked as the heading for its row',
        name: 'setScopeRow',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasActions: {
        defaultValue: null,
        description: 'Indicates if the cell is dedicated to row actions\n' + '(used for mobile styling and desktop action hover behavior)',
        name: 'hasActions',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean | "custom"'
        }
      },
      isExpander: {
        defaultValue: null,
        description: 'Indicates if the column is dedicated as the expandable row toggle',
        name: 'isExpander',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      mobileOptions: {
        defaultValue: null,
        description: 'Mobile options for displaying differently at small screens;\n' + 'See #EuiTableRowCellMobileOptionsShape',
        name: 'mobileOptions',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'EuiTableRowCellMobileOptionsShape'
        }
      },
      append: {
        defaultValue: null,
        description: 'Content rendered outside the visible cell content wrapper. Useful for, e.g. screen reader text.\n' + '\n' + 'Used by EuiBasicTable to render hidden copy markers',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      textOnly: {
        defaultValue: {
          value: 'true'
        },
        description: 'Creates a text wrapper around cell content that helps word break or truncate\n' + 'long text correctly.',
        name: 'textOnly',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      truncateText: {
        defaultValue: {
          value: 'false'
        },
        description: 'Indicates whether this column should truncate overflowing text content.\n' + '- Set to `true` to enable single-line truncation.\n' + '- To enable multi-line truncation, use a configuration object with `lines`\n' + 'set to a number of lines to truncate to.',
        name: 'truncateText',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'boolean | { lines: number; }'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'TdHTMLAttributes', 'EuiTableRowCellProps', 'EuiTableRowCellSharedPropsShape']
  };
} catch (e) {}