/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
export var EuiComboBoxTitle = ({
  children
}) => <div className="euiComboBoxTitle">{children}</div>;
try {
  EuiComboBoxTitle.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/combo_box/combo_box_options_list/combo_box_title.tsx',
    description: '',
    displayName: 'EuiComboBoxTitle',
    methods: [],
    props: {},
    extendedInterfaces: []
  };
} catch (e) {}