/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { getFixedLinearGradient } from '../utils';
import { EuiScreenReaderOnly } from '../../accessibility';
import { euiColorPaletteDisplayFixed__bleedArea } from './color_palette_display_fixed.styles';
export var EuiColorPaletteDisplayFixed = ({
  palette,
  title,
  ...rest
}) => {
  const fixedGradient = getFixedLinearGradient(palette);
  const paletteStops = fixedGradient.map((item, index) => <span style={{
    backgroundColor: item.color,
    width: item.width
  }} key={`${item.color}-${index}`} />);
  return <span {...rest}>
      {title && <EuiScreenReaderOnly>
          <span>{title}</span>
        </EuiScreenReaderOnly>}
      <span
    // aria-hidden="true" is to ensure color blocks are ignored by screen readers,
    // and the only accessible text for options is the EuiScreenReaderOnly {title}
    aria-hidden="true" css={euiColorPaletteDisplayFixed__bleedArea} className="euiColorPaletteDisplayFixed__bleedArea">
        {paletteStops}
      </span>
    </span>;
};
EuiColorPaletteDisplayFixed.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.
     */
  palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.any.isRequired).isRequired]).isRequired
};
try {
  EuiColorPaletteDisplayFixed.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/color_picker/color_palette_display/color_palette_display_fixed.tsx',
    description: '',
    displayName: 'EuiColorPaletteDisplayFixed',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      palette: {
        defaultValue: null,
        description: 'Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.',
        name: 'palette',
        parent: {
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'EuiColorPaletteDisplayShared'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'EuiColorPaletteDisplayShared'
        }],
        required: true,
        type: {
          name: 'string[] | PaletteColorStop[]'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'EuiColorPaletteDisplayShared']
  };
} catch (e) {}