/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { useFormContext } from '../eui_form_context';
import { EuiFormControlLayout } from '../form_control_layout';
import { EuiValidatableControl } from '../validatable_control';
import { euiSelectStyles } from './select.styles';
export var EuiSelect = props => {
  const {
    defaultFullWidth
  } = useFormContext();
  const {
    className,
    options = [],
    id,
    name,
    inputRef,
    isInvalid,
    fullWidth = defaultFullWidth,
    isLoading = false,
    hasNoInitialSelection = false,
    defaultValue,
    compressed = false,
    value: _value,
    prepend,
    append,
    onMouseUp,
    disabled,
    ...rest
  } = props;
  // if this is injecting an empty option for `hasNoInitialSelection` then
  // value needs to fallback to an empty string to interact properly with `defaultValue`
  const value = hasNoInitialSelection ? _value ?? '' : _value;

  // React HTML input can not have both value and defaultValue properties.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  const selectDefaultValue = value != null ? undefined : defaultValue || '';
  const handleMouseUp = useCallback(e => {
    // Normalizes cross-browser mouse eventing by preventing propagation,
    // notably for use in conjunction with EuiOutsideClickDetector.
    // See https://github.com/elastic/eui/pull/1926 for full discussion on
    // rationale and alternatives should this intervention become problematic.
    e.nativeEvent.stopImmediatePropagation();
    onMouseUp?.(e);
  }, [onMouseUp]);
  const classes = classNames('euiSelect', {
    'euiSelect-isLoading': isLoading
  }, className);
  const inGroup = !!(prepend || append);
  const styles = useEuiMemoizedStyles(euiSelectStyles);
  const cssStyles = [styles.euiSelect, compressed ? styles.compressed : styles.uncompressed, fullWidth ? styles.fullWidth : styles.formWidth, inGroup && styles.inGroup, styles.lineHeight.removePadding, inGroup ? compressed ? styles.lineHeight.inGroup.compressed : styles.lineHeight.inGroup.uncompressed : compressed ? styles.lineHeight.compressed : styles.lineHeight.uncompressed];
  return <EuiFormControlLayout isDropdown={true} fullWidth={fullWidth} isLoading={isLoading} isInvalid={isInvalid} isDisabled={disabled} compressed={compressed} prepend={prepend} append={append} inputId={id}>
      <EuiValidatableControl isInvalid={isInvalid}>
        <select id={id} name={name} className={classes} css={cssStyles} ref={inputRef} defaultValue={selectDefaultValue} value={value} onMouseUp={handleMouseUp} disabled={disabled} {...rest}>
          {hasNoInitialSelection && <option value="" disabled hidden style={{
          display: 'none'
        }}>
              &nbsp;
            </option>}
          {options.map((option, index) => {
          const {
            text,
            ...rest
          } = option;
          return <option {...rest} key={index}>
                {text}
              </option>;
        })}
        </select>
      </EuiValidatableControl>
    </EuiFormControlLayout>;
};
EuiSelect.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * @default []
       */
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node.isRequired
  }).isRequired),
  isInvalid: PropTypes.bool,
  /**
       * Expand to fill 100% of the parent.
       * Defaults to `fullWidth` prop of `<EuiForm>`.
       * @default false
       */
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  /**
       * Simulates no selection by creating an empty, selected, hidden first option
       * @default false
       */
  hasNoInitialSelection: PropTypes.bool,
  inputRef: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  /**
       * when `true` creates a shorter height input
       * @default false
       */
  compressed: PropTypes.bool,
  /**
       * Creates an input group with element(s) coming before select.
       * `string` | `ReactElement` or an array of these
       */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
       * Creates an input group with element(s) coming after select.
       * `string` | `ReactElement` or an array of these
       */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired])
};
try {
  EuiSelect.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/select/select.tsx',
    description: '',
    displayName: 'EuiSelect',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      options: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiSelectOption[]'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasNoInitialSelection: {
        defaultValue: {
          value: 'false'
        },
        description: 'Simulates no selection by creating an empty, selected, hidden first option',
        name: 'hasNoInitialSelection',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLSelectElement>'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before select.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after select.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/select/select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'SelectHTMLAttributes', 'CommonProps']
  };
} catch (e) {}