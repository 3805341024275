/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiFormFieldset } from '../form_fieldset';
import { EuiCheckbox } from './checkbox';
import { euiCheckboxGroupStyles } from './checkbox_group.styles';

// Must omit inherit `onChange` properties or else TS complains when applying to the EuiRadio

export var EuiCheckboxGroup = ({
  options = [],
  idToSelectedMap = {},
  onChange,
  className,
  disabled,
  compressed,
  legend,
  ...rest
}) => {
  const classes = classNames('euiCheckboxGroup', className);
  const styles = useEuiMemoizedStyles(euiCheckboxGroupStyles);
  const cssStyles = [styles.euiCheckboxGroup, compressed ? styles.compressed : styles.uncompressed];
  const checkboxes = options.map((option, index) => {
    const {
      disabled: isOptionDisabled,
      className: optionClass,
      ...optionRest
    } = option;
    return <EuiCheckbox className={classNames('euiCheckboxGroup__item', optionClass)} key={index} checked={idToSelectedMap[option.id]} disabled={disabled || isOptionDisabled} onChange={onChange.bind(null, option.id)} {...optionRest} />;
  });
  if (!!legend) {
    // Be sure to pass down the compressed option to the legend
    legend.compressed = compressed;
    return <EuiFormFieldset css={cssStyles} className={classes} legend={legend} {...rest}>
        {checkboxes}
      </EuiFormFieldset>;
  }
  return <div css={cssStyles} className={classes} {...rest}>
      {checkboxes}
    </div>;
};
EuiCheckboxGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired).isRequired,
  idToSelectedMap: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  /**
     * Tightens up the spacing between checkbox rows
     */
  compressed: PropTypes.bool,
  /**
     * Passed down to all child `EuiCheckbox`es
     */
  disabled: PropTypes.bool,
  /**
     * If the individual labels for each radio do not provide a sufficient description, add a legend.
     * Wraps the group in a `EuiFormFieldset` which adds an `EuiLegend` for titling the whole group.
     * Accepts an `EuiFormLegendProps` shape.
     */
  legend: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    /**
         * ReactNode to render as this component's content
         */
    children: PropTypes.node.isRequired,
    /**
         * For a hidden legend that is still visible to the screen reader, set to 'hidden'
         */
    display: PropTypes.oneOf(["hidden", "visible"]),
    compressed: PropTypes.bool
  })
};
try {
  EuiCheckboxGroup.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/checkbox/checkbox_group.tsx',
    description: '',
    displayName: 'EuiCheckboxGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      options: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiCheckboxGroupOption[]'
        }
      },
      idToSelectedMap: {
        defaultValue: {
          value: '{}'
        },
        description: '',
        name: 'idToSelectedMap',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiCheckboxGroupIdToSelectedMap'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(optionId: string) => void'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'Tightens up the spacing between checkbox rows',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: 'Passed down to all child `EuiCheckbox`es',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      legend: {
        defaultValue: null,
        description: 'Adds an EuiFormLegend element as the first child\n' + 'If the individual labels for each radio do not provide a sufficient description, add a legend.\n' + 'Wraps the group in a `EuiFormFieldset` which adds an `EuiLegend` for titling the whole group.\n' + 'Accepts an `EuiFormLegendProps` shape.',
        name: 'legend',
        parent: {
          fileName: 'eui/src/components/form/form_fieldset/form_fieldset.tsx',
          name: 'EuiFormFieldsetProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/form_fieldset/form_fieldset.tsx',
          name: 'EuiFormFieldsetProps'
        }, {
          fileName: 'eui/src/components/form/checkbox/checkbox_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLLegendElement> & CommonProps & { children: ReactNode; display?: "hidden" | "visible"; compressed?: boolean; }'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiFormFieldsetProps']
  };
} catch (e) {}