/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { useInnerText } from '../inner_text';
import { euiImageWrapperStyles } from './image_wrapper.styles';
import { EuiImageButton } from './image_button';
import { EuiImageCaption } from './image_caption';
export var EuiImageWrapper = ({
  alt,
  caption,
  hasShadow,
  allowFullScreen,
  float,
  margin,
  children,
  setIsFullScreen,
  wrapperProps,
  fullScreenIconColor,
  isFullWidth,
  onFullScreen
}) => {
  const openFullScreen = () => {
    setIsFullScreen(true);
    onFullScreen?.(true);
  };
  const classes = classNames('euiImageWrapper', wrapperProps && wrapperProps.className);
  const euiTheme = useEuiTheme();
  const styles = euiImageWrapperStyles(euiTheme);
  const cssFigureStyles = [styles.euiImageWrapper, float && styles[float], margin && styles[margin], allowFullScreen && styles.allowFullScreen, isFullWidth && styles.fullWidth, wrapperProps?.css];
  const [optionalCaptionRef, optionalCaptionText] = useInnerText();
  return <figure aria-label={optionalCaptionText} {...wrapperProps} className={classes} css={cssFigureStyles}>
      {allowFullScreen ? <>
          <EuiImageButton hasAlt={!!alt} hasShadow={hasShadow} onClick={openFullScreen} data-test-subj="activateFullScreenButton" isFullWidth={isFullWidth} fullScreenIconColor={fullScreenIconColor}>
            {children}
          </EuiImageButton>
        </> : children}

      <EuiImageCaption ref={optionalCaptionRef} caption={caption} />
    </figure>;
};
EuiImageWrapper.propTypes = {
  isFullWidth: PropTypes.bool.isRequired,
  setIsFullScreen: PropTypes.func.isRequired
};
try {
  EuiImageWrapper.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/image/image_wrapper.tsx',
    description: '',
    displayName: 'EuiImageWrapper',
    methods: [],
    props: {
      caption: {
        defaultValue: null,
        description: 'Provides a visible caption to the image',
        name: 'caption',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      wrapperProps: {
        defaultValue: null,
        description: 'Props to add to the wrapping figure element',
        name: 'wrapperProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement>'
        }
      },
      hasShadow: {
        defaultValue: null,
        description: 'When set to `true` (default) will apply a slight shadow to the image',
        name: 'hasShadow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      alt: {
        defaultValue: null,
        description: 'Alt text should describe the image to aid screen reader users. See\n' + 'https://webaim.org/techniques/alttext/ for a guide on writing\n' + 'effective alt text.\n' + '\n' + 'If no meaningful description exists, or if the image is adequately\n' + 'described by the surrounding text, pass an empty string.',
        name: 'alt',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      margin: {
        defaultValue: null,
        description: 'Margin around the image.',
        name: 'margin',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      float: {
        defaultValue: null,
        description: 'Float the image to the left or right. Useful in large text blocks.',
        name: 'float',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      allowFullScreen: {
        defaultValue: null,
        description: 'When set to `true` will make the image clickable to a larger version',
        name: 'allowFullScreen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onFullScreen: {
        defaultValue: null,
        description: 'Callback when the image is clicked and `allowFullScreen` is `true`',
        name: 'onFullScreen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(isFullScreen: boolean) => void'
        }
      },
      fullScreenIconColor: {
        defaultValue: null,
        description: 'Changes the color of the icon that floats above the image when it can be clicked to fullscreen.\n' + 'The default value of `light` is fine unless your image has a white background, in which case you should change it to `dark`.',
        name: 'fullScreenIconColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiImageButtonIconColor',
          value: [{
            value: '"light"'
          }, {
            value: '"dark"'
          }]
        }
      },
      isFullWidth: {
        defaultValue: null,
        description: '',
        name: 'isFullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      setIsFullScreen: {
        defaultValue: null,
        description: '',
        name: 'setIsFullScreen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(isFullScreen: boolean) => void'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}