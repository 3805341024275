/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { cloneElement, Children, useState, useCallback, useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useGeneratedHtmlId, useEuiMemoizedStyles } from '../../../services';
import { EuiSpacer } from '../../spacer';
import { EuiFormHelpText } from '../form_help_text';
import { EuiFormErrorText } from '../form_error_text';
import { EuiFormLabel } from '../form_label';
import { useFormContext } from '../eui_form_context';
import { euiFormRowStyles } from './form_row.styles';
export var DISPLAYS = ['row', 'columnCompressed', 'center', 'centerCompressed',
/**
 * @deprecated
 */
'columnCompressedSwitch',
/**
 * @deprecated
 */
'rowCompressed'];
export var EuiFormRow = ({
  className,
  children,
  helpText,
  isInvalid,
  error,
  label,
  labelType = 'label',
  labelAppend,
  hasEmptyLabelSpace = false,
  fullWidth: _fullWidth,
  describedByIds,
  display = 'row',
  hasChildLabel = true,
  id: propsId,
  isDisabled,
  ...rest
}) => {
  const {
    defaultFullWidth
  } = useFormContext();
  const fullWidth = _fullWidth ?? defaultFullWidth;
  const id = useGeneratedHtmlId({
    conditionalId: propsId
  });
  const hasLabel = label || labelAppend;
  const [isFocused, setIsFocused] = useState(false);
  const onFocusWithin = useCallback(() => setIsFocused(true), []);
  const onBlurWithin = useCallback(() => setIsFocused(false), []);
  const classes = classNames('euiFormRow', {
    'euiFormRow--hasEmptyLabelSpace': hasEmptyLabelSpace,
    'euiFormRow--hasLabel': hasLabel
  }, className);
  const styles = useEuiMemoizedStyles(euiFormRowStyles);
  const cssStyles = [styles.euiFormRow, fullWidth ? styles.fullWidth : styles.formWidth, styles[display]];
  const optionalHelpTexts = useMemo(() => {
    if (!helpText) return;
    const helpTexts = Array.isArray(helpText) ? helpText : [helpText];
    return helpTexts.map((helpText, i) => {
      const key = typeof helpText === 'string' ? helpText : i;
      return <EuiFormHelpText key={key} id={`${id}-help-${i}`} className="euiFormRow__text">
          {helpText}
        </EuiFormHelpText>;
    });
  }, [helpText, id]);
  const optionalErrors = useMemo(() => {
    if (!(error && isInvalid)) return;
    const errorTexts = Array.isArray(error) ? error : [error];
    return errorTexts.map((error, i) => {
      const key = typeof error === 'string' ? error : i;
      return <EuiFormErrorText key={key} id={`${id}-error-${i}`} className="euiFormRow__text">
          {error}
        </EuiFormErrorText>;
    });
  }, [error, isInvalid, id]);
  const ariaDescribedBy = useMemo(() => {
    const describingIds = [...(describedByIds || [])];
    if (optionalHelpTexts?.length) {
      optionalHelpTexts.forEach(optionalHelpText => describingIds.push(optionalHelpText.props.id));
    }
    if (optionalErrors?.length) {
      optionalErrors.forEach(error => describingIds.push(error.props.id));
    }
    if (describingIds.length) {
      return describingIds.join(' ');
    }
  }, [describedByIds, optionalHelpTexts, optionalErrors]);
  const field = useMemo(() => {
    const child = Children.only(children);
    return cloneElement(child, {
      id,
      // Allow the child's disabled or isDisabled prop to supercede the `isDisabled`
      disabled: child.props.disabled ?? child.props.isDisabled ?? isDisabled,
      'aria-describedby': ariaDescribedBy
    });
  }, [children, id, isDisabled, ariaDescribedBy]);
  const Element = labelType === 'legend' ? 'fieldset' : 'div';
  return <Element css={cssStyles} className={classes} id={`${id}-row`} {...rest}>
      {hasLabel ? <div className="euiFormRow__labelWrapper">
          <EuiFormLabel className="euiFormRow__label" aria-invalid={isInvalid} isInvalid={isInvalid} isDisabled={isDisabled} isFocused={isFocused && !isDisabled} id={`${id}-label`}
      // ExclusiveUnion shenanigans
      {...labelType === 'legend' ? {
        type: labelType
      } : {
        type: labelType,
        htmlFor: hasChildLabel ? id : undefined
      }}>
            {label}
          </EuiFormLabel>
          {labelAppend && ' '}
          {labelAppend}
        </div> : hasEmptyLabelSpace && <EuiSpacer size="m" className="euiFormRow__labelWrapper" />}
      <div className="euiFormRow__fieldWrapper" onFocus={onFocusWithin} onBlur={onBlurWithin}>
        {field}
        {optionalErrors}
        {optionalHelpTexts}
      </div>
    </Element>;
};
EuiFormRow.propTypes = {
  /**
     * Defaults to rendering a `<label>` but if passed `'legend'` for labelType,
     * will render both a `<legend>` and the surrounding container as a `<fieldset>`
     */
  labelType: PropTypes.oneOfType([PropTypes.oneOf(["label"]), PropTypes.oneOf(["legend"])]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * - `columnCompressed` creates a compressed and horizontal layout
     * - `columnCompressedSwitch` - **deprecated**, use `columnCompressed` instead
     * - `center`/`centerCompressed` helps align non-input content better with inline form layouts
     * - `rowCompressed` - **deprecated**, does not currently affect styling
     */
  /**
     * - `columnCompressed` creates a compressed and horizontal layout
     * - `columnCompressedSwitch` - **deprecated**, use `columnCompressed` instead
     * - `center`/`centerCompressed` helps align non-input content better with inline form layouts
     * - `rowCompressed` - **deprecated**, does not currently affect styling
     */
  display: PropTypes.any,
  /**
     * Useful for inline form layouts, primarily for content that
     * needs to be aligned with inputs but does not need a label
     */
  /**
     * Useful for inline form layouts, primarily for content that
     * needs to be aligned with inputs but does not need a label
     */
  hasEmptyLabelSpace: PropTypes.bool,
  /**
     * Expand to fill 100% of the parent.
     * Defaults to `fullWidth` prop of `<EuiForm>`.
     * @default false
     */
  /**
     * Expand to fill 100% of the parent.
     * Defaults to `fullWidth` prop of `<EuiForm>`.
     * @default false
     */
  fullWidth: PropTypes.bool,
  /**
     * IDs of additional elements that should be part of children's `aria-describedby`
     */
  /**
     * IDs of additional elements that should be part of children's `aria-describedby`
     */
  describedByIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  /**
     * Escape hatch to not render duplicate labels if the child also renders a label
     */
  /**
     * Escape hatch to not render duplicate labels if the child also renders a label
     */
  hasChildLabel: PropTypes.bool,
  /**
     * ReactElement to render as this component's content
     */
  /**
     * ReactElement to render as this component's content
     */
  children: PropTypes.element.isRequired,
  label: PropTypes.node,
  /**
     * Adds an extra node to the right of the form label without
     * being contained inside the form label. Good for things
     * like documentation links.
     */
  /**
     * Adds an extra node to the right of the form label without
     * being contained inside the form label. Good for things
     * like documentation links.
     */
  labelAppend: PropTypes.any,
  id: PropTypes.string,
  isInvalid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired]),
  /**
     *  Adds a single node/string or an array of nodes/strings below the input
     */
  /**
     *  Adds a single node/string or an array of nodes/strings below the input
     */
  helpText: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired]),
  /**
     *  Passed along to the label element; and to the child field element when `disabled` doesn't already exist on the child field element.
     */
  /**
     *  Passed along to the label element; and to the child field element when `disabled` doesn't already exist on the child field element.
     */
  isDisabled: PropTypes.bool
};
try {
  EuiFormRow.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/form_row/form_row.tsx',
    description: '',
    displayName: 'EuiFormRow',
    methods: [],
    props: {
      labelType: {
        defaultValue: {
          value: 'label'
        },
        description: "Defaults to rendering a `<label>` but if passed `'legend'` for labelType,\n" + 'will render both a `<legend>` and the surrounding container as a `<fieldset>`',
        name: 'labelType',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"label" | "legend"',
          value: [{
            value: '"label"'
          }, {
            value: '"legend"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      display: {
        defaultValue: {
          value: 'row'
        },
        description: '- `columnCompressed` creates a compressed and horizontal layout\n' + '- `columnCompressedSwitch` - **deprecated**, use `columnCompressed` instead\n' + '- `center`/`centerCompressed` helps align non-input content better with inline form layouts\n' + '- `rowCompressed` - **deprecated**, does not currently affect styling',
        name: 'display',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "row" | "columnCompressed" | "centerCompressed" | "columnCompressedSwitch" | "rowCompressed"',
          value: [{
            value: '"center"'
          }, {
            value: '"row"'
          }, {
            value: '"columnCompressed"'
          }, {
            value: '"centerCompressed"'
          }, {
            value: '"columnCompressedSwitch"'
          }, {
            value: '"rowCompressed"'
          }]
        }
      },
      hasEmptyLabelSpace: {
        defaultValue: {
          value: 'false'
        },
        description: 'Useful for inline form layouts, primarily for content that\n' + 'needs to be aligned with inputs but does not need a label',
        name: 'hasEmptyLabelSpace',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      describedByIds: {
        defaultValue: null,
        description: "IDs of additional elements that should be part of children's `aria-describedby`",
        name: 'describedByIds',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      hasChildLabel: {
        defaultValue: {
          value: 'true'
        },
        description: 'Escape hatch to not render duplicate labels if the child also renders a label',
        name: 'hasChildLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactElement to render as this component's content",
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      labelAppend: {
        defaultValue: null,
        description: 'Adds an extra node to the right of the form label without\n' + 'being contained inside the form label. Good for things\n' + 'like documentation links.',
        name: 'labelAppend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      error: {
        defaultValue: null,
        description: '',
        name: 'error',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode | ReactNode[]'
        }
      },
      helpText: {
        defaultValue: null,
        description: 'Adds a single node/string or an array of nodes/strings below the input',
        name: 'helpText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode | ReactNode[]'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: "Passed along to the label element; and to the child field element when `disabled` doesn't already exist on the child field element.",
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_row/form_row.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}