/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiRadio, EuiCheckbox } from '../../form';
import { EuiSplitPanel } from '../../panel';
import { useEuiTheme } from '../../../services';
import { euiCheckableCardStyles } from './checkable_card.styles';

// if `checkableType` is left out or set to 'radio', use EuiRadioProps

// if `checkableType` is set to 'checkbox', use EuiCheckboxProps

export var EuiCheckableCard = ({
  children,
  className,
  css,
  checkableType = 'radio',
  label,
  checked,
  disabled,
  hasShadow,
  hasBorder = true,
  ...rest
}) => {
  const euiThemeContext = useEuiTheme();
  const styles = euiCheckableCardStyles(euiThemeContext);
  const baseStyles = [styles.euiCheckableCard, checked && !disabled && styles.isChecked, css];
  const labelStyles = [styles.label.euiCheckableCard__label, disabled && styles.label.isDisabled];
  const childStyles = [styles.euiCheckableCard__children];
  const {
    id
  } = rest;
  const labelEl = useRef(null);
  const classes = classNames('euiCheckableCard', className);
  let checkableElement;
  if (checkableType === 'radio') {
    checkableElement = <EuiRadio checked={checked} disabled={disabled} {...rest} />;
  } else {
    checkableElement = <EuiCheckbox checked={checked} disabled={disabled} {...rest} />;
  }
  const labelClasses = classNames('euiCheckableCard__label');
  const onChangeAffordance = () => {
    if (labelEl.current) {
      labelEl.current.click();
    }
  };
  return <EuiSplitPanel.Outer responsive={false} hasShadow={hasShadow} hasBorder={hasBorder} direction="row" className={classes} css={baseStyles}>
      <EuiSplitPanel.Inner
    // Bubbles up the change event when clicking on the whole div for extra affordance
    onClick={disabled ? undefined : onChangeAffordance} color={checked ? 'primary' : 'subdued'} grow={false}>
        {checkableElement}
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner>
        <label ref={labelEl} className={labelClasses} css={labelStyles} htmlFor={id} aria-describedby={children ? `${id}-details` : undefined}>
          {label}
        </label>
        {children && <div id={`${id}-details`} className="euiCheckableCard__children" css={childStyles}>
            {children}
          </div>}
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>;
};
EuiCheckableCard.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  hasShadow: PropTypes.any,
  hasBorder: PropTypes.any
};
try {
  EuiCheckableCard.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/card/checkable_card/checkable_card.tsx',
    description: '',
    displayName: 'EuiCheckableCard',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }, {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: true,
        type: {
          name: 'ChangeEventHandler<HTMLInputElement>'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }, {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }, {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      labelProps: {
        defaultValue: null,
        description: 'Object of props passed to the <label/>',
        name: 'labelProps',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }, {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & LabelHTMLAttributes<HTMLLabelElement>'
        }
      },
      checkableType: {
        defaultValue: {
          value: 'radio'
        },
        description: 'Whether the control is a radio button or checkbox',
        name: 'checkableType',
        parent: {
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardAsCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardAsCheckboxProps'
        }, {
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardAsRadioProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"checkbox" | "radio"',
          value: [{
            value: '"checkbox"'
          }, {
            value: '"radio"'
          }]
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: {
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      hasShadow: {
        defaultValue: null,
        description: '',
        name: 'hasShadow',
        parent: {
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasBorder: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'hasBorder',
        parent: {
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/card/checkable_card/checkable_card.tsx',
          name: 'EuiCheckableCardBaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiCheckboxProps', 'CommonProps', 'EuiCheckableCardAsCheckboxProps', 'EuiCheckableCardBaseProps']
  };
} catch (e) {}