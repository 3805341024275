/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiColorPaletteDisplayFixed } from './color_palette_display_fixed';
import { EuiColorPaletteDisplayGradient } from './color_palette_display_gradient';
import { euiColorPaletteDisplayStyles } from './color_palette_display.styles';
export var SIZES = ['xs', 's', 'm'];
export var EuiColorPaletteDisplay = ({
  type = 'fixed',
  palette,
  className,
  size = 's',
  ...rest
}) => {
  const classes = classnames('euiColorPaletteDisplay', className);
  const styles = useEuiMemoizedStyles(euiColorPaletteDisplayStyles);
  const cssStyles = [styles.euiColorPaletteDisplay, styles[size]];
  return <>
      {type === 'fixed' ? <EuiColorPaletteDisplayFixed css={cssStyles} className={classes} palette={palette} {...rest} /> : <EuiColorPaletteDisplayGradient css={cssStyles} className={classes} palette={palette} {...rest} />}
    </>;
};
EuiColorPaletteDisplay.propTypes = {
  /**
     * Height of the palette display
     */
  size: PropTypes.any,
  /**
     *   Specify the type of palette.
     *  `gradient`: each color fades into the next.
     */
  /**
     *  `fixed`: individual color blocks.
     */
  type: PropTypes.oneOfType([PropTypes.oneOf(["fixed"]), PropTypes.oneOf(["gradient"]).isRequired]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.
     */
  /**
     * Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.
     */
  palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.any.isRequired).isRequired]).isRequired
};
try {
  EuiColorPaletteDisplay.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
    description: '',
    displayName: 'EuiColorPaletteDisplay',
    methods: [],
    props: {
      size: {
        defaultValue: {
          value: 's'
        },
        description: 'Height of the palette display',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      type: {
        defaultValue: {
          value: 'fixed'
        },
        description: 'Specify the type of palette.\n' + '`gradient`: each color fades into the next.\n' + '`fixed`: individual color blocks.',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'DisplayGradient'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'DisplayGradient'
        }, {
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'DisplayFixed'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "gradient"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"gradient"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      palette: {
        defaultValue: null,
        description: 'Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.',
        name: 'palette',
        parent: {
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'EuiColorPaletteDisplayShared'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_palette_display/color_palette_display.tsx',
          name: 'EuiColorPaletteDisplayShared'
        }],
        required: true,
        type: {
          name: 'string[] | PaletteColorStop[]'
        }
      }
    },
    extendedInterfaces: ['DisplayGradient', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'EuiColorPaletteDisplayShared']
  };
} catch (e) {}