import React from 'react';
import PropTypes from "prop-types";
export var EuiOverlayMaskProps = () => <div />;
EuiOverlayMaskProps.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,
  /**
     * Should the mask visually sit above or below the EuiHeader (controlled by z-index)
     */
  headerZindexLocation: PropTypes.oneOf(["above", "below"]),
  /**
     * React ref to be passed to the wrapping container
     */
  maskRef: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired])
};
try {
  EuiOverlayMaskProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/overlay_mask/props.tsx',
    description: '',
    displayName: 'EuiOverlayMaskProps',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      headerZindexLocation: {
        defaultValue: null,
        description: 'Should the mask visually sit above or below the EuiHeader (controlled by z-index)',
        name: 'headerZindexLocation',
        parent: {
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"above" | "below"',
          value: [{
            value: '"above"'
          }, {
            value: '"below"'
          }]
        }
      },
      maskRef: {
        defaultValue: null,
        description: 'React ref to be passed to the wrapping container',
        name: 'maskRef',
        parent: {
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement> | MutableRefObject<HTMLDivElement>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['EuiOverlayMaskInterface', 'CommonProps']
  };
} catch (e) {}