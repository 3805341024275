/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { Component } from 'react';
import PropTypes from "prop-types";
export class EuiWindowEvent extends Component {
  componentDidMount() {
    this.addEvent(this.props);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.event !== this.props.event || prevProps.handler !== this.props.handler) {
      this.removeEvent(prevProps);
      this.addEvent(this.props);
    }
  }
  componentWillUnmount() {
    this.removeEvent(this.props);
  }
  addEvent({
    event,
    handler
  }) {
    window.addEventListener(event, handler);
  }
  removeEvent({
    event,
    handler
  }) {
    window.removeEventListener(event, handler);
  }
  render() {
    return null;
  }
}
EuiWindowEvent.propTypes = {
  event: PropTypes.any.isRequired,
  handler: PropTypes.func.isRequired
};
try {
  EuiWindowEvent.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/services/window_event/window_event.ts',
    description: '',
    displayName: 'EuiWindowEvent',
    methods: [],
    props: {
      event: {
        defaultValue: null,
        description: '',
        name: 'event',
        parent: {
          fileName: 'eui/src/services/window_event/window_event.ts',
          name: 'Props'
        },
        declarations: [{
          fileName: 'eui/src/services/window_event/window_event.ts',
          name: 'Props'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'keyof WindowEventMap',
          value: [{
            value: '"input"'
          }, {
            value: '"progress"'
          }, {
            value: '"select"'
          }, {
            value: '"devicemotion"'
          }, {
            value: '"deviceorientation"'
          }, {
            value: '"gamepadconnected"'
          }, {
            value: '"gamepaddisconnected"'
          }, {
            value: '"orientationchange"'
          }, {
            value: '"abort"'
          }, {
            value: '"animationcancel"'
          }, {
            value: '"animationend"'
          }, {
            value: '"animationiteration"'
          }, {
            value: '"animationstart"'
          }, {
            value: '"auxclick"'
          }, {
            value: '"beforeinput"'
          }, {
            value: '"blur"'
          }, {
            value: '"canplay"'
          }, {
            value: '"canplaythrough"'
          }, {
            value: '"change"'
          }, {
            value: '"click"'
          }, {
            value: '"close"'
          }, {
            value: '"compositionend"'
          }, {
            value: '"compositionstart"'
          }, {
            value: '"compositionupdate"'
          }, {
            value: '"contextmenu"'
          }, {
            value: '"cuechange"'
          }, {
            value: '"dblclick"'
          }, {
            value: '"drag"'
          }, {
            value: '"dragend"'
          }, {
            value: '"dragenter"'
          }, {
            value: '"dragleave"'
          }, {
            value: '"dragover"'
          }, {
            value: '"dragstart"'
          }, {
            value: '"drop"'
          }, {
            value: '"durationchange"'
          }, {
            value: '"emptied"'
          }, {
            value: '"ended"'
          }, {
            value: '"error"'
          }, {
            value: '"focus"'
          }, {
            value: '"focusin"'
          }, {
            value: '"focusout"'
          }, {
            value: '"formdata"'
          }, {
            value: '"gotpointercapture"'
          }, {
            value: '"invalid"'
          }, {
            value: '"keydown"'
          }, {
            value: '"keypress"'
          }, {
            value: '"keyup"'
          }, {
            value: '"load"'
          }, {
            value: '"loadeddata"'
          }, {
            value: '"loadedmetadata"'
          }, {
            value: '"loadstart"'
          }, {
            value: '"lostpointercapture"'
          }, {
            value: '"mousedown"'
          }, {
            value: '"mouseenter"'
          }, {
            value: '"mouseleave"'
          }, {
            value: '"mousemove"'
          }, {
            value: '"mouseout"'
          }, {
            value: '"mouseover"'
          }, {
            value: '"mouseup"'
          }, {
            value: '"pause"'
          }, {
            value: '"play"'
          }, {
            value: '"playing"'
          }, {
            value: '"pointercancel"'
          }, {
            value: '"pointerdown"'
          }, {
            value: '"pointerenter"'
          }, {
            value: '"pointerleave"'
          }, {
            value: '"pointermove"'
          }, {
            value: '"pointerout"'
          }, {
            value: '"pointerover"'
          }, {
            value: '"pointerup"'
          }, {
            value: '"ratechange"'
          }, {
            value: '"reset"'
          }, {
            value: '"resize"'
          }, {
            value: '"scroll"'
          }, {
            value: '"securitypolicyviolation"'
          }, {
            value: '"seeked"'
          }, {
            value: '"seeking"'
          }, {
            value: '"selectionchange"'
          }, {
            value: '"selectstart"'
          }, {
            value: '"stalled"'
          }, {
            value: '"submit"'
          }, {
            value: '"suspend"'
          }, {
            value: '"timeupdate"'
          }, {
            value: '"toggle"'
          }, {
            value: '"touchcancel"'
          }, {
            value: '"touchend"'
          }, {
            value: '"touchmove"'
          }, {
            value: '"touchstart"'
          }, {
            value: '"transitioncancel"'
          }, {
            value: '"transitionend"'
          }, {
            value: '"transitionrun"'
          }, {
            value: '"transitionstart"'
          }, {
            value: '"volumechange"'
          }, {
            value: '"waiting"'
          }, {
            value: '"webkitanimationend"'
          }, {
            value: '"webkitanimationiteration"'
          }, {
            value: '"webkitanimationstart"'
          }, {
            value: '"webkittransitionend"'
          }, {
            value: '"wheel"'
          }, {
            value: '"afterprint"'
          }, {
            value: '"beforeprint"'
          }, {
            value: '"beforeunload"'
          }, {
            value: '"hashchange"'
          }, {
            value: '"languagechange"'
          }, {
            value: '"message"'
          }, {
            value: '"messageerror"'
          }, {
            value: '"offline"'
          }, {
            value: '"online"'
          }, {
            value: '"pagehide"'
          }, {
            value: '"pageshow"'
          }, {
            value: '"popstate"'
          }, {
            value: '"rejectionhandled"'
          }, {
            value: '"storage"'
          }, {
            value: '"unhandledrejection"'
          }, {
            value: '"unload"'
          }]
        }
      },
      handler: {
        defaultValue: null,
        description: '',
        name: 'handler',
        parent: {
          fileName: 'eui/src/services/window_event/window_event.ts',
          name: 'Props'
        },
        declarations: [{
          fileName: 'eui/src/services/window_event/window_event.ts',
          name: 'Props'
        }],
        required: true,
        type: {
          name: '(this: Window, ev: WindowEventMap[E]) => any'
        }
      }
    },
    extendedInterfaces: ['Props']
  };
} catch (e) {}