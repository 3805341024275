const _excluded = ["className", "onRefreshChange", "intervalUnits", "isDisabled", "isPaused", "refreshInterval", "minInterval", "readOnly"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiFieldText } from '../../form';
import { EuiButtonEmpty } from '../../button/button_empty/button_empty';
import { EuiInputPopover, EuiPopover } from '../../popover';
import { useEuiI18n } from '../../i18n';
import { usePrettyInterval } from '../super_date_picker/pretty_interval';
import { EuiRefreshInterval } from './refresh_interval';
export var EuiAutoRefresh = function EuiAutoRefresh(_ref) {
  var className = _ref.className,
    onRefreshChange = _ref.onRefreshChange,
    intervalUnits = _ref.intervalUnits,
    isDisabled = _ref.isDisabled,
    _ref$isPaused = _ref.isPaused,
    isPaused = _ref$isPaused === void 0 ? true : _ref$isPaused,
    _ref$refreshInterval = _ref.refreshInterval,
    refreshInterval = _ref$refreshInterval === void 0 ? 1000 : _ref$refreshInterval,
    _ref$minInterval = _ref.minInterval,
    minInterval = _ref$minInterval === void 0 ? 0 : _ref$minInterval,
    _ref$readOnly = _ref.readOnly,
    readOnly = _ref$readOnly === void 0 ? true : _ref$readOnly,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiAutoRefresh', className);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isPopoverOpen = _useState2[0],
    setIsPopoverOpen = _useState2[1];
  var autoRefeshLabel = useEuiI18n('euiAutoRefresh.autoRefreshLabel', 'Auto refresh');
  return ___EmotionJSX(EuiInputPopover, {
    className: classes,
    fullWidth: rest.fullWidth,
    input: ___EmotionJSX(EuiFieldText, _extends({
      "aria-label": autoRefeshLabel,
      onClick: function onClick() {
        return setIsPopoverOpen(function (isOpen) {
          return !isOpen;
        });
      },
      prepend: ___EmotionJSX(EuiButtonEmpty, {
        className: "euiFormControlLayout__prepend",
        onClick: function onClick() {
          return setIsPopoverOpen(function (isOpen) {
            return !isOpen;
          });
        },
        size: "s",
        color: "text",
        iconType: "timeRefresh",
        isDisabled: isDisabled
      }, ___EmotionJSX("strong", null, ___EmotionJSX("small", null, autoRefeshLabel))),
      readOnly: readOnly,
      disabled: isDisabled,
      value: usePrettyInterval(Boolean(isPaused), refreshInterval, {
        unit: intervalUnits
      })
    }, rest)),
    isOpen: isPopoverOpen,
    closePopover: function closePopover() {
      setIsPopoverOpen(false);
    }
  }, ___EmotionJSX(EuiRefreshInterval, {
    onRefreshChange: onRefreshChange,
    isPaused: isPaused,
    refreshInterval: refreshInterval,
    minInterval: minInterval,
    intervalUnits: intervalUnits
  }));
};
EuiAutoRefresh.propTypes = {
  /**
     * Is refresh paused or running.
     */
  isPaused: PropTypes.bool,
  refreshInterval: PropTypes.number,
  minInterval: PropTypes.number,
  /**
     * By default, refresh interval units will be rounded up to next largest unit of time
     * (for example, 90 seconds will become 2m).
     *
     * If you do not want this behavior, you can manually control the rendered unit via this prop.
     */
  intervalUnits: PropTypes.any,
  /**
     * Passes back the updated state of `isPaused`, `refreshInterval`, and `intervalUnits`.
     */
  onRefreshChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  /**
     * The input is `readOnly` by default because the input value is handled by the popover form.
     */
  readOnly: PropTypes.any
};
export var EuiAutoRefreshButton = ({
  className,
  onRefreshChange,
  intervalUnits,
  isDisabled,
  isPaused = true,
  refreshInterval = 1000,
  minInterval = 0,
  shortHand = false,
  size = 's',
  color = 'text',
  ...rest
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const classes = classNames('euiAutoRefreshButton', className);
  const autoRefeshLabelOff = useEuiI18n('euiAutoRefresh.buttonLabelOff', 'Auto refresh is off');
  const autoRefeshLabelOn = useEuiI18n('euiAutoRefresh.buttonLabelOn', 'Auto refresh is on and set to {prettyInterval}', {
    prettyInterval: usePrettyInterval(Boolean(isPaused), refreshInterval, {
      unit: intervalUnits
    })
  });
  return <EuiPopover button={<EuiButtonEmpty onClick={() => setIsPopoverOpen(isOpen => !isOpen)} className={classes} size={size} color={color} iconType="timeRefresh" title={isPaused ? autoRefeshLabelOff : autoRefeshLabelOn} isDisabled={isDisabled} {...rest}>
          {usePrettyInterval(Boolean(isPaused), refreshInterval, {
      shortHand,
      unit: intervalUnits
    })}
        </EuiButtonEmpty>} isOpen={isPopoverOpen} closePopover={() => {
    setIsPopoverOpen(false);
  }} popoverScreenReaderText={isPaused ? autoRefeshLabelOff : autoRefeshLabelOn}>
      <EuiRefreshInterval onRefreshChange={onRefreshChange} isPaused={isPaused} refreshInterval={refreshInterval} minInterval={minInterval} intervalUnits={intervalUnits} />
    </EuiPopover>;
};
EuiAutoRefreshButton.propTypes = {
  isPaused: PropTypes.bool,
  refreshInterval: PropTypes.number,
  minInterval: PropTypes.number,
  intervalUnits: PropTypes.any,
  onRefreshChange: PropTypes.func.isRequired,
  /**
     * `disabled` is also allowed
     */
  isDisabled: PropTypes.bool,
  /**
     * Reduces the time unit to a single letter
     */
  shortHand: PropTypes.bool,
  /**
     * Any of the named color palette options.
     */
  color: PropTypes.any,
  size: PropTypes.any,
  /**
     * Ensure the text of the button sits flush to the left, right, or both sides of its container
     */
  flush: PropTypes.any,
  /**
     * Force disables the button and changes the icon to a loading spinner
     */
  isLoading: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  buttonRef: PropTypes.any,
  /**
     * Object of props passed to the `<span>` wrapping the button's content
     */
  contentProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
     * Object of props passed to the `<span>` wrapping the content's text/children only (not icon)
     *
     * This span wrapper can be removed by passing `textProps={false}`.
     */
  textProps: PropTypes.oneOfType([PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    ref: PropTypes.any,
    "data-text": PropTypes.string
  }).isRequired, PropTypes.oneOf([false])]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiAutoRefresh.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/auto_refresh/auto_refresh.tsx',
    description: '',
    displayName: 'EuiAutoRefresh',
    methods: [],
    props: {
      isPaused: {
        defaultValue: {
          value: 'true'
        },
        description: 'Is refresh paused or running.',
        name: 'isPaused',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      refreshInterval: {
        defaultValue: {
          value: '1000'
        },
        description: 'Refresh interval in milliseconds.',
        name: 'refreshInterval',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      minInterval: {
        defaultValue: {
          value: '0'
        },
        description: 'Allows specifying a minimum interval in milliseconds',
        name: 'minInterval',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      intervalUnits: {
        defaultValue: null,
        description: 'By default, refresh interval units will be rounded up to next largest unit of time\n' + '(for example, 90 seconds will become 2m).\n' + '\n' + 'If you do not want this behavior, you can manually control the rendered unit via this prop.',
        name: 'intervalUnits',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "h"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"h"'
          }]
        }
      },
      onRefreshChange: {
        defaultValue: null,
        description: 'Passes back the updated state of `isPaused`, `refreshInterval`, and `intervalUnits`.',
        name: 'onRefreshChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ApplyRefreshInterval'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/auto_refresh.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: {
          value: 'true'
        },
        description: 'The input is `readOnly` by default because the input value is handled by the popover form.',
        name: 'readOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/auto_refresh.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_text/field_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_text/field_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_text/field_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_text/field_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_text/field_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLInputElement>'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_text/field_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', 'CommonProps', 'CommonEuiButtonEmptyProps', 'EuiButtonDisplayContentProps']
  };
} catch (e) {}
try {
  EuiAutoRefreshButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/auto_refresh/auto_refresh.tsx',
    description: '',
    displayName: 'EuiAutoRefreshButton',
    methods: [],
    props: {
      isPaused: {
        defaultValue: {
          value: 'true'
        },
        description: 'Is refresh paused or running.',
        name: 'isPaused',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      refreshInterval: {
        defaultValue: {
          value: '1000'
        },
        description: 'Refresh interval in milliseconds.',
        name: 'refreshInterval',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      minInterval: {
        defaultValue: {
          value: '0'
        },
        description: 'Allows specifying a minimum interval in milliseconds',
        name: 'minInterval',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      intervalUnits: {
        defaultValue: null,
        description: 'By default, refresh interval units will be rounded up to next largest unit of time\n' + '(for example, 90 seconds will become 2m).\n' + '\n' + 'If you do not want this behavior, you can manually control the rendered unit via this prop.',
        name: 'intervalUnits',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "h"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"h"'
          }]
        }
      },
      onRefreshChange: {
        defaultValue: null,
        description: 'Passes back the updated state of `isPaused`, `refreshInterval`, and `intervalUnits`.',
        name: 'onRefreshChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ApplyRefreshInterval'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '`disabled` is also allowed',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/auto_refresh.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      shortHand: {
        defaultValue: {
          value: 'false'
        },
        description: 'Reduces the time unit to a single letter',
        name: 'shortHand',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/auto_refresh.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      color: {
        defaultValue: {
          value: 'text'
        },
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      rel: {
        defaultValue: null,
        description: '',
        name: 'rel',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      size: {
        defaultValue: {
          value: 's'
        },
        description: '',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Force disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      contentProps: {
        defaultValue: null,
        description: "Object of props passed to the `<span>` wrapping the button's content",
        name: 'contentProps',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      textProps: {
        defaultValue: null,
        description: "Object of props passed to the `<span>` wrapping the content's text/children only (not icon)\n" + '\n' + 'This span wrapper can be removed by passing `textProps={false}`.',
        name: 'textProps',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: "false | (HTMLAttributes<HTMLSpanElement> & CommonProps & { ref?: Ref<HTMLSpanElement>; 'data-text'?: string; })"
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLAnchorElement | HTMLButtonElement>'
        }
      },
      flush: {
        defaultValue: null,
        description: 'Ensure the text of the button sits flush to the left, right, or both sides of its container',
        name: 'flush',
        parent: {
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_empty/button_empty.tsx',
          name: 'CommonEuiButtonEmptyProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "both"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"both"'
          }]
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', 'CommonProps', 'CommonEuiButtonEmptyProps', 'EuiButtonDisplayContentProps']
  };
} catch (e) {}