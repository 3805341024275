/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useEuiTheme } from '../../services';
import { euiToolTipStyles } from './tool_tip.styles';
export var EuiToolTipArrow = ({
  position,
  ...props
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiToolTipStyles(euiTheme);
  const cssStyles = [styles.euiToolTip__arrow, styles.arrowPositions[position]];
  return <div css={cssStyles} {...props} />;
};
EuiToolTipArrow.propTypes = {
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]).isRequired
};
try {
  EuiToolTipArrow.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tool_tip/tool_tip_arrow.tsx',
    description: '',
    displayName: 'EuiToolTipArrow',
    methods: [],
    props: {
      position: {
        defaultValue: null,
        description: '',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_arrow.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'ToolTipPositions',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}