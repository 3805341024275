function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
import "core-js/modules/es.object.assign.js";
function _objectDestructuringEmpty(t) {
  if (null == t) throw new TypeError("Cannot destructure " + t);
}
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from "prop-types";
import { EuiThemeProvider, useEuiTheme, useIsWithinBreakpoints } from '../../../../src/services';
import { EUI_THEMES } from '../../../../src/themes';
import { ThemeContext } from '../with_theme';
// @ts-ignore Not TS
import { GuideLocaleSelector } from '../guide_locale_selector';
import { EuiPopover, EuiHorizontalRule, EuiButton, EuiContextMenuPanel, EuiContextMenuItem } from '../../../../src/components';
export var GuideThemeSelector = function GuideThemeSelector(_ref) {
  var rest = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
  return ___EmotionJSX(ThemeContext.Consumer, null, function (context) {
    return ___EmotionJSX(GuideThemeSelectorComponent, _extends({
      context: context
    }, rest));
  });
};
GuideThemeSelector.propTypes = {
  onToggleLocale: PropTypes.func.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  context: PropTypes.any
};
var GuideThemeSelectorComponent = ({
  context,
  onToggleLocale,
  selectedLocale
}) => {
  const isMobileSize = useIsWithinBreakpoints(['xs', 's']);
  const [isPopoverOpen, setPopover] = useState(false);
  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };
  const closePopover = () => {
    setPopover(false);
  };
  const systemColorMode = useEuiTheme().colorMode.toLowerCase();
  const currentTheme = EUI_THEMES.find(theme => theme.value === (context.theme ?? systemColorMode)) || EUI_THEMES[0];
  const getIconType = value => {
    return value === currentTheme.value ? 'check' : 'empty';
  };
  const items = EUI_THEMES.map(theme => {
    return <EuiContextMenuItem key={theme.value} icon={getIconType(theme.value)} onClick={() => {
      closePopover();
      context.changeTheme(theme.value);
    }}>
        {theme.text}
      </EuiContextMenuItem>;
  });
  const button = <EuiThemeProvider colorMode="dark" wrapperProps={{
    cloneElement: true
  }}>
      <EuiButton size="s" iconType="arrowDown" iconSide="right" color="text" minWidth={0} onClick={onButtonClick}>
        {isMobileSize ? 'Theme' : currentTheme.text}
      </EuiButton>
    </EuiThemeProvider>;
  return <EuiPopover id="docsThemeSelector" repositionOnScroll button={button} isOpen={isPopoverOpen} closePopover={closePopover} panelPaddingSize="none" anchorPosition="downRight">
      <EuiContextMenuPanel size="s" items={items} />
      {location.host.includes('803') && <>
          <EuiHorizontalRule margin="none" />
          <div style={{
        padding: 8
      }}>
            <GuideLocaleSelector onToggleLocale={onToggleLocale} selectedLocale={selectedLocale} />
          </div>
        </>}
    </EuiPopover>;
};
GuideThemeSelectorComponent.propTypes = {
  onToggleLocale: PropTypes.func.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  context: PropTypes.any
};