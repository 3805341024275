import React from 'react';
export var BreadcrumbProps = () => <div />;
try {
  BreadcrumbProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/breadcrumbs/props.tsx',
    description: '',
    displayName: 'BreadcrumbProps',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      text: {
        defaultValue: null,
        description: 'Visible label of the breadcrumb',
        name: 'text',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      truncate: {
        defaultValue: null,
        description: 'Force a max-width on the breadcrumb text',
        name: 'truncate',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      color: {
        defaultValue: null,
        description: '@deprecated - if a custom color is wanted, use the `css` prop to pass custom css',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "ghost"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"ghost"'
          }]
        }
      },
      'aria-current': {
        defaultValue: null,
        description: 'Override the existing `aria-current` which defaults to `page` for the last breadcrumb',
        name: 'aria-current',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "time" | "true" | "false" | "page" | "step" | "location" | "date"'
        }
      },
      popoverContent: {
        defaultValue: null,
        description: 'Creates a breadcrumb that toggles a popover dialog. Takes any rendered node(s),\n' + 'or a render function that will pass callback allowing you to close the\n' + 'breadcrumb popover from within your popover content.\n' + '\n' + "If passed, both `href` and `onClick` will be ignored - the breadcrumb's\n" + 'click behavior should only trigger a popover.',
        name: 'popoverContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode | ((closePopover: () => void) => ReactNode)'
        }
      },
      popoverProps: {
        defaultValue: null,
        description: 'Allows customizing the popover if necessary. Accepts any props that\n' + '[EuiPopover](/#/layout/popover) accepts, except for props that control state.',
        name: 'popoverProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Omit<EuiPopoverProps, "button" | "isOpen" | "closePopover">'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}