/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { isValidElement } from 'react';
import PropTypes from "prop-types";
import { cloneElementWithCss } from '../../services';
import { euiTextAlignStyles as styles } from './text_align.styles';
export var ALIGNMENTS = ['left', 'right', 'center'];
export var EuiTextAlign = ({
  children,
  component: Component = 'div',
  textAlign = 'left',
  cloneElement = false,
  ...rest
}) => {
  const cssStyles = [styles.euiTextAlign, styles[textAlign]];
  const props = {
    css: cssStyles,
    ...rest
  };
  if (isValidElement(children) && cloneElement) {
    return cloneElementWithCss(children, props);
  } else {
    return <Component {...props}>{children}</Component>;
  }
};
EuiTextAlign.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * The HTML element/tag to render.
       * Use with care when nesting multiple components to ensure valid XHTML:
       * - `<div>` and other block tags are not valid to use inside `<p>`. If using the `<p>` tag, we recommend passing strings/text only.
       * - `<span>` is valid to be nested in any tag, and can have any tag nested within it.
       */
  component: PropTypes.oneOf(["div", "span", "p"]),
  /**
     * Applies text styling to the child element instead of rendering a parent wrapper.
     * Can only be used when wrapping a *single* child element/tag, and not raw text.
     */
  cloneElement: PropTypes.bool,
  /**
     * Applies horizontal text alignment
     * @default left
     */
  textAlign: PropTypes.any
};
try {
  EuiTextAlign.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/text/text_align.tsx',
    description: '',
    displayName: 'EuiTextAlign',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      component: {
        defaultValue: null,
        description: 'The HTML element/tag to render.\n' + 'Use with care when nesting multiple components to ensure valid XHTML:\n' + '- `<div>` and other block tags are not valid to use inside `<p>`. If using the `<p>` tag, we recommend passing strings/text only.\n' + '- `<span>` is valid to be nested in any tag, and can have any tag nested within it.',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "p" | "span"',
          value: [{
            value: '"div"'
          }, {
            value: '"p"'
          }, {
            value: '"span"'
          }]
        }
      },
      cloneElement: {
        defaultValue: {
          value: 'false'
        },
        description: 'Applies text styling to the child element instead of rendering a parent wrapper.\n' + 'Can only be used when wrapping a *single* child element/tag, and not raw text.',
        name: 'cloneElement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      textAlign: {
        defaultValue: {
          value: 'left'
        },
        description: 'Applies horizontal text alignment',
        name: 'textAlign',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "left" | "right"',
          value: [{
            value: '"center"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}