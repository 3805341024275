/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from "prop-types";
import dateMath from '@elastic/datemath';
import { useEuiPaddingCSS } from '../../../../global_styling';
import { useUpdateEffect, useGeneratedHtmlId } from '../../../../services';
import { useEuiI18n, EuiI18n } from '../../../i18n';
import { EuiScreenReaderOnly } from '../../../accessibility';
import { EuiFlexGroup, EuiFlexItem } from '../../../flex';
import { EuiForm, EuiFormRow, EuiSelect, EuiFieldNumber, EuiFieldText, EuiSwitch, EuiFormLabel } from '../../../form';
import { EuiPopoverFooter } from '../../../popover';
import { EuiSpacer } from '../../../spacer';
import { INVALID_DATE } from '../date_modes';
import { parseRelativeParts, toRelativeStringFromParts } from '../relative_utils';
export var EuiRelativeTab = ({
  timeOptions: {
    relativeOptions,
    relativeRoundingLabels
  },
  dateFormat,
  locale,
  value,
  onChange,
  roundUp,
  labelPrefix
}) => {
  const initialRelativeParts = useRef(parseRelativeParts(value));
  const {
    roundUnit
  } = initialRelativeParts.current;
  const [unit, setUnit] = useState(initialRelativeParts.current.unit);
  const onUnitChange = useCallback(event => {
    setUnit(event.target.value);
  }, []);
  const [round, setRound] = useState(initialRelativeParts.current.round);
  const onRoundChange = useCallback(event => {
    setRound(event.target.checked);
  }, []);
  const [count, setCount] = useState(initialRelativeParts.current.count);
  const onCountChange = useCallback(event => {
    const sanitizedValue = parseInt(event.target.value, 10);
    const count = isNaN(sanitizedValue) ? undefined : sanitizedValue;
    setCount(count);
  }, []);
  useUpdateEffect(() => {
    if (count === undefined || count < 0) return;
    const date = toRelativeStringFromParts({
      count,
      round: !!round,
      roundUnit,
      unit
    });
    onChange(date);
  }, [onChange, count, round, roundUnit, unit]);
  const invalidDate = value === INVALID_DATE;
  const invalidValue = count === undefined || count < 0;
  const isInvalid = invalidValue || invalidDate;
  const formattedValue = useMemo(() => {
    if (isInvalid) return '';
    const parsedValue = dateMath.parse(value, {
      roundUp
    });
    if (!parsedValue || !parsedValue.isValid()) return '';
    return parsedValue.locale(locale || 'en').format(dateFormat);
  }, [isInvalid, value, roundUp, locale, dateFormat]);
  const relativeDateInputNumberDescriptionId = useGeneratedHtmlId();
  const numberAriaLabel = useEuiI18n('euiRelativeTab.numberInputLabel', 'Time span amount');
  const numberInputError = useEuiI18n('euiRelativeTab.numberInputError', 'Must be >= 0');
  const dateInputError = useEuiI18n('euiRelativeTab.dateInputError', 'Must be a valid range');
  const unitSelectAriaLabel = useEuiI18n('euiRelativeTab.unitInputLabel', 'Relative time span');
  return <>
      <EuiForm css={useEuiPaddingCSS().s}>
        <EuiFlexGroup gutterSize="s" responsive={false}>
          <EuiFlexItem>
            <EuiFormRow isInvalid={isInvalid} error={invalidValue ? numberInputError : invalidDate ? dateInputError : null}>
              <EuiFieldNumber compressed aria-label={numberAriaLabel} aria-describedby={relativeDateInputNumberDescriptionId} data-test-subj="superDatePickerRelativeDateInputNumber" value={count} onChange={onCountChange} isInvalid={isInvalid} />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSelect compressed aria-label={unitSelectAriaLabel} data-test-subj="superDatePickerRelativeDateInputUnitSelector" value={unit} options={relativeOptions} onChange={onUnitChange} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />
        <EuiFieldText compressed value={formattedValue} readOnly prepend={<EuiFormLabel>{labelPrefix}</EuiFormLabel>} />
        <EuiScreenReaderOnly>
          <p id={relativeDateInputNumberDescriptionId}>
            <EuiI18n token="euiRelativeTab.fullDescription" default="The unit is changeable. Currently set to {unit}." values={{
            unit
          }} />
          </p>
        </EuiScreenReaderOnly>
      </EuiForm>
      <EuiPopoverFooter paddingSize="s">
        <EuiSwitch data-test-subj="superDatePickerRelativeDateRoundSwitch" label={relativeRoundingLabels[unit.substring(0, 1)]} checked={!!round} onChange={onRoundChange} />
      </EuiPopoverFooter>
    </>;
};
EuiRelativeTab.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  locale: PropTypes.any,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  roundUp: PropTypes.bool,
  labelPrefix: PropTypes.string.isRequired,
  timeOptions: PropTypes.shape({
    timeTenseOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    timeUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    relativeOptions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired, PropTypes.oneOf(["s+", "m+", "h+", "d+", "w+", "M+", "y+"]).isRequired]).isRequired
    }).isRequired).isRequired,
    relativeRoundingLabels: PropTypes.any.isRequired,
    refreshUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    commonDurationRanges: PropTypes.arrayOf(PropTypes.shape({
      end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
      label: PropTypes.string,
      start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
    }).isRequired).isRequired
  }).isRequired
};
try {
  EuiRelativeTab.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
    description: '',
    displayName: 'EuiRelativeTab',
    methods: [],
    props: {
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      locale: {
        defaultValue: null,
        description: '',
        name: 'locale',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        }],
        required: false,
        type: {
          name: 'LocaleSpecifier'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        }],
        required: true,
        type: {
          name: '(date: string) => void'
        }
      },
      roundUp: {
        defaultValue: null,
        description: '',
        name: 'roundUp',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      labelPrefix: {
        defaultValue: null,
        description: '',
        name: 'labelPrefix',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      timeOptions: {
        defaultValue: null,
        description: '',
        name: 'timeOptions',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/relative_tab.tsx',
          name: 'EuiRelativeTabProps'
        }],
        required: true,
        type: {
          name: 'TimeOptions'
        }
      }
    },
    extendedInterfaces: ['EuiRelativeTabProps']
  };
} catch (e) {}