import React from 'react';
import PropTypes from "prop-types";
export var EuiHeaderSectionsProp = () => <div />;
EuiHeaderSectionsProp.propTypes = {
  /**
     * An arry of items that will be wrapped in a #EuiHeaderSectionItem
     */
  items: PropTypes.arrayOf(PropTypes.node.isRequired),
  /**
     * Breadcrumbs in the header cannot be wrapped in a #EuiHeaderSection in order for truncation to work.
     * Simply pass the array of EuiBreadcrumb objects
     */
  breadcrumbs: PropTypes.arrayOf(PropTypes.any.isRequired),
  /**
     * Other props to pass to #EuiHeaderBreadcrumbs
     */
  breadcrumbProps: PropTypes.any
};
try {
  EuiHeaderSectionsProp.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/header/props.tsx',
    description: '',
    displayName: 'EuiHeaderSectionsProp',
    methods: [],
    props: {
      items: {
        defaultValue: null,
        description: 'An arry of items that will be wrapped in a #EuiHeaderSectionItem',
        name: 'items',
        parent: {
          fileName: 'eui/src/components/header/header.tsx',
          name: 'EuiHeaderSections'
        },
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'EuiHeaderSections'
        }],
        required: false,
        type: {
          name: 'ReactNode[]'
        }
      },
      breadcrumbs: {
        defaultValue: null,
        description: 'Breadcrumbs in the header cannot be wrapped in a #EuiHeaderSection in order for truncation to work.\n' + 'Simply pass the array of EuiBreadcrumb objects',
        name: 'breadcrumbs',
        parent: {
          fileName: 'eui/src/components/header/header.tsx',
          name: 'EuiHeaderSections'
        },
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'EuiHeaderSections'
        }],
        required: false,
        type: {
          name: 'EuiBreadcrumbProps[]'
        }
      },
      breadcrumbProps: {
        defaultValue: null,
        description: 'Other props to pass to #EuiHeaderBreadcrumbs',
        name: 'breadcrumbProps',
        parent: {
          fileName: 'eui/src/components/header/header.tsx',
          name: 'EuiHeaderSections'
        },
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'EuiHeaderSections'
        }],
        required: false,
        type: {
          name: 'Omit<EuiBreadcrumbsProps, "breadcrumbs">'
        }
      }
    },
    extendedInterfaces: ['EuiHeaderSections']
  };
} catch (e) {}