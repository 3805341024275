/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { euiFilterGroupStyles } from './filter_group.styles';
export var EuiFilterGroup = ({
  children,
  className,
  fullWidth = false,
  compressed,
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiFilterGroupStyles(euiTheme);
  const cssStyles = [styles.euiFilterGroup, fullWidth && styles.fullWidth, compressed ? styles.compressed : styles.uncompressed];
  const classes = classNames('euiFilterGroup', className);
  return <div className={classes} css={cssStyles} {...rest}>
      {children}
    </div>;
};
EuiFilterGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  children: PropTypes.node,
  /**
       * Expand the whole bar to fill its parent's width
       */
  fullWidth: PropTypes.bool,
  /**
       *  When `true`, creates a shorter height filter group matching that of `compressed` form controls
       */
  compressed: PropTypes.bool
};
try {
  EuiFilterGroup.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/filter_group/filter_group.tsx',
    description: '',
    displayName: 'EuiFilterGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: "Expand the whole bar to fill its parent's width",
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'When `true`, creates a shorter height filter group matching that of `compressed` form controls',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}