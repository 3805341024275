/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { EuiFilterButton } from '../../filter_group';
import { isNil } from '../../../services/predicate';
import { Query } from '../query';
export class IsFilter extends Component {
  resolveDisplay(clause) {
    const {
      name,
      negatedName
    } = this.props.config;
    if (isNil(clause)) {
      return {
        hasActiveFilters: false,
        name
      };
    }
    return Query.isMust(clause) ? {
      hasActiveFilters: true,
      name
    } : {
      hasActiveFilters: true,
      name: negatedName ? negatedName : `Not ${name}`
    };
  }
  valueChanged(field, checked) {
    const query = checked ? this.props.query.removeIsClause(field) : this.props.query.addMustIsClause(field);
    this.props.onChange(query);
  }
  render() {
    const {
      query,
      config
    } = this.props;
    const clause = query.getIsClause(config.field);
    const checked = !isNil(clause);
    const {
      hasActiveFilters,
      name
    } = this.resolveDisplay(clause);
    const onClick = () => {
      this.valueChanged(config.field, checked);
    };
    return <EuiFilterButton onClick={onClick} hasActiveFilters={hasActiveFilters} aria-pressed={!!hasActiveFilters}>
        {name}
      </EuiFilterButton>;
  }
}
IsFilter.propTypes = {
  index: PropTypes.number.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(["is"]).isRequired,
    field: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    negatedName: PropTypes.string,
    available: PropTypes.func
  }).isRequired,
  query: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};
try {
  IsFilter.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/search_bar/filters/is_filter.tsx',
    description: '',
    displayName: 'IsFilter',
    methods: [],
    props: {
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      config: {
        defaultValue: null,
        description: '',
        name: 'config',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: 'IsFilterConfigType'
        }
      },
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: 'Query'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/is_filter.tsx',
          name: 'IsFilterProps'
        }],
        required: true,
        type: {
          name: '(value: Query) => void'
        }
      }
    },
    extendedInterfaces: ['IsFilterProps']
  };
} catch (e) {}