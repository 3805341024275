const _excluded = ["className", "children", "title", "status"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
import PropTypes from "prop-types";
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
import "core-js/modules/es.array.map.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { EuiStep } from './step';
function renderSteps(steps, firstStepNumber, headingElement, titleSize) {
  return steps.map(function (step, index) {
    var className = step.className,
      children = step.children,
      title = step.title,
      status = step.status,
      rest = _objectWithoutProperties(step, _excluded);
    return ___EmotionJSX(EuiStep, _extends({
      className: className,
      key: index,
      headingElement: headingElement,
      step: firstStepNumber + index,
      title: title,
      titleSize: titleSize,
      status: status
    }, rest), children);
  });
}
export var EuiSteps = ({
  className,
  firstStepNumber = 1,
  headingElement = 'p',
  titleSize,
  steps,
  ...rest
}) => {
  const classes = classNames('euiSteps', className);
  return <div className={classes} {...rest}>
      {renderSteps(steps, firstStepNumber, headingElement, titleSize)}
    </div>;
};
EuiSteps.propTypes = {
  /**
     * An array of `EuiStep` objects excluding the `step` prop
     */
  steps: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  /**
     * The number the steps should begin from
     */
  firstStepNumber: PropTypes.number,
  /**
     * The HTML tag used for the title
     */
  headingElement: PropTypes.string,
  /**
     * Title sizing equivalent to **EuiTitle**, but only `m`, `s`, `xs` font sizes.
     * The `xxs` size reduces the size of the accompanying step indicator, but not the title itself.
     * @default s
     */
  titleSize: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiSteps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/steps/steps.tsx',
    description: '',
    displayName: 'EuiSteps',
    methods: [],
    props: {
      steps: {
        defaultValue: null,
        description: 'An array of `EuiStep` objects excluding the `step` prop',
        name: 'steps',
        parent: {
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        }],
        required: true,
        type: {
          name: 'EuiContainedStepProps[]'
        }
      },
      firstStepNumber: {
        defaultValue: {
          value: '1'
        },
        description: 'The number the steps should begin from',
        name: 'firstStepNumber',
        parent: {
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      headingElement: {
        defaultValue: {
          value: 'p'
        },
        description: 'The HTML tag used for the title',
        name: 'headingElement',
        parent: {
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      titleSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Title sizing equivalent to **EuiTitle**, but only `m`, `s`, `xs` font sizes.\n' + 'The `xxs` size reduces the size of the accompanying step indicator, but not the title itself.',
        name: 'titleSize',
        parent: {
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/steps.tsx',
          name: 'EuiStepsProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiStepsProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}