import React from 'react';
import PropTypes from "prop-types";
// Otherwise the props table lists the forwarded `ref`, which we don't actually want to document
export var EuiResizableButton = () => <></>;
EuiResizableButton.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Defaults to displaying a resizer for vertical (y-axis) resizing.
       * Set to `true` to display a resizer for horizontal (x-axis) resizing.
       */
  isHorizontal: PropTypes.bool,
  /**
       * By default, EuiResizableButton will show a grab handle to indicate resizability.
       * This indicator can be optionally hidden to show a plain border instead.
       */
  indicator: PropTypes.oneOf(["handle", "border"]),
  /**
       * Allows customizing the alignment of grab `handle` indicators (does nothing for
       * border indicators). Defaults to `center`, but consider using `start` for extremely
       * tall content that scrolls off-screen
       */
  alignIndicator: PropTypes.oneOf(["center", "start", "end"]),
  /**
       * When disabled, the resizer button will be completely hidden
       */
  disabled: PropTypes.bool
};
try {
  EuiResizableButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/resizable_container/_props.tsx',
    description: '',
    displayName: 'EuiResizableButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isHorizontal: {
        defaultValue: null,
        description: 'Defaults to displaying a resizer for vertical (y-axis) resizing.\n' + 'Set to `true` to display a resizer for horizontal (x-axis) resizing.',
        name: 'isHorizontal',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      indicator: {
        defaultValue: null,
        description: 'By default, EuiResizableButton will show a grab handle to indicate resizability.\n' + 'This indicator can be optionally hidden to show a plain border instead.',
        name: 'indicator',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"border" | "handle"',
          value: [{
            value: '"border"'
          }, {
            value: '"handle"'
          }]
        }
      },
      alignIndicator: {
        defaultValue: null,
        description: 'Allows customizing the alignment of grab `handle` indicators (does nothing for\n' + 'border indicators). Defaults to `center`, but consider using `start` for extremely\n' + 'tall content that scrolls off-screen',
        name: 'alignIndicator',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "end" | "start"',
          value: [{
            value: '"center"'
          }, {
            value: '"end"'
          }, {
            value: '"start"'
          }]
        }
      }
    },
    extendedInterfaces: ['ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}