/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useContext, useMemo, memo } from 'react';
import classNames from 'classnames';
import { DataGridSortedContext } from '../../utils/sorting';
import { DataGridCellPopoverContext } from './data_grid_cell_popover';
import { EuiDataGridCell } from './data_grid_cell';
/**
 * A DRY wrapper used by both custom and virtualized grid cells.
 * It grabs context,  determines the type of cell being rendered
 * (e.g. control vs data cell), & sets shared props between all cells
 */
export var CellWrapper = memo(({
  colIndex,
  visibleRowIndex,
  style,
  schema,
  schemaDetectors,
  pagination,
  columns,
  leadingControlColumns,
  trailingControlColumns,
  visibleColCount,
  columnWidths,
  defaultColumnWidth,
  renderCellValue,
  cellContext,
  renderCellPopover,
  interactiveCellId,
  setRowHeight,
  rowHeightsOptions,
  rowHeightUtils,
  rowManager,
  ...rest
}) => {
  const popoverContext = useContext(DataGridCellPopoverContext);
  const {
    getCorrectRowIndex
  } = useContext(DataGridSortedContext);
  const isFirstColumn = colIndex === 0;
  const isLastColumn = colIndex === visibleColCount - 1;
  const isLeadingControlColumn = colIndex < leadingControlColumns.length;
  const isTrailingControlColumn = colIndex >= leadingControlColumns.length + columns.length;
  const datacolIndex = colIndex - leadingControlColumns.length;
  const column = columns[datacolIndex];
  const columnId = column?.id;
  const textTransform = useMemo(() => schemaDetectors.filter(row => {
    return column?.schema ? column?.schema === row.type : columnId === row.type;
  })[0]?.textTransform, [columnId, column?.schema, schemaDetectors]);
  const sharedCellProps = useMemo(() => {
    const classes = classNames({
      'euiDataGridRowCell--firstColumn': isFirstColumn,
      'euiDataGridRowCell--lastColumn': isLastColumn,
      'euiDataGridRowCell--controlColumn': isLeadingControlColumn || isTrailingControlColumn,
      [`euiDataGridRowCell--${textTransform}`]: textTransform
    });
    return {
      rowIndex: getCorrectRowIndex(visibleRowIndex),
      visibleRowIndex,
      colIndex,
      interactiveCellId,
      className: classes,
      style,
      rowHeightsOptions,
      rowHeightUtils,
      setRowHeight: isFirstColumn ? setRowHeight : undefined,
      rowManager,
      popoverContext,
      pagination,
      cellContext
    };
  }, [colIndex, setRowHeight, visibleRowIndex, getCorrectRowIndex, interactiveCellId, style, rowHeightsOptions, rowHeightUtils, rowManager, popoverContext, pagination, cellContext, isFirstColumn, isLastColumn, isLeadingControlColumn, isTrailingControlColumn, textTransform]);
  if (isLeadingControlColumn) {
    const leadingColumn = leadingControlColumns[colIndex];
    const {
      id,
      rowCellRender
    } = leadingColumn;
    return <EuiDataGridCell {...sharedCellProps} columnId={id} width={leadingColumn.width} renderCellValue={rowCellRender} isExpandable={false} {...rest} />;
  } else if (isTrailingControlColumn) {
    const columnOffset = columns.length + leadingControlColumns.length;
    const trailingcolIndex = colIndex - columnOffset;
    const trailingColumn = trailingControlColumns[trailingcolIndex];
    const {
      id,
      rowCellRender
    } = trailingColumn;
    return <EuiDataGridCell {...sharedCellProps} columnId={id} width={trailingColumn.width} renderCellValue={rowCellRender} isExpandable={false} {...rest} />;
  } else {
    // this is a normal data cell
    const columnType = schema[columnId] ? schema[columnId].columnType : null;
    const isExpandable = column?.isExpandable !== undefined ? column?.isExpandable : true;
    const width = columnWidths[columnId] || defaultColumnWidth;
    return <EuiDataGridCell {...sharedCellProps} columnId={columnId} column={column} columnType={columnType} width={width || undefined} renderCellValue={renderCellValue} renderCellPopover={renderCellPopover} interactiveCellId={interactiveCellId} isExpandable={isExpandable} {...rest} />;
  }
});
CellWrapper.displayName = 'CellWrapper';
try {
  CellWrapper.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/datagrid/body/cell/data_grid_cell_wrapper.tsx',
    description: 'A DRY wrapper used by both custom and virtualized grid cells.\n' + 'It grabs context,  determines the type of cell being rendered\n' + '(e.g. control vs data cell), & sets shared props between all cells',
    displayName: 'CellWrapper',
    methods: [],
    props: {
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      renderCellValue: {
        defaultValue: null,
        description: '',
        name: 'renderCellValue',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: true,
        type: {
          name: 'RenderCellValue'
        }
      },
      cellContext: {
        defaultValue: null,
        description: '',
        name: 'cellContext',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'CellContext'
        }
      },
      renderCellPopover: {
        defaultValue: null,
        description: '',
        name: 'renderCellPopover',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'JSXElementConstructor<EuiDataGridCellPopoverElementProps> | ((props: EuiDataGridCellPopoverElementProps) => ReactNode)'
        }
      },
      rowHeightsOptions: {
        defaultValue: null,
        description: '',
        name: 'rowHeightsOptions',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'EuiDataGridRowHeightsOptions'
        }
      },
      column: {
        defaultValue: null,
        description: '',
        name: 'column',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'EuiDataGridColumn'
        }
      },
      visibleRowIndex: {
        defaultValue: null,
        description: '',
        name: 'visibleRowIndex',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      colIndex: {
        defaultValue: null,
        description: '',
        name: 'colIndex',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      interactiveCellId: {
        defaultValue: null,
        description: '',
        name: 'interactiveCellId',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      setRowHeight: {
        defaultValue: null,
        description: '',
        name: 'setRowHeight',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: '(height: number) => void'
        }
      },
      rowHeightUtils: {
        defaultValue: null,
        description: '',
        name: 'rowHeightUtils',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'RowHeightUtilsType'
        }
      },
      rowManager: {
        defaultValue: null,
        description: '',
        name: 'rowManager',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'EuiDataGridRowManager'
        }
      },
      gridStyles: {
        defaultValue: null,
        description: '',
        name: 'gridStyles',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridStyle'
        }
      },
      columns: {
        defaultValue: null,
        description: '',
        name: 'columns',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridColumn[]'
        }
      },
      leadingControlColumns: {
        defaultValue: null,
        description: '',
        name: 'leadingControlColumns',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridControlColumn[]'
        }
      },
      trailingControlColumns: {
        defaultValue: null,
        description: '',
        name: 'trailingControlColumns',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridControlColumn[]'
        }
      },
      schemaDetectors: {
        defaultValue: null,
        description: '',
        name: 'schemaDetectors',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridSchemaDetector[]'
        }
      },
      pagination: {
        defaultValue: null,
        description: '',
        name: 'pagination',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        }, {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'Required<EuiDataGridPaginationProps>'
        }
      },
      schema: {
        defaultValue: null,
        description: '',
        name: 'schema',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridSchema'
        }
      },
      visibleColCount: {
        defaultValue: null,
        description: '',
        name: 'visibleColCount',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridBodyProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      columnWidths: {
        defaultValue: null,
        description: '',
        name: 'columnWidths',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderRowPropsSpecificProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderRowPropsSpecificProps'
        }],
        required: true,
        type: {
          name: 'EuiDataGridColumnWidths'
        }
      },
      defaultColumnWidth: {
        defaultValue: null,
        description: '',
        name: 'defaultColumnWidth',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderRowPropsSpecificProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderRowPropsSpecificProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      rowIndex: {
        defaultValue: null,
        description: '',
        name: 'rowIndex',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      columnId: {
        defaultValue: null,
        description: '',
        name: 'columnId',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      columnType: {
        defaultValue: null,
        description: '',
        name: 'columnType',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      width: {
        defaultValue: null,
        description: '',
        name: 'width',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      isExpandable: {
        defaultValue: null,
        description: '',
        name: 'isExpandable',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      popoverContext: {
        defaultValue: null,
        description: '',
        name: 'popoverContext',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: 'DataGridCellPopoverContextShape'
        }
      },
      getRowHeight: {
        defaultValue: null,
        description: '',
        name: 'getRowHeight',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridCellProps'
        }],
        required: false,
        type: {
          name: '(rowIndex: number) => number'
        }
      }
    },
    extendedInterfaces: ['EuiDataGridCellProps', 'EuiDataGridBodyProps', 'EuiDataGridHeaderRowPropsSpecificProps']
  };
} catch (e) {}