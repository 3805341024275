/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component, createRef } from 'react';
import PropTypes from "prop-types";
import { htmlIdGenerator } from '../../../services';
import { EuiTabs } from '../tabs';
import { EuiTab } from '../tab';
/**
 * Marked as const so type is `['initial', 'selected']` instead of `string[]`
 */
export var AUTOFOCUS = ['initial', 'selected'];
export class EuiTabbedContent extends Component {
  static defaultProps = {
    autoFocus: 'initial'
  };
  rootId = htmlIdGenerator()();
  tabsRef = createRef();
  constructor(props) {
    super(props);
    const {
      initialSelectedTab,
      selectedTab,
      tabs
    } = props;

    // Only track selection state if it's not controlled externally.
    let selectedTabId;
    if (!selectedTab) {
      selectedTabId = initialSelectedTab?.id || tabs[0].id;
    }
    this.state = {
      selectedTabId,
      inFocus: false
    };
  }
  focusTab = () => {
    const targetTab = this.tabsRef.current.querySelector(`#${this.state.selectedTabId}`);
    targetTab.focus();
  };
  initializeFocus = () => {
    if (!this.state.inFocus && this.props.autoFocus === 'selected') {
      // Must wait for setState to finish before calling `.focus()`
      // as the focus call triggers a blur on the first tab
      this.setState({
        inFocus: true
      }, () => {
        this.focusTab();
      });
    }
  };
  removeFocus = blurEvent => {
    // only set inFocus to false if the wrapping div doesn't contain the now-focusing element
    const currentTarget = blurEvent.currentTarget;
    const relatedTarget = blurEvent.relatedTarget;
    if (currentTarget.contains(relatedTarget) === false) {
      this.setState({
        inFocus: false
      });
    }
  };
  onTabClick = selectedTab => {
    const {
      onTabClick,
      selectedTab: externalSelectedTab
    } = this.props;
    if (onTabClick) {
      onTabClick(selectedTab);
    }

    // Only track selection state if it's not controlled externally.
    if (!externalSelectedTab) {
      this.setState({
        selectedTabId: selectedTab.id
      }, () => {
        this.focusTab();
      });
    }
  };
  render() {
    const {
      className,
      expand,
      initialSelectedTab,
      onTabClick,
      selectedTab: externalSelectedTab,
      size,
      tabs,
      autoFocus,
      ...rest
    } = this.props;

    // Allow the consumer to control tab selection.
    const selectedTab = externalSelectedTab || tabs.find(tab => tab.id === this.state.selectedTabId) || tabs[0]; // Fall back to the first tab if a selected tab can't be found

    const {
      content: selectedTabContent,
      id: selectedTabId
    } = selectedTab;
    return <div className={className} {...rest}>
        <EuiTabs ref={this.tabsRef} expand={expand} size={size} onFocus={this.initializeFocus} onBlur={this.removeFocus}>
          {tabs.map(tab => {
          const {
            id,
            name,
            content,
            // eslint-disable-line no-unused-vars
            ...tabProps
          } = tab;
          const props = {
            key: id,
            id,
            ...tabProps,
            onClick: () => this.onTabClick(tab),
            isSelected: tab === selectedTab,
            'aria-controls': `${this.rootId}`
          };
          return <EuiTab {...props}>{name}</EuiTab>;
        })}
        </EuiTabs>

        <div role="tabpanel" id={`${this.rootId}`} aria-labelledby={selectedTabId}>
          {selectedTabContent}
        </div>
      </div>;
  }
}
EuiTabbedContent.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * When tabbing into the tabs, set the focus on `initial` for the first tab,
       * or `selected` for the currently selected tab. Best use case is for inside of
       * overlay content like popovers or flyouts.
       */
  autoFocus: PropTypes.oneOf(["initial", "selected"]),
  /**
       * Evenly stretches each tab to fill the horizontal space
       */
  expand: PropTypes.bool,
  /**
       * Use this prop to set the initially selected tab while letting the tabbed content component
       * control selection state internally
       */
  initialSelectedTab: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    /**
       * Places content before the tab content/children.
       * Will be excluded from interactive effects.
       */
    prepend: PropTypes.node,
    /**
       * Places content after the tab content/children.
       * Will be excluded from interactive effects.
       */
    append: PropTypes.node,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  onTabClick: PropTypes.func,
  /**
       * Use this prop if you want to control selection state within the owner component
       */
  selectedTab: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    prepend: PropTypes.node,
    append: PropTypes.node,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  size: PropTypes.any,
  /**
       * Each tab needs id and content properties, so we can associate it with its panel for accessibility.
       * The name property (a node) is also required to display to the user.
       */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    isSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    prepend: PropTypes.node,
    append: PropTypes.node,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired).isRequired
};
try {
  AUTOFOCUS.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
    description: "Marked as const so type is `['initial', 'selected']` instead of `string[]`",
    displayName: 'AUTOFOCUS',
    methods: [],
    props: {},
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}
try {
  EuiTabbedContent.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
    description: '',
    displayName: 'EuiTabbedContent',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      autoFocus: {
        defaultValue: {
          value: 'initial'
        },
        description: 'When tabbing into the tabs, set the focus on `initial` for the first tab,\n' + 'or `selected` for the currently selected tab. Best use case is for inside of\n' + 'overlay content like popovers or flyouts.',
        name: 'autoFocus',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"initial" | "selected"',
          value: [{
            value: '"initial"'
          }, {
            value: '"selected"'
          }]
        }
      },
      expand: {
        defaultValue: null,
        description: 'Evenly stretches each tab to fill the horizontal space',
        name: 'expand',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialSelectedTab: {
        defaultValue: null,
        description: 'Use this prop to set the initially selected tab while letting the tabbed content component\n' + 'control selection state internally',
        name: 'initialSelectedTab',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiTabbedContentTab'
        }
      },
      onTabClick: {
        defaultValue: null,
        description: '',
        name: 'onTabClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(selectedTab: EuiTabbedContentTab) => void'
        }
      },
      selectedTab: {
        defaultValue: null,
        description: 'Use this prop if you want to control selection state within the owner component',
        name: 'selectedTab',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiTabbedContentTab'
        }
      },
      size: {
        defaultValue: null,
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      tabs: {
        defaultValue: null,
        description: 'Each tab needs id and content properties, so we can associate it with its panel for accessibility.\n' + 'The name property (a node) is also required to display to the user.',
        name: 'tabs',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabbed_content/tabbed_content.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'EuiTabbedContentTab[]'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}