export var DefaultPluginsConfig = function DefaultPluginsConfig() {
  return null;
};
import PropTypes from "prop-types";
DefaultPluginsConfig.propTypes = {
  exclude: PropTypes.any,
  parsingConfig: PropTypes.any,
  processingConfig: PropTypes.any
};
export var DefaultParsingPluginsConfig = function DefaultParsingPluginsConfig() {
  return null;
};
export var DefaultProcessingPluginsConfig = function DefaultProcessingPluginsConfig() {
  return null;
};
export var EuiMarkdownLinkValidatorOptions = () => null;
try {
  DefaultPluginsConfig.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/markdown_editor/markdown_plugin_props.tsx',
    description: '',
    displayName: 'DefaultPluginsConfig',
    methods: [],
    props: {
      exclude: {
        defaultValue: null,
        description: '',
        name: 'exclude',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/markdown_editor/markdown_plugin_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ExcludableDefaultPlugins',
          value: [{
            value: '"checkbox"'
          }, {
            value: '"tooltip"'
          }, {
            value: '"emoji"'
          }, {
            value: '"lineBreaks"'
          }, {
            value: '"linkValidator"'
          }]
        }
      },
      parsingConfig: {
        defaultValue: null,
        description: '',
        name: 'parsingConfig',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/markdown_editor/markdown_plugin_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'DefaultParsingPluginsConfig'
        }
      },
      processingConfig: {
        defaultValue: null,
        description: '',
        name: 'processingConfig',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/markdown_editor/markdown_plugin_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'DefaultProcessingPluginsConfig'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  DefaultParsingPluginsConfig.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/markdown_editor/markdown_plugin_props.tsx',
    description: '',
    displayName: 'DefaultParsingPluginsConfig',
    methods: [],
    props: {
      emoji: {
        defaultValue: {
          value: '{ emoticon: false }'
        },
        description: 'Allows enabling emoji rendering for emoticons such as :) and :(',
        name: 'emoji',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_default_plugins/parsing_plugins.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ emoticon?: boolean; }'
        }
      },
      linkValidator: {
        defaultValue: null,
        description: 'Allows configuring the `allowRelative` and `allowProtocols` of\n' + '#EuiMarkdownLinkValidatorOptions',
        name: 'linkValidator',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_default_plugins/parsing_plugins.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiMarkdownLinkValidatorOptions'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  DefaultProcessingPluginsConfig.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/markdown_editor/markdown_plugin_props.tsx',
    description: '',
    displayName: 'DefaultProcessingPluginsConfig',
    methods: [],
    props: {
      linkProps: {
        defaultValue: null,
        description: 'Allows customizing all formatted links.\n' + 'Accepts any prop that [EuiLink](/#/navigation/link) or any anchor link tag accepts.\n' + 'Useful for, e.g. setting `target="_blank"` on all links',
        name: 'linkProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_default_plugins/processing_plugins.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<(DisambiguateSet<EuiLinkButtonProps, EuiLinkAnchorProps> & EuiLinkAnchorProps) | (DisambiguateSet<...> & EuiLinkButtonProps)>'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  EuiMarkdownLinkValidatorOptions.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/markdown_editor/markdown_plugin_props.tsx',
    description: '',
    displayName: 'EuiMarkdownLinkValidatorOptions',
    methods: [],
    props: {
      allowRelative: {
        defaultValue: {
          value: 'true'
        },
        description: 'Allow or disallow relative links (links that begin with a `/`)',
        name: 'allowRelative',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_link_validator.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      allowProtocols: {
        defaultValue: {
          value: "['https:', 'http:', 'mailto:']"
        },
        description: 'Allow or disallow specific [URL protocols or schemes](https://developer.mozilla.org/en-US/docs/Web/URI/Schemes)',
        name: 'allowProtocols',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/plugins/markdown_link_validator.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}