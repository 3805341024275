/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { Component } from 'react';
import PropTypes from "prop-types";
export class EuiDelayRender extends Component {
  static defaultProps = {
    delay: 500
  };
  delayID;
  toBeDelayed = true;
  constructor(props) {
    super(props);
    this.state = {
      toggle: false
    };
  }
  shouldUpdate() {
    this.setState(({
      toggle
    }) => ({
      toggle: !toggle
    }));
  }
  startDelaying = () => {
    window.clearTimeout(this.delayID);
    this.toBeDelayed = true;
    this.delayID = window.setTimeout(this.stopDelaying, this.props.delay);
  };
  stopDelaying = () => {
    window.clearTimeout(this.delayID);
    this.toBeDelayed = false;
    this.shouldUpdate();
  };
  componentDidMount() {
    this.startDelaying();
  }
  shouldComponentUpdate() {
    if (this.toBeDelayed) {
      this.startDelaying();
    }
    return true;
  }
  componentWillUnmount() {
    this.stopDelaying();
  }
  componentDidUpdate() {
    this.toBeDelayed = true;
  }
  render() {
    return !this.toBeDelayed ? this.props.children : null;
  }
}
EuiDelayRender.propTypes = {
  delay: PropTypes.number.isRequired
};
try {
  EuiDelayRender.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/delay_render/delay_render.tsx',
    description: '',
    displayName: 'EuiDelayRender',
    methods: [],
    props: {
      delay: {
        defaultValue: {
          value: '500'
        },
        description: '',
        name: 'delay',
        parent: {
          fileName: 'eui/src/components/delay_render/delay_render.tsx',
          name: 'EuiDelayRenderProps'
        },
        declarations: [{
          fileName: 'eui/src/components/delay_render/delay_render.tsx',
          name: 'EuiDelayRenderProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['EuiDelayRenderProps']
  };
} catch (e) {}