import React, { useState } from 'react';
import PropTypes from "prop-types";
import { EuiIcon, EuiSpacer, EuiText, EuiThemeProvider, useEuiTheme, EuiButtonGroup } from '../../../../../src';
var Box = ({
  children
}) => {
  const {
    euiTheme
  } = useEuiTheme();
  return <EuiText css={{
    background: euiTheme.colors.lightShade,
    padding: euiTheme.size.xl
  }}>
      <p>{children}</p>
    </EuiText>;
};
Box.propTypes = {
  children: PropTypes.node.isRequired
};
export default (() => {
  const boxColorButtons = [{
    id: 'light',
    label: 'Light'
  }, {
    id: 'dark',
    label: 'Dark'
  }, {
    id: 'inverse',
    label: 'Inverse'
  }];
  const [boxColorModeSelected, setBoxColorMode] = useState('light');
  const onChange = colorMode => {
    setBoxColorMode(colorMode);
  };
  return <>
      <EuiButtonGroup legend="Change color mode" options={boxColorButtons} idSelected={boxColorModeSelected} onChange={id => onChange(id)} />

      <EuiSpacer />

      <EuiThemeProvider colorMode={boxColorModeSelected}>
        <Box>
          <EuiIcon type="faceHappy" /> The colors of this box is in{' '}
          <strong>{boxColorModeSelected}</strong> color mode
        </Box>
      </EuiThemeProvider>
    </>;
});