/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import { highlight } from 'refractor';
import classNames from 'classnames';
import { DEFAULT_LANGUAGE, checkSupportedLanguage, getHtmlContent } from './utils';
import { useEuiMemoizedStyles } from '../../services';
import { euiCodeStyles } from './code.styles';
export var EuiCode = ({
  transparentBackground = false,
  language: _language = DEFAULT_LANGUAGE,
  children,
  className,
  ...rest
}) => {
  const language = useMemo(() => checkSupportedLanguage(_language), [_language]);
  const data = useMemo(() => {
    if (typeof children !== 'string') {
      return [];
    }
    return highlight(children, language);
  }, [children, language]);
  const content = useMemo(() => getHtmlContent(data, children), [data, children]);
  const classes = classNames('euiCode', className);
  const styles = useEuiMemoizedStyles(euiCodeStyles);
  const cssStyles = [styles.euiCode, transparentBackground && styles.transparentBackground];
  return <code className={classes} css={cssStyles} data-code-language={language} {...rest}>
      {content}
    </code>;
};
EuiCode.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Sets the syntax highlighting for a specific language
       * @see [https://prismjs.com/#supported-languages](https://prismjs.com/#supported-languages) for options
       */
  language: PropTypes.string,
  transparentBackground: PropTypes.bool
};
try {
  EuiCode.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/code/code.tsx',
    description: '',
    displayName: 'EuiCode',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      language: {
        defaultValue: null,
        description: 'Sets the syntax highlighting for a specific language\n' + '@see [https://prismjs.com/#supported-languages](https://prismjs.com/#supported-languages) for options',
        name: 'language',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/utils.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      transparentBackground: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'transparentBackground',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/utils.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}