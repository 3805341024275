/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles, useIsWithinBreakpoints } from '../../services';
import { EuiDescriptionListContext } from './description_list_context';
import { EuiDescriptionListTitle } from './description_list_title';
import { EuiDescriptionListDescription } from './description_list_description';
import { euiDescriptionListStyles } from './description_list.styles';
export var EuiDescriptionList = ({
  align = 'left',
  children,
  className,
  style,
  compressed = false,
  descriptionProps,
  listItems,
  textStyle = 'normal',
  titleProps,
  type: _type = 'row',
  rowGutterSize = 's',
  columnGutterSize = 's',
  columnWidths,
  ...rest
}) => {
  const showResponsiveColumns = useIsWithinBreakpoints(['xs', 's']);
  const type = useMemo(() => {
    if (_type === 'responsiveColumn') {
      return showResponsiveColumns ? 'row' : 'column';
    } else {
      return _type;
    }
  }, [_type, showResponsiveColumns]);
  const styles = useEuiMemoizedStyles(euiDescriptionListStyles);
  const cssStyles = [styles.euiDescriptionList, styles[type], styles[align], type === 'column' && styles.rowGap[rowGutterSize], type === 'column' && styles.columnGap[columnGutterSize]];
  const inlineStyles = useMemo(() => {
    if (type === 'column' && columnWidths) {
      // Leave string values as is - e.g. if a consumer passes in a specific '200px' or 'minmax()'
      const convertNumbersToFr = value => typeof value === 'number' ? `${value}fr` : value;
      const titleWidth = convertNumbersToFr(columnWidths[0]);
      const descriptionWidth = convertNumbersToFr(columnWidths[1]);
      return {
        gridTemplateColumns: `${titleWidth} ${descriptionWidth}`,
        ...style
      };
    }
    return style;
  }, [style, type, columnWidths]);
  const classes = classNames('euiDescriptionList', className);
  const renderedListItems = useMemo(() => {
    if (listItems) {
      return listItems.map((item, index) => {
        return [<EuiDescriptionListTitle key={`title-${index}`} {...titleProps}>
            {item.title}
          </EuiDescriptionListTitle>, <EuiDescriptionListDescription key={`description-${index}`} {...descriptionProps}>
            {item.description}
          </EuiDescriptionListDescription>];
      });
    }
  }, [listItems, descriptionProps, titleProps]);
  return <EuiDescriptionListContext.Provider value={{
    type,
    compressed,
    textStyle,
    align,
    rowGutterSize
  }}>
      <dl className={classes} css={cssStyles} style={inlineStyles} {...rest} data-type={_type}>
        {listItems ? renderedListItems : children}
      </dl>
    </EuiDescriptionListContext.Provider>;
};
EuiDescriptionList.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  listItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired
  }).isRequired),
  /**
     * Text alignment
     */
  align: PropTypes.any,
  /**
     * Smaller text and condensed spacing
     */
  compressed: PropTypes.bool,
  /**
     * How should the content be styled, by default
     * this will emphasize the title
     */
  textStyle: PropTypes.oneOf(["normal", "reverse"]),
  /**
     * How each item should be laid out
     */
  type: PropTypes.any,
  /**
     * Props object to be passed to `EuiDescriptionListTitle`
     */
  titleProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
     * Props object to be passed to `EuiDescriptionListDescription`
     */
  descriptionProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
     * Allows customizing the vertical spacing between rows.
     */
  rowGutterSize: PropTypes.any,
  /**
     * Allows customizing the horizontal spacing between columns.
     *
     * Only applies to `column` and `responsiveColumn` types.
     */
  columnGutterSize: PropTypes.any,
  /**
     * Allows customizing specific column widths (e.g. `['100px', '200px']`). The first
     * array value applies to the title column, and the second applies to the description column.
     *
     * Passing numbers instead of CSS width strings will use a ratio of widths.
     * For example, [1, 3] will render a description column 3x the width of the title column.
     * In other words, descriptions will have a width of `75%` and titles will have a width of `25%`.
     *
     * Only applies to `column` and `responsiveColumn` types.
     *
     * _Advanced usage note:_ column width strings also accept [CSS grid special units,
     * sizing, keywords, and sizing functions](https://css-tricks.com/snippets/css/complete-guide-grid/#aa-special-units-functions).
     */
  columnWidths: PropTypes.any
};
try {
  EuiDescriptionList.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/description_list/description_list.tsx',
    description: '',
    displayName: 'EuiDescriptionList',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      listItems: {
        defaultValue: null,
        description: '',
        name: 'listItems',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: '{ title: ReactNode; description: ReactNode; }[]'
        }
      },
      align: {
        defaultValue: {
          value: 'left'
        },
        description: 'Text alignment',
        name: 'align',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "left"',
          value: [{
            value: '"center"'
          }, {
            value: '"left"'
          }]
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'Smaller text and condensed spacing',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      textStyle: {
        defaultValue: {
          value: 'normal'
        },
        description: 'How should the content be styled, by default\n' + 'this will emphasize the title',
        name: 'textStyle',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"reverse" | "normal"',
          value: [{
            value: '"reverse"'
          }, {
            value: '"normal"'
          }]
        }
      },
      type: {
        defaultValue: null,
        description: 'How each item should be laid out',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "inline" | "column" | "responsiveColumn"',
          value: [{
            value: '"row"'
          }, {
            value: '"inline"'
          }, {
            value: '"column"'
          }, {
            value: '"responsiveColumn"'
          }]
        }
      },
      titleProps: {
        defaultValue: null,
        description: 'Props object to be passed to `EuiDescriptionListTitle`',
        name: 'titleProps',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLElement> & CommonProps'
        }
      },
      descriptionProps: {
        defaultValue: null,
        description: 'Props object to be passed to `EuiDescriptionListDescription`',
        name: 'descriptionProps',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLElement> & CommonProps'
        }
      },
      rowGutterSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Allows customizing the vertical spacing between rows.',
        name: 'rowGutterSize',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      columnGutterSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Allows customizing the horizontal spacing between columns.\n' + '\n' + 'Only applies to `column` and `responsiveColumn` types.',
        name: 'columnGutterSize',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      columnWidths: {
        defaultValue: null,
        description: "Allows customizing specific column widths (e.g. `['100px', '200px']`). The first\n" + 'array value applies to the title column, and the second applies to the description column.\n' + '\n' + 'Passing numbers instead of CSS width strings will use a ratio of widths.\n' + 'For example, [1, 3] will render a description column 3x the width of the title column.\n' + 'In other words, descriptions will have a width of `75%` and titles will have a width of `25%`.\n' + '\n' + 'Only applies to `column` and `responsiveColumn` types.\n' + '\n' + '_Advanced usage note:_ column width strings also accept [CSS grid special units,\n' + 'sizing, keywords, and sizing functions](https://css-tricks.com/snippets/css/complete-guide-grid/#aa-special-units-functions).',
        name: 'columnWidths',
        parent: {
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        },
        declarations: [{
          fileName: 'eui/src/components/description_list/description_list_types.ts',
          name: 'EuiDescriptionListProps'
        }],
        required: false,
        type: {
          name: '[string | number, string | number]'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiDescriptionListProps']
  };
} catch (e) {}