/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import classNames from 'classnames';
import PropTypes from "prop-types";
import React from 'react';
import { useEuiTheme } from '../../services';
import { EuiStepNumber } from './step_number';
import { useI18nCompleteStep, useI18nCurrentStep, useI18nDisabledStep, useI18nIncompleteStep, useI18nStep, useI18nWarningStep, useI18nErrorsStep, useI18nLoadingStep } from './step_strings';
import { euiStepHorizontalStyles, euiStepHorizontalNumberStyles, euiStepHorizontalTitleStyles } from './step_horizontal.styles';
// The titleSize map is not 1 to 1; small == xs on the titleSize map
var stepNumberSizeMap = {
  xs: 'none',
  s: 'xs',
  m: 'm'
};
export var EuiStepHorizontal = ({
  className,
  step = 1,
  title,
  onClick,
  disabled,
  status = 'incomplete',
  size = 'm',
  ...rest
}) => {
  if (disabled) status = 'disabled';
  const classes = classNames('euiStepHorizontal', className);
  const euiTheme = useEuiTheme();
  const styles = euiStepHorizontalStyles(euiTheme);
  const cssStyles = [styles.euiStepHorizontal, styles[size], status === 'disabled' ? styles.disabled : styles.enabled];
  const numberStyles = euiStepHorizontalNumberStyles(euiTheme);
  const cssNumberStyles = [numberStyles.euiStepHorizontal__number];
  const titleStyles = euiStepHorizontalTitleStyles(euiTheme);
  const cssTitleStyles = [titleStyles.euiStepHorizontal__title, status === 'disabled' && titleStyles.disabled];
  const titleAttrsMap = {
    step: useI18nStep({
      number: step,
      title
    }),
    current: useI18nCurrentStep({
      number: step,
      title
    }),
    disabled: useI18nDisabledStep({
      number: step,
      title
    }),
    incomplete: useI18nIncompleteStep({
      number: step,
      title
    }),
    complete: useI18nCompleteStep({
      number: step,
      title
    }),
    warning: useI18nWarningStep({
      number: step,
      title
    }),
    danger: useI18nErrorsStep({
      number: step,
      title
    }),
    loading: useI18nLoadingStep({
      number: step,
      title
    })
  };
  const titleAttr = titleAttrsMap[status || 'step'];
  const onStepClick = event => {
    if (!disabled) onClick(event);
  };
  return <button aria-disabled={status === 'disabled' ? true : undefined} className={classes} title={titleAttr} onClick={onStepClick} disabled={disabled} css={cssStyles} data-step-status={status} {...rest}>
      <EuiStepNumber className="euiStepHorizontal__number" status={status} number={step} titleSize={stepNumberSizeMap[size]} css={cssNumberStyles} />

      <span className="euiStepHorizontal__title" css={cssTitleStyles}>
        {title}
      </span>
    </button>;
};
EuiStepHorizontal.propTypes = {
  onClick: PropTypes.func.isRequired,
  /**
     * Makes the whole step button disabled.
     */
  disabled: PropTypes.bool,
  /**
     * The number of the step in the list of steps
     */
  step: PropTypes.number,
  title: PropTypes.string,
  /**
     * Visual representation of the step number indicator.
     * May replace the number provided in props.step with alternate styling.
     * The `disabled` prop will override this.
     */
  status: PropTypes.any,
  size: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiStepHorizontal.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/steps/step_horizontal.tsx',
    description: '',
    displayName: 'EuiStepHorizontal',
    methods: [],
    props: {
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        }],
        required: true,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      disabled: {
        defaultValue: null,
        description: 'Makes the whole step button disabled.',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number of the step in the list of steps',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      status: {
        defaultValue: {
          value: 'incomplete'
        },
        description: 'Visual representation of the step number indicator.\n' + 'May replace the number provided in props.step with alternate styling.\n' + 'The `disabled` prop will override this.',
        name: 'status',
        parent: {
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"warning" | "danger" | "disabled" | "current" | "loading" | "incomplete" | "complete"',
          value: [{
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"disabled"'
          }, {
            value: '"current"'
          }, {
            value: '"loading"'
          }, {
            value: '"incomplete"'
          }, {
            value: '"complete"'
          }]
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_horizontal.tsx',
          name: 'EuiStepHorizontalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiStepHorizontalProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'ButtonHTMLAttributes', 'CommonProps']
  };
} catch (e) {}