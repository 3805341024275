/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { logicalStyles } from '../../global_styling';
import { useEuiMemoizedStyles } from '../../services';
import { euiBeaconStyles } from './beacon.styles';
export var COLORS = ['subdued', 'primary', 'success', 'accent', 'danger', 'warning'];
export var EuiBeacon = ({
  className,
  size = 12,
  color = 'success',
  style,
  ...rest
}) => {
  const styles = useEuiMemoizedStyles(euiBeaconStyles);
  const classes = classNames('euiBeacon', className);
  const cssStyles = [styles.euiBeacon, styles[color]];
  const beaconStyle = useMemo(() => logicalStyles({
    ...style,
    height: size,
    width: size
  }), [style, size]);
  return <div className={classes} css={cssStyles} style={beaconStyle} {...rest} />;
};
EuiBeacon.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Height and width of the center circle. Value is passed directly to the `style` attribute
       */
  size: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
       * Any of the named color palette options.
       */
  color: PropTypes.any
};
try {
  EuiBeacon.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/beacon/beacon.tsx',
    description: '',
    displayName: 'EuiBeacon',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: {
          value: '12'
        },
        description: 'Height and width of the center circle. Value is passed directly to the `style` attribute',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/beacon/beacon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      color: {
        defaultValue: {
          value: 'success'
        },
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/beacon/beacon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"accent" | "primary" | "success" | "warning" | "danger" | "subdued"',
          value: [{
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }]
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}