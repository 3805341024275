import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.map.js";
import React from 'react';

// @ts-ignore Importing from JS
import PropTypes from "prop-types";
import { useJsonVars } from '../_json/_get_json_vars';
import { ThemeExample } from '../_components/_theme_example';
import { ThemeValuesTable } from '../_components/_theme_values_table';
var animationSpeeds = ['euiAnimSpeedExtraFast', 'euiAnimSpeedFast', 'euiAnimSpeedNormal', 'euiAnimSpeedSlow', 'euiAnimSpeedExtraSlow'];
var animationEases = ['euiAnimSlightBounce', 'euiAnimSlightResistance'];
export var SpeedSass = function SpeedSass(_ref) {
  var description = _ref.description;
  return ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiAnimSpeed[Speed]"),
    description: description,
    example: ___EmotionJSX("div", {
      className: "guideSass__speedExample"
    }, ___EmotionJSX("strong", null, "Hover me")),
    snippet: 'transition: background $euiAnimSpeedSlow;',
    snippetLanguage: "scss"
  });
};
SpeedSass.propTypes = {
  description: PropTypes.node
};
export var SpeedValuesSass = function SpeedValuesSass() {
  var values = useJsonVars();
  return ___EmotionJSX(ThemeValuesTable, {
    items: animationSpeeds.map(function (speed) {
      return {
        id: speed,
        token: "$".concat(speed),
        value: "".concat(values[speed])
      };
    }),
    sampleColumnProps: {
      width: '80px',
      align: 'left'
    },
    render: function render(item) {
      return ___EmotionJSX("div", {
        className: 'guideSass__animRow'
      }, ___EmotionJSX("div", {
        className: 'guideSass__animParent'
      }, ___EmotionJSX("div", {
        className: "guideSass__animChild"
        // Using inline style tag to override `:focus`
        ,

        style: {
          transitionDuration: item.value
        }
      })));
    }
  });
};
export var EasingSass = ({
  description
}) => {
  return <ThemeExample title={<code>$euiThemeAnimation[Ease]</code>} description={description} example={<div className="guideSass__easeExample">
          <strong>Hover me</strong>
        </div>} snippet={'transition: padding $euiAnimSpeedSlow $euiAnimSlightResistance;'} snippetLanguage="scss" />;
};
EasingSass.propTypes = {
  description: PropTypes.node
};
export const EasingValuesSass = () => {
  const values = useJsonVars();
  return <ThemeValuesTable items={animationEases.map(ease => {
    return {
      id: ease,
      token: `$${ease}`,
      value: `${values[ease]}`
    };
  })} sampleColumnProps={{
    width: '80px',
    align: 'left'
  }} valueColumnProps={{
    width: '260px'
  }} render={item => <div className={'guideSass__animRow'}>
          <div className={'guideSass__animParent'}>
            <div className="guideSass__animChild"
      // Using inline style tag to override `:focus`
      style={{
        transitionTimingFunction: item.value
      }} />
          </div>
        </div>} />;
};
try {
  SpeedSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/other/_animation_sass.tsx',
    description: '',
    displayName: 'SpeedSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  EasingSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/other/_animation_sass.tsx',
    description: '',
    displayName: 'EasingSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}