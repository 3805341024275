/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { EuiTitle } from '../title';
export var EuiModalHeaderTitle = ({
  className,
  children,
  component: Component = 'h1',
  ...rest
}) => {
  const classes = classnames('euiModalHeader__title', className);
  return <EuiTitle size="m" className={classes} {...rest}>
      <Component>{children}</Component>
    </EuiTitle>;
};
EuiModalHeaderTitle.propTypes = {
  /**
         * The tag to render. Can be changed to a lower heading
         * level like `h2` or a container `div`.
         * @default h1
         */
  component: PropTypes.any
};
try {
  EuiModalHeaderTitle.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/modal/modal_header_title.tsx',
    description: '',
    displayName: 'EuiModalHeaderTitle',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: null,
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      textTransform: {
        defaultValue: null,
        description: '',
        name: 'textTransform',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"uppercase"'
        }
      },
      component: {
        defaultValue: {
          value: 'h1'
        },
        description: 'The tag to render. Can be changed to a lower heading\n' + 'level like `h2` or a container `div`.',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/modal/modal_header_title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ElementType<any, keyof IntrinsicElements>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}