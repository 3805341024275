/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { setStyleForRestrictedPageWidth } from './_restrict_width';
import { useEuiPaddingCSS } from '../../global_styling';
import { euiPageStyles } from './page.styles';
import { useEuiTheme } from '../../services';
export var EuiPage = ({
  children,
  restrictWidth = false,
  className,
  paddingSize = 'none',
  grow = true,
  direction = 'row',
  ...rest
}) => {
  // Set max-width as a style prop
  const widthStyles = setStyleForRestrictedPageWidth(restrictWidth, rest?.style);
  const euiTheme = useEuiTheme();
  const styles = euiPageStyles(euiTheme);
  const padding = useEuiPaddingCSS()[paddingSize];
  const stylesCSS = [styles.euiPage, styles[direction], grow && styles.grow, padding, restrictWidth && styles.restrictWidth];
  const classes = classNames('euiPage', className);
  return <div css={stylesCSS} className={classes} {...rest} style={widthStyles}>
      {children}
    </div>;
};
EuiPage.propTypes = {
  /**
     * Adjust the padding.
     * When using this setting it's best to be consistent throughout all similar usages
     */
  paddingSize: PropTypes.any,
  /**
     * Adds `flex-grow: 1` to the whole page for stretching to fit vertically.
     * Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`
     */
  grow: PropTypes.bool,
  /**
     * Changes the `flex-direction` property.
     * Flip to `column` when not including a sidebar.
     */
  direction: PropTypes.oneOf(["row", "column"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired])
};
try {
  EuiPage.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/page/page.tsx',
    description: '',
    displayName: 'EuiPage',
    methods: [],
    props: {
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adjust the padding.\n' + "When using this setting it's best to be consistent throughout all similar usages",
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/page/page.tsx',
          name: 'EuiPageProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page.tsx',
          name: 'EuiPageProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds `flex-grow: 1` to the whole page for stretching to fit vertically.\n' + 'Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`',
        name: 'grow',
        parent: {
          fileName: 'eui/src/components/page/page.tsx',
          name: 'EuiPageProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page.tsx',
          name: 'EuiPageProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      direction: {
        defaultValue: {
          value: 'row'
        },
        description: 'Changes the `flex-direction` property.\n' + 'Flip to `column` when not including a sidebar.',
        name: 'direction',
        parent: {
          fileName: 'eui/src/components/page/page.tsx',
          name: 'EuiPageProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page.tsx',
          name: 'EuiPageProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1200px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      }
    },
    extendedInterfaces: ['EuiPageProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}