import React from 'react';
import PropTypes from "prop-types";
import { EuiCallOut, EuiLink } from '../../../../../src';
export var ThemeNotice = ({
  type = 'support'
}) => {
  const support = <EuiCallOut color="warning" title="Partial component support">
      <p>
        EUI is transitioning to a CSS-in-JS solution for theming and requires
        consuming/customizing global variables in{' '}
        <strong>both the Sass and CSS-in-JS</strong> methods. To track this
        effort, subscribe to the{' '}
        <EuiLink href="https://github.com/elastic/eui/issues/3912">
          Meta ticket
        </EuiLink>
        .
      </p>
    </EuiCallOut>;
  switch (type) {
    case 'support':
      return support;
    default:
      return <></>;
  }
};
ThemeNotice.propTypes = {
  type: PropTypes.oneOf(["support", null])
};
try {
  ThemeNotice.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/_components/_theme_notice.tsx',
    description: '',
    displayName: 'ThemeNotice',
    methods: [],
    props: {
      type: {
        defaultValue: {
          value: 'support'
        },
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_notice.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"support"'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}