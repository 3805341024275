function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
import PropTypes from "prop-types";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import { RenderWithEuiTheme, htmlIdGenerator } from '../../services';
import { isString } from '../../services/predicate';
import { EuiFlexGroup, EuiFlexItem } from '../flex';
import { EuiSearchBox } from './search_box';
import { EuiSearchBarFilters } from './search_filters';
import { Query } from './query';
import { euiSearchBar__searchHolder, euiSearchBar__filtersHolder } from './search_bar.styles';
export { Query, AST as Ast } from './query';
var parseQuery = function parseQuery(query, props) {
  var _props$box, _props$box2;
  var schema = undefined;
  if ((_props$box = props.box) !== null && _props$box !== void 0 && _props$box.schema && _typeof((_props$box2 = props.box) === null || _props$box2 === void 0 ? void 0 : _props$box2.schema) === 'object') {
    schema = props.box.schema;
  }
  var dateFormat = props.dateFormat;
  var parseOptions = {
    schema: schema,
    dateFormat: dateFormat
  };
  if (!query) {
    return Query.parse('', parseOptions);
  }
  return isString(query) ? Query.parse(query, parseOptions) : query;
};
export class EuiSearchBar extends Component {
  static Query = Query;
  hintId = htmlIdGenerator('__hint')();
  constructor(props) {
    super(props);
    const query = parseQuery(props.defaultQuery || props.query, props);
    this.state = {
      query,
      queryText: query.text,
      error: null,
      isHintVisible: false
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.query || nextProps.query === '') && (!prevState.query || typeof nextProps.query !== 'string' && nextProps.query.text !== prevState.query.text || typeof nextProps.query === 'string' && nextProps.query !== prevState.query.text)) {
      const query = parseQuery(nextProps.query, nextProps);
      return {
        query,
        queryText: query.text,
        error: null,
        isHintVisible: prevState.isHintVisible
      };
    }
    return null;
  }
  notifyControllingParent(newState) {
    const {
      onChange
    } = this.props;
    if (!onChange) {
      return;
    }
    const oldState = this.state;
    const {
      query,
      queryText,
      error
    } = newState;
    const isQueryDifferent = oldState.queryText !== queryText;
    const oldError = oldState.error ? oldState.error.message : null;
    const newError = error ? error.message : null;
    const isErrorDifferent = oldError !== newError;
    if (isQueryDifferent || isErrorDifferent) {
      if (error == null) {
        onChange({
          query: query,
          queryText,
          error
        });
      } else {
        onChange({
          query: null,
          queryText,
          error
        });
      }
    }
  }
  onSearch = queryText => {
    try {
      const query = parseQuery(queryText, this.props);
      this.notifyControllingParent({
        query,
        queryText,
        error: null
      });
      this.setState({
        query,
        queryText,
        error: null
      });
    } catch (e) {
      const error = e instanceof Error ? {
        name: e.name,
        message: e.message
      } : {
        name: 'Unexpected error',
        message: String(e)
      };
      this.notifyControllingParent({
        query: null,
        queryText,
        error
      });
      this.setState({
        queryText,
        error
      });
    }
  };
  onFiltersChange = query => {
    this.notifyControllingParent({
      query,
      queryText: query.text,
      error: null
    });
    this.setState({
      query,
      queryText: query.text,
      error: null
    });
  };
  renderTools(tools) {
    if (!tools) {
      return undefined;
    }
    if (Array.isArray(tools)) {
      return tools.map(tool => <EuiFlexItem grow={false} key={tool.key == null ? undefined : tool.key}>
          {tool}
        </EuiFlexItem>);
    }
    return <EuiFlexItem grow={false}>{tools}</EuiFlexItem>;
  }
  render() {
    const {
      query,
      queryText,
      error,
      isHintVisible: isHintVisibleState
    } = this.state;
    const {
      box: {
        schema,
        ...box
      } = {
        schema: ''
      },
      // strip `schema` out to prevent passing it to EuiSearchBox
      filters,
      toolsLeft,
      toolsRight,
      hint
    } = this.props;
    const toolsLeftEl = this.renderTools(toolsLeft);
    const toolsRightEl = this.renderTools(toolsRight);
    const isHintVisible = hint?.popoverProps?.isOpen ?? isHintVisibleState;
    return <RenderWithEuiTheme>
        {euiTheme => <EuiFlexGroup gutterSize="m" alignItems="center" wrap>
            {toolsLeftEl}
            <EuiFlexItem className="euiSearchBar__searchHolder" css={euiSearchBar__searchHolder(euiTheme)} grow={true}>
              <EuiSearchBox {...box} query={queryText} onSearch={this.onSearch} isInvalid={error != null} title={error ? error.message : undefined} aria-describedby={isHintVisible ? `${this.hintId}` : undefined} hint={hint ? {
            isVisible: isHintVisible,
            setIsVisible: isVisible => {
              this.setState({
                isHintVisible: isVisible
              });
            },
            id: this.hintId,
            ...hint
          } : undefined} />
            </EuiFlexItem>
            {filters && <EuiFlexItem className="euiSearchBar__filtersHolder" css={euiSearchBar__filtersHolder(euiTheme)} grow={false}>
                <EuiSearchBarFilters filters={filters} query={query} onChange={this.onFiltersChange} />
              </EuiFlexItem>}
            {toolsRightEl}
          </EuiFlexGroup>}
      </RenderWithEuiTheme>;
  }
}
EuiSearchBar.propTypes = {
  onChange: PropTypes.func,
  /**
     The initial query the bar will hold when first mounted
     */
  defaultQuery: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.string.isRequired]),
  /**
     If you wish to use the search bar as a controlled component, continuously pass the query via this prop.
     */
  query: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.string.isRequired]),
  /**
     Configures the search box. Set `placeholder` to change the placeholder text in the box and `incremental` to support incremental (as you type) search.
     */
  box: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    isInvalid: PropTypes.bool,
    /**
       * Expand to fill 100% of the parent.
       * Defaults to `fullWidth` prop of `<EuiForm>`.
       * @default false
       */
    fullWidth: PropTypes.bool,
    isLoading: PropTypes.bool,
    /**
       * Called when the user presses [Enter] OR on change if the incremental prop is `true`.
       * If you don't need the on[Enter] functionality, prefer using onChange
       */
    onSearch: PropTypes.func,
    /**
       * When `true` the search will be executed (that is, the `onSearch` will be called) as the
       * user types.
       */
    incremental: PropTypes.bool,
    /**
       * when `true` creates a shorter height input
       */
    compressed: PropTypes.bool,
    inputRef: PropTypes.func,
    /**
       * Shows a button that quickly clears any input
       */
    isClearable: PropTypes.bool,
    /**
       * Creates an input group with element(s) coming before input
       * `string` | `ReactElement` or an array of these
       */
    prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
    /**
       * Creates an input group with element(s) coming after input.
       * `string` | `ReactElement` or an array of these
       */
    append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    // Boolean values are not meaningful to this EuiSearchBox, but are allowed so that other
    // components can use e.g. a true value to mean "auto-derive a schema". See EuiInMemoryTable.
    // Admittedly, this is a bit of a hack.
    schema: PropTypes.oneOfType([PropTypes.shape({
      strict: PropTypes.bool,
      fields: PropTypes.any,
      flags: PropTypes.arrayOf(PropTypes.string.isRequired),
      // Controls which phrases will be parsed as field clauses
      recognizedFields: PropTypes.arrayOf(PropTypes.string.isRequired)
    }).isRequired, PropTypes.bool.isRequired])
  }),
  /**
     An array of search filters. See #SearchFilterConfig.
     */
  filters: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    type: PropTypes.oneOf(["is"]).isRequired,
    field: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    negatedName: PropTypes.string,
    available: PropTypes.func
  }).isRequired, PropTypes.shape({
    type: PropTypes.oneOf(["field_value_selection"]).isRequired,
    field: PropTypes.string,
    name: PropTypes.string.isRequired,
    /**
       * See #FieldValueOptionType
       */
    options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired, PropTypes.shape({
        type: PropTypes.oneOf(["date"]).isRequired,
        raw: PropTypes.any.isRequired,
        granularity: PropTypes.oneOfType([PropTypes.shape({
          es: PropTypes.oneOf(["d", "w", "M", "y"]).isRequired,
          js: PropTypes.oneOf(["day", "week", "month", "year"]).isRequired,
          isSame: PropTypes.func.isRequired,
          start: PropTypes.func.isRequired,
          startOfNext: PropTypes.func.isRequired,
          iso8601: PropTypes.func.isRequired
        }).isRequired, PropTypes.oneOf([undefined])]).isRequired,
        text: PropTypes.string.isRequired,
        resolve: PropTypes.func.isRequired
      }).isRequired]).isRequired,
      name: PropTypes.string,
      view: PropTypes.node
    }).isRequired).isRequired, PropTypes.func.isRequired]).isRequired,
    filterWith: PropTypes.oneOfType([PropTypes.oneOf(["prefix", "includes"]), PropTypes.func.isRequired]),
    cache: PropTypes.number,
    multiSelect: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["and", "or"])]),
    loadingMessage: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    searchThreshold: PropTypes.number,
    available: PropTypes.func,
    autoClose: PropTypes.bool,
    operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
  }).isRequired, PropTypes.shape({
    type: PropTypes.oneOf(["field_value_toggle"]).isRequired,
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired, PropTypes.shape({
      type: PropTypes.oneOf(["date"]).isRequired,
      raw: PropTypes.any.isRequired,
      granularity: PropTypes.oneOfType([PropTypes.shape({
        es: PropTypes.oneOf(["d", "w", "M", "y"]).isRequired,
        js: PropTypes.oneOf(["day", "week", "month", "year"]).isRequired,
        isSame: PropTypes.func.isRequired,
        start: PropTypes.func.isRequired,
        startOfNext: PropTypes.func.isRequired,
        iso8601: PropTypes.func.isRequired
      }).isRequired, PropTypes.oneOf([undefined])]).isRequired,
      text: PropTypes.string.isRequired,
      resolve: PropTypes.func.isRequired
    }).isRequired]).isRequired,
    name: PropTypes.string.isRequired,
    negatedName: PropTypes.string,
    available: PropTypes.func,
    operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
  }).isRequired, PropTypes.shape({
    type: PropTypes.oneOf(["field_value_toggle_group"]).isRequired,
    field: PropTypes.string.isRequired,
    /**
       * See #FieldValueToggleGroupFilterItemType
       */
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired]).isRequired,
      name: PropTypes.string.isRequired,
      negatedName: PropTypes.string,
      operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
    }).isRequired).isRequired,
    available: PropTypes.func
  }).isRequired, PropTypes.shape({
    type: PropTypes.oneOf(["custom_component"]).isRequired,
    component: PropTypes.elementType.isRequired,
    available: PropTypes.func
  }).isRequired]).isRequired),
  /**
     * Tools which go to the left of the search bar.
     */
  toolsLeft: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element.isRequired).isRequired]),
  /**
     * Tools which go to the right of the search bar.
     */
  toolsRight: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element.isRequired).isRequired]),
  /**
     * Date formatter to use when parsing date values
     */
  dateFormat: PropTypes.any,
  /**
     * Hint to render below the search bar
     */
  hint: PropTypes.shape({
    content: PropTypes.node.isRequired,
    popoverProps: PropTypes.any
  }),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  Query.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/search_bar/search_bar.tsx',
    description: "This is the consumer interface for the query - it's effectively a wrapper construct around\n" + 'the AST and some of its related utility functions (e.g. parsing, text representation, executing, etc...)\n' + 'It is immutable - all mutating operations return a new (mutated) query instance.',
    displayName: 'Query',
    methods: [],
    props: {},
    extendedInterfaces: ['EuiSearchBarProps', 'CommonProps']
  };
} catch (e) {}
try {
  EuiSearchBar.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/search_bar/search_bar.tsx',
    description: '',
    displayName: 'EuiSearchBar',
    methods: [],
    props: {
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: '(args: EuiSearchBarOnChangeArgs) => boolean | void'
        }
      },
      defaultQuery: {
        defaultValue: null,
        description: 'The initial query the bar will hold when first mounted',
        name: 'defaultQuery',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'QueryType'
        }
      },
      query: {
        defaultValue: null,
        description: 'If you wish to use the search bar as a controlled component, continuously pass the query via this prop.',
        name: 'query',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'QueryType'
        }
      },
      box: {
        defaultValue: null,
        description: 'Configures the search box. Set `placeholder` to change the placeholder text in the box and `incremental` to support incremental (as you type) search.',
        name: 'box',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'EuiFieldSearchProps & { schema?: boolean | SchemaType; }'
        }
      },
      filters: {
        defaultValue: null,
        description: 'An array of search filters. See #SearchFilterConfig.',
        name: 'filters',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'SearchFilterConfig[]'
        }
      },
      toolsLeft: {
        defaultValue: null,
        description: 'Tools which go to the left of the search bar.',
        name: 'toolsLeft',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'Tools'
        }
      },
      toolsRight: {
        defaultValue: null,
        description: 'Tools which go to the right of the search bar.',
        name: 'toolsRight',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'Tools'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: 'Date formatter to use when parsing date values',
        name: 'dateFormat',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: 'object'
        }
      },
      hint: {
        defaultValue: null,
        description: 'Hint to render below the search bar',
        name: 'hint',
        parent: {
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_bar.tsx',
          name: 'EuiSearchBarProps'
        }],
        required: false,
        type: {
          name: '{ content: React.ReactNode; popoverProps?: Partial<Pick<EuiInputPopoverProps, "data-test-subj" | "fullWidth" | "isOpen" | "closePopover" | "panelClassName" | "panelPaddingSize" | ... 5 more ... | "disableFocusTrap">>; }'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiSearchBarProps', 'CommonProps']
  };
} catch (e) {}
try {
  EuiSearchBar.Query.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/search_bar/search_bar.tsx',
    description: "This is the consumer interface for the query - it's effectively a wrapper construct around\n" + 'the AST and some of its related utility functions (e.g. parsing, text representation, executing, etc...)\n' + 'It is immutable - all mutating operations return a new (mutated) query instance.',
    displayName: 'EuiSearchBar.Query',
    methods: [],
    props: {},
    extendedInterfaces: ['EuiSearchBarProps', 'CommonProps']
  };
} catch (e) {}