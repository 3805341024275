/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useEuiTheme, useIsWithinBreakpoints } from '../../../services';
import { euiPageOuterStyles } from './page_outer.styles';
export var PAGE_DIRECTIONS = ['row', 'column'];
export var _EuiPageOuter = ({
  children,
  grow = true,
  direction = 'row',
  responsive = ['xs', 's'],
  ...rest
}) => {
  const themeContext = useEuiTheme();
  const styles = euiPageOuterStyles(themeContext);
  const isResponding = useIsWithinBreakpoints(responsive);
  const cssStyles = [styles.euiPageOuter, styles[isResponding ? 'column' : direction], grow && styles.grow];
  return <div css={cssStyles} {...rest}>
      {children}
    </div>;
};
_EuiPageOuter.propTypes = {
  /**
     * Adds `flex-grow: 1` to the whole page for stretching to fit vertically.
     * Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`
     */
  grow: PropTypes.bool,
  /**
     * Changes the `flex-direction` property.
     * Flip to `column` when not including a sidebar.
     */
  direction: PropTypes.any,
  /**
     * When direction is `row`, it will flip to `column` when within these breakpoints.
     */
  responsive: PropTypes.arrayOf(PropTypes.any.isRequired),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  _EuiPageOuter.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/page_template/outer/page_outer.tsx',
    description: '',
    displayName: '_EuiPageOuter',
    methods: [],
    props: {
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds `flex-grow: 1` to the whole page for stretching to fit vertically.\n' + 'Must be wrapped inside a flexbox, preferrably with `min-height: 100vh`',
        name: 'grow',
        parent: {
          fileName: 'eui/src/components/page_template/outer/page_outer.tsx',
          name: '_EuiPageOuterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page_template/outer/page_outer.tsx',
          name: '_EuiPageOuterProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      direction: {
        defaultValue: {
          value: 'row'
        },
        description: 'Changes the `flex-direction` property.\n' + 'Flip to `column` when not including a sidebar.',
        name: 'direction',
        parent: {
          fileName: 'eui/src/components/page_template/outer/page_outer.tsx',
          name: '_EuiPageOuterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page_template/outer/page_outer.tsx',
          name: '_EuiPageOuterProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }]
        }
      },
      responsive: {
        defaultValue: {
          value: "['xs', 's']"
        },
        description: 'When direction is `row`, it will flip to `column` when within these breakpoints.',
        name: 'responsive',
        parent: {
          fileName: 'eui/src/components/page_template/outer/page_outer.tsx',
          name: '_EuiPageOuterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page_template/outer/page_outer.tsx',
          name: '_EuiPageOuterProps'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['_EuiPageOuterProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}