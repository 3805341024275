/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiTitle } from '../../title';
import { EuiText } from '../../text';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
import { useFormContext } from '../eui_form_context';
import { euiDescribedFormGroupStyles } from './described_form_group.styles';
export var EuiDescribedFormGroup = props => {
  const {
    defaultFullWidth
  } = useFormContext();
  const {
    children,
    className,
    gutterSize = 'l',
    fullWidth = defaultFullWidth,
    ratio = 'half',
    titleSize = 'xs',
    title,
    description,
    descriptionFlexItemProps,
    fieldFlexItemProps,
    ...rest
  } = props;
  const styles = useEuiMemoizedStyles(euiDescribedFormGroupStyles);
  const cssStyles = [styles.euiDescribedFormGroup, fullWidth ? styles.fullWidth : styles.formWidth];
  const descriptionColumnStyles = [styles.euiDescribedFormGroup__descriptionColumn, descriptionFlexItemProps?.css];
  const fieldColumnStyles = [styles.euiDescribedFormGroup__fields, fieldFlexItemProps?.css];
  const classes = classNames('euiDescribedFormGroup', className);
  const fieldClasses = classNames('euiDescribedFormGroup__fields', fieldFlexItemProps && fieldFlexItemProps.className);
  let renderedDescription;
  if (description) {
    renderedDescription = <EuiText size="s" color="subdued" css={styles.euiDescribedFormGroup__description} className="euiDescribedFormGroup__description">
        {
      // If the description is just a string, wrap it in a paragraph element
      typeof description === 'string' ? <p>{description}</p> : description}
      </EuiText>;
  }
  let fieldGrowth;
  switch (ratio) {
    case 'half':
      fieldGrowth = 1;
      break;
    case 'third':
      fieldGrowth = 2;
      break;
    case 'quarter':
      fieldGrowth = 3;
      break;
    default:
      console.warn('Please provide an allowed ratio to EuiDescribedFromRow');
      break;
  }
  return <EuiFlexGroup role="group" {...rest} css={cssStyles} className={classes} alignItems="baseline" gutterSize={gutterSize}>
      <EuiFlexItem grow={1} {...descriptionFlexItemProps} css={descriptionColumnStyles} className={classNames('euiDescribedFormGroup__descriptionColumn', descriptionFlexItemProps?.className)}>
        <EuiTitle size={titleSize} className="euiDescribedFormGroup__title">
          {title}
        </EuiTitle>

        {renderedDescription}
      </EuiFlexItem>

      <EuiFlexItem grow={fieldGrowth} {...fieldFlexItemProps} css={fieldColumnStyles} className={fieldClasses}>
        {children}
      </EuiFlexItem>
    </EuiFlexGroup>;
};
EuiDescribedFormGroup.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * One or more `EuiFormRow`s.
       */
  children: PropTypes.node,
  /**
       * Passed to `EuiFlexGroup`.
       * @default l
       */
  gutterSize: PropTypes.any,
  /**
       * Expand to fill 100% of the parent.
       * Defaults to `fullWidth` prop of `<EuiForm>`.
       * Default max-width is 800px.
       * @default false
       */
  fullWidth: PropTypes.bool,
  /**
       * Width ratio of description column compared to field column.
       * Can be used in conjunction with `fullWidth` and
       * may require `fullWidth` to be applied to child elements.
       * @default half
       */
  ratio: PropTypes.oneOf(["half", "third", "quarter"]),
  /**
       * For better accessibility, it's recommended to use an HTML heading.
       */
  title: PropTypes.any.isRequired,
  /**
       * Adjust the visual `size` of the EuiTitle that wraps `title`.
       * @default xs
       */
  titleSize: PropTypes.any,
  /**
       * Added as a child of `EuiText`.
       */
  description: PropTypes.node,
  /**
       * For customizing the field container. Extended from `EuiFlexItem`.
       */
  descriptionFlexItemProps: PropTypes.any,
  fieldFlexItemProps: PropTypes.any
};
try {
  EuiDescribedFormGroup.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/described_form_group/described_form_group.tsx',
    description: '',
    displayName: 'EuiDescribedFormGroup',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      children: {
        defaultValue: null,
        description: 'One or more `EuiFormRow`s.',
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      gutterSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Passed to `EuiFlexGroup`.',
        name: 'gutterSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.\n' + 'Default max-width is 800px.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ratio: {
        defaultValue: {
          value: 'half'
        },
        description: 'Width ratio of description column compared to field column.\n' + 'Can be used in conjunction with `fullWidth` and\n' + 'may require `fullWidth` to be applied to child elements.',
        name: 'ratio',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"half" | "third" | "quarter"',
          value: [{
            value: '"half"'
          }, {
            value: '"third"'
          }, {
            value: '"quarter"'
          }]
        }
      },
      title: {
        defaultValue: null,
        description: "For better accessibility, it's recommended to use an HTML heading.",
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      titleSize: {
        defaultValue: {
          value: 'xs'
        },
        description: 'Adjust the visual `size` of the EuiTitle that wraps `title`.',
        name: 'titleSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      description: {
        defaultValue: null,
        description: 'Added as a child of `EuiText`.',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      descriptionFlexItemProps: {
        defaultValue: null,
        description: 'For customizing the description container. Extended from `EuiFlexItem`.',
        name: 'descriptionFlexItemProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ children?: ReactNode; } & CommonProps & Omit<any, "ref"> & { grow?: boolean | 0 | 1 | 2 | 3 | 4 | 6 | 10 | 7 | 8 | 5 | 9; component?: ElementType<any, keyof IntrinsicElements>; } & { ...; }'
        }
      },
      fieldFlexItemProps: {
        defaultValue: null,
        description: 'For customizing the field container. Extended from `EuiFlexItem`.',
        name: 'fieldFlexItemProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/described_form_group/described_form_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ children?: ReactNode; } & CommonProps & Omit<any, "ref"> & { grow?: boolean | 0 | 1 | 2 | 3 | 4 | 6 | 10 | 7 | 8 | 5 | 9; component?: ElementType<any, keyof IntrinsicElements>; } & { ...; }'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}