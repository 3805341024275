/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { withEuiStylesMemoizer, htmlIdGenerator } from '../../../services';
import { EuiButtonEmpty } from '../../button';
import { EuiProgress } from '../../progress';
import { EuiIcon } from '../../icon';
import { EuiI18n } from '../../i18n';
import { EuiLoadingSpinner } from '../../loading';
import { FormContext } from '../eui_form_context';
import { EuiValidatableControl } from '../validatable_control';
import { EuiFormControlLayoutClearButton } from '../form_control_layout/form_control_layout_clear_button';
import { euiFilePickerStyles } from './file_picker.styles';
export class EuiFilePickerClass extends Component {
  static contextType = FormContext;
  static defaultProps = {
    initialPromptText: <EuiI18n token="euiFilePicker.promptText" default="Select or drag and drop a file" />,
    compressed: false,
    display: 'large'
  };
  state = {
    promptText: null,
    isHoveringDrop: false
  };
  fileInput = null;
  generatedId = htmlIdGenerator()();
  handleChange = () => {
    if (!this.fileInput) return;
    if (this.fileInput.files && this.fileInput.files.length > 1) {
      this.setState({
        promptText: <EuiI18n token="euiFilePicker.filesSelected" default="{fileCount} files selected" values={{
          fileCount: this.fileInput.files.length
        }} />
      });
    } else if (this.fileInput.files && this.fileInput.files.length === 0) {
      this.setState({
        promptText: null
      });
    } else {
      this.setState({
        promptText: this.fileInput.value.split('\\').pop()
      });
    }
    const {
      onChange
    } = this.props;
    if (onChange) {
      onChange(this.fileInput.files);
    }
  };
  removeFiles = e => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.fileInput) return;
    this.fileInput.value = '';
    this.handleChange();
  };
  showDrop = () => {
    if (!this.props.disabled) {
      this.setState({
        isHoveringDrop: true
      });
    }
  };
  hideDrop = () => {
    this.setState({
      isHoveringDrop: false
    });
  };
  render() {
    const {
      defaultFullWidth
    } = this.context;
    return <EuiI18n token="euiFilePicker.removeSelectedAriaLabel" default="Remove selected files">
        {removeSelectedAriaLabel => {
        const {
          stylesMemoizer,
          id,
          name,
          initialPromptText,
          className,
          disabled,
          compressed,
          onChange,
          isInvalid,
          fullWidth = defaultFullWidth,
          isLoading,
          display,
          ...rest
        } = this.props;
        const promptId = `${id || this.generatedId}-filePicker__prompt`;
        const isOverridingInitialPrompt = this.state.promptText != null;
        const normalFormControl = display === 'default';
        const classes = classNames('euiFilePicker', {
          'euiFilePicker-isDroppingFile': this.state.isHoveringDrop,
          'euiFilePicker-isInvalid': isInvalid,
          'euiFilePicker-isLoading': isLoading,
          'euiFilePicker-hasFiles': isOverridingInitialPrompt
        }, className);
        const styles = stylesMemoizer(euiFilePickerStyles);
        const cssStyles = [styles.euiFilePicker, fullWidth ? styles.fullWidth : styles.formWidth, this.state.isHoveringDrop && styles.isDroppingFile, isInvalid && !disabled && styles.invalid, isOverridingInitialPrompt && !disabled && styles.hasFiles, isLoading && styles.loading];
        const inputStyles = [styles.input.euiFilePicker__input, !normalFormControl && !disabled && styles.input.largeInteractive];
        const promptStyles = [styles.euiFilePicker__prompt, disabled && styles.disabled, ...(normalFormControl ? [compressed ? styles.compressed : styles.uncompressed] : [styles.large.large, compressed ? styles.large.compressed : styles.large.uncompressed])];
        const iconStyles = [styles.icon.euiFilePicker__icon, ...(normalFormControl ? [styles.icon.normal, compressed ? styles.icon.compresssed : styles.icon.uncompressed] : [styles.icon.large])];
        const rightIconStyles = normalFormControl ? [styles.rightIcon.euiFilePicker__rightIcon, compressed ? styles.rightIcon.compressed : styles.rightIcon.uncompressed] : undefined;
        let clearButton;
        if (isLoading && normalFormControl) {
          // Override clear button with loading spinner if it is in loading state
          clearButton = <EuiLoadingSpinner css={rightIconStyles} className="euiFilePicker__loadingSpinner" size={compressed ? 's' : 'm'} />;
        } else if (isOverridingInitialPrompt && !disabled) {
          if (normalFormControl) {
            clearButton = <EuiFormControlLayoutClearButton aria-label={removeSelectedAriaLabel} css={[styles.euiFilePicker__clearButton, rightIconStyles]} className="euiFilePicker__clearButton" onClick={this.removeFiles} size={compressed ? 's' : 'm'} />;
          } else {
            clearButton = <EuiButtonEmpty aria-label={removeSelectedAriaLabel} css={styles.euiFilePicker__clearButton} className="euiFilePicker__clearButton" size="xs" onClick={this.removeFiles}>
                  <EuiI18n token="euiFilePicker.removeSelected" default="Remove" />
                </EuiButtonEmpty>;
          }
        } else {
          clearButton = null;
        }
        const loader = !normalFormControl && isLoading && <EuiProgress size="xs" color="accent" position="absolute" />;
        return <div css={cssStyles} className={classes}>
              <EuiValidatableControl isInvalid={isInvalid}>
                <input type="file" id={id} name={name} css={inputStyles} className="euiFilePicker__input" onChange={this.handleChange} ref={input => {
              this.fileInput = input;
            }} onDragOver={this.showDrop} onDragLeave={this.hideDrop} onDrop={this.hideDrop} disabled={disabled} aria-describedby={promptId} {...rest} />
              </EuiValidatableControl>
              <div css={promptStyles} className="euiFilePicker__prompt" id={promptId}>
                <EuiIcon css={iconStyles} className="euiFilePicker__icon" color={isInvalid ? 'danger' : disabled ? 'subdued' : 'primary'} type={isInvalid ? 'alert' : 'importAction'} size={normalFormControl ? 'm' : 'l'} aria-hidden="true" />
                <span className="euiFilePicker__promptText">
                  {this.state.promptText || initialPromptText}
                </span>
                {clearButton}
                {loader}
              </div>
            </div>;
      }}
      </EuiI18n>;
  }
}
EuiFilePickerClass.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  /**
     * The content that appears in the dropzone if no file is attached
     * @default 'Select or drag and drop a file'
     */
  initialPromptText: PropTypes.node,
  /**
     * Use as a callback to access the HTML FileList API
     */
  onChange: PropTypes.func,
  /**
     * Reduces the size to a typical (compressed) input
     * @default false
     */
  compressed: PropTypes.bool,
  /**
     * Size or type of display;
     * `default` for normal height, similar to other controls;
     * `large` for taller size
     * @default large
     */
  display: PropTypes.oneOf(["default", "large"]),
  /**
     * Expand to fill 100% of the parent.
     * Defaults to `fullWidth` prop of `<EuiForm>`.
     * @default false
     */
  fullWidth: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
export const EuiFilePicker = withEuiStylesMemoizer(EuiFilePickerClass);
try {
  EuiFilePickerClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/file_picker/file_picker.tsx',
    description: '',
    displayName: 'EuiFilePickerClass',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      initialPromptText: {
        defaultValue: {
          value: '(\n' + '      <EuiI18n\n' + '        token="euiFilePicker.promptText"\n' + '        default="Select or drag and drop a file"\n' + '      />\n' + '    )'
        },
        description: 'The content that appears in the dropzone if no file is attached',
        name: 'initialPromptText',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'Use as a callback to access the HTML FileList API',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: '(files: FileList) => void'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'Reduces the size to a typical (compressed) input',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      display: {
        defaultValue: {
          value: 'large'
        },
        description: 'Size or type of display;\n' + '`default` for normal height, similar to other controls;\n' + '`large` for taller size',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "large"',
          value: [{
            value: '"default"'
          }, {
            value: '"large"'
          }]
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      stylesMemoizer: {
        defaultValue: null,
        description: '',
        name: 'stylesMemoizer',
        parent: {
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        }],
        required: true,
        type: {
          name: '<T extends (theme: UseEuiTheme<{}>) => StylesMap>(stylesGenerator: T) => ReturnType<T>'
        }
      }
    },
    extendedInterfaces: ['EuiFilePickerProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'WithEuiStylesMemoizerProps', 'Attributes']
  };
} catch (e) {}
try {
  EuiFilePicker.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/file_picker/file_picker.tsx',
    description: '',
    displayName: 'EuiFilePicker',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'Use as a callback to access the HTML FileList API',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: '(files: FileList) => void'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      display: {
        defaultValue: {
          value: 'large'
        },
        description: 'Size or type of display;\n' + '`default` for normal height, similar to other controls;\n' + '`large` for taller size',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "large"',
          value: [{
            value: '"default"'
          }, {
            value: '"large"'
          }]
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'Reduces the size to a typical (compressed) input',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialPromptText: {
        defaultValue: {
          value: "'Select or drag and drop a file'"
        },
        description: 'The content that appears in the dropzone if no file is attached',
        name: 'initialPromptText',
        parent: {
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/file_picker/file_picker.tsx',
          name: 'EuiFilePickerProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['EuiFilePickerProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'WithEuiStylesMemoizerProps', 'Attributes']
  };
} catch (e) {}