function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import { useEuiTheme } from '../../../services';
import { logicalStyles } from '../../../global_styling';
import { useInnerText } from '../../inner_text';
import { calculateThumbPosition, EUI_THUMB_SIZE } from './utils';
import { euiRangeTicksStyles, euiRangeTickStyles } from './range_ticks.styles';
var EuiTickValue = function EuiTickValue(_ref) {
  var disabled = _ref.disabled,
    ticks = _ref.ticks,
    min = _ref.min,
    max = _ref.max,
    value = _ref.value,
    onChange = _ref.onChange,
    percentageWidth = _ref.percentageWidth,
    tickValue = _ref.tickValue,
    compressed = _ref.compressed,
    trackWidth = _ref.trackWidth;
  var euiTheme = useEuiTheme();
  var hasCustomTicks = !!ticks;
  var tickObject = useMemo(function () {
    return hasCustomTicks ? ticks.find(function (o) {
      return o.value === tickValue;
    }) : {
      value: tickValue,
      label: tickValue
    };
  }, [hasCustomTicks, ticks, tickValue]);
  var isMinTick = (tickObject === null || tickObject === void 0 ? void 0 : tickObject.value) === min;
  var isMaxTick = (tickObject === null || tickObject === void 0 ? void 0 : tickObject.value) === max;
  var label = tickObject ? tickObject.label : tickValue;

  // Math worked out by trial and error
  // Shifts the label into the reserved margin of EuiRangeTrack
  var labelShiftVal = useMemo(function () {
    return (isMinTick || isMaxTick) && label.length > 3 ? Math.min(label.length * 0.25, 1.25) : 0;
  }, [isMinTick, isMaxTick, label]);
  var tickStyle = useMemo(function () {
    var styles = {};
    var shift = "-".concat(labelShiftVal, "em");
    if (isMaxTick && labelShiftVal) {
      styles.right = '0%';
      styles.marginRight = shift;
    } else {
      var position = calculateThumbPosition(tickValue, min, max, trackWidth);
      var thumbOffset = labelShiftVal ? 0 : EUI_THUMB_SIZE / 2;
      styles.left = "calc(".concat(position, "% + ").concat(thumbOffset, "px)");
      if (labelShiftVal) styles.marginLeft = shift;
    }
    styles.maxWidth = hasCustomTicks ? undefined : "".concat(percentageWidth, "%");
    return logicalStyles(styles);
  }, [isMaxTick, labelShiftVal, trackWidth, tickValue, min, max, hasCustomTicks, percentageWidth]);

  // Some ticks need an actual DOM element instead of using a ::before
  var pseudoTick = tickObject && !!labelShiftVal && (isMinTick || isMaxTick);
  var pseudoShift = useMemo(function () {
    if (!labelShiftVal) return {};
    var marginProperty = isMaxTick ? 'marginRight' : 'marginLeft';
    var tickOffset = euiTheme.euiTheme.size.xs; // xs derived from .euiRangeTicks left/right offset

    return logicalStyles(_defineProperty({}, marginProperty, "calc(".concat(labelShiftVal, "em + ").concat(tickOffset, ")")));
  }, [labelShiftVal, isMaxTick, euiTheme.euiTheme.size.xs]);
  var styles = euiRangeTickStyles(euiTheme);
  var cssTickStyles = [styles.euiRangeTick, value === String(tickValue) && styles.selected, hasCustomTicks && styles.isCustom, labelShiftVal && isMinTick && styles.isMin, labelShiftVal && isMaxTick && styles.isMax, !pseudoTick && styles.hasPseudoTickMark, compressed ? styles.compressed : styles.regular];
  var _useInnerText = useInnerText(),
    _useInnerText2 = _slicedToArray(_useInnerText, 2),
    ref = _useInnerText2[0],
    innerText = _useInnerText2[1];
  return ___EmotionJSX("button", {
    type: "button",
    className: "euiRangeTick",
    css: cssTickStyles,
    value: tickValue,
    disabled: disabled,
    onClick: onChange,
    style: tickStyle,
    tabIndex: -1,
    ref: ref,
    title: typeof label === 'string' ? label : innerText
  }, pseudoTick && ___EmotionJSX("span", {
    className: "euiRangeTick__pseudo",
    css: styles.euiRangeTick__pseudo,
    "aria-hidden": true,
    style: pseudoShift
  }), label);
};
EuiTickValue.propTypes = {
  tickSequence: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  trackWidth: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired, PropTypes.any.isRequired]),
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  /**
     * The number to increment or decrement between each interval
     * @default 1
     */
  step: PropTypes.number,
  tickValue: PropTypes.any.isRequired,
  percentageWidth: PropTypes.number.isRequired
};
export var EuiRangeTicks = props => {
  const {
    ticks,
    tickSequence,
    max,
    min,
    tickInterval = 1,
    compressed
  } = props;

  // Calculate the width of each tick mark
  const percentageWidth = useMemo(() => tickInterval / (max - min + tickInterval) * 100, [tickInterval, min, max]);
  const euiTheme = useEuiTheme();
  const styles = euiRangeTicksStyles(euiTheme);
  const cssStyles = [styles.euiRangeTicks, compressed ? styles.compressed : styles.regular, ticks && styles.isCustom];
  return <div className="euiRangeTicks" css={cssStyles}>
      {tickSequence.map(tickValue => <EuiTickValue key={tickValue} {...props} percentageWidth={percentageWidth} tickValue={tickValue} />)}
    </div>;
};
EuiRangeTicks.propTypes = {
  tickSequence: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  trackWidth: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired, PropTypes.any.isRequired]),
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number
};
try {
  EuiRangeTicks.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_ticks.tsx',
    description: '',
    displayName: 'EuiRangeTicks',
    methods: [],
    props: {
      tickSequence: {
        defaultValue: null,
        description: '',
        name: 'tickSequence',
        parent: {
          fileName: 'eui/src/components/form/range/range_ticks.tsx',
          name: 'EuiRangeTicksProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_ticks.tsx',
          name: 'EuiRangeTicksProps'
        }],
        required: true,
        type: {
          name: 'number[]'
        }
      },
      trackWidth: {
        defaultValue: null,
        description: '',
        name: 'trackWidth',
        parent: {
          fileName: 'eui/src/components/form/range/range_ticks.tsx',
          name: 'EuiRangeTicksProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_ticks.tsx',
          name: 'EuiRangeTicksProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/range_ticks.tsx',
          name: 'EuiRangeTicksProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_ticks.tsx',
          name: 'EuiRangeTicksProps'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangesValues'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangesValues'
        }],
        required: false,
        type: {
          name: 'string | number | [string | number, string | number]'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeTick[]'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'number'
        }
      }
    },
    extendedInterfaces: ['EuiRangeTicksProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'ButtonHTMLAttributes', '_SharedRangesValues', '_SharedRangeValuesProps', '_SharedRangeInputProps', '_SharedRangeDataStructures']
  };
} catch (e) {}