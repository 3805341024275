/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { LEFT_ALIGNMENT, useEuiMemoizedStyles } from '../../services';
import { isObject } from '../../services/predicate';
import { EuiTextBlockTruncate } from '../text_truncate';
import { useEuiTableIsResponsive } from './mobile/responsive_context';
import { euiTableCellContentStyles } from './_table_cell_content.styles';
export var EuiTableCellContent = ({
  children,
  className,
  align = LEFT_ALIGNMENT,
  textOnly,
  truncateText = false,
  hasActions,
  ...rest
}) => {
  const isResponsive = useEuiTableIsResponsive();
  const styles = useEuiMemoizedStyles(euiTableCellContentStyles);
  const cssStyles = [styles.euiTableCellContent, styles[align], truncateText === true && styles.truncateText, truncateText === false && styles.wrapText, ...(hasActions ? [styles.hasActions.actions, !isResponsive && styles.hasActions.desktop, isResponsive && hasActions !== 'custom' && styles.hasActions.mobile] : [])];
  const classes = classNames('euiTableCellContent', className);
  const renderedChildren = useMemo(() => {
    const textClasses = 'euiTableCellContent__text';
    if (isObject(truncateText) && truncateText.lines) {
      return <EuiTextBlockTruncate lines={truncateText.lines} cloneElement>
          <span className={textClasses}>{children}</span>
        </EuiTextBlockTruncate>;
    }
    if (textOnly === true || truncateText === true) {
      return <span className={textClasses}>{children}</span>;
    }
    return children;
  }, [children, textOnly, truncateText]);
  return <div css={cssStyles} className={classes} {...rest}>
      {renderedChildren}
    </div>;
};
EuiTableCellContent.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  truncateText: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    lines: PropTypes.number.isRequired
  }).isRequired]).isRequired, PropTypes.oneOf([null])])
};
try {
  EuiTableCellContent.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/_table_cell_content.tsx',
    description: '',
    displayName: 'EuiTableCellContent',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      align: {
        defaultValue: {
          value: 'left'
        },
        description: 'Horizontal alignment of the text in the cell',
        name: 'align',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'HorizontalAlignment',
          value: [{
            value: '"center"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      hasActions: {
        defaultValue: null,
        description: 'Indicates if the cell is dedicated to row actions\n' + '(used for mobile styling and desktop action hover behavior)',
        name: 'hasActions',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellProps'
        }],
        required: false,
        type: {
          name: 'boolean | "custom"'
        }
      },
      textOnly: {
        defaultValue: {
          value: 'true'
        },
        description: 'Creates a text wrapper around cell content that helps word break or truncate\n' + 'long text correctly.',
        name: 'textOnly',
        parent: {
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row_cell.tsx',
          name: 'EuiTableRowCellSharedPropsShape'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      truncateText: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'truncateText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/_table_cell_content.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | { lines: number; }'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiTableRowCellSharedPropsShape', 'EuiTableRowCellProps']
  };
} catch (e) {}