/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { FocusOn } from 'react-focus-on';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { findElementBySelectorOrRef } from '../../services';
import { usePropsWithComponentDefaults } from '../provider/component_defaults';
export var EuiFocusTrap = function EuiFocusTrap(props) {
  var propsWithDefaults = usePropsWithComponentDefaults('EuiFocusTrap', props);
  return ___EmotionJSX(EuiFocusTrapClass, propsWithDefaults);
};
EuiFocusTrap.propTypes = {
  // For some reason, Storybook doesn't register these props if they're Pick<>'d
  className: PropTypes.string,
  css: PropTypes.any,
  style: PropTypes.any,
  /**
     * @default false
     */
  disabled: PropTypes.bool,
  /**
     * Whether `onClickOutside` should be called on mouseup instead of mousedown.
     * This flag can be used to prevent conflicts with outside toggle buttons by delaying the closing click callback.
     */
  closeOnMouseup: PropTypes.bool,
  /**
     * Clicking outside the trap area will disable the trap
     * @default false
     */
  clickOutsideDisables: PropTypes.bool,
  /**
     * Reference to element that will get focus when the trap is initiated
     */
  initialFocus: PropTypes.any,
  /**
     * if `scrollLock` is set to true, the body's scrollbar width will be preserved on lock
     * via the `gapMode` CSS property. Depending on your custom CSS, you may prefer to use
     * `margin` instead of `padding`.
     * @default padding
     */
  gapMode: PropTypes.oneOf(["padding", "margin"]),
  /**
     * Configures focus trapping between iframes.
     * By default, EuiFocusTrap allows focus to leave iframes and move to elements outside of it.
     * Set to `true` if you want focus to remain trapped within the iframe.
     * @default false
     */
  crossFrame: PropTypes.any,
  /**
     * @default false
     */
  scrollLock: PropTypes.any,
  /**
     * @default true
     */
  noIsolation: PropTypes.any,
  /**
     * @default true
     */
  returnFocus: PropTypes.any
};
class EuiFocusTrapClass extends Component {
  static defaultProps = {
    clickOutsideDisables: false,
    disabled: false,
    returnFocus: true,
    noIsolation: true,
    scrollLock: false,
    crossFrame: false,
    gapMode: 'padding' // EUI defaults to padding because Kibana's body/layout CSS ignores `margin`
  };
  state = {
    hasBeenDisabledByClick: false
  };
  lastInterceptedEvent = null;
  preventFocusExit = false;
  componentDidMount() {
    this.setInitialFocus(this.props.initialFocus);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.disabled === true && this.props.disabled === false) {
      this.setState({
        hasBeenDisabledByClick: false
      });
    }
  }
  componentWillUnmount() {
    this.removeMouseupListener();
  }

  // Programmatically sets focus on a nested DOM node; optional
  setInitialFocus = initialFocus => {
    if (!initialFocus) return;
    const node = findElementBySelectorOrRef(initialFocus);
    if (!node) return;
    // `data-autofocus` is part of the 'react-focus-on' API
    node.setAttribute('data-autofocus', 'true');
  };
  onMouseupOutside = e => {
    this.removeMouseupListener();
    // Timeout gives precedence to the consumer to initiate close if it has toggle behavior.
    // Otherwise this event may occur first and the consumer toggle will reopen the flyout.
    setTimeout(() => this.props.onClickOutside?.(e));
  };
  addMouseupListener = () => {
    document.addEventListener('mouseup', this.onMouseupOutside);
    document.addEventListener('touchend', this.onMouseupOutside);
  };
  removeMouseupListener = () => {
    document.removeEventListener('mouseup', this.onMouseupOutside);
    document.removeEventListener('touchend', this.onMouseupOutside);
  };
  handleOutsideClick = event => {
    const {
      onClickOutside,
      clickOutsideDisables,
      closeOnMouseup
    } = this.props;
    if (clickOutsideDisables) {
      this.setState({
        hasBeenDisabledByClick: true
      });
    }
    if (onClickOutside) {
      closeOnMouseup ? this.addMouseupListener() : onClickOutside(event);
    }
  };
  render() {
    const {
      children,
      clickOutsideDisables,
      disabled,
      returnFocus,
      noIsolation,
      scrollLock,
      gapMode,
      ...rest
    } = this.props;
    const isDisabled = disabled || this.state.hasBeenDisabledByClick;
    const focusOnProps = {
      returnFocus,
      noIsolation,
      enabled: !isDisabled,
      ...rest,
      onClickOutside: this.handleOutsideClick,
      /**
       * `scrollLock` should always be unset on FocusOn, as it can prevent scrolling on
       * portals (i.e. popovers, comboboxes, dropdown menus, etc.) within modals & flyouts
       * @see https://github.com/theKashey/react-focus-on/issues/49
       */
      scrollLock: false
    };
    return <FocusOn {...focusOnProps}>
        {children}
        {!isDisabled && scrollLock && <RemoveScrollBar gapMode={gapMode} />}
      </FocusOn>;
  }
}
EuiFocusTrapClass.propTypes = {
  // For some reason, Storybook doesn't register these props if they're Pick<>'d
  className: PropTypes.string,
  css: PropTypes.any,
  style: PropTypes.any,
  /**
     * @default false
     */
  disabled: PropTypes.bool,
  /**
     * Whether `onClickOutside` should be called on mouseup instead of mousedown.
     * This flag can be used to prevent conflicts with outside toggle buttons by delaying the closing click callback.
     */
  closeOnMouseup: PropTypes.bool,
  /**
     * Clicking outside the trap area will disable the trap
     * @default false
     */
  clickOutsideDisables: PropTypes.bool,
  /**
     * Reference to element that will get focus when the trap is initiated
     */
  initialFocus: PropTypes.any,
  /**
     * if `scrollLock` is set to true, the body's scrollbar width will be preserved on lock
     * via the `gapMode` CSS property. Depending on your custom CSS, you may prefer to use
     * `margin` instead of `padding`.
     * @default padding
     */
  gapMode: PropTypes.oneOf(["padding", "margin"]),
  /**
     * Configures focus trapping between iframes.
     * By default, EuiFocusTrap allows focus to leave iframes and move to elements outside of it.
     * Set to `true` if you want focus to remain trapped within the iframe.
     * @default false
     */
  crossFrame: PropTypes.any,
  /**
     * @default false
     */
  scrollLock: PropTypes.any,
  /**
     * @default true
     */
  noIsolation: PropTypes.any,
  /**
     * @default true
     */
  returnFocus: PropTypes.any
};
try {
  EuiFocusTrap.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/focus_trap/focus_trap.tsx',
    description: '',
    displayName: 'EuiFocusTrap',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      disabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      closeOnMouseup: {
        defaultValue: null,
        description: 'Whether `onClickOutside` should be called on mouseup instead of mousedown.\n' + 'This flag can be used to prevent conflicts with outside toggle buttons by delaying the closing click callback.',
        name: 'closeOnMouseup',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      clickOutsideDisables: {
        defaultValue: {
          value: 'false'
        },
        description: 'Clicking outside the trap area will disable the trap',
        name: 'clickOutsideDisables',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Reference to element that will get focus when the trap is initiated',
        name: 'initialFocus',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ElementTarget'
        }
      },
      gapMode: {
        defaultValue: {
          value: 'padding'
        },
        description: "if `scrollLock` is set to true, the body's scrollbar width will be preserved on lock\n" + 'via the `gapMode` CSS property. Depending on your custom CSS, you may prefer to use\n' + '`margin` instead of `padding`.',
        name: 'gapMode',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"padding" | "margin"',
          value: [{
            value: '"padding"'
          }, {
            value: '"margin"'
          }]
        }
      },
      crossFrame: {
        defaultValue: {
          value: 'false'
        },
        description: 'Configures focus trapping between iframes.\n' + 'By default, EuiFocusTrap allows focus to leave iframes and move to elements outside of it.\n' + 'Set to `true` if you want focus to remain trapped within the iframe.',
        name: 'crossFrame',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      scrollLock: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'scrollLock',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      noIsolation: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'noIsolation',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      returnFocus: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'returnFocus',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/focus_trap/focus_trap.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | FocusOptions | ((returnTo: Element) => boolean | FocusOptions)'
        }
      }
    },
    extendedInterfaces: ['ReactFocusOnProps', 'CommonProps']
  };
} catch (e) {}