/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useGeneratedHtmlId } from '../../../../services';
import { useEuiI18n } from '../../../i18n';
import { EuiFlexGrid, EuiFlexItem } from '../../../flex';
import { EuiLink } from '../../../link';
import { EuiQuickSelectPanel } from './quick_select_panel';
export var EuiCommonlyUsedTimeRanges = ({
  applyTime,
  commonlyUsedRanges
}) => {
  const title = useEuiI18n('euiCommonlyUsedTimeRanges.legend', 'Commonly used');
  const legendId = useGeneratedHtmlId();
  const links = commonlyUsedRanges.map(({
    start,
    end,
    label
  }) => {
    const applyCommonlyUsed = () => {
      applyTime({
        start,
        end
      });
    };
    const dataTestSubj = label ? `superDatePickerCommonlyUsed_${label.replace(' ', '_')}` : undefined;
    return <EuiFlexItem key={label} component="li">
        <EuiLink onClick={applyCommonlyUsed} data-test-subj={dataTestSubj}>
          {label}
        </EuiLink>
      </EuiFlexItem>;
  });
  return <EuiQuickSelectPanel component="fieldset" titleId={legendId} title={title}>
      <EuiFlexGrid aria-labelledby={legendId} gutterSize="s" columns={2} direction="column" responsive={false} component="ul">
        {links}
      </EuiFlexGrid>
    </EuiQuickSelectPanel>;
};
EuiCommonlyUsedTimeRanges.propTypes = {
  applyTime: PropTypes.func.isRequired,
  commonlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired
};
EuiCommonlyUsedTimeRanges.displayName = 'EuiCommonlyUsedTimeRanges';
try {
  EuiCommonlyUsedTimeRanges.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/quick_select_popover/commonly_used_time_ranges.tsx',
    description: '',
    displayName: 'EuiCommonlyUsedTimeRanges',
    methods: [],
    props: {
      applyTime: {
        defaultValue: null,
        description: '',
        name: 'applyTime',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/commonly_used_time_ranges.tsx',
          name: 'EuiCommonlyUsedTimeRangesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/commonly_used_time_ranges.tsx',
          name: 'EuiCommonlyUsedTimeRangesProps'
        }],
        required: true,
        type: {
          name: 'ApplyTime'
        }
      },
      commonlyUsedRanges: {
        defaultValue: null,
        description: '',
        name: 'commonlyUsedRanges',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/commonly_used_time_ranges.tsx',
          name: 'EuiCommonlyUsedTimeRangesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/commonly_used_time_ranges.tsx',
          name: 'EuiCommonlyUsedTimeRangesProps'
        }],
        required: true,
        type: {
          name: 'DurationRange[]'
        }
      }
    },
    extendedInterfaces: ['EuiCommonlyUsedTimeRangesProps']
  };
} catch (e) {}