import "core-js/modules/es.array.for-each.js";
import PropTypes from "prop-types";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { withEuiStylesMemoizer } from '../../services';
import { EuiHorizontalRule } from '../horizontal_rule';
import { EuiContextMenuPanel } from './context_menu_panel';
import { EuiContextMenuItem } from './context_menu_item';
import { euiContextMenuStyles } from './context_menu.styles';
export var SIZES = ['s', 'm'];
var isItemSeparator = function isItemSeparator(item) {
  return item.isSeparator === true;
};
function mapIdsToPanels(panels) {
  var map = {};
  panels.forEach(function (panel) {
    map[panel.id] = panel;
  });
  return map;
}
function mapIdsToPreviousPanels(panels) {
  var idToPreviousPanelIdMap = {};
  panels.forEach(function (panel) {
    if (Array.isArray(panel.items)) {
      panel.items.forEach(function (item) {
        if (isItemSeparator(item)) return;
        var isCloseable = item.panel !== undefined;
        if (isCloseable) {
          idToPreviousPanelIdMap[item.panel] = panel.id;
        }
      });
    }
  });
  return idToPreviousPanelIdMap;
}
function mapPanelItemsToPanels(panels) {
  var idAndItemIndexToPanelIdMap = {};
  panels.forEach(function (panel) {
    idAndItemIndexToPanelIdMap[panel.id] = {};
    if (panel.items) {
      panel.items.forEach(function (item, index) {
        if (isItemSeparator(item)) return;
        if (item.panel) {
          idAndItemIndexToPanelIdMap[panel.id][index] = item.panel;
        }
      });
    }
  });
  return idAndItemIndexToPanelIdMap;
}
export class EuiContextMenuClass extends Component {
  static defaultProps = {
    panels: [],
    size: 'm'
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      panels
    } = nextProps;
    if (panels && prevState.prevProps.panels !== panels) {
      return {
        prevProps: {
          panels
        },
        idToPanelMap: mapIdsToPanels(panels),
        idToPreviousPanelIdMap: mapIdsToPreviousPanels(panels),
        idAndItemIndexToPanelIdMap: mapPanelItemsToPanels(panels)
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.state = {
      prevProps: {},
      idToPanelMap: {},
      idToPreviousPanelIdMap: {},
      idAndItemIndexToPanelIdMap: {},
      idToRenderedItemsMap: this.mapIdsToRenderedItems(this.props.panels),
      height: undefined,
      outgoingPanelId: undefined,
      incomingPanelId: props.initialPanelId,
      transitionDirection: undefined,
      isOutgoingPanelVisible: false,
      focusedItemIndex: undefined,
      isUsingKeyboardToNavigate: false
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.panels !== this.props.panels) {
      this.setState({
        idToRenderedItemsMap: this.mapIdsToRenderedItems(this.props.panels)
      });
    }
  }
  hasPreviousPanel = panelId => {
    const previousPanelId = this.state.idToPreviousPanelIdMap[panelId];
    return typeof previousPanelId !== 'undefined';
  };
  showPanel(panelId, direction) {
    this.setState({
      outgoingPanelId: this.state.incomingPanelId,
      incomingPanelId: panelId,
      transitionDirection: direction,
      isOutgoingPanelVisible: true
    });
    this.props.onPanelChange?.({
      panelId,
      direction
    });
  }
  showNextPanel = itemIndex => {
    if (itemIndex == null) {
      return;
    }
    const nextPanelId = this.state.idAndItemIndexToPanelIdMap[this.state.incomingPanelId][itemIndex];
    if (nextPanelId) {
      if (this.state.isUsingKeyboardToNavigate) {
        this.setState(({
          idToPanelMap
        }) => ({
          focusedItemIndex: idToPanelMap[nextPanelId].initialFocusedItemIndex
        }));
      }
      this.showPanel(nextPanelId, 'next');
    }
  };
  showPreviousPanel = () => {
    // If there's a previous panel, then we can close the current panel to go back to it.
    if (this.hasPreviousPanel(this.state.incomingPanelId)) {
      const previousPanelId = this.state.idToPreviousPanelIdMap[this.state.incomingPanelId];

      // Set focus on the item which shows the panel we're leaving.
      const previousPanel = this.state.idToPanelMap[previousPanelId];
      const focusedItemIndex = previousPanel.items.filter(item => !isItemSeparator(item)).findIndex(item => item.panel === this.state.incomingPanelId);
      if (focusedItemIndex !== -1) {
        this.setState({
          focusedItemIndex
        });
      }
      this.showPanel(previousPanelId, 'previous');
    }
  };
  onIncomingPanelHeightChange = height => {
    this.setState(({
      height: prevHeight
    }) => {
      if (height === prevHeight) {
        return null;
      }
      return {
        height
      };
    });
  };
  onOutGoingPanelTransitionComplete = () => {
    this.setState({
      isOutgoingPanelVisible: false
    });
  };
  onUseKeyboardToNavigate = () => {
    if (!this.state.isUsingKeyboardToNavigate) {
      this.setState({
        isUsingKeyboardToNavigate: true
      });
    }
  };
  mapIdsToRenderedItems = (panels = []) => {
    const idToRenderedItemsMap = {};

    // Pre-rendering the items lets us check reference equality inside of EuiContextMenuPanel.
    panels.forEach(panel => {
      idToRenderedItemsMap[panel.id] = this.renderItems(panel.items);
    });
    return idToRenderedItemsMap;
  };
  renderItems(items = []) {
    return items.map((item, index) => {
      if (item.renderItem) {
        return <Fragment key={item.key ?? index}>{item.renderItem()}</Fragment>;
      }
      if (isItemSeparator(item)) {
        const {
          isSeparator: omit,
          key = index,
          ...rest
        } = item;
        return <EuiHorizontalRule key={key} margin="none" {...rest} />;
      }
      const {
        panel,
        name,
        key,
        icon,
        onClick,
        ...rest
      } = item;
      const onClickHandler = panel ? event => {
        if (onClick && event) {
          event.persist();
        }
        // This component is commonly wrapped in a EuiOutsideClickDetector, which means we'll
        // need to wait for that logic to complete before re-rendering the DOM via showPanel.
        window.requestAnimationFrame(() => {
          if (onClick) {
            onClick(event);
          }
          this.showNextPanel(index);
        });
      } : onClick;
      return <EuiContextMenuItem key={key || (typeof name === 'string' ? name : undefined) || index} icon={icon} onClick={onClickHandler} hasPanel={Boolean(panel)} {...rest}>
          {name}
        </EuiContextMenuItem>;
    });
  }
  renderPanel(panelId, transitionType) {
    const panel = this.state.idToPanelMap[panelId];
    if (!panel) {
      return;
    }

    // As above, we need to wait for EuiOutsideClickDetector to complete its logic before
    // re-rendering via showPanel.
    let onClose;
    if (this.hasPreviousPanel(panelId)) {
      onClose = () => window.requestAnimationFrame(this.showPreviousPanel);
    }
    const cssStyles = {
      position: 'absolute',
      label: 'euiContextMenu__panel'
    };
    return <EuiContextMenuPanel key={panelId} size={this.props.size} css={cssStyles} onHeightChange={transitionType === 'in' ? this.onIncomingPanelHeightChange : undefined} onTransitionComplete={transitionType === 'out' ? this.onOutGoingPanelTransitionComplete : undefined} title={panel.title} onClose={onClose} transitionType={this.state.isOutgoingPanelVisible ? transitionType : undefined} transitionDirection={this.state.isOutgoingPanelVisible ? this.state.transitionDirection : undefined} items={this.state.idToRenderedItemsMap[panelId]} initialFocusedItemIndex={this.state.isUsingKeyboardToNavigate ? this.state.focusedItemIndex : panel.initialFocusedItemIndex} onUseKeyboardToNavigate={this.onUseKeyboardToNavigate} showNextPanel={this.showNextPanel} showPreviousPanel={this.showPreviousPanel}>
        {panel.content}
      </EuiContextMenuPanel>;
  }
  render() {
    const {
      stylesMemoizer,
      panels,
      onPanelChange,
      className,
      initialPanelId,
      size,
      ...rest
    } = this.props;
    const incomingPanel = this.renderPanel(this.state.incomingPanelId, 'in');
    let outgoingPanel;
    if (this.state.isOutgoingPanelVisible) {
      outgoingPanel = this.renderPanel(this.state.outgoingPanelId, 'out');
    }
    const width = this.state.idToPanelMap[this.state.incomingPanelId] && this.state.idToPanelMap[this.state.incomingPanelId].width ? this.state.idToPanelMap[this.state.incomingPanelId].width : undefined;
    const classes = classNames('euiContextMenu', className);
    const styles = stylesMemoizer(euiContextMenuStyles);
    return <div css={styles.euiContextMenu} className={classes} style={{
      height: this.state.height,
      width: width
    }} {...rest}>
        {outgoingPanel}
        {incomingPanel}
      </div>;
  }
}
EuiContextMenuClass.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  panels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    title: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.node,
      key: PropTypes.string,
      panel: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      isSeparator: PropTypes.oneOf([true]),
      /**
         * Defines the width of the HR.
         */
      size: PropTypes.any,
      margin: PropTypes.any,
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any,
      /**
         * Allows rendering any custom content alongside your array of context menu items.
         * Accepts either a component or an inline function component that returns any JSX.
         */
      renderItem: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.func.isRequired])
    }).isRequired),
    content: PropTypes.node,
    width: PropTypes.any,
    initialFocusedItemIndex: PropTypes.number,
    /**
       * Alters the size of the items and the title
       */
    size: PropTypes.any
  }).isRequired),
  /**
       * Optional callback that fires on every panel change. Passes back
       * the new panel ID and whether its direction was `next` or `previous`.
       */
  onPanelChange: PropTypes.func,
  initialPanelId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  /**
       * Alters the size of the items and the title
       */
  size: PropTypes.any
};
export const EuiContextMenu = withEuiStylesMemoizer(EuiContextMenuClass);
try {
  EuiContextMenuClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/context_menu/context_menu.tsx',
    description: '',
    displayName: 'EuiContextMenuClass',
    methods: [],
    props: {
      stylesMemoizer: {
        defaultValue: null,
        description: '',
        name: 'stylesMemoizer',
        parent: {
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        }],
        required: true,
        type: {
          name: '<T extends (theme: UseEuiTheme<{}>) => StylesMap>(stylesGenerator: T) => ReturnType<T>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      panels: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'panels',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelDescriptor[]'
        }
      },
      onPanelChange: {
        defaultValue: null,
        description: 'Optional callback that fires on every panel change. Passes back\n' + 'the new panel ID and whether its direction was `next` or `previous`.',
        name: 'onPanelChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(panelDetails: { panelId: EuiContextMenuPanelId; direction?: EuiContextMenuPanelTransitionDirection; }) => void'
        }
      },
      initialPanelId: {
        defaultValue: null,
        description: '',
        name: 'initialPanelId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelId'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: 'Alters the size of the items and the title',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      }
    },
    extendedInterfaces: ['WithEuiStylesMemoizerProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}
try {
  EuiContextMenu.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/context_menu/context_menu.tsx',
    description: '',
    displayName: 'EuiContextMenu',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: null,
        description: 'Alters the size of the items and the title',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      panels: {
        defaultValue: null,
        description: '',
        name: 'panels',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelDescriptor[]'
        }
      },
      onPanelChange: {
        defaultValue: null,
        description: 'Optional callback that fires on every panel change. Passes back\n' + 'the new panel ID and whether its direction was `next` or `previous`.',
        name: 'onPanelChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(panelDetails: { panelId: EuiContextMenuPanelId; direction?: EuiContextMenuPanelTransitionDirection; }) => void'
        }
      },
      initialPanelId: {
        defaultValue: null,
        description: '',
        name: 'initialPanelId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelId'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['WithEuiStylesMemoizerProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}