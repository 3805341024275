const _excluded = ["children", "className"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useIsWithinBreakpoints } from '../../../services/breakpoint';
import { EuiPanel } from '../panel';
import { euiSplitPanelOuterStyles, euiSplitPanelInnerStyles } from './split_panel.styles';
/**
 * Consumed via `EuiSplitPanel.Inner`.
 * Extends most `EuiPanelProps`.
 */
export var _EuiSplitPanelInner = function _EuiSplitPanelInner(_ref) {
  var children = _ref.children,
    className = _ref.className,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiSplitPanel__inner', className);
  var panelProps = {
    hasShadow: false,
    color: 'transparent',
    borderRadius: 'none',
    hasBorder: false
  };
  return ___EmotionJSX(EuiPanel, _extends({
    element: "div",
    className: classes,
    css: euiSplitPanelInnerStyles.euiSplitPanelInner
  }, panelProps, rest), children);
};
_EuiSplitPanelInner.propTypes = {
  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,
  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,
  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
/**
 * Consumed via `EuiSplitPanel.Outer`.
 * Extends most `EuiPanelProps`.
 */
export var _EuiSplitPanelOuter = ({
  children,
  className,
  direction = 'column',
  responsive = ['xs', 's'],
  ...rest
}) => {
  const isResponsive = useIsWithinBreakpoints(responsive, !!responsive);
  const styles = euiSplitPanelOuterStyles;
  const cssStyles = [styles.euiSplitPanelOuter, direction === 'row' && !isResponsive ? styles.row : styles.column];
  const classes = classNames('euiSplitPanel', className);
  return <EuiPanel paddingSize="none" grow={false} className={classes} css={cssStyles} {...rest}>
      {children}
    </EuiPanel>;
};
_EuiSplitPanelOuter.propTypes = {
  /**
     * Any number of _EuiSplitPanelInner components
     */
  children: PropTypes.node,
  /**
     * Changes the flex-direction
     */
  direction: PropTypes.oneOf(["column", "row"]),
  /**
     * Stacks row display on small screens.
     * Remove completely with `false` or provide your own list of breakpoint sizes to stack on.
     */
  responsive: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.arrayOf(PropTypes.any.isRequired).isRequired])
};
export const EuiSplitPanel = {
  Outer: _EuiSplitPanelOuter,
  Inner: _EuiSplitPanelInner
};
try {
  _EuiSplitPanelInner.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/panel/split_panel/split_panel.tsx',
    description: 'Consumed via `EuiSplitPanel.Inner`.\nExtends most `EuiPanelProps`.',
    displayName: '_EuiSplitPanelInner',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      grow: {
        defaultValue: null,
        description: 'When true the panel will grow in height to match `EuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', '_EuiPanelProps']
  };
} catch (e) {}
try {
  _EuiSplitPanelOuter.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/panel/split_panel/split_panel.tsx',
    description: 'Consumed via `EuiSplitPanel.Outer`.\nExtends most `EuiPanelProps`.',
    displayName: '_EuiSplitPanelOuter',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: 'Any number of _EuiSplitPanelInner components',
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/panel/split_panel/split_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      direction: {
        defaultValue: {
          value: 'column'
        },
        description: 'Changes the flex-direction',
        name: 'direction',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/panel/split_panel/split_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }]
        }
      },
      responsive: {
        defaultValue: {
          value: "['xs', 's']"
        },
        description: 'Stacks row display on small screens.\n' + 'Remove completely with `false` or provide your own list of breakpoint sizes to stack on.',
        name: 'responsive',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/panel/split_panel/split_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'false | string[]'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      hasBorder: {
        defaultValue: null,
        description: 'Adds a slight 1px border on all edges.\n' + 'Only works when `color="plain | transparent"`',
        name: 'hasBorder',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasShadow: {
        defaultValue: null,
        description: 'Adds a medium shadow to the panel;\nOnly works when `color="plain"`',
        name: 'hasShadow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      borderRadius: {
        defaultValue: null,
        description: 'Corner border radius',
        name: 'borderRadius',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "m"',
          value: [{
            value: '"none"'
          }, {
            value: '"m"'
          }]
        }
      },
      grow: {
        defaultValue: null,
        description: 'When true the panel will grow in height to match `EuiFlexItem`',
        name: 'grow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', '_EuiPanelProps']
  };
} catch (e) {}