/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import classNames from 'classnames';
import PropTypes from "prop-types";
import { useEuiMemoizedStyles, cloneElementWithCss } from '../../services';
import { euiTitleStyles } from './title.styles';
export var TITLE_SIZES = ['xxxs', 'xxs', 'xs', 's', 'm', 'l'];
export var TEXT_TRANSFORM = ['uppercase'];
export var EuiTitle = ({
  size = 'm',
  children,
  className,
  textTransform,
  ...rest
}) => {
  const styles = useEuiMemoizedStyles(euiTitleStyles);
  const cssStyles = [styles.euiTitle, textTransform ? styles[textTransform] : undefined, styles[size]];
  const classes = classNames('euiTitle', className, children.props.className);
  const props = {
    css: cssStyles,
    className: classes,
    ...rest
  };
  return cloneElementWithCss(children, props);
};
EuiTitle.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * ReactElement to render as this component's content
     */
  children: PropTypes.element.isRequired,
  size: PropTypes.any,
  textTransform: PropTypes.any,
  id: PropTypes.string
};
try {
  EuiTitle.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/title/title.tsx',
    description: '',
    displayName: 'EuiTitle',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactElement to render as this component's content",
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      textTransform: {
        defaultValue: null,
        description: '',
        name: 'textTransform',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"uppercase"'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}