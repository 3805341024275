import React from 'react';

import { EuiMark } from '../../../../src/components';

export default () => {
  return (
    <>
      The quick brown fox <EuiMark>jumped over</EuiMark> the lazy dog
    </>
  );
};
