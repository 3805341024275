import React from 'react';
import PropTypes from "prop-types";
import { EuiEmptyPrompt, EuiButton, EuiTitle, EuiLink } from '../../../../src/components';
export var Panel = ({
  color
}) => {
  return <EuiEmptyPrompt iconType="securityAnalyticsApp" title={<h2>Start adding cases</h2>} color={color} body={<p>Add a new case or change your filter settings.</p>} actions={<EuiButton color="primary" fill>
          Add a case
        </EuiButton>} footer={<>
          <EuiTitle size="xxs">
            <h3>Want to learn more?</h3>
          </EuiTitle>
          <EuiLink href="#" target="_blank">
            Read the docs
          </EuiLink>
        </>} />;
};
Panel.propTypes = {
  color: PropTypes.any.isRequired
};
try {
  Panel.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/empty_prompt/empty_prompt_panel.tsx',
    description: '',
    displayName: 'Panel',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/empty_prompt/empty_prompt_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"transparent" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "plain"',
          value: [{
            value: '"transparent"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"plain"'
          }]
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}