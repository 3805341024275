import React, { useContext } from 'react';
import PropTypes from "prop-types";
import { Switch, Route, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { slugify } from '../../../../src/services/string/slugify';
import { EuiPageHeader, EuiBetaBadge } from '../../../../src/components';
import { LanguageSelector, ThemeContext } from '../with_theme';
import { GuideSection } from '../guide_section/guide_section';
export var GuideTabbedPage = ({
  description,
  guidelines,
  intro,
  isBeta,
  isNew,
  notice,
  pages,
  rightSideItems: _rightSideItems,
  showThemeLanguageToggle,
  tabs: _tabs,
  title,
  children
}) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const themeContext = useContext(ThemeContext);
  const currentLanguage = themeContext.themeLanguage;
  const showSass = currentLanguage.includes('sass');
  const headerBadge = isBeta ? <EuiBetaBadge label="Beta" color="accent" tooltipContent="This component is still under development and may contain breaking changes in the nearby future." /> : isNew ? <EuiBetaBadge label="New" color="accent" /> : undefined;
  let tabs = undefined;
  if (pages) {
    tabs = pages.map(page => {
      const id = slugify(page.title);
      return {
        id: id,
        name: page.title,
        handleClick: () => {
          history.push(`${match.path}/${id}`);
        }
      };
    });
  } else if (guidelines) {
    tabs = [{
      id: 'examples',
      name: 'Examples',
      handleClick: () => {
        history.push(`${match.path}`);
      }
    }, {
      id: 'guidelines',
      name: 'Guidelines',
      handleClick: () => {
        history.push(`${match.path}/guidelines`);
      }
    }];
  }
  let pagesRoutes;
  if (pages) {
    pagesRoutes = pages.map(page => {
      const pathname = location.pathname;
      const id = slugify(page.title);
      const firstTabId = slugify(pages[0].title);

      // first nav level redirects to first tab
      if (match.path === pathname) {
        return <Redirect key={pathname} from={`${match.path}`} to={`${match.path}/${firstTabId}`} />;
      } else {
        let rendered;
        if (page.page) {
          const PageComponent = page.page;
          rendered = <PageComponent showSass={showSass} />;
        } else {
          rendered = page.sections.map(sectionProps => <GuideSection {...sectionProps} />);
        }
        return <Route key={pathname} path={`${match.path}/${id}`}>
            {rendered}
          </Route>;
      }
    });
  } else {
    pagesRoutes = [guidelines ? <Route key={'guidelines'} path={`${match.path}/guidelines`}>
          <GuideSection>{guidelines}</GuideSection>
        </Route> : undefined, <Route key="default" path="">
        {children}
      </Route>];
  }
  const renderTabs = () => {
    if (_tabs) {
      return _tabs;
    } else if (!tabs) {
      return undefined;
    }
    return tabs.map(({
      id,
      handleClick,
      name
    }, index) => {
      const pathname = location.pathname;
      let isSelected = false;
      if (id === 'guidelines') isSelected = pathname.includes(`${match.path}/guidelines`);else if (id === 'examples') isSelected = !pathname.includes(`${match.path}/`) || pathname.includes('examples');else isSelected = pathname.includes(`${match.path}/${id}`);
      return {
        onClick: () => {
          if (handleClick) handleClick();
        },
        isSelected,
        key: index,
        label: name
      };
    });
  };
  const renderNotice = () => {
    if (showSass && notice) {
      return <GuideSection>
          <div role="region" aria-label="Notice">
            {notice}
          </div>
        </GuideSection>;
    }
  };
  const rightSideItems = _rightSideItems || [];
  if (showThemeLanguageToggle) {
    rightSideItems.push(<LanguageSelector />);
  }
  return <>
      {renderNotice()}
      <EuiPageHeader restrictWidth paddingSize="l" pageTitle={<>
            {title} {headerBadge}
          </>} tabs={renderTabs()} description={description} rightSideItems={rightSideItems} bottomBorder="extended">
        {intro}
      </EuiPageHeader>

      <Switch>{pagesRoutes}</Switch>
    </>;
};
GuideTabbedPage.propTypes = {
  description: PropTypes.node,
  guidelines: PropTypes.node,
  intro: PropTypes.node,
  isBeta: PropTypes.bool,
  isNew: PropTypes.bool,
  notice: PropTypes.node,
  pages: PropTypes.any,
  rightSideItems: PropTypes.arrayOf(PropTypes.node.isRequired),
  showThemeLanguageToggle: PropTypes.bool,
  tabs: PropTypes.any,
  title: PropTypes.string.isRequired
};
try {
  GuideTabbedPage.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
    description: '',
    displayName: 'GuideTabbedPage',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      guidelines: {
        defaultValue: null,
        description: '',
        name: 'guidelines',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      intro: {
        defaultValue: null,
        description: '',
        name: 'intro',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      isBeta: {
        defaultValue: null,
        description: '',
        name: 'isBeta',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isNew: {
        defaultValue: null,
        description: '',
        name: 'isNew',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      notice: {
        defaultValue: null,
        description: '',
        name: 'notice',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      pages: {
        defaultValue: null,
        description: '',
        name: 'pages',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      rightSideItems: {
        defaultValue: null,
        description: '',
        name: 'rightSideItems',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode[]'
        }
      },
      showThemeLanguageToggle: {
        defaultValue: null,
        description: '',
        name: 'showThemeLanguageToggle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      tabs: {
        defaultValue: null,
        description: '',
        name: 'tabs',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_tabbed_page/guide_tabbed_page.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}