/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useEuiMemoizedStyles } from '../../services';
import { logicalStyle } from '../../global_styling';
import { EuiIcon } from '../icon';
import { EuiI18n } from '../i18n';
import { EuiScreenReaderOnly } from '../accessibility';

/**
 * DRY util for indicating external links both via icon and to
 * screen readers. Used internally by at EuiLink and EuiListGroupItem
 */

var iconStyle = function iconStyle(_ref) {
  var euiTheme = _ref.euiTheme;
  return logicalStyle('margin-left', euiTheme.size.xs);
};
export var EuiExternalLinkIcon = ({
  target,
  external,
  ...rest
}) => {
  const iconCssStyle = useEuiMemoizedStyles(iconStyle);
  const showExternalLinkIcon = target === '_blank' && external !== false || external === true;
  return <>
      {showExternalLinkIcon && <>
          <EuiIcon css={iconCssStyle} size="s" type="popout" role="presentation" {...rest} />
          {target === '_blank' ? <EuiScreenReaderOnly>
              <span>
                <EuiI18n token="euiExternalLinkIcon.newTarget.screenReaderOnlyText" default="(external, opens in a new tab or window)" />
              </span>
            </EuiScreenReaderOnly> : <>
              <EuiScreenReaderOnly>
                <span>
                  <EuiI18n token="euiExternalLinkIcon.externalTarget.screenReaderOnlyText" default="(external)" />
                </span>
              </EuiScreenReaderOnly>
            </>}
        </>}
    </>;
};
EuiExternalLinkIcon.propTypes = {
  target: PropTypes.any,
  /**
     * Set to true to show an icon indicating that it is an external link;
     * Defaults to true if `target="_blank"`
     */
  external: PropTypes.bool
};
try {
  EuiExternalLinkIcon.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/link/external_link_icon.tsx',
    description: '',
    displayName: 'EuiExternalLinkIcon',
    methods: [],
    props: {
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/link/external_link_icon.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'SVGAttributes'
        }],
        required: false,
        type: {
          name: 'HTMLAttributeAnchorTarget & string'
        }
      },
      external: {
        defaultValue: null,
        description: 'Set to true to show an icon indicating that it is an external link;\n' + 'Defaults to true if `target="_blank"`',
        name: 'external',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/link/external_link_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'SVGAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      type: {
        defaultValue: null,
        description: '`Enum` is any of the named icons listed in the docs, `string` is usually a URL to an SVG file, and `elementType` is any React SVG component',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      color: {
        defaultValue: null,
        description: "One of EUI's color palette or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value.\n" + 'Note that coloring only works if your SVG is removed of fill attributes.',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      size: {
        defaultValue: null,
        description: 'Note that every size other than `original` assumes the provided SVG sits on a square viewbox.',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "original" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"original"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      title: {
        defaultValue: null,
        description: 'Descriptive title for naming the icon based on its use',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      titleId: {
        defaultValue: null,
        description: 'A unique identifier for the title element',
        name: 'titleId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onIconLoad: {
        defaultValue: null,
        description: 'Callback when the icon has been loaded & rendered',
        name: 'onIconLoad',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/icon/icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'SVGAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}