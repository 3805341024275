/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { EuiButtonIcon } from '../../button';
import { euiAccordionArrowStyles } from './accordion_arrow.styles';
export var EuiAccordionArrow = ({
  arrowDisplay = 'left',
  arrowProps,
  isOpen,
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  if (arrowDisplay === 'none') return null;
  const styles = euiAccordionArrowStyles(euiTheme);
  const cssStyles = [styles.euiAccordion__arrow, styles[arrowDisplay], isOpen ? styles.isOpen : styles.isClosed, arrowProps?.css];
  const classes = classNames('euiAccordion__arrow', arrowProps?.className);
  return <EuiButtonIcon color="text" {...arrowProps} {...rest} className={classes} css={cssStyles} iconType="arrowRight" />;
};
EuiAccordionArrow.propTypes = {
  isOpen: PropTypes.bool.isRequired
};
try {
  EuiAccordionArrow.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accordion/accordion_trigger/accordion_arrow.tsx',
    description: '',
    displayName: 'EuiAccordionArrow',
    methods: [],
    props: {
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      iconType: {
        defaultValue: null,
        description: '',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      size: {
        defaultValue: null,
        description: 'Overall size of button.\nMatches the sizes of other EuiButtons',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      iconSize: {
        defaultValue: null,
        description: 'Size of the icon only.\n' + 'This will not affect the overall size of the button',
        name: 'iconSize',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "original" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"original"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      display: {
        defaultValue: null,
        description: 'Sets the display style for matching other EuiButton types.\n' + '`base` is equivalent to a typical EuiButton\n' + '`fill` is equivalent to a filled EuiButton\n' + '`empty` (default) is equivalent to an EuiButtonEmpty',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"base" | "fill" | "empty"',
          value: [{
            value: '"base"'
          }, {
            value: '"fill"'
          }, {
            value: '"empty"'
          }]
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      arrowProps: {
        defaultValue: null,
        description: 'Extra props to pass to the EuiButtonIcon containing the arrow.',
        name: 'arrowProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiButtonIconProps, "aria-labelledby" | "onClick" | "iconType">>'
        }
      },
      arrowDisplay: {
        defaultValue: {
          value: 'left'
        },
        description: "Placement of the arrow indicator, or 'none' to hide it.",
        name: 'arrowDisplay',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "none"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"none"'
          }]
        }
      },
      isOpen: {
        defaultValue: null,
        description: '',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion_trigger/accordion_arrow.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiButtonIconProps', 'CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}