/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { Component } from 'react';
export class EuiObserver extends Component {
  name = 'EuiObserver';
  childNode = null;
  observer = null;
  componentDidMount() {
    if (this.childNode == null) {
      throw new Error(`${this.name} did not receive a ref`);
    }
  }
  componentWillUnmount() {
    if (this.observer != null) {
      this.observer.disconnect();
    }
  }
  updateChildNode = ref => {
    if (this.childNode === ref) return; // node hasn't changed

    // if there's an existing observer disconnect it
    if (this.observer != null) {
      this.observer.disconnect();
      this.observer = null;
    }
    this.childNode = ref;
    if (this.childNode != null) {
      this.beginObserve();
    }
  };
  beginObserve = () => {
    throw new Error('EuiObserver has no default observation method');
  };
  render() {
    const props = this.props;
    return props.children(this.updateChildNode);
  }
}
try {
  EuiObserver.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/observer/observer.ts',
    description: '',
    displayName: 'EuiObserver',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/observer/observer.ts',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/observer/observer.ts',
          name: 'BaseProps'
        }],
        required: true,
        type: {
          name: '(ref: any) => ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}