import "core-js/modules/es.symbol.js";
import PropTypes from "prop-types";
import "core-js/modules/es.symbol.description.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { useEuiMemoizedStyles, LEFT_ALIGNMENT } from '../../services';
import { EuiI18n } from '../i18n';
import { EuiScreenReaderOnly } from '../accessibility';
import { EuiIcon } from '../icon';
import { EuiInnerText } from '../inner_text';
import { resolveWidthAsStyle } from './utils';
import { useEuiTableIsResponsive } from './mobile/responsive_context';
import { EuiTableCellContent } from './_table_cell_content';
import { euiTableHeaderFooterCellStyles } from './table_cells_shared.styles';
var CellContents = function CellContents(_ref) {
  var className = _ref.className,
    align = _ref.align,
    description = _ref.description,
    children = _ref.children,
    canSort = _ref.canSort,
    isSorted = _ref.isSorted,
    isSortAscending = _ref.isSortAscending;
  return ___EmotionJSX(EuiTableCellContent, {
    className: className,
    align: align,
    textOnly: false,
    truncateText: null
  }, ___EmotionJSX(EuiInnerText, null, function (ref, innerText) {
    return ___EmotionJSX(EuiI18n, {
      token: "euiTableHeaderCell.titleTextWithDesc",
      default: "{innerText}; {description}",
      values: {
        innerText: innerText,
        description: description
      }
    }, function (titleTextWithDesc) {
      return ___EmotionJSX("span", {
        title: description ? titleTextWithDesc : innerText,
        ref: ref,
        className: "eui-textTruncate"
      }, children);
    });
  }), description && ___EmotionJSX(EuiScreenReaderOnly, null, ___EmotionJSX("span", null, description)), isSorted ? ___EmotionJSX(EuiIcon, {
    className: "euiTableSortIcon",
    type: isSortAscending ? 'sortUp' : 'sortDown',
    size: "m"
  }) : canSort ? ___EmotionJSX(EuiIcon, {
    className: "euiTableSortIcon euiTableSortIcon--sortable",
    type: "sortable",
    size: "m",
    color: "subdued" // Tinted a bit further via CSS
  }) : null);
};
export var EuiTableHeaderCell = ({
  children,
  align = LEFT_ALIGNMENT,
  onSort,
  isSorted,
  isSortAscending,
  className,
  scope,
  mobileOptions,
  width,
  style,
  readOnly,
  description,
  ...rest
}) => {
  const styles = useEuiMemoizedStyles(euiTableHeaderFooterCellStyles);
  const isResponsive = useEuiTableIsResponsive();
  const hideForDesktop = !isResponsive && mobileOptions?.only;
  const hideForMobile = isResponsive && mobileOptions?.show === false;
  if (hideForDesktop || hideForMobile) return null;
  const classes = classNames('euiTableHeaderCell', className);
  const inlineStyles = resolveWidthAsStyle(style, width);
  const CellComponent = children ? 'th' : 'td';
  const cellScope = CellComponent === 'th' ? scope ?? 'col' : undefined; // `scope` is only valid on `th` elements

  const canSort = !!(onSort && !readOnly);
  let ariaSortValue;
  if (isSorted) {
    ariaSortValue = isSortAscending ? 'ascending' : 'descending';
  } else if (canSort) {
    ariaSortValue = 'none';
  }
  const cellContentsProps = {
    css: styles.euiTableHeaderCell__content,
    align,
    description,
    canSort,
    isSorted,
    isSortAscending,
    children
  };
  return <CellComponent css={styles.euiTableHeaderCell} className={classes} scope={cellScope} role="columnheader" aria-sort={ariaSortValue} style={inlineStyles} {...rest}>
      {canSort ? <button type="button" css={styles.euiTableHeaderCell__button} className={classNames('euiTableHeaderButton', {
      'euiTableHeaderButton-isSorted': isSorted
    })} onClick={onSort} data-test-subj="tableHeaderSortButton">
          <CellContents {...cellContentsProps} />
        </button> : <CellContents {...cellContentsProps} />}
    </CellComponent>;
};
EuiTableHeaderCell.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  align: PropTypes.any,
  isSortAscending: PropTypes.bool,
  isSorted: PropTypes.bool,
  mobileOptions: PropTypes.any,
  onSort: PropTypes.func,
  scope: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  description: PropTypes.string,
  /**
       * Shows the sort indicator but removes the button
       */
  readOnly: PropTypes.bool
};
try {
  EuiTableHeaderCell.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/table_header_cell.tsx',
    description: '',
    displayName: 'EuiTableHeaderCell',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      align: {
        defaultValue: {
          value: 'left'
        },
        description: '',
        name: 'align',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'HorizontalAlignment',
          value: [{
            value: '"center"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      isSortAscending: {
        defaultValue: null,
        description: '',
        name: 'isSortAscending',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSorted: {
        defaultValue: null,
        description: '',
        name: 'isSorted',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      mobileOptions: {
        defaultValue: null,
        description: '',
        name: 'mobileOptions',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Pick<EuiTableRowCellMobileOptionsShape, "only" | "show">'
        }
      },
      onSort: {
        defaultValue: null,
        description: '',
        name: 'onSort',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      scope: {
        defaultValue: null,
        description: '',
        name: 'scope',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"col" | "colgroup" | "row" | "rowgroup"',
          value: [{
            value: '"col"'
          }, {
            value: '"colgroup"'
          }, {
            value: '"row"'
          }, {
            value: '"rowgroup"'
          }]
        }
      },
      width: {
        defaultValue: null,
        description: '',
        name: 'width',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      readOnly: {
        defaultValue: null,
        description: 'Shows the sort indicator but removes the button',
        name: 'readOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/table/table_header_cell.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'ThHTMLAttributes']
  };
} catch (e) {}