/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { logicalStyles } from '../../global_styling';
export var EuiAspectRatio = ({
  children,
  className,
  style,
  height,
  width,
  maxWidth,
  ...rest
}) => {
  const classes = classNames('euiAspectRatio', className, children.props.className);
  const euiAspectRatioStyle = useMemo(() => logicalStyles({
    aspectRatio: `${width} / ${height}`,
    height: 'auto',
    width: '100%',
    maxWidth
  }), [height, width, maxWidth]);
  return React.cloneElement(children, {
    ...rest,
    className: classes,
    style: {
      ...children.props.style,
      ...euiAspectRatioStyle,
      ...style
    }
  });
};
EuiAspectRatio.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Aspect ratio height. For example 9 would be widescreen video.
       */
  height: PropTypes.number.isRequired,
  /**
       * Aspect ratio width. For example 16 would be widescreen video.
       */
  width: PropTypes.number.isRequired,
  /**
       * The maximum width you want the child to stretch to.
       */
  maxWidth: PropTypes.any,
  children: PropTypes.element.isRequired
};
try {
  EuiAspectRatio.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/aspect_ratio/aspect_ratio.tsx',
    description: '',
    displayName: 'EuiAspectRatio',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      height: {
        defaultValue: null,
        description: 'Aspect ratio height. For example 9 would be widescreen video.',
        name: 'height',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/aspect_ratio/aspect_ratio.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      width: {
        defaultValue: null,
        description: 'Aspect ratio width. For example 16 would be widescreen video.',
        name: 'width',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/aspect_ratio/aspect_ratio.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      maxWidth: {
        defaultValue: null,
        description: 'The maximum width you want the child to stretch to.',
        name: 'maxWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/aspect_ratio/aspect_ratio.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Width<string | number>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}