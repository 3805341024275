/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles, cloneElementWithCss } from '../../../services';
import { useEuiI18n } from '../../i18n';
import { EuiIcon } from '../../icon';
import { EuiText } from '../../text';
import { FormContext, useFormContext } from '../eui_form_context';
import { EuiFormControlLayout } from './form_control_layout';
import { euiFormControlLayoutDelimitedStyles, euiFormControlLayoutDelimited__delimiter, euiFormControlLayoutDelimited__input } from './form_control_layout_delimited.styles';
export var EuiFormControlLayoutDelimited = ({
  startControl,
  endControl,
  delimiter,
  className,
  fullWidth: _fullWidth,
  ...rest
}) => {
  const {
    defaultFullWidth
  } = useFormContext();
  const fullWidth = _fullWidth ?? defaultFullWidth;
  const {
    isInvalid,
    isDisabled,
    readOnly
  } = rest;
  const showInvalidState = isInvalid && !isDisabled && !readOnly;
  const classes = classNames('euiFormControlLayoutDelimited', className, {
    'euiFormControlLayoutDelimited--isInvalid': showInvalidState
  });
  const styles = useEuiMemoizedStyles(euiFormControlLayoutDelimitedStyles);
  const cssStyles = [styles.delimited, rest.isDisabled && styles.disabled, rest.readOnly && styles.readOnly];
  const wrapperStyles = [styles.childrenWrapper.delimited, showInvalidState && styles.childrenWrapper.invalid, rest.wrapperProps?.css];
  return <EuiFormControlLayout isDelimited css={cssStyles} className={classes} iconsPosition="static" fullWidth={fullWidth} {...rest} wrapperProps={{
    ...rest.wrapperProps,
    css: wrapperStyles
  }}>
      <FormContext.Provider value={{
      defaultFullWidth: fullWidth
    }}>
        {addClassesToControl(startControl)}
        <EuiFormControlDelimiter delimiter={delimiter} isInvalid={showInvalidState} />
        {addClassesToControl(endControl)}
      </FormContext.Provider>
    </EuiFormControlLayout>;
};
EuiFormControlLayoutDelimited.propTypes = {
  /**
       * Left side control
       */
  startControl: PropTypes.element.isRequired,
  /**
       * Right side control
       */
  endControl: PropTypes.element.isRequired,
  /**
       * The center content. Accepts a string to be wrapped in a subdued EuiText
       * or a single ReactElement
       */
  delimiter: PropTypes.node,
  className: PropTypes.string
};
const addClassesToControl = control => {
  return cloneElementWithCss(control, {
    css: euiFormControlLayoutDelimited__input,
    className: classNames(control.props.className, 'euiFormControlLayoutDelimited__input')
  }, 'before');
};
const EuiFormControlDelimiter = ({
  delimiter,
  isInvalid
}) => {
  const defaultAriaLabel = useEuiI18n('euiFormControlLayoutDelimited.delimiterLabel', 'to');
  return <EuiText css={euiFormControlLayoutDelimited__delimiter} className="euiFormControlLayoutDelimited__delimiter" size="s" color={isInvalid ? 'danger' : 'subdued'}>
      {delimiter ?? <EuiIcon type="sortRight" aria-label={defaultAriaLabel} />}
    </EuiText>;
};
try {
  EuiFormControlLayoutDelimited.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
    description: '',
    displayName: 'EuiFormControlLayoutDelimited',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before children.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after children.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'IconType | IconShape'
        }
      },
      iconsPosition: {
        defaultValue: {
          value: 'absolute'
        },
        description: 'Determines whether icons are absolutely or statically rendered. For single inputs,\n' + 'absolute rendering is typically preferred.',
        name: 'iconsPosition',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"static" | "absolute"',
          value: [{
            value: '"static"'
          }, {
            value: '"absolute"'
          }]
        }
      },
      clear: {
        defaultValue: null,
        description: '',
        name: 'clear',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & ButtonHTMLAttributes<HTMLButtonElement> & { size?: "s" | "m"; }'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readOnly: {
        defaultValue: null,
        description: '',
        name: 'readOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isDropdown: {
        defaultValue: null,
        description: 'Controls the adding of and visibility of a down arrow icon',
        name: 'isDropdown',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputId: {
        defaultValue: null,
        description: 'Connects the prepend and append labels to the input',
        name: 'inputId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      wrapperProps: {
        defaultValue: null,
        description: 'Allows passing optional additional props to `.euiFormControlLayout__childrenWrapper`',
        name: 'wrapperProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement>'
        }
      },
      startControl: {
        defaultValue: null,
        description: 'Left side control',
        name: 'startControl',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      endControl: {
        defaultValue: null,
        description: 'Right side control',
        name: 'endControl',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      delimiter: {
        defaultValue: null,
        description: 'The center content. Accepts a string to be wrapped in a subdued EuiText\n' + 'or a single ReactElement',
        name: 'delimiter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout_delimited.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}