/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { euiHealthStyles } from './health.styles';
import { EuiIcon } from '../icon';
import { EuiFlexGroup, EuiFlexItem } from '../flex';
export var TEXT_SIZES = ['xs', 's', 'm', 'inherit'];
export var EuiHealth = ({
  children,
  className,
  color,
  textSize = 's',
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiHealthStyles(euiTheme);
  const cssStyles = [styles.euiHealth, styles[textSize]];
  const classes = classNames('euiHealth', className);
  return <div css={cssStyles} className={classes} {...rest}>
      <EuiFlexGroup gutterSize="xs" alignItems="center" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon type="dot" color={color} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{children}</EuiFlexItem>
      </EuiFlexGroup>
    </div>;
};
EuiHealth.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Sets the color of the dot icon.
       * It accepts any `IconColor`: `default`, `primary`, `success`, `accent`, `warning`, `danger`, `text`,
       * `subdued` or `ghost`; or any valid CSS color value as a `string`
       */
  color: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.any.isRequired]),
  /**
       * Matches the text scales of EuiText.
       * The `inherit` style will get its font size from the parent element
       */
  textSize: PropTypes.any
};
try {
  EuiHealth.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/health/health.tsx',
    description: '',
    displayName: 'EuiHealth',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      color: {
        defaultValue: null,
        description: 'Sets the color of the dot icon.\n' + 'It accepts any `IconColor`: `default`, `primary`, `success`, `accent`, `warning`, `danger`, `text`,\n' + '`subdued` or `ghost`; or any valid CSS color value as a `string`',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/health/health.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      textSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Matches the text scales of EuiText.\n' + 'The `inherit` style will get its font size from the parent element',
        name: 'textSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/health/health.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inherit" | "s" | "m" | "xs"',
          value: [{
            value: '"inherit"'
          }, {
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}