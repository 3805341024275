import "core-js/modules/es.number.to-fixed.js";
import PropTypes from "prop-types";
import "core-js/modules/es.parse-float.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import { htmlIdGenerator } from '../../../services';
import { EuiI18n } from '../../i18n';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
import { EuiSelect, EuiFieldNumber, EuiFormLabel, EuiSwitch } from '../../form';
import { EuiScreenReaderOnly } from '../../accessibility';
import { RenderI18nTimeOptions } from '../super_date_picker/time_options';
import { EuiQuickSelectPanel } from '../super_date_picker/quick_select_popover/quick_select_panel';
var MILLISECONDS_IN_SECOND = 1000;
var MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
var MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
var fromMilliseconds = function fromMilliseconds(milliseconds, unit) {
  var round = function round(value) {
    return parseFloat(value.toFixed(2));
  };
  if (unit === 'h' || !unit && milliseconds > MILLISECONDS_IN_HOUR) {
    return {
      units: 'h',
      value: round(milliseconds / MILLISECONDS_IN_HOUR)
    };
  }
  if (unit === 'm' || !unit && milliseconds > MILLISECONDS_IN_MINUTE) {
    return {
      units: 'm',
      value: round(milliseconds / MILLISECONDS_IN_MINUTE)
    };
  }
  return {
    units: 's',
    value: round(milliseconds / MILLISECONDS_IN_SECOND)
  };
};
var toMilliseconds = function toMilliseconds(units, value) {
  switch (units) {
    case 'h':
      return Math.round(value * MILLISECONDS_IN_HOUR);
    case 'm':
      return Math.round(value * MILLISECONDS_IN_MINUTE);
    case 's':
    default:
      return Math.round(value * MILLISECONDS_IN_SECOND);
  }
};
var getMinInterval = function getMinInterval(minInterval, unit) {
  if (!minInterval) return 0;
  var _fromMilliseconds = fromMilliseconds(minInterval, unit),
    value = _fromMilliseconds.value;
  return Math.floor(value || 0);
};
export class EuiRefreshInterval extends Component {
  static defaultProps = {
    isPaused: true,
    refreshInterval: 1000,
    minInterval: 0
  };
  state = {
    ...fromMilliseconds(this.props.refreshInterval || 0, this.props.intervalUnits),
    min: getMinInterval(this.props.minInterval, this.props.intervalUnits)
  };
  generateId = htmlIdGenerator();
  refreshSelectionId = this.generateId();
  onValueChange = event => {
    const sanitizedValue = parseFloat(event.target.value);
    this.setState({
      value: isNaN(sanitizedValue) ? '' : sanitizedValue
    }, this.applyRefreshInterval);
  };
  onUnitsChange = event => {
    const units = event.target.value;
    this.setState({
      units,
      min: getMinInterval(this.props.minInterval, units)
    }, this.applyRefreshInterval);
  };
  startRefresh = () => {
    const {
      onRefreshChange
    } = this.props;
    const {
      value,
      units
    } = this.state;
    if (value !== '' && value > 0 && onRefreshChange !== undefined) {
      onRefreshChange({
        refreshInterval: toMilliseconds(units, value),
        intervalUnits: units,
        isPaused: false
      });
    }
  };
  handleKeyDown = ({
    key
  }) => {
    if (key === 'Enter') {
      this.startRefresh();
    }
  };
  applyRefreshInterval = () => {
    const {
      onRefreshChange,
      isPaused,
      minInterval
    } = this.props;
    const {
      units,
      value
    } = this.state;
    if (value === '') {
      return;
    }
    if (!onRefreshChange) {
      return;
    }
    const refreshInterval = Math.max(toMilliseconds(units, value), minInterval || 0);
    onRefreshChange({
      refreshInterval,
      intervalUnits: units,
      isPaused: refreshInterval <= 0 ? true : !!isPaused
    });
  };
  toggleRefresh = () => {
    const {
      onRefreshChange,
      isPaused
    } = this.props;
    const {
      units,
      value
    } = this.state;
    if (!onRefreshChange || value === '') {
      return;
    }
    onRefreshChange({
      refreshInterval: toMilliseconds(units, value),
      intervalUnits: units,
      isPaused: !isPaused
    });
  };
  renderScreenReaderText = refreshUnitsOptions => {
    const {
      isPaused
    } = this.props;
    const {
      value,
      units
    } = this.state;
    const options = refreshUnitsOptions.find(({
      value
    }) => value === units);
    const optionText = options ? options.text : '';
    const fullDescription = isPaused ? <EuiI18n token="euiRefreshInterval.fullDescriptionOff" default="Refresh is off, interval set to {optionValue} {optionText}." values={{
      optionValue: value,
      optionText
    }} /> : <EuiI18n token="euiRefreshInterval.fullDescriptionOn" default="Refresh is on, interval set to {optionValue} {optionText}." values={{
      optionValue: value,
      optionText
    }} />;
    return <EuiScreenReaderOnly>
        <p id={this.refreshSelectionId}>{fullDescription}</p>
      </EuiScreenReaderOnly>;
  };
  render() {
    const {
      isPaused
    } = this.props;
    const {
      value,
      units,
      min
    } = this.state;
    return <EuiI18n tokens={['euiRefreshInterval.toggleLabel', 'euiRefreshInterval.toggleAriaLabel', 'euiRefreshInterval.valueAriaLabel', 'euiRefreshInterval.unitsAriaLabel']} defaults={['Refresh every', 'Toggle refresh', 'Refresh interval value', 'Refresh interval units']}>
        {([toggleLabel, toggleAriaLabel, valueAriaLabel, unitsAriaLabel]) => <RenderI18nTimeOptions>
            {({
          refreshUnitsOptions
        }) => <EuiQuickSelectPanel>
                <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false} wrap>
                  <EuiFlexItem grow={false}>
                    <EuiSwitch data-test-subj="superDatePickerToggleRefreshButton" checked={!isPaused} onChange={this.toggleRefresh} compressed
              // The IDs attached to this visible label are unused - we override with our own aria-label
              label={<EuiFormLabel>{toggleLabel}</EuiFormLabel>} aria-label={toggleAriaLabel} aria-labelledby={undefined} aria-describedby={this.refreshSelectionId} />
                  </EuiFlexItem>
                  <EuiFlexItem style={{
              minWidth: 60
            }}>
                    <EuiFieldNumber compressed fullWidth value={value} min={min} onChange={this.onValueChange} onKeyDown={this.handleKeyDown} isInvalid={!isPaused && (value === '' || value <= 0)} disabled={isPaused} aria-label={valueAriaLabel} aria-describedby={this.refreshSelectionId} data-test-subj="superDatePickerRefreshIntervalInput" />
                  </EuiFlexItem>
                  <EuiFlexItem style={{
              minWidth: 100
            }} grow={2}>
                    <EuiSelect compressed fullWidth aria-label={unitsAriaLabel} aria-describedby={this.refreshSelectionId} value={units} disabled={isPaused} options={refreshUnitsOptions} onChange={this.onUnitsChange} onKeyDown={this.handleKeyDown} data-test-subj="superDatePickerRefreshIntervalUnitsSelect" />
                  </EuiFlexItem>
                </EuiFlexGroup>
                {this.renderScreenReaderText(refreshUnitsOptions)}
              </EuiQuickSelectPanel>}
          </RenderI18nTimeOptions>}
      </EuiI18n>;
  }
}
EuiRefreshInterval.propTypes = {
  /**
     * Is refresh paused or running.
     */
  isPaused: PropTypes.bool,
  /**
     * Refresh interval in milliseconds.
     */
  refreshInterval: PropTypes.number,
  /**
     * Allows specifying a minimum interval in milliseconds
     */
  minInterval: PropTypes.number,
  /**
     * By default, refresh interval units will be rounded up to next largest unit of time
     * (for example, 90 seconds will become 2m).
     *
     * If you do not want this behavior, you can manually control the rendered unit via this prop.
     */
  intervalUnits: PropTypes.any,
  /**
     * Passes back the updated state of `isPaused`, `refreshInterval`, and `intervalUnits`.
     */
  onRefreshChange: PropTypes.func.isRequired
};
try {
  EuiRefreshInterval.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
    description: '',
    displayName: 'EuiRefreshInterval',
    methods: [],
    props: {
      isPaused: {
        defaultValue: {
          value: 'true'
        },
        description: 'Is refresh paused or running.',
        name: 'isPaused',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      refreshInterval: {
        defaultValue: {
          value: '1000'
        },
        description: 'Refresh interval in milliseconds.',
        name: 'refreshInterval',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      minInterval: {
        defaultValue: {
          value: '0'
        },
        description: 'Allows specifying a minimum interval in milliseconds',
        name: 'minInterval',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      intervalUnits: {
        defaultValue: null,
        description: 'By default, refresh interval units will be rounded up to next largest unit of time\n' + '(for example, 90 seconds will become 2m).\n' + '\n' + 'If you do not want this behavior, you can manually control the rendered unit via this prop.',
        name: 'intervalUnits',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "h"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"h"'
          }]
        }
      },
      onRefreshChange: {
        defaultValue: null,
        description: 'Passes back the updated state of `isPaused`, `refreshInterval`, and `intervalUnits`.',
        name: 'onRefreshChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/auto_refresh/refresh_interval.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ApplyRefreshInterval'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}