/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { logicalStyles } from '../../../global_styling';
import { EuiResizeObserver } from '../../observer/resize_observer';
import { euiRangeLevelColor } from './range_levels_colors';
import { euiRangeSliderStyles, euiRangeSliderThumbStyles } from './range_slider.styles';
export var EuiRangeSlider = ({
  className,
  disabled,
  id,
  max,
  min,
  name,
  step,
  onChange,
  tabIndex,
  value,
  style,
  showTicks,
  showRange,
  thumbColor,
  onResize,
  ariaValueText,
  ...rest
}) => {
  const classes = classNames('euiRangeSlider', className);
  const euiTheme = useEuiTheme();
  const styles = euiRangeSliderStyles(euiTheme);
  const thumbStyles = euiRangeSliderThumbStyles(euiTheme);
  const cssStyles = [styles.euiRangeSlider, showTicks && styles.hasTicks, showRange && styles.hasRange, thumbColor && thumbStyles.thumb];
  const sliderStyle = useMemo(() => {
    return logicalStyles({
      color: thumbColor && euiRangeLevelColor(thumbColor, euiTheme.euiTheme),
      ...style
    });
  }, [thumbColor, euiTheme, style]);
  return <EuiResizeObserver onResize={onResize}>
      {resizeRef => <input aria-valuetext={ariaValueText} ref={resizeRef} type="range" id={id} name={name} className={classes} css={cssStyles} min={min} max={max} step={step} value={value} disabled={disabled} onChange={onChange} style={sliderStyle} tabIndex={tabIndex} {...rest} />}
    </EuiResizeObserver>;
};
EuiRangeSlider.propTypes = {
  onChange: PropTypes.any,
  thumbColor: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  onResize: PropTypes.func.isRequired,
  ariaValueText: PropTypes.string,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiRangeSlider.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_slider.tsx',
    description: '',
    displayName: 'EuiRangeSlider',
    methods: [],
    props: {
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        }],
        required: false,
        type: {
          name: 'ChangeEventHandler<HTMLInputElement>'
        }
      },
      thumbColor: {
        defaultValue: null,
        description: '',
        name: 'thumbColor',
        parent: {
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        }],
        required: false,
        type: {
          name: 'Color | "primary" | "success" | "warning" | "danger"'
        }
      },
      onResize: {
        defaultValue: null,
        description: '',
        name: 'onResize',
        parent: {
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        }],
        required: true,
        type: {
          name: '(dimensions: { height: number; width: number; }) => void'
        }
      },
      ariaValueText: {
        defaultValue: null,
        description: '',
        name: 'ariaValueText',
        parent: {
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_slider.tsx',
          name: 'EuiRangeSliderProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Only impacts inputs rendered when the `showInput` prop is set to `"inputWithPopover"`',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      showRange: {
        defaultValue: null,
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiRangeSliderProps', 'HTMLAttributes', 'InputHTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', '_SharedRangeInputProps', '_SharedRangeValuesProps', '_SharedRangeVisualConfiguration']
  };
} catch (e) {}