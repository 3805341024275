/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from "prop-types";
import { useEuiI18n } from '../i18n';
import { EuiFieldSearch } from '../form';
import { EuiInputPopover } from '../popover';
export var EuiSearchBox = ({
  query,
  placeholder,
  incremental,
  hint,
  ...rest
}) => {
  const inputRef = useRef(null);
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = query;
      inputRef.current.dispatchEvent(new Event('change'));
    }
  }, [query]);
  const defaultPlaceholder = useEuiI18n('euiSearchBox.placeholder', 'Search...');
  const ariaLabelIncremental = useEuiI18n('euiSearchBox.incrementalAriaLabel', 'This is a search bar. As you type, the results lower in the page will automatically filter.');
  const ariaLabelEnter = useEuiI18n('euiSearchBox.ariaLabel', 'This is a search bar. After typing your query, hit enter to filter the results lower in the page.');
  const search = <EuiFieldSearch inputRef={input => inputRef.current = input} fullWidth defaultValue={query} incremental={incremental} aria-label={incremental ? ariaLabelIncremental : ariaLabelEnter} placeholder={placeholder ?? defaultPlaceholder} onFocus={() => {
    hint?.setIsVisible(true);
  }} {...rest} />;
  if (hint) {
    return <EuiInputPopover disableFocusTrap input={search} isOpen={hint.isVisible} fullWidth closePopover={() => {
      hint.setIsVisible(false);
    }} panelProps={{
      'aria-live': undefined,
      'aria-modal': undefined,
      role: undefined,
      tabIndex: -1,
      id: hint.id
    }} {...hint.popoverProps}>
        {hint.content}
      </EuiInputPopover>;
  }
  return search;
};
EuiSearchBox.propTypes = {
  query: PropTypes.string.isRequired,
  // This is optional in EuiFieldSearchProps
  onSearch: PropTypes.func.isRequired,
  /**
     * @default Search...
     */
  placeholder: PropTypes.string,
  hint: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    content: PropTypes.node.isRequired,
    popoverProps: PropTypes.any
  })
};
try {
  EuiSearchBox.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/search_bar/search_box.tsx',
    description: '',
    displayName: 'EuiSearchBox',
    methods: [],
    props: {
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      onSearch: {
        defaultValue: null,
        description: 'Called when the user presses [Enter] OR on change if the incremental prop is `true`.\n' + "If you don't need the on[Enter] functionality, prefer using onChange",
        name: 'onSearch',
        parent: {
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        }],
        required: true,
        type: {
          name: '(queryText: string) => void'
        }
      },
      placeholder: {
        defaultValue: {
          value: 'Search...'
        },
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      hint: {
        defaultValue: null,
        description: '',
        name: 'hint',
        parent: {
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/search_box.tsx',
          name: 'EuiSearchBoxProps'
        }],
        required: false,
        type: {
          name: '{ id: string; isVisible: boolean; setIsVisible: (isVisible: boolean) => void; } & { content: React.ReactNode; popoverProps?: Partial<Pick<EuiInputPopoverProps, "data-test-subj" | "fullWidth" | ... 9 more ... | "disableFocusTrap">>; }'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      incremental: {
        defaultValue: null,
        description: 'When `true` the search will be executed (that is, the `onSearch` will be called) as the\n' + 'user types.',
        name: 'incremental',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(node: HTMLInputElement) => void'
        }
      },
      isClearable: {
        defaultValue: null,
        description: 'Shows a button that quickly clears any input',
        name: 'isClearable',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiSearchBoxProps', 'EuiFieldSearchProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}