/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { EuiScreenReaderOnly } from '../screen_reader_only';
export var EuiScreenReaderLive = ({
  children,
  isActive = true,
  role = 'status',
  'aria-live': ariaLive = 'polite',
  focusRegionOnTextChange = false
}) => {
  const [toggle, setToggle] = useState(false);
  const focusRef = useRef(null);
  useEffect(() => {
    setToggle(toggle => !toggle);
  }, [children]);
  useEffect(() => {
    if (focusRef.current !== null && focusRegionOnTextChange) {
      focusRef.current.focus();
    }
  }, [toggle, focusRegionOnTextChange]);
  return (
    /**
     * Intentionally uses two persistent live regions with oscillating content updates.
     * This resolves the problem of duplicate screen reader announcements in rapid succession
     * caused by React's virtual DOM behaviour (https://github.com/nvaccess/nvda/issues/7996#issuecomment-413641709)
     *
     * Adapted from https://github.com/alphagov/accessible-autocomplete/blob/a7106f03150941fc15e6c1ceb0a90e8872fa86ef/src/status.js
     * Debouncing was not needed for this case, but could prove to be useful for future use cases.
     * See also https://github.com/AlmeroSteyn/react-aria-live and https://github.com/dequelabs/ngA11y
     * for more examples of the double region approach.
     */
    <EuiScreenReaderOnly>
      <div ref={focusRef} tabIndex={focusRegionOnTextChange ? -1 : undefined}>
        <div role={role} aria-atomic="true"
        // Setting `aria-hidden` and setting `aria-live` to "off" prevents
        // double announcements from VO when `focusRegionOnTextChange` is true
        aria-hidden={toggle ? undefined : 'true'} aria-live={!toggle || focusRegionOnTextChange ? 'off' : ariaLive}>
          {isActive && toggle ? children : ''}
        </div>
        <div role={role} aria-atomic="true" aria-hidden={!toggle ? undefined : 'true'} aria-live={toggle || focusRegionOnTextChange ? 'off' : ariaLive}>
          {isActive && !toggle ? children : ''}
        </div>
      </div>
    </EuiScreenReaderOnly>
  );
};
EuiScreenReaderLive.propTypes = {
  /**
     * Whether to make screen readers aware of the content
     */
  isActive: PropTypes.bool,
  /**
     * Content for screen readers to announce
     */
  children: PropTypes.node,
  /**
     * `role` attribute for both live regions.
     *
     * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions#roles_with_implicit_live_region_attributes
     */
  role: PropTypes.any,
  /**
     * `aria-live` attribute for both live regions
     */
  "aria-live": PropTypes.any,
  /**
     * On `children`/text change, the region will auto-focus itself, causing screen readers
     * to automatically read out the text content. This prop should primarily be used for
     * navigation or page changes, where programmatically resetting focus location back to
     * a certain part of the page is desired.
     */
  focusRegionOnTextChange: PropTypes.bool
};
try {
  EuiScreenReaderLive.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
    description: '',
    displayName: 'EuiScreenReaderLive',
    methods: [],
    props: {
      isActive: {
        defaultValue: {
          value: 'true'
        },
        description: 'Whether to make screen readers aware of the content',
        name: 'isActive',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      children: {
        defaultValue: null,
        description: 'Content for screen readers to announce',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      role: {
        defaultValue: {
          value: 'status'
        },
        description: '`role` attribute for both live regions.\n' + '\n' + 'https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions#roles_with_implicit_live_region_attributes',
        name: 'role',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        }],
        required: false,
        type: {
          name: 'AriaRole'
        }
      },
      'aria-live': {
        defaultValue: null,
        description: '`aria-live` attribute for both live regions',
        name: 'aria-live',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"off" | "assertive" | "polite"',
          value: [{
            value: '"off"'
          }, {
            value: '"assertive"'
          }, {
            value: '"polite"'
          }]
        }
      },
      focusRegionOnTextChange: {
        defaultValue: {
          value: 'false'
        },
        description: 'On `children`/text change, the region will auto-focus itself, causing screen readers\n' + 'to automatically read out the text content. This prop should primarily be used for\n' + 'navigation or page changes, where programmatically resetting focus location back to\n' + 'a certain part of the page is desired.',
        name: 'focusRegionOnTextChange',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_live/screen_reader_live.tsx',
          name: 'EuiScreenReaderLiveProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiScreenReaderLiveProps']
  };
} catch (e) {}