/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { euiToolTipStyles } from './tool_tip.styles';
export var EuiToolTipPopover = ({
  children,
  title,
  className,
  positionToolTip,
  popoverRef,
  calculatedPosition,
  ...rest
}) => {
  const popover = useRef();
  const euiTheme = useEuiTheme();
  const styles = euiToolTipStyles(euiTheme);
  const cssStyles = [styles.euiToolTip, calculatedPosition && styles[calculatedPosition]];
  const updateDimensions = useCallback(() => {
    requestAnimationFrame(() => {
      // Because of this delay, sometimes `positionToolTip` becomes unavailable.
      if (popover.current) {
        positionToolTip();
      }
    });
  }, [positionToolTip]);
  const setPopoverRef = ref => {
    if (popoverRef) {
      popoverRef(ref);
    }
  };
  useEffect(() => {
    document.body.classList.add('euiBody-hasPortalContent');
    window.addEventListener('resize', updateDimensions);
    return () => {
      document.body.classList.remove('euiBody-hasPortalContent');
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);
  const classes = classNames('euiToolTipPopover', className);
  return <div css={cssStyles} className={classes} ref={setPopoverRef} data-position={calculatedPosition} {...rest}>
      {title && <div css={styles.euiToolTip__title} className="euiToolTip__title">
          {title}
        </div>}
      {children}
    </div>;
};
EuiToolTipPopover.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  positionToolTip: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  popoverRef: PropTypes.func,
  calculatedPosition: PropTypes.oneOf(["top", "right", "bottom", "left"])
};
try {
  EuiToolTipPopover.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tool_tip/tool_tip_popover.tsx',
    description: '',
    displayName: 'EuiToolTipPopover',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      positionToolTip: {
        defaultValue: null,
        description: '',
        name: 'positionToolTip',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_popover.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_popover.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_popover.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(ref: HTMLDivElement) => void'
        }
      },
      calculatedPosition: {
        defaultValue: null,
        description: '',
        name: 'calculatedPosition',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip_popover.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToolTipPositions',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}