/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { forwardRef, useContext } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiMarkdownContext } from './markdown_context';
import { euiMarkdownEditorTextAreaStyles } from './markdown_editor_text_area.styles';
export var EuiMarkdownEditorTextArea = forwardRef(({
  children,
  compressed,
  id,
  isInvalid,
  name,
  placeholder,
  rows,
  height,
  maxHeight,
  ...rest
}, ref) => {
  const {
    readOnly
  } = useContext(EuiMarkdownContext);
  const classes = classNames('euiMarkdownEditorTextArea', {
    'euiMarkdownEditorTextArea-isReadOnly': readOnly
  });
  const styles = useEuiMemoizedStyles(euiMarkdownEditorTextAreaStyles);
  const cssStyles = [styles.euiMarkdownEditorTextArea, readOnly ? styles.readOnly : styles.editable];

  // Ignore invalid empty string style values
  const style = {};
  if (height !== '') {
    style.height = height;
  }
  if (maxHeight !== '') {
    style.maxHeight = maxHeight;
  }
  return <textarea ref={ref} data-test-subj="euiMarkdownEditorTextArea" style={style} className={classes} css={cssStyles} {...rest} rows={6} name={name} id={id} placeholder={placeholder} readOnly={readOnly}>
        {children}
      </textarea>;
});
EuiMarkdownEditorTextArea.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  isInvalid: PropTypes.bool,
  fullWidth: PropTypes.bool,
  compressed: PropTypes.bool,
  height: PropTypes.string.isRequired,
  maxHeight: PropTypes.string.isRequired
};
EuiMarkdownEditorTextArea.displayName = 'EuiMarkdownEditorTextArea';
try {
  EuiMarkdownEditorTextArea.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/markdown_editor/markdown_editor_text_area.tsx',
    description: '',
    displayName: 'EuiMarkdownEditorTextArea',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_editor_text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_editor_text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_editor_text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      height: {
        defaultValue: null,
        description: '',
        name: 'height',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_editor_text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      maxHeight: {
        defaultValue: null,
        description: '',
        name: 'maxHeight',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_editor_text_area.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLTextAreaElement>'
        }
      }
    },
    extendedInterfaces: ['TextareaHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'Attributes']
  };
} catch (e) {}