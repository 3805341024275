/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import unified from 'unified';
import classNames from 'classnames';
import { EuiText } from '../text/text';
import { _isNamedColor } from '../text/text_color';
import { useEuiMemoizedStyles } from '../../services';
import { euiMarkdownFormatStyles } from './markdown_format.styles';
import { defaultProcessingPlugins, defaultParsingPlugins } from './plugins/markdown_default_plugins';
export var EuiMarkdownFormat = ({
  children,
  className,
  parsingPluginList = defaultParsingPlugins,
  processingPluginList = defaultProcessingPlugins,
  textSize = 'm',
  color = 'default',
  ...rest
}) => {
  const processor = useMemo(() => unified().use(parsingPluginList).use(processingPluginList), [parsingPluginList, processingPluginList]);
  const result = useMemo(() => {
    try {
      const processed = processor.processSync(children);
      // `.result` is intentionally `unknown` (https://github.com/vfile/vfile/pull/53)
      // cast to something expected.
      return processed.result ?? processed.contents;
    } catch (e) {
      return children;
    }
  }, [children, processor]);
  const styles = useEuiMemoizedStyles(euiMarkdownFormatStyles);
  const cssStyles = [styles.euiMarkdownFormat, styles[textSize], _isNamedColor(color) ? styles.colors[color] : styles.colors.custom];
  const classes = classNames('euiMarkdownFormat', className);
  return <EuiText size={textSize} css={cssStyles} className={classes} color={color} {...rest}>
      {result}
    </EuiText>;
};
EuiMarkdownFormat.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  children: PropTypes.string.isRequired,
  /** array of unified plugins to parse content into an AST */parsingPluginList: PropTypes.any,
  /** array of unified plugins to convert the AST into a ReactNode */processingPluginList: PropTypes.any,
  /**
       * Determines the text size. Choose `relative` to control the `font-size` based on the value of a parent container.
       */
  textSize: PropTypes.any
};
try {
  EuiMarkdownFormat.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/markdown_editor/markdown_format.tsx',
    description: '',
    displayName: 'EuiMarkdownFormat',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      color: {
        defaultValue: {
          value: 'default'
        },
        description: 'Any of our named colors or a `hex`, `rgb` or `rgba` value.',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Color | "default" | "accent" | "success" | "warning" | "danger" | "subdued" | "ghost"'
        }
      },
      textAlign: {
        defaultValue: null,
        description: '',
        name: 'textAlign',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "left" | "right"',
          value: [{
            value: '"center"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      grow: {
        defaultValue: null,
        description: '',
        name: 'grow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      parsingPluginList: {
        defaultValue: {
          value: 'getDefaultEuiMarkdownParsingPlugins()'
        },
        description: 'array of unified plugins to parse content into an AST',
        name: 'parsingPluginList',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_format.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PluggableList<Settings>'
        }
      },
      processingPluginList: {
        defaultValue: {
          value: 'getDefaultEuiMarkdownProcessingPlugins()'
        },
        description: 'array of unified plugins to convert the AST into a ReactNode',
        name: 'processingPluginList',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_format.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PluggableList<Settings>'
        }
      },
      textSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'Determines the text size. Choose `relative` to control the `font-size` based on the value of a parent container.',
        name: 'textSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/markdown_editor/markdown_format.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "relative"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"relative"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}