/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { PropsWithChildren, useEffect } from 'react';

/**
 * Clipboard text cleaning logic
 */

// Special visually hidden unicode characters that we use to manually clean content
// and force our own newlines/horizontal tabs
export const CHARS = {
  NEWLINE: '↵',
  TAB: '↦',
  // Use multiple characters to reduce the chances of consumers also using these characters
  TABULAR_CONTENT_BOUND: '𐘂𐘂',
  NO_COPY_BOUND: '✄𐘗',
};
// This regex finds all content between two bounds
const noCopyBoundsRegex = new RegExp(
  `${CHARS.NO_COPY_BOUND}[^${CHARS.NO_COPY_BOUND}]*${CHARS.NO_COPY_BOUND}`,
  'gs'
);

const hasCharsToReplace = (text: string) => {
  for (const char of Object.values(CHARS)) {
    if (text.indexOf(char) >= 0) return true;
  }
  return false;
};

// Strip all existing newlines and replace our special hidden characters
// with the desired spacing needed to paste cleanly into a spreadsheet
export const onTabularCopy = (event: ClipboardEvent | React.ClipboardEvent) => {
  if (!event.clipboardData) return;

  const selectedText = window.getSelection()?.toString();
  if (!selectedText || !hasCharsToReplace(selectedText)) return;

  const amendedText = selectedText
    .split(CHARS.TABULAR_CONTENT_BOUND)
    .map((text) => {
      return hasCharsToReplace(text)
        ? text
            .replace(/\r?\n/g, '') // remove all other newlines generated by content or block display
            .replaceAll(CHARS.NEWLINE, '\n') // insert newline for each table/grid row
            .replace(/\t/g, '') // remove tabs generated by content or automatically by <td> elements
            .replaceAll(CHARS.TAB, '\u0009') // insert horizontal tab for each table/grid cell
            .replace(noCopyBoundsRegex, '') // remove text that should not be copied (e.g. screen reader instructions)
        : text;
    })
    .join('');

  event.clipboardData.setData('text/plain', amendedText);
  event.preventDefault();
};

/**
 * JSX utils for rendering the hidden marker characters
 */

const VisuallyHide = ({
  children,
  type = 'true',
}: PropsWithChildren<{ type?: string }>) => (
  // Hides the characters to both sighted user and screen readers
  // Sadly, we can't use `hidden` as that hides the chars from the clipboard as well
  <span
    className="euiScreenReaderOnly"
    aria-hidden
    data-tabular-copy-marker={type}
  >
    {children}
  </span>
);

export const tabularCopyMarkers = {
  hiddenTab: <VisuallyHide type="tab">{CHARS.TAB}</VisuallyHide>,
  hiddenNewline: <VisuallyHide type="newline">{CHARS.NEWLINE}</VisuallyHide>,
  hiddenWrapperBoundary: (
    <VisuallyHide type="boundary">{CHARS.TABULAR_CONTENT_BOUND}</VisuallyHide>
  ),
  hiddenNoCopyBoundary: (
    <VisuallyHide type="no-copy">{CHARS.NO_COPY_BOUND}</VisuallyHide>
  ),
};

/**
 * Wrapper setup around table/grid tabular content we want to override/clean up on copy
 */

export const OverrideCopiedTabularContent = ({
  children,
}: PropsWithChildren) => {
  useEffect(() => {
    // Chrome and webkit browsers work perfectly when passing `onTabularCopy` to a React
    // `onCopy` prop, but sadly Firefox does not if copying more than just the table/grid
    // (e.g. Ctrl+A). So we have to set up a global window event listener
    window.document.addEventListener('copy', onTabularCopy);
    // Note: Since onCopy is static, we don't have to worry about duplicate
    // event listeners - it's automatically handled by the browser. See:
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Multiple_identical_event_listeners
  }, []);

  return (
    <>
      {tabularCopyMarkers.hiddenWrapperBoundary}
      {children}
      {tabularCopyMarkers.hiddenWrapperBoundary}
    </>
  );
};
