/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

/**
 * NOTE: We can't test this component because Enzyme doesn't support rendering
 * into portals.
 */

import { Component } from 'react';
import PropTypes from "prop-types";
import { createPortal } from 'react-dom';
import { EuiNestedThemeContext } from '../../services';
import { usePropsWithComponentDefaults } from '../provider/component_defaults';
var INSERT_POSITIONS = ['after', 'before'];
var insertPositions = {
  after: 'afterend',
  before: 'beforebegin'
};
export var EuiPortal = function EuiPortal(props) {
  var propsWithDefaults = usePropsWithComponentDefaults('EuiPortal', props);
  return ___EmotionJSX(EuiPortalClass, propsWithDefaults);
};
EuiPortal.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  /**
     * If not specified, `EuiPortal` will insert itself
     * into the end of the `document.body` by default
     */
  insert: PropTypes.shape({
    sibling: PropTypes.any.isRequired,
    position: PropTypes.any.isRequired
  }),
  /**
     * Optional ref callback
     */
  portalRef: PropTypes.func
};
export class EuiPortalClass extends Component {
  static contextType = EuiNestedThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      portalNode: null
    };
  }
  componentDidMount() {
    const {
      insert
    } = this.props;
    const portalNode = document.createElement('div');
    portalNode.dataset.euiportal = 'true';
    if (insert == null) {
      // no insertion defined, append to body
      document.body.appendChild(portalNode);
    } else {
      // inserting before or after an element
      const {
        sibling,
        position
      } = insert;
      sibling.insertAdjacentElement(insertPositions[position], portalNode);
    }
    this.setThemeColor(portalNode);
    this.updatePortalRef(portalNode);

    // Update state with portalNode to intentionally trigger component rerender
    // and call createPortal with correct root element in render()
    this.setState({
      portalNode
    });
  }
  componentWillUnmount() {
    const {
      portalNode
    } = this.state;
    if (portalNode?.parentNode) {
      portalNode.parentNode.removeChild(portalNode);
    }
    this.updatePortalRef(null);
  }

  // Set the inherited color of the portal based on the wrapping EuiThemeProvider
  setThemeColor(portalNode) {
    if (this.context) {
      const {
        hasDifferentColorFromGlobalTheme,
        colorClassName
      } = this.context;
      if (hasDifferentColorFromGlobalTheme && this.props.insert == null) {
        portalNode.classList.add(colorClassName);
      }
    }
  }
  updatePortalRef(ref) {
    if (this.props.portalRef) {
      this.props.portalRef(ref);
    }
  }
  render() {
    const {
      portalNode
    } = this.state;
    if (!portalNode) {
      return null;
    }
    return createPortal(this.props.children, portalNode);
  }
}
EuiPortalClass.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  /**
     * If not specified, `EuiPortal` will insert itself
     * into the end of the `document.body` by default
     */
  insert: PropTypes.shape({
    sibling: PropTypes.any.isRequired,
    position: PropTypes.any.isRequired
  }),
  /**
     * Optional ref callback
     */
  portalRef: PropTypes.func
};
try {
  EuiPortal.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/portal/portal.tsx',
    description: '',
    displayName: 'EuiPortal',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      insert: {
        defaultValue: null,
        description: 'If not specified, `EuiPortal` will insert itself\n' + 'into the end of the `document.body` by default',
        name: 'insert',
        parent: {
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      portalRef: {
        defaultValue: null,
        description: 'Optional ref callback',
        name: 'portalRef',
        parent: {
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        }],
        required: false,
        type: {
          name: '(ref: HTMLDivElement) => void'
        }
      }
    },
    extendedInterfaces: ['EuiPortalProps']
  };
} catch (e) {}
try {
  EuiPortalClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/portal/portal.tsx',
    description: '',
    displayName: 'EuiPortalClass',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      insert: {
        defaultValue: null,
        description: 'If not specified, `EuiPortal` will insert itself\n' + 'into the end of the `document.body` by default',
        name: 'insert',
        parent: {
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      portalRef: {
        defaultValue: null,
        description: 'Optional ref callback',
        name: 'portalRef',
        parent: {
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/portal/portal.tsx',
          name: 'EuiPortalProps'
        }],
        required: false,
        type: {
          name: '(ref: HTMLDivElement) => void'
        }
      }
    },
    extendedInterfaces: ['EuiPortalProps']
  };
} catch (e) {}
try {
  EuiPortalClass.contextType.__docgenInfo = {
    tags: {
      see: '{@link https ://react.dev/learn/passing-data-deeply-with-context React Docs}\n' + '{@link https ://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/ React TypeScript Cheatsheet}',
      example: '```tsx\n' + "import { createContext } from 'react';\n" + '\n' + "const ThemeContext = createContext('light');\n" + '```'
    },
    filePath: '/app/packages/eui/src/components/portal/portal.tsx',
    description: 'Context lets components pass information deep down without explicitly\n' + 'passing props.\n' + '\n' + 'Created from {@link createContext}',
    displayName: 'EuiPortalClass.contextType',
    methods: [],
    props: {},
    extendedInterfaces: ['EuiPortalProps']
  };
} catch (e) {}