export var EuiResizablePanelController = { __docgenInfo: { "displayName": "EuiResizablePanelController", "props": { "id": { "description": "", "name": "id", "required": true, "type": { "name": "string" } }, "size": { "description": "", "name": "size", "required": true, "type": { "name": "number" } }, "getSizePx": { "description": "", "name": "getSizePx", "required": true, "type": { "name": "() => number" } }, "minSize": { "description": "", "name": "minSize", "required": true, "type": { "name": "string[]" } }, "mode": { "description": "", "name": "mode", "required": false, "type": { "name": "enum", "value": [{ "value": "\"collapsible\"" }, { "value": "\"main\"" }, { "value": "\"custom\"" }] } }, "isCollapsed": { "description": "", "name": "isCollapsed", "required": true, "type": { "name": "boolean" } }, "prevSize": { "description": "", "name": "prevSize", "required": true, "type": { "name": "number" } }, "position": { "description": "", "name": "position", "required": true, "type": { "name": "enum", "value": [{ "value": "\"first\"" }, { "value": "\"middle\"" }, { "value": "\"last\"" }] } } } } };
export var EuiResizableButtonController = { __docgenInfo: { "displayName": "EuiResizableButtonController", "props": { "id": { "description": "", "name": "id", "required": true, "type": { "name": "string" } }, "ref": { "description": "", "name": "ref", "required": true, "type": { "name": "HTMLElement" } }, "isDisabled": { "description": "", "name": "isDisabled", "required": true, "type": { "name": "boolean" } }, "isFocused": { "description": "", "name": "isFocused", "required": true, "type": { "name": "boolean" } } } } };
export var EuiResizableContainerRegistry = { __docgenInfo: { "displayName": "EuiResizableContainerRegistry", "props": { "panels": { "description": "", "name": "panels", "required": true, "type": { "name": "{ [key: string]: EuiResizablePanelController; }" } }, "resizers": { "description": "", "name": "resizers", "required": true, "type": { "name": "{ [key: string]: EuiResizableButtonController; }" } } } } };
export var EuiResizableContainerState = { __docgenInfo: { "displayName": "EuiResizableContainerState", "props": { "isDragging": { "description": "", "name": "isDragging", "required": true, "type": { "name": "boolean" } }, "currentResizerPos": { "description": "", "name": "currentResizerPos", "required": true, "type": { "name": "number" } }, "prevPanelId": { "description": "", "name": "prevPanelId", "required": true, "type": { "name": "string | null" } }, "nextPanelId": { "description": "", "name": "nextPanelId", "required": true, "type": { "name": "string | null" } }, "containerSize": { "description": "", "name": "containerSize", "required": true, "type": { "name": "number" } }, "isHorizontal": { "description": "", "name": "isHorizontal", "required": false, "type": { "name": "boolean" } }, "panels": { "description": "", "name": "panels", "required": true, "type": { "name": "{ [key: string]: EuiResizablePanelController; }" } }, "resizers": { "description": "", "name": "resizers", "required": true, "type": { "name": "{ [key: string]: EuiResizableButtonController; }" } } } } };
export var ActionToggleOptions = { __docgenInfo: { "displayName": "ActionToggleOptions", "props": { "direction": { "description": "", "name": "direction", "required": true, "type": { "name": "enum", "value": [{ "value": "\"left\"" }, { "value": "\"right\"" }] } } } } };
export var ActionDragStart = { __docgenInfo: { "displayName": "ActionDragStart", "props": { "type": { "description": "", "name": "type", "required": true, "type": { "name": "\"EUI_RESIZABLE_DRAG_START\"" } }, "payload": { "description": "", "name": "payload", "required": true, "type": { "name": "{ prevPanelId: string; nextPanelId: string; position: number; }" } } } } };
export var ActionDragMove = { __docgenInfo: { "displayName": "ActionDragMove", "props": { "type": { "description": "", "name": "type", "required": true, "type": { "name": "\"EUI_RESIZABLE_DRAG_MOVE\"" } }, "payload": { "description": "", "name": "payload", "required": true, "type": { "name": "{ prevPanelId: string; nextPanelId: string; position: number; }" } } } } };
export var ActionKeyMove = { __docgenInfo: { "displayName": "ActionKeyMove", "props": { "type": { "description": "", "name": "type", "required": true, "type": { "name": "\"EUI_RESIZABLE_KEY_MOVE\"" } }, "payload": { "description": "", "name": "payload", "required": true, "type": { "name": "{ prevPanelId: string; nextPanelId: string; direction: KeyMoveDirection; }" } } } } };
export var ActionResize = { __docgenInfo: { "displayName": "ActionResize", "props": { "type": { "description": "", "name": "type", "required": true, "type": { "name": "\"EUI_RESIZABLE_RESIZE\"" } }, "payload": { "description": "", "name": "payload", "required": true, "type": { "name": "{}" } } } } };
export var ActionToggle = { __docgenInfo: { "displayName": "ActionToggle", "props": { "type": { "description": "", "name": "type", "required": true, "type": { "name": "\"EUI_RESIZABLE_TOGGLE\"" } }, "payload": { "description": "", "name": "payload", "required": true, "type": { "name": "{ panelId: string; options: ActionToggleOptions; }" } } } } };
export var ActionFocus = { __docgenInfo: { "displayName": "ActionFocus", "props": { "type": { "description": "", "name": "type", "required": true, "type": { "name": "\"EUI_RESIZABLE_BUTTON_FOCUS\"" } }, "payload": { "description": "", "name": "payload", "required": true, "type": { "name": "{ resizerId: string; }" } } } } };
export var EuiResizableContainerActions = { __docgenInfo: { "displayName": "EuiResizableContainerActions", "props": { "reset": { "description": "", "name": "reset", "required": true, "type": { "name": "() => void" } }, "initContainer": { "description": "", "name": "initContainer", "required": true, "type": { "name": "(isHorizontal: boolean) => void" } }, "registerPanel": { "description": "", "name": "registerPanel", "required": true, "type": { "name": "(panel: EuiResizablePanelController) => void" } }, "deregisterPanel": { "description": "", "name": "deregisterPanel", "required": true, "type": { "name": "(panelId: string) => void" } }, "registerResizer": { "description": "", "name": "registerResizer", "required": true, "type": { "name": "(resizer: EuiResizableButtonController) => void" } }, "deregisterResizer": { "description": "", "name": "deregisterResizer", "required": true, "type": { "name": "(resizerId: string) => void" } }, "dragStart": { "description": "", "name": "dragStart", "required": true, "type": { "name": "({ prevPanelId, nextPanelId, position, }: { prevPanelId: string; nextPanelId: string; position: number; }) => void" } }, "dragMove": { "description": "", "name": "dragMove", "required": true, "type": { "name": "({ prevPanelId, nextPanelId, position, }: { prevPanelId: string; nextPanelId: string; position: number; }) => void" } }, "keyMove": { "description": "", "name": "keyMove", "required": true, "type": { "name": "({ prevPanelId, nextPanelId, direction, }: { prevPanelId: string; nextPanelId: string; direction: KeyMoveDirection; }) => void" } }, "resizerFocus": { "description": "", "name": "resizerFocus", "required": true, "type": { "name": "(resizerId: string) => void" } }, "resizerBlur": { "description": "", "name": "resizerBlur", "required": true, "type": { "name": "() => void" } }, "togglePanel": { "description": "", "name": "togglePanel", "required": true, "type": { "name": "(panelId: string, options: ActionToggleOptions) => void" } } } } };
export var PanelModeType = { __docgenInfo: { "displayName": "PanelModeType", "props": { "PanelModeType": { "description": "", "name": "PanelModeType", "required": false, "type": { "name": "\"collapsible\",\"main\",\"custom\"" } } } } };
export var PanelPosition = { __docgenInfo: { "displayName": "PanelPosition", "props": { "PanelPosition": { "description": "", "name": "PanelPosition", "required": false, "type": { "name": "\"first\",\"middle\",\"last\"" } } } } };
export var PanelDirection = { __docgenInfo: { "displayName": "PanelDirection", "props": { "PanelDirection": { "description": "", "name": "PanelDirection", "required": false, "type": { "name": "\"left\",\"right\"" } } } } };
export var KeyMoveDirection = { __docgenInfo: { "displayName": "KeyMoveDirection", "props": { "KeyMoveDirection": { "description": "", "name": "KeyMoveDirection", "required": false, "type": { "name": "\"forward\",\"backward\"" } } } } };
export var ResizeTrigger = { __docgenInfo: { "displayName": "ResizeTrigger", "props": { "ResizeTrigger": { "description": "", "name": "ResizeTrigger", "required": false, "type": { "name": "\"pointer\",\"key\"" } } } } };
export var EuiResizableButtonMouseEvent = { __docgenInfo: { "displayName": "EuiResizableButtonMouseEvent", "props": { "EuiResizableButtonMouseEvent": { "description": "", "name": "EuiResizableButtonMouseEvent", "required": false, "type": { "name": "MouseEvent<HTMLButtonElement, MouseEvent>,TouchEvent<HTMLButtonElement>" } } } } };
export var EuiResizableButtonKeyEvent = { __docgenInfo: { "displayName": "EuiResizableButtonKeyEvent", "props": { "altKey": { "description": "", "name": "altKey", "required": true, "type": { "name": "boolean" } }, "charCode": { "description": "", "name": "charCode", "required": true, "type": { "name": "number" } }, "ctrlKey": { "description": "", "name": "ctrlKey", "required": true, "type": { "name": "boolean" } }, "code": { "description": "", "name": "code", "required": true, "type": { "name": "string" } }, "getModifierState": { "description": "", "name": "getModifierState", "required": true, "type": { "name": "(key: ModifierKey) => boolean" } }, "key": { "description": "", "name": "key", "required": true, "type": { "name": "string" } }, "keyCode": { "description": "", "name": "keyCode", "required": true, "type": { "name": "number" } }, "locale": { "description": "", "name": "locale", "required": true, "type": { "name": "string" } }, "location": { "description": "", "name": "location", "required": true, "type": { "name": "number" } }, "metaKey": { "description": "", "name": "metaKey", "required": true, "type": { "name": "boolean" } }, "repeat": { "description": "", "name": "repeat", "required": true, "type": { "name": "boolean" } }, "shiftKey": { "description": "", "name": "shiftKey", "required": true, "type": { "name": "boolean" } }, "which": { "description": "", "name": "which", "required": true, "type": { "name": "number" } } } } };
export var EuiResizableContainerAction = { __docgenInfo: { "displayName": "EuiResizableContainerAction", "props": { "EuiResizableContainerAction": { "description": "", "name": "EuiResizableContainerAction", "required": false, "type": { "name": "ActionReset,ActionInit,ActionRegisterPanel,ActionDeregisterPanel,ActionRegisterResizer,ActionDeregisterResizer,ActionDragStart,ActionDragMove,ActionKeyMove,ActionResize,ActionToggle,ActionFocus,ActionBlur,ActionOnChange" } } } } };