function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import PropTypes from "prop-types";
import "core-js/modules/es.string.match.js";
import React, { useState } from 'react';
import { EuiBadge, EuiRadioGroup, EuiSpacer, EuiSwitch, EuiPanel, EuiText, EuiTitle } from '../../../../src/components';
import { BarSeries, LineSeries, AreaSeries } from '@elastic/charts';
import euiPackage from '../../../../package.json';
var devDependencies = euiPackage.devDependencies;
export var chartsVersion = devDependencies['@elastic/charts'].match(/\d+\.\d+\.\d+/)[0];
export var CHART_COMPONENTS = {
  BarSeries: BarSeries,
  LineSeries: LineSeries,
  AreaSeries: AreaSeries
};
export var ExternalBadge = function ExternalBadge() {
  return ___EmotionJSX(EuiBadge, {
    iconType: "popout",
    iconSide: "right",
    onClickAriaLabel: "Go to @elastic/charts docs",
    onClick: function onClick() {
      return window.open("https://github.com/elastic/elastic-charts/tree/v".concat(chartsVersion));
    }
  }, "External library: @elastic/charts v", chartsVersion);
};
export var ChartCard = function ChartCard(_ref) {
  var title = _ref.title,
    description = _ref.description,
    children = _ref.children;
  return ___EmotionJSX(EuiPanel, null, ___EmotionJSX(EuiTitle, {
    size: "s"
  }, ___EmotionJSX("span", null, title)), ___EmotionJSX(EuiSpacer, {
    size: "s"
  }), description && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EuiText, {
    size: "s"
  }, ___EmotionJSX("p", null, description)), ___EmotionJSX(EuiSpacer, {
    size: "s"
  })), children);
};
ChartCard.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node
};
export var ChartTypeCard = function ChartTypeCard(props) {
  var idPrefix = 'chartType';
  var toggleButtonsIcons = [{
    id: "".concat(idPrefix, "0"),
    label: 'BarSeries'
  }, {
    id: "".concat(idPrefix, "1"),
    label: 'LineSeries'
  }, {
    id: "".concat(idPrefix, "2"),
    label: 'AreaSeries'
  }];
  var _useState = useState("".concat(idPrefix, "0")),
    _useState2 = _slicedToArray(_useState, 2),
    toggleIdSelected = _useState2[0],
    setToggleIdSelectd = _useState2[1];
  var onChartTypeChange = function onChartTypeChange(optionId) {
    setToggleIdSelectd(optionId);
    var chartType = toggleButtonsIcons.find(function (_ref2) {
      var id = _ref2.id;
      return id === optionId;
    }).label;
    props.onChange(chartType);
  };
  if (props.mixed) {
    toggleButtonsIcons[3] = {
      id: "".concat(idPrefix, "3"),
      label: 'Mixed',
      disabled: props.mixed === 'disabled'
    };
  }
  return ___EmotionJSX(ChartCard, {
    title: "Chart types",
    description: "".concat(props.type, " charts can be displayed as any x/y series type.")
  }, ___EmotionJSX(EuiRadioGroup, {
    compressed: true,
    options: toggleButtonsIcons,
    idSelected: toggleIdSelected,
    onChange: onChartTypeChange,
    disabled: props.disabled
  }));
};
export var MultiChartCard = ({
  onChange
}) => {
  const [multi, setMulti] = useState(false);
  const [stacked, setStacked] = useState(false);
  return <ChartCard title="Single vs multiple series" description="Legends are only necessary when there are multiple series. Stacked series indicates accumulation but can hide subtle differences. Do not stack line charts.">
      <EuiSwitch label="Show multi-series" checked={multi} onChange={e => {
      const isStacked = e.target.checked ? stacked : false;
      setMulti(e.target.checked);
      setStacked(isStacked);
      onChange({
        multi: e.target.checked,
        stacked
      });
    }} />
      <EuiSpacer size="s" />
      <EuiSwitch label="Stacked" checked={stacked} onChange={e => {
      setStacked(e.target.checked);
      onChange({
        multi: multi,
        stacked: e.target.checked
      });
    }} disabled={!multi} />
    </ChartCard>;
};
MultiChartCard.propTypes = {
  onChange: PropTypes.func.isRequired
};
try {
  ChartCard.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/shared.tsx',
    description: '',
    displayName: 'ChartCard',
    methods: [],
    props: {
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/shared.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/shared.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  ChartTypeCard.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/shared.tsx',
    description: '',
    displayName: 'ChartTypeCard',
    methods: [],
    props: {
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/shared.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      mixed: {
        defaultValue: null,
        description: '',
        name: 'mixed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/shared.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"disabled" | "enabled"',
          value: [{
            value: '"disabled"'
          }, {
            value: '"enabled"'
          }]
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/shared.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '((chartType: "BarSeries" | "LineSeries" | "AreaSeries" | "Mixed") => void) | ((chartType: "BarSeries" | "LineSeries" | "AreaSeries") => void)'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/shared.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  MultiChartCard.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/elastic_charts/shared.tsx',
    description: '',
    displayName: 'MultiChartCard',
    methods: [],
    props: {
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/elastic_charts/shared.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '({ multi, stacked }: { multi: boolean; stacked: boolean; }) => void'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}