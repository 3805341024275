/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { Component } from 'react';
import PropTypes from "prop-types";
function isComponentBecomingVisible() {
  var prevHide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var nextHide = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return prevHide === true && nextHide === false;
}
export class EuiDelayHide extends Component {
  static defaultProps = {
    hide: false,
    minimumDuration: 1000
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const isBecomingVisible = isComponentBecomingVisible(prevState.hide, nextProps.hide);
    return {
      hide: nextProps.hide,
      countdownExpired: isBecomingVisible ? false : prevState.countdownExpired
    };
  }
  state = {
    hide: this.props.hide,
    countdownExpired: this.props.hide
  };
  timeoutId;
  componentDidMount() {
    // if the component begins visible start counting
    if (this.props.hide === false) {
      this.startCountdown();
    }
  }
  componentDidUpdate(prevProps) {
    const isBecomingVisible = isComponentBecomingVisible(prevProps.hide, this.props.hide);
    if (isBecomingVisible) {
      this.startCountdown();
    }
  }
  componentWillUnmount() {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
    }
  }
  startCountdown = () => {
    // only start the countdown if there is not one in progress
    if (this.timeoutId == null) {
      this.timeoutId = setTimeout(this.finishCountdown,
      // even though `minimumDuration` cannot be undefined, passing a strict number type to setTimeout makes TS interpret
      // it as a NodeJS.Timer instead of a number. The DOM lib defines the setTimeout call as taking `number | undefined`
      // so we cast minimumDuration to this type instead to force TS's cooperation
      this.props.minimumDuration);
    }
  };
  finishCountdown = () => {
    this.timeoutId = undefined;
    this.setState({
      countdownExpired: true
    });
  };
  render() {
    const shouldHideContent = this.props.hide === true && this.state.countdownExpired;
    return shouldHideContent ? null : this.props.render();
  }
}
EuiDelayHide.propTypes = {
  hide: PropTypes.bool.isRequired,
  minimumDuration: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired
};
try {
  EuiDelayHide.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/delay_hide/delay_hide.tsx',
    description: '',
    displayName: 'EuiDelayHide',
    methods: [],
    props: {
      hide: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'hide',
        parent: {
          fileName: 'eui/src/components/delay_hide/delay_hide.tsx',
          name: 'EuiDelayHideProps'
        },
        declarations: [{
          fileName: 'eui/src/components/delay_hide/delay_hide.tsx',
          name: 'EuiDelayHideProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minimumDuration: {
        defaultValue: {
          value: '1000'
        },
        description: '',
        name: 'minimumDuration',
        parent: {
          fileName: 'eui/src/components/delay_hide/delay_hide.tsx',
          name: 'EuiDelayHideProps'
        },
        declarations: [{
          fileName: 'eui/src/components/delay_hide/delay_hide.tsx',
          name: 'EuiDelayHideProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      render: {
        defaultValue: null,
        description: '',
        name: 'render',
        parent: {
          fileName: 'eui/src/components/delay_hide/delay_hide.tsx',
          name: 'EuiDelayHideProps'
        },
        declarations: [{
          fileName: 'eui/src/components/delay_hide/delay_hide.tsx',
          name: 'EuiDelayHideProps'
        }],
        required: true,
        type: {
          name: '() => ReactNode'
        }
      }
    },
    extendedInterfaces: ['EuiDelayHideProps']
  };
} catch (e) {}