/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { Children, cloneElement, useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
function isMutableRef(ref) {
  return ref != null && ref.hasOwnProperty('current');
}

/**
 * The `EuiValidatableControl` component should be used in scenarios where
 * we can render the validated `<input>` as its direct child.
 */

export var EuiValidatableControl = ({
  isInvalid,
  children
}) => {
  // Note that this must be state and not a ref to cause a rerender/set invalid state on initial mount
  const [control, setControl] = useState(null);
  const child = Children.only(children);
  const childRef = child.ref;
  const replacedRef = useCallback(element => {
    setControl(element);

    // Call the original ref, if any
    if (typeof childRef === 'function') {
      childRef(element);
    } else if (isMutableRef(childRef)) {
      childRef.current = element;
    }
  }, [childRef]);
  useSetControlValidity({
    controlEl: control,
    isInvalid
  });
  return cloneElement(child, {
    ref: replacedRef,
    'aria-invalid': isInvalid || child.props['aria-invalid']
  });
};

/**
 * The `UseEuiValidatableControl` hook should be used in scenarios where
 * we *cannot* control where the validated `<input>` is rendered (e.g., ReactDatePicker)
 * and instead need to access the input via a ref and pass the element in directly
 */
EuiValidatableControl.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  isInvalid: PropTypes.bool,
  children: PropTypes.shape({
    ref: PropTypes.any
  }).isRequired
};
export const useEuiValidatableControl = ({
  isInvalid,
  controlEl
}) => {
  useSetControlValidity({
    controlEl,
    isInvalid
  });
  useEffect(() => {
    if (!controlEl) return;
    if (typeof isInvalid === 'boolean') {
      controlEl.setAttribute('aria-invalid', String(isInvalid));
    } else {
      controlEl.removeAttribute('aria-invalid');
    }
  }, [isInvalid, controlEl]);
};

/**
 * Internal `setCustomValidity` helper
 */
const useSetControlValidity = ({
  controlEl,
  isInvalid
}) => {
  useEffect(() => {
    if (controlEl == null || typeof controlEl.setCustomValidity !== 'function') {
      return;
    }
    if (isInvalid) {
      controlEl.setCustomValidity('Invalid');
    } else {
      controlEl.setCustomValidity('');
    }
  }, [isInvalid, controlEl]);
};
try {
  EuiValidatableControl.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/validatable_control/validatable_control.tsx',
    description: '',
    displayName: 'EuiValidatableControl',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'EuiValidatableControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'EuiValidatableControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'EuiValidatableControlProps', 'UseEuiValidatableControlProps']
  };
} catch (e) {}
try {
  useEuiValidatableControl.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/validatable_control/validatable_control.tsx',
    description: '',
    displayName: 'useEuiValidatableControl',
    methods: [],
    props: {
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'UseEuiValidatableControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'UseEuiValidatableControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      controlEl: {
        defaultValue: null,
        description: '',
        name: 'controlEl',
        parent: {
          fileName: 'eui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'UseEuiValidatableControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/validatable_control/validatable_control.tsx',
          name: 'UseEuiValidatableControlProps'
        }],
        required: true,
        type: {
          name: 'HTMLInputElement | HTMLConstraintValidityElement'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'EuiValidatableControlProps', 'UseEuiValidatableControlProps']
  };
} catch (e) {}