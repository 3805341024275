/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { EuiFormLegend } from './form_legend';
export var EuiFormFieldset = ({
  children,
  className,
  legend,
  ...rest
}) => {
  const legendDisplay = !!legend && <EuiFormLegend {...legend} />;
  return <fieldset className={className} {...rest}>
      {legendDisplay}
      {children}
    </fieldset>;
};
EuiFormFieldset.propTypes = {
  /**
     * Adds an EuiFormLegend element as the first child
     */
  legend: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    /**
         * ReactNode to render as this component's content
         */
    children: PropTypes.node.isRequired,
    /**
         * For a hidden legend that is still visible to the screen reader, set to 'hidden'
         */
    display: PropTypes.oneOf(["hidden", "visible"]),
    compressed: PropTypes.bool
  }),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiFormFieldset.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/form_fieldset/form_fieldset.tsx',
    description: '',
    displayName: 'EuiFormFieldset',
    methods: [],
    props: {
      legend: {
        defaultValue: null,
        description: 'Adds an EuiFormLegend element as the first child',
        name: 'legend',
        parent: {
          fileName: 'eui/src/components/form/form_fieldset/form_fieldset.tsx',
          name: 'EuiFormFieldsetProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/form_fieldset/form_fieldset.tsx',
          name: 'EuiFormFieldsetProps'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLLegendElement> & CommonProps & { children: ReactNode; display?: "hidden" | "visible"; compressed?: boolean; }'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiFormFieldsetProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}