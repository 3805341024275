function _EMOTION_STRINGIFIED_CSS_ERROR__() {
  return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
}
import PropTypes from "prop-types";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { isValidElement, useMemo } from 'react';
import classNames from 'classnames';
import { cloneElementWithCss } from '../../services';
var styles = {
  euiTextBlockTruncate: process.env.NODE_ENV === "production" ? {
    name: "1cfm3ge-euiTextBlockTruncate",
    styles: "display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:0;overflow:hidden;label:euiTextBlockTruncate;"
  } : {
    name: "1cfm3ge-euiTextBlockTruncate",
    styles: "display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:0;overflow:hidden;label:euiTextBlockTruncate;",
    toString: _EMOTION_STRINGIFIED_CSS_ERROR__
  }
};
export var EuiTextBlockTruncate = ({
  children,
  className,
  style,
  lines,
  cloneElement,
  ...rest
}) => {
  const classes = classNames('euiTextBlockTruncate', className);
  const cssStyles = styles.euiTextBlockTruncate;
  const inlineStyles = useMemo(() => ({
    WebkitLineClamp: lines,
    ...style
  }), [lines, style]);
  if (isValidElement(children) && cloneElement) {
    return cloneElementWithCss(children, {
      css: cssStyles,
      style: {
        ...children.props.style,
        ...inlineStyles
      },
      className: classNames(children.props.className, classes)
    });
  } else {
    return <div className={classes} css={cssStyles} style={inlineStyles} {...rest}>
        {children}
      </div>;
  }
};
EuiTextBlockTruncate.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Number of lines of text to truncate to
       */
  lines: PropTypes.number.isRequired,
  /**
       * Applies styling to the child element instead of rendering a parent wrapper `div`.
       * Can only be used when wrapping a *single* child element/tag, and not raw text.
       */
  cloneElement: PropTypes.bool
};
try {
  EuiTextBlockTruncate.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/text_truncate/text_block_truncate.tsx',
    description: '',
    displayName: 'EuiTextBlockTruncate',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      lines: {
        defaultValue: null,
        description: 'Number of lines of text to truncate to',
        name: 'lines',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text_truncate/text_block_truncate.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      cloneElement: {
        defaultValue: null,
        description: 'Applies styling to the child element instead of rendering a parent wrapper `div`.\n' + 'Can only be used when wrapping a *single* child element/tag, and not raw text.',
        name: 'cloneElement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text_truncate/text_block_truncate.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}