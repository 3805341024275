/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiBreadcrumbs } from '../../breadcrumbs';
import { euiHeaderBreadcrumbsStyles } from './header_breadcrumbs.styles';
export var EuiHeaderBreadcrumbs = ({
  className,
  breadcrumbs,
  ...rest
}) => {
  const classes = classNames('euiHeaderBreadcrumbs', className);
  const styles = useEuiMemoizedStyles(euiHeaderBreadcrumbsStyles);
  return <EuiBreadcrumbs max={4} truncate breadcrumbs={breadcrumbs} className={classes} css={styles.euiHeaderBreadcrumbs} type="application" {...rest} />;
};
EuiHeaderBreadcrumbs.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Hides extra (above the max) breadcrumbs under a collapsed item as the window gets smaller.
     * Pass a custom #EuiBreadcrumbResponsiveMaxCount object to change the number of breadcrumbs to show at the particular breakpoints.
     *
     * Pass `false` to turn this behavior off.
     *
     * Default: `{ xs: 1, s: 2, m: 4 }`
     */
  responsive: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.any.isRequired]),
  /**
     * Forces all breadcrumbs to single line and
     * truncates each breadcrumb to a particular width,
     * except for the last item
     */
  truncate: PropTypes.bool,
  /**
     * Collapses the inner items past the maximum set here
     * into a single ellipses item.
     * Omitting or passing a `0` value will show all breadcrumbs.
     */
  max: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf([null])]),
  /**
     * The array of individual #EuiBreadcrumb items
     */
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    href: PropTypes.string,
    rel: PropTypes.string,
    onClick: PropTypes.func,
    /**
         * Visible label of the breadcrumb
         */
    text: PropTypes.node.isRequired,
    /**
         * Force a max-width on the breadcrumb text
         */
    truncate: PropTypes.bool,
    /**
         * @deprecated - if a custom color is wanted, use the `css` prop to pass custom css
         */
    color: PropTypes.any,
    /**
         * Override the existing `aria-current` which defaults to `page` for the last breadcrumb
         */
    "aria-current": PropTypes.any,
    /**
         * Creates a breadcrumb that toggles a popover dialog. Takes any rendered node(s),
         * or a render function that will pass callback allowing you to close the
         * breadcrumb popover from within your popover content.
         *
         * If passed, both `href` and `onClick` will be ignored - the breadcrumb's
         * click behavior should only trigger a popover.
         */
    popoverContent: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired]),
    /**
         * Allows customizing the popover if necessary. Accepts any props that
         * [EuiPopover](/#/layout/popover) accepts, except for props that control state.
         */
    popoverProps: PropTypes.shape({
      /**
         * Alignment of the popover and arrow relative to the button
         */
      anchorPosition: PropTypes.any,
      /**
         * Style and position alteration for arrow-less attachment.
         * Intended for use with inputs as anchors, e.g. EuiInputPopover
         */
      attachToAnchor: PropTypes.bool,
      /**
         * Restrict the popover's position within this element
         */
      container: PropTypes.any,
      /**
         * CSS display type for both the popover and anchor
         */
      display: PropTypes.any,
      /**
         * Object of props passed to EuiFocusTrap
         */
      focusTrapProps: PropTypes.any,
      /**
         * Show arrow indicating to originating button
         */
      hasArrow: PropTypes.bool,
      /**
         * Specifies what element should initially have focus; Can be a DOM
         * node, or a selector string (which will be passed to
         * document.querySelector() to find the DOM node), or a function that
         * returns a DOM node.
         *
         * If not passed, initial focus defaults to the popover panel.
         */
      initialFocus: PropTypes.any,
      /**
         * Passed directly to EuiPortal for DOM positioning. Both properties are
         * required if prop is specified
         */
      insert: PropTypes.shape({
        sibling: PropTypes.any.isRequired,
        position: PropTypes.oneOf(["before", "after"]).isRequired
      }),
      /**
         * Traps tab focus within the popover contents
         */
      ownFocus: PropTypes.bool,
      /**
         * Custom class added to the EuiPanel containing the popover contents
         */
      panelClassName: PropTypes.string,
      /**
         * EuiPanel padding on all sides
         */
      panelPaddingSize: PropTypes.any,
      /**
         * Standard DOM `style` attribute. Passed to the EuiPanel
         */
      panelStyle: PropTypes.any,
      /**
         * Object of props passed to EuiPanel. See #EuiPopoverPanelProps
         */
      panelProps: PropTypes.shape({
        element: PropTypes.oneOf(["div"]),
        /**
           * Padding for all four sides
           */
        paddingSize: PropTypes.any,
        /**
           * Corner border radius
           */
        borderRadius: PropTypes.any,
        /**
           * When true the panel will grow in height to match `EuiFlexItem`
           */
        grow: PropTypes.bool,
        panelRef: PropTypes.any,
        className: PropTypes.string,
        "aria-label": PropTypes.string,
        "data-test-subj": PropTypes.string,
        css: PropTypes.any
      }),
      panelRef: PropTypes.any,
      /**
         * Optional screen reader instructions to announce upon popover open,
         * in addition to EUI's default popover instructions for Escape on close.
         * Useful for popovers that may have additional keyboard capabilities such as
         * arrow navigation.
         */
      popoverScreenReaderText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
      popoverRef: PropTypes.any,
      /**
         * When `true`, the popover's position is re-calculated when the user
         * scrolls, this supports having fixed-position popover anchors. When nesting
         * an `EuiPopover` in a scrollable container, `repositionOnScroll` should be `true`
         */
      repositionOnScroll: PropTypes.bool,
      /**
         * By default, popovers will attempt to position themselves along the initial
         * axis specified. If there is not enough room either vertically or horizontally
         * however, the popover will attempt to reposition itself along the secondary
         * cross axis if there is room there instead.
         *
         * If you do not not want this repositioning to occur (and it is acceptable for
         * the popover to appear offscreen), set this to false to disable this behavior.
         *
         * @default true
         */
      repositionToCrossAxis: PropTypes.bool,
      /**
         * Must be set to true if using `EuiDragDropContext` within a popover,
         * otherwise your nested drag & drop will have incorrect positioning
         *
         * @deprecated - use `usePortal` prop on children `EuiDraggable` components instead.
         */
      hasDragDrop: PropTypes.bool,
      /**
         * By default, popover content inherits the z-index of the anchor
         * component; pass `zIndex` to override
         */
      zIndex: PropTypes.number,
      /**
         * Distance away from the anchor that the popover will render
         */
      offset: PropTypes.number,
      /**
         * Minimum distance between the popover and the bounding container;
         * Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`
         * @default 16
         */
      buffer: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.any.isRequired]),
      /**
         * Element to pass as the child element of the arrow;
         * Use case is typically limited to an accompanying `EuiBeacon`
         */
      arrowChildren: PropTypes.node,
      /**
         * Provide a name to the popover panel
         */
      "aria-label": PropTypes.string,
      /**
         * Alternative option to `aria-label` that takes an `id`.
         * Usually takes the `id` of the popover title
         */
      "aria-labelledby": PropTypes.string,
      /**
         * Function callback for when the popover positon changes
         */
      onPositionChange: PropTypes.func,
      className: PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any
    })
  }).isRequired).isRequired,
  /**
     * Determines breadcrumbs appearance, with `page` being the default styling.
     * Application breadcrumbs should only be once per page, in (e.g.) EuiHeader
     */
  type: PropTypes.oneOf(["page", "application"]),
  /**
     * Whether the last breadcrumb should be semantically highlighted as the
     * current page. (improves accessibility for screen readers users)
     * Defaults to true.
     */
  lastBreadcrumbIsCurrentPage: PropTypes.bool
};
try {
  EuiHeaderBreadcrumbs.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/header/header_breadcrumbs/header_breadcrumbs.tsx',
    description: '',
    displayName: 'EuiHeaderBreadcrumbs',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      responsive: {
        defaultValue: null,
        description: 'Hides extra (above the max) breadcrumbs under a collapsed item as the window gets smaller.\n' + 'Pass a custom #EuiBreadcrumbResponsiveMaxCount object to change the number of breadcrumbs to show at the particular breakpoints.\n' + '\n' + 'Pass `false` to turn this behavior off.\n' + '\n' + 'Default: `{ xs: 1, s: 2, m: 4 }`',
        name: 'responsive',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | EuiBreadcrumbResponsiveMaxCount'
        }
      },
      truncate: {
        defaultValue: null,
        description: 'Forces all breadcrumbs to single line and\n' + 'truncates each breadcrumb to a particular width,\n' + 'except for the last item',
        name: 'truncate',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      max: {
        defaultValue: null,
        description: 'Collapses the inner items past the maximum set here\n' + 'into a single ellipses item.\n' + 'Omitting or passing a `0` value will show all breadcrumbs.',
        name: 'max',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      breadcrumbs: {
        defaultValue: null,
        description: 'The array of individual #EuiBreadcrumb items',
        name: 'breadcrumbs',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'EuiBreadcrumbProps[]'
        }
      },
      type: {
        defaultValue: null,
        description: 'Determines breadcrumbs appearance, with `page` being the default styling.\n' + 'Application breadcrumbs should only be once per page, in (e.g.) EuiHeader',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"application" | "page"',
          value: [{
            value: '"application"'
          }, {
            value: '"page"'
          }]
        }
      },
      lastBreadcrumbIsCurrentPage: {
        defaultValue: null,
        description: 'Whether the last breadcrumb should be semantically highlighted as the\n' + 'current page. (improves accessibility for screen readers users)\n' + 'Defaults to true.',
        name: 'lastBreadcrumbIsCurrentPage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/breadcrumbs/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}