const _excluded = ["children", "className", "sections", "position", "theme"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
import "core-js/modules/es.array.map.js";
import PropTypes from "prop-types";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useEuiTheme, useEuiThemeCSSVariables } from '../../services';
import { mathWithUnits, logicalStyles } from '../../global_styling';
import { EuiHeaderBreadcrumbs } from './header_breadcrumbs';
import { EuiHeaderSectionItem, EuiHeaderSection } from './header_section';
import { euiHeaderStyles, euiHeaderVariables } from './header.styles';
var createHeaderSection = function createHeaderSection(sections) {
  return sections.map(function (section, index) {
    return ___EmotionJSX(EuiHeaderSectionItem, {
      key: index
    }, section);
  });
};
export var EuiHeader = function EuiHeader(_ref) {
  var children = _ref.children,
    className = _ref.className,
    sections = _ref.sections,
    _ref$position = _ref.position,
    position = _ref$position === void 0 ? 'static' : _ref$position,
    _ref$theme = _ref.theme,
    theme = _ref$theme === void 0 ? 'default' : _ref$theme,
    rest = _objectWithoutProperties(_ref, _excluded);
  var classes = classNames('euiHeader', className);
  var euiTheme = useEuiTheme();
  var styles = euiHeaderStyles(euiTheme);
  var cssStyles = [styles.euiHeader, styles[position], styles[theme]];
  var contents;
  if (sections) {
    if (children) {
      // In case both children and sections are passed, warn in the console that the children will be disregarded
      console.warn('EuiHeader cannot accept both `children` and `sections`. It will disregard the `children`.');
    }
    contents = sections.map(function (section, index) {
      var content = [];
      if (section.items) {
        // Items get wrapped in EuiHeaderSection and each item in a EuiHeaderSectionItem
        content.push(___EmotionJSX(EuiHeaderSection, {
          key: "items-".concat(index)
        }, createHeaderSection(section.items)));
      }
      if (section.breadcrumbs) {
        content.push(
        // Breadcrumbs are separate and cannot be contained in a EuiHeaderSection
        // in order for truncation to work
        ___EmotionJSX(EuiHeaderBreadcrumbs, _extends({
          key: "breadcrumbs-".concat(index),
          breadcrumbs: section.breadcrumbs
        }, section.breadcrumbProps)));
      }
      return content;
    });
  } else {
    contents = children;
  }
  return position === 'fixed' ? ___EmotionJSX(EuiFixedHeader, _extends({
    css: cssStyles,
    className: classes
  }, rest), contents) : ___EmotionJSX("div", _extends({
    css: cssStyles,
    className: classes
  }, rest), contents);
};

/**
 * Fixed headers - logic around dynamically calculating the total
 * page offset and setting the `top` position of subsequent headers
 */

// Start a counter to manage the total number of fixed headers
// Exported for unit testing only
EuiHeader.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * An array of objects to wrap in a #EuiHeaderSection.
       * Each section is spaced using `space-between`.
       * See #EuiHeaderSectionsProp for object details.
       * This prop disregards the prop `children` if both are passed.
       */
  sections: PropTypes.arrayOf(PropTypes.shape({
    /**
       * An arry of items that will be wrapped in a #EuiHeaderSectionItem
       */
    items: PropTypes.arrayOf(PropTypes.node.isRequired),
    /**
       * Breadcrumbs in the header cannot be wrapped in a #EuiHeaderSection in order for truncation to work.
       * Simply pass the array of EuiBreadcrumb objects
       */
    breadcrumbs: PropTypes.arrayOf(PropTypes.any.isRequired),
    /**
       * Other props to pass to #EuiHeaderBreadcrumbs
       */
    breadcrumbProps: PropTypes.any
  }).isRequired),
  /**
       * Helper that positions the header against the window body and
       * adds the correct amount of top padding to the window when in `fixed` mode
       */
  position: PropTypes.oneOf(["static", "fixed"]),
  /**
       * The `default` will inherit its coloring from the light or dark theme.
       * Or, force the header into pseudo `dark` theme for all themes.
       */
  theme: PropTypes.oneOf(["default", "dark"])
};
export var euiFixedHeadersCount = 0;

// Exported for unit testing only
export var EuiFixedHeader = ({
  children,
  style,
  ...rest
}) => {
  const {
    setGlobalCSSVariables
  } = useEuiThemeCSSVariables();
  const euiTheme = useEuiTheme();
  const headerHeight = euiHeaderVariables(euiTheme).height;
  const getHeaderOffset = useCallback(() => mathWithUnits(headerHeight, x => x * euiFixedHeadersCount), [headerHeight]);
  const [topPosition, setTopPosition] = useState();
  useEffect(() => {
    // Get the top position from the offset of previous header(s)
    setTopPosition(getHeaderOffset());

    // Increment fixed header counter for each fixed header
    euiFixedHeadersCount++;
    setGlobalCSSVariables({
      '--euiFixedHeadersOffset': getHeaderOffset()
    });
    document.body.classList.add('euiBody--headerIsFixed'); // TODO: Consider deleting this legacy className

    return () => {
      euiFixedHeadersCount--;
      setGlobalCSSVariables({
        '--euiFixedHeadersOffset': getHeaderOffset()
      });
      if (euiFixedHeadersCount === 0) {
        document.body.classList.remove('euiBody--headerIsFixed'); // TODO: Consider deleting this legacy className
      }
    };
  }, [getHeaderOffset, setGlobalCSSVariables]);
  const inlineStyles = useMemo(() => logicalStyles({
    top: topPosition,
    ...style
  }), [topPosition, style]);
  return <div data-fixed-header={true} // Used by EuiFlyouts as a query selector
  style={inlineStyles} {...rest}>
      {children}
    </div>;
};
EuiFixedHeader.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * An array of objects to wrap in a #EuiHeaderSection.
       * Each section is spaced using `space-between`.
       * See #EuiHeaderSectionsProp for object details.
       * This prop disregards the prop `children` if both are passed.
       */
  sections: PropTypes.arrayOf(PropTypes.shape({
    /**
       * An arry of items that will be wrapped in a #EuiHeaderSectionItem
       */
    items: PropTypes.arrayOf(PropTypes.node.isRequired),
    /**
       * Breadcrumbs in the header cannot be wrapped in a #EuiHeaderSection in order for truncation to work.
       * Simply pass the array of EuiBreadcrumb objects
       */
    breadcrumbs: PropTypes.arrayOf(PropTypes.any.isRequired),
    /**
       * Other props to pass to #EuiHeaderBreadcrumbs
       */
    breadcrumbProps: PropTypes.any
  }).isRequired),
  /**
       * Helper that positions the header against the window body and
       * adds the correct amount of top padding to the window when in `fixed` mode
       */
  position: PropTypes.oneOf(["static", "fixed"]),
  /**
       * The `default` will inherit its coloring from the light or dark theme.
       * Or, force the header into pseudo `dark` theme for all themes.
       */
  theme: PropTypes.oneOf(["default", "dark"])
};
try {
  EuiHeader.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/header/header.tsx',
    description: '',
    displayName: 'EuiHeader',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      sections: {
        defaultValue: null,
        description: 'An array of objects to wrap in a #EuiHeaderSection.\n' + 'Each section is spaced using `space-between`.\n' + 'See #EuiHeaderSectionsProp for object details.\n' + 'This prop disregards the prop `children` if both are passed.',
        name: 'sections',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiHeaderSections[]'
        }
      },
      position: {
        defaultValue: {
          value: 'static'
        },
        description: 'Helper that positions the header against the window body and\n' + 'adds the correct amount of top padding to the window when in `fixed` mode',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "static"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"static"'
          }]
        }
      },
      theme: {
        defaultValue: {
          value: 'default'
        },
        description: 'The `default` will inherit its coloring from the light or dark theme.\n' + 'Or, force the header into pseudo `dark` theme for all themes.',
        name: 'theme',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "dark"',
          value: [{
            value: '"default"'
          }, {
            value: '"dark"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}
try {
  euiFixedHeadersCount.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/header/header.tsx',
    description: 'Fixed headers - logic around dynamically calculating the total\n' + 'page offset and setting the `top` position of subsequent headers',
    displayName: 'euiFixedHeadersCount',
    methods: [],
    props: {},
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}
try {
  EuiFixedHeader.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/header/header.tsx',
    description: '',
    displayName: 'EuiFixedHeader',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      sections: {
        defaultValue: null,
        description: 'An array of objects to wrap in a #EuiHeaderSection.\n' + 'Each section is spaced using `space-between`.\n' + 'See #EuiHeaderSectionsProp for object details.\n' + 'This prop disregards the prop `children` if both are passed.',
        name: 'sections',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiHeaderSections[]'
        }
      },
      position: {
        defaultValue: {
          value: 'static'
        },
        description: 'Helper that positions the header against the window body and\n' + 'adds the correct amount of top padding to the window when in `fixed` mode',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "static"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"static"'
          }]
        }
      },
      theme: {
        defaultValue: {
          value: 'default'
        },
        description: 'The `default` will inherit its coloring from the light or dark theme.\n' + 'Or, force the header into pseudo `dark` theme for all themes.',
        name: 'theme',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"default" | "dark"',
          value: [{
            value: '"default"'
          }, {
            value: '"dark"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}