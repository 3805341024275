/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../../services';
import { useEuiPaddingCSS } from '../../../global_styling';
import { EuiPageHeaderContent } from './page_header_content';
import { euiPageHeaderStyles } from './page_header.styles';
export var EuiPageHeader = ({
  className,
  restrictWidth = false,
  paddingSize = 'none',
  bottomBorder,
  // Page header content shared props:
  alignItems,
  responsive = true,
  children,
  // Page header content only props:
  pageTitle,
  pageTitleProps,
  iconType,
  iconProps,
  tabs,
  tabsProps,
  breadcrumbs,
  breadcrumbProps,
  description,
  rightSideItems,
  rightSideGroupProps,
  ...rest
}) => {
  const useTheme = useEuiTheme();
  const styles = euiPageHeaderStyles(useTheme);
  const inlinePadding = useEuiPaddingCSS('horizontal');
  const cssStyles = [styles.euiPageHeader, inlinePadding[paddingSize], bottomBorder === 'extended' && styles.border];
  const classes = classNames('euiPageHeader', className);
  const contentProps = {
    restrictWidth,
    paddingSize,
    // Set the bottom to false to ensure the forced one doesn't render
    // when we'll be rendering it at this parent
    bottomBorder: bottomBorder === 'extended' ? false : bottomBorder,
    alignItems,
    responsive,
    pageTitle,
    pageTitleProps,
    iconType,
    iconProps,
    tabs,
    tabsProps,
    description,
    rightSideItems,
    rightSideGroupProps,
    breadcrumbs,
    breadcrumbProps
  };
  return <header className={classes} css={cssStyles} {...rest}>
      <EuiPageHeaderContent {...contentProps}>{children}</EuiPageHeaderContent>
    </header>;
};
EuiPageHeader.propTypes = {
  /**
     * Adjust the overall padding.
     */
  paddingSize: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
     * Adds a bottom border to separate it from the content after;
     * Passing `extended` will ensure the border touches the sides of the parent container.
     */
  bottomBorder: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["extended"])])
};
try {
  EuiPageHeader.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/page/page_header/page_header.tsx',
    description: '',
    displayName: 'EuiPageHeader',
    methods: [],
    props: {
      paddingSize: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adjust the overall padding.',
        name: 'paddingSize',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header.tsx',
          name: 'EuiPageHeaderProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header.tsx',
          name: 'EuiPageHeaderProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      description: {
        defaultValue: null,
        description: 'Position is dependent on existing with a `pageTitle` or `tabs`\n' + 'Automatically get wrapped in a single paragraph tag inside an EuiText block',
        name: 'description',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentLeft'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentLeft'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      rightSideItems: {
        defaultValue: null,
        description: 'Pass custom an array of content to this side usually up to 3 buttons.\n' + 'The first button should be primary, usually with `fill`. At larger breakpoints, items will\n' + 'render from right to left, but will collapse vertically and render left to right on smaller mobile screens.',
        name: 'rightSideItems',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        }],
        required: false,
        type: {
          name: 'ReactNode[]'
        }
      },
      tabs: {
        defaultValue: null,
        description: 'In-app navigation presented as large borderless tabs.\n' + 'Accepts an array of `EuiTab` objects;',
        name: 'tabs',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTabs'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTabs'
        }],
        required: false,
        type: {
          name: 'Tab[]'
        }
      },
      iconType: {
        defaultValue: null,
        description: 'Optional icon to place to the left of the title',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      responsive: {
        defaultValue: {
          value: 'true'
        },
        description: "Set to false if you don't want the children to stack at small screen sizes.\n" + 'Set to `reverse` to display the right side content first for the sake of hierarchy (like global time)',
        name: 'responsive',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        }],
        required: false,
        type: {
          name: 'boolean | "reverse"'
        }
      },
      breadcrumbs: {
        defaultValue: null,
        description: 'Optional array breadcrumbs that render before the `pageTitle`',
        name: 'breadcrumbs',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        }],
        required: false,
        type: {
          name: 'EuiBreadcrumbProps[]'
        }
      },
      iconProps: {
        defaultValue: null,
        description: 'Additional EuiIcon props to apply to the optional icon',
        name: 'iconProps',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiIconProps, "type">>'
        }
      },
      alignItems: {
        defaultValue: null,
        description: 'Vertical alignment of the left and right side content;\n' + 'Default is `center` for custom content, but `top` for when `pageTitle` or `tabs` are included',
        name: 'alignItems',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "top" | "bottom" | "stretch"',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }, {
            value: '"stretch"'
          }]
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1200px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      },
      rightSideGroupProps: {
        defaultValue: null,
        description: 'Additional EuiFlexGroup props to pass to the container of the `rightSideItems`',
        name: 'rightSideGroupProps',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: '_EuiPageHeaderContentProps'
        }],
        required: false,
        type: {
          name: 'Partial<EuiFlexGroupProps<"div">>'
        }
      },
      pageTitle: {
        defaultValue: null,
        description: 'Wrapped in an `H1` so choose appropriately.\nA simple string is best',
        name: 'pageTitle',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      pageTitleProps: {
        defaultValue: null,
        description: 'Additional props to pass to the EuiTitle',
        name: 'pageTitleProps',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        }],
        required: false,
        type: {
          name: 'Omit<EuiTitleProps, "children" | "size">'
        }
      },
      breadcrumbProps: {
        defaultValue: null,
        description: 'Adjust the props of [EuiBreadcrumbs](#/navigation/breadcrumbs)',
        name: 'breadcrumbProps',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTitle'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiBreadcrumbsProps, "breadcrumbs">>'
        }
      },
      tabsProps: {
        defaultValue: null,
        description: 'Any extras to apply to the outer tabs container.\nExtends `EuiTabs`',
        name: 'tabsProps',
        parent: {
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTabs'
        },
        declarations: [{
          fileName: 'eui/src/components/page/page_header/page_header_content.tsx',
          name: 'EuiPageHeaderContentTabs'
        }],
        required: false,
        type: {
          name: 'Omit<EuiTabsProps, "size" | "expand">'
        }
      },
      bottomBorder: {
        defaultValue: null,
        description: 'Adds a bottom border to separate it from the content after;\n' + 'Passing `extended` will ensure the border touches the sides of the parent container.',
        name: 'bottomBorder',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/_bottom_border.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "extended"'
        }
      }
    },
    extendedInterfaces: ['EuiPageHeaderProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiPageHeaderContentLeft', '_EuiPageHeaderContentProps', 'EuiPageHeaderContentTabs', 'EuiPageHeaderContentTitle']
  };
} catch (e) {}