/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useContext } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { getSecureRelForTarget, useEuiMemoizedStyles } from '../../services';
import { validateHref } from '../../services/security/href_validator';
import { euiTabStyles, euiTabContentStyles } from './tab.styles';
import { EuiTabsContext } from './tabs_context';
export var EuiTab = ({
  isSelected,
  children,
  className,
  disabled: _disabled,
  href,
  target,
  rel,
  prepend,
  append,
  ...rest
}) => {
  const {
    size,
    expand
  } = useContext(EuiTabsContext);
  const isHrefValid = !href || validateHref(href);
  const disabled = _disabled || !isHrefValid;

  // Keep CSS classnames for reference
  const classes = classNames('euiTab', className, {
    'euiTab-isSelected': isSelected
  });
  const tabStyles = useEuiMemoizedStyles(euiTabStyles);
  const cssTabStyles = [tabStyles.euiTab, expand && tabStyles.expanded, disabled && tabStyles.disabled.disabled, isSelected && (disabled ? tabStyles.disabled.selected : tabStyles.selected)];
  const tabContentStyles = useEuiMemoizedStyles(euiTabContentStyles);
  const cssTabContentStyles = [tabContentStyles.euiTab__content, size && tabContentStyles[size]];
  const prependNode = prepend && <span className="euiTab__prepend">{prepend}</span>;
  const appendNode = append && <span className="euiTab__append">{append}</span>;

  //  <a> elements don't respect the `disabled` attribute. So if we're disabled, we'll just pretend
  //  this is a button and piggyback off its disabled styles.
  if (href && !disabled) {
    const secureRel = getSecureRelForTarget({
      href,
      target,
      rel
    });
    return <a role="tab" aria-selected={!!isSelected} className={classes} css={cssTabStyles} href={href} target={target} rel={secureRel} {...rest}>
        {prependNode}
        <span className="euiTab__content" css={cssTabContentStyles}>
          {children}
        </span>
        {appendNode}
      </a>;
  }
  return <button role="tab" aria-selected={!!isSelected} type="button" className={classes} css={cssTabStyles} disabled={disabled} {...rest}>
      {prependNode}
      <span className="euiTab__content eui-textTruncate" css={cssTabContentStyles}>
        {children}
      </span>
      {appendNode}
    </button>;
};
EuiTab.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
     * Places content before the tab content/children.
     * Will be excluded from interactive effects.
     */
  /**
     * Places content before the tab content/children.
     * Will be excluded from interactive effects.
     */
  prepend: PropTypes.node,
  /**
     * Places content after the tab content/children.
     * Will be excluded from interactive effects.
     */
  /**
     * Places content after the tab content/children.
     * Will be excluded from interactive effects.
     */
  append: PropTypes.node,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  href: PropTypes.string,
  onClick: PropTypes.func
};
try {
  EuiTab.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tabs/tab.tsx',
    description: '',
    displayName: 'EuiTab',
    methods: [],
    props: {
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isSelected: {
        defaultValue: null,
        description: '',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }, {
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Places content before the tab content/children.\n' + 'Will be excluded from interactive effects.',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      append: {
        defaultValue: null,
        description: 'Places content after the tab content/children.\n' + 'Will be excluded from interactive effects.',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'EuiTabProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/tabs/tab.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLAnchorElement> | MouseEventHandler<HTMLButtonElement>'
        }
      }
    },
    extendedInterfaces: ['AnchorHTMLAttributes', 'EuiTabProps', 'CommonProps', 'HTMLAttributes', 'ButtonHTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}