import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { EuiCodeBlock, EuiBasicTable, EuiSpacer } from '../../../../src/components';
import { getPropsFromComponent } from '../../services/props/get_props';
import { getDescription } from '../../services/props/get_description';
export var DataGridPropsTable = ({
  component,
  exclude,
  snippets,
  links
}) => {
  const gridProps = getPropsFromComponent(component);
  const gridPropsKeys = Object.keys(gridProps).filter(i => !exclude?.includes(i)).sort();

  // Manually move the cellContext prop after renderCellValue
  const cellContext = gridPropsKeys.splice(gridPropsKeys.findIndex(prop => prop === 'cellContext'), 1)[0];
  if (cellContext) {
    gridPropsKeys.splice(gridPropsKeys.findIndex(prop => prop === 'renderCellValue') + 1, 0, cellContext);
  }
  const items = gridPropsKeys.map(prop => {
    return {
      id: prop,
      prop: prop,
      type: gridProps[prop],
      sample: snippets[prop],
      link: links && links[prop]
    };
  });
  const renderPropDescription = item => {
    const description = getDescription(item.type || item, {
      color: 'subdued'
    });
    if (description) {
      return <>
          <EuiSpacer size="s" />
          {description}
        </>;
    }
  };
  const renderProp = item => {
    if (item.link) {
      return <Link to={item.link}>
          <strong>{item.prop}</strong>
        </Link>;
    } else {
      return <strong>{item.prop}</strong>;
    }
  };
  const renderSample = sample => {
    if (sample) {
      return <div style={{
        flexGrow: 1
      }}>
          <EuiSpacer />
          {typeof sample === 'string' ? <EuiCodeBlock paddingSize="s" language="js" isCopyable>
              {sample}
            </EuiCodeBlock> : sample}
        </div>;
    }
  };
  const columns = [{
    field: 'prop',
    name: 'Prop',
    valign: 'top',
    textOnly: false,
    render: (prop, item) => <div>
          {renderProp(item)}
          {renderPropDescription(item)}
        </div>,
    mobileOptions: {
      header: false,
      // Won't show inline header in mobile view
      width: '100%' // Applies a specific width
    }
  }, {
    field: 'sample',
    name: 'Sample snippet',
    align: 'left',
    valign: 'top',
    render: sample => renderSample(sample),
    mobileOptions: {
      header: false,
      // Won't show inline header in mobile view
      width: '100%' // Applies a specific width
    }
  }];
  return <EuiBasicTable items={items} columns={columns} />;
};
DataGridPropsTable.propTypes = {
  component: PropTypes.any.isRequired,
  exclude: PropTypes.arrayOf(PropTypes.string.isRequired),
  snippets: PropTypes.any.isRequired,
  links: PropTypes.any
};
try {
  DataGridPropsTable.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/datagrid/_props_table.tsx',
    description: '',
    displayName: 'DataGridPropsTable',
    methods: [],
    props: {
      component: {
        defaultValue: null,
        description: '',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/datagrid/_props_table.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'any'
        }
      },
      exclude: {
        defaultValue: null,
        description: '',
        name: 'exclude',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/datagrid/_props_table.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      snippets: {
        defaultValue: null,
        description: '',
        name: 'snippets',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/datagrid/_props_table.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'any'
        }
      },
      links: {
        defaultValue: null,
        description: '',
        name: 'links',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/datagrid/_props_table.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}