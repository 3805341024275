/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { memo } from 'react';
import PropTypes from "prop-types";
import { useEuiTheme } from '../../services';
import { EuiPopoverTitle } from '../popover';
import { EuiTitle } from '../title';
import { euiTourHeaderStyles } from './_tour_header.styles';
export var EuiTourHeader = memo(({
  id,
  title,
  subtitle
}) => {
  const euiTheme = useEuiTheme();
  const headerStyles = euiTourHeaderStyles(euiTheme);
  return <EuiPopoverTitle css={headerStyles.euiTourHeader} className="euiTourHeader" id={id}>
        {subtitle && <EuiTitle css={headerStyles.euiTourHeader__subtitle} size="xxxs">
            <h2>{subtitle}</h2>
          </EuiTitle>}
        <EuiTitle css={headerStyles.euiTourHeader__title} size="xxs">
          {subtitle ? <h3>{title}</h3> : <h2>{title}</h2>}
        </EuiTitle>
      </EuiPopoverTitle>;
});
EuiTourHeader.propTypes = {
  id: PropTypes.string.isRequired
};
EuiTourHeader.displayName = '_EuiTourHeader';
try {
  _EuiTourHeader.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tour/_tour_header.tsx',
    description: '',
    displayName: '_EuiTourHeader',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/_tour_header.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: 'Larger title text specific to this step. The title gets wrapped in the appropriate heading level.',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      subtitle: {
        defaultValue: null,
        description: 'Smaller title text that appears atop each step in the tour. The subtitle gets wrapped in the appropriate heading level.',
        name: 'subtitle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}