/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiButtonIcon } from '../button';
import { euiScreenReaderOnlyStyles } from '../accessibility/screen_reader_only/screen_reader_only.styles';
import { euiResizableCollapseButtonStyles } from './resizable_collapse_button.styles';
export var EuiResizableCollapseButton = ({
  className,
  externalPosition = 'before',
  internalPosition = 'middle',
  direction = 'horizontal',
  isVisible,
  isCollapsed,
  ...rest
}) => {
  const isHorizontal = direction === 'horizontal';
  const showOnFocus = !isCollapsed && !isVisible;
  const styles = useEuiMemoizedStyles(euiResizableCollapseButtonStyles);
  const collapsedStyles = [styles.collapsed.collapsed, styles.collapsed[direction], styles.collapsed[`${direction}Positions`][internalPosition]];
  const collapsibleStyles = [styles.collapsible.collapsible, styles.collapsible[direction][externalPosition], styles.collapsible[direction][internalPosition]];
  const cssStyles = [styles.euiResizableCollapseButton, showOnFocus && euiScreenReaderOnlyStyles['euiScreenReaderOnly-showOnFocus'], ...(isCollapsed ? collapsedStyles : collapsibleStyles)];
  let COLLAPSED_ICON = '';
  let NOT_COLLAPSED_ICON = '';
  switch (externalPosition) {
    case 'before':
      COLLAPSED_ICON = isHorizontal ? 'menuLeft' : 'menuUp';
      NOT_COLLAPSED_ICON = isHorizontal ? 'menuRight' : 'menuDown';
      break;
    case 'after':
      COLLAPSED_ICON = isHorizontal ? 'menuRight' : 'menuDown';
      NOT_COLLAPSED_ICON = isHorizontal ? 'menuLeft' : 'menuUp';
      break;
  }
  const classes = classNames('euiResizableCollapseButton', className);
  return <EuiButtonIcon display={isCollapsed ? 'empty' : 'base'} color="text" className={classes} css={cssStyles} {...rest} iconType={isCollapsed ? COLLAPSED_ICON : NOT_COLLAPSED_ICON} />;
};
EuiResizableCollapseButton.propTypes = {
  /**
     * Position of the toggle button.
     * Enums based on the `direction` of the EuiResizableContainer
     */
  internalPosition: PropTypes.oneOf(["top", "middle", "bottom", "left", "right"]),
  /**
     * Position of the toggle button.
     * Enums based on the `direction` of the EuiResizableContainer
     */
  externalPosition: PropTypes.oneOf(["before", "after"]),
  /**
     * Same direction derived from EuiResizableContainer
     */
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  isVisible: PropTypes.bool,
  isCollapsed: PropTypes.bool
};
try {
  EuiResizableCollapseButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/resizable_container/resizable_collapse_button.tsx',
    description: '',
    displayName: 'EuiResizableCollapseButton',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: null,
        description: 'Overall size of button.\nMatches the sizes of other EuiButtons',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'ButtonHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      iconSize: {
        defaultValue: null,
        description: 'Size of the icon only.\n' + 'This will not affect the overall size of the button',
        name: 'iconSize',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl" | "original" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"original"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      display: {
        defaultValue: null,
        description: 'Sets the display style for matching other EuiButton types.\n' + '`base` is equivalent to a typical EuiButton\n' + '`fill` is equivalent to a filled EuiButton\n' + '`empty` (default) is equivalent to an EuiButtonEmpty',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_icon/button_icon.tsx',
          name: 'EuiButtonIconProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"base" | "fill" | "empty"',
          value: [{
            value: '"base"'
          }, {
            value: '"fill"'
          }, {
            value: '"empty"'
          }]
        }
      },
      internalPosition: {
        defaultValue: {
          value: 'middle'
        },
        description: 'Position of the toggle button.\n' + 'Enums based on the `direction` of the EuiResizableContainer',
        name: 'internalPosition',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "top" | "bottom" | "middle"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }, {
            value: '"middle"'
          }]
        }
      },
      externalPosition: {
        defaultValue: {
          value: 'before'
        },
        description: 'Position of the toggle button.\n' + 'Enums based on the `direction` of the EuiResizableContainer',
        name: 'externalPosition',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"before" | "after"',
          value: [{
            value: '"before"'
          }, {
            value: '"after"'
          }]
        }
      },
      direction: {
        defaultValue: {
          value: 'horizontal'
        },
        description: 'Same direction derived from EuiResizableContainer',
        name: 'direction',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"horizontal" | "vertical"',
          value: [{
            value: '"horizontal"'
          }, {
            value: '"vertical"'
          }]
        }
      },
      isVisible: {
        defaultValue: null,
        description: '',
        name: 'isVisible',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isCollapsed: {
        defaultValue: null,
        description: '',
        name: 'isCollapsed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_collapse_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'EuiButtonIconProps', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'ButtonHTMLAttributes']
  };
} catch (e) {}