/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiContextMenuItem } from '../../context_menu';
import { euiSuperSelectItemStyles } from './super_select.styles';

// Type exposed to consumers via API

// Actual props used by below component, transmogged by parent EuiSuperSelect
// from consumer props to internal EUI props

// Internal subcomponent util, primarily for easier usage of hooks
export var EuiSuperSelectItem = ({
  children,
  className,
  hasDividers,
  ...rest
}) => {
  const classes = classNames('euiSuperSelect__item', className);
  const styles = useEuiMemoizedStyles(euiSuperSelectItemStyles);
  const cssStyles = [styles.euiSuperSelect__item, hasDividers && styles.hasDividers];
  return <EuiContextMenuItem css={cssStyles} className={classes} role="option" {...rest}>
      {children}
    </EuiContextMenuItem>;
};
EuiSuperSelectItem.propTypes = {
  hasDividers: PropTypes.bool
};
try {
  EuiSuperSelectItem.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/super_select/super_select_item.tsx',
    description: '',
    displayName: 'EuiSuperSelectItem',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      icon: {
        defaultValue: null,
        description: '',
        name: 'icon',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuItemIcon'
        }
      },
      hasPanel: {
        defaultValue: null,
        description: '',
        name: 'hasPanel',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: '(event: React.MouseEvent) => void'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      toolTipContent: {
        defaultValue: null,
        description: 'Required if using a tooltip. Add an optional tooltip on hover',
        name: 'toolTipContent',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: 'Optional configuration to pass to the underlying [EuiToolTip](/#/display/tooltip).\n' + 'Accepts any prop that EuiToolTip does, except for `content` and `children`.',
        name: 'toolTipProps',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiToolTipProps, "content" | "children">>'
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      layoutAlign: {
        defaultValue: null,
        description: 'How to align icon with content of button',
        name: 'layoutAlign',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiContextMenuItemLayoutAlignment',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      },
      size: {
        defaultValue: null,
        description: 'Reduce the size to `s` when in need of a more compressed menu',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_item.tsx',
          name: 'EuiContextMenuItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      hasDividers: {
        defaultValue: null,
        description: '',
        name: 'hasDividers',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'ButtonHTMLAttributes', 'EuiContextMenuItemProps']
  };
} catch (e) {}