/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useContext } from 'react';
import PropTypes from "prop-types";
import { Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { useEuiTheme, cloneElementWithCss } from '../../services';
import { EuiDroppableContext } from './droppable';
import { euiDraggableStyles, euiDraggableItemStyles } from './draggable.styles';
import { EuiPortal } from '../portal';
export var EuiDraggable = ({
  customDragHandle = false,
  draggableId,
  isDragDisabled = false,
  hasInteractiveChildren = false,
  isRemovable = false,
  usePortal = false,
  index,
  children,
  className,
  spacing = 'none',
  style,
  'data-test-subj': dataTestSubj = 'draggable',
  ...rest
}) => {
  const {
    cloneItems
  } = useContext(EuiDroppableContext);
  const euiTheme = useEuiTheme();
  const styles = euiDraggableStyles(euiTheme);
  return <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled} {...rest}>
      {(provided, snapshot, rubric) => {
      const {
        isDragging
      } = snapshot;
      const cssStyles = [styles.euiDraggable, cloneItems && !isDragging && styles.hasClone, isDragging && styles.isDragging, isRemovable && styles.isRemovable, styles.spacing[spacing]];
      const classes = classNames('euiDraggable', className);
      const childClasses = classNames('euiDraggable__item', {
        'euiDraggable__item-isDisabled': isDragDisabled
      });
      const DraggableElement = typeof children === 'function' ? children(provided, snapshot, rubric) : children;
      const content = <>
            <div {...provided.draggableProps} {...!customDragHandle ? provided.dragHandleProps : {}} ref={provided.innerRef} data-test-subj={dataTestSubj} className={classes} css={cssStyles} style={{
          ...style,
          ...provided.draggableProps.style
        }}
        // We use [role="group"] instead of [role="button"] when we expect a nested
        // interactive element. Screen readers will cue users that this is a container
        // and has one or more elements inside that are part of a related group.
        role={hasInteractiveChildren ? 'group' : provided.dragHandleProps?.role}
        // If the container includes an interactive element, we remove the tabindex=0
        // because [role="group"] does not permit or warrant a tab stop
        tabIndex={hasInteractiveChildren ? undefined : provided.dragHandleProps?.tabIndex}>
              {cloneElementWithCss(DraggableElement, {
            className: classNames(DraggableElement.props.className, childClasses),
            css: [euiDraggableItemStyles.euiDraggable__item, isDragDisabled && euiDraggableItemStyles.disabled]
          })}
            </div>
            {cloneItems && isDragging && <div className={classNames(classes, 'euiDraggable--clone')} css={cssStyles}>
                {DraggableElement}
              </div>}
          </>;
      return isDragging && usePortal ? <EuiPortal>{content}</EuiPortal> : content;
    }}
    </Draggable>;
};
EuiDraggable.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.any.isRequired]).isRequired,
  className: PropTypes.string,
  /**
     * Whether the `children` will provide and set up its own drag handle
     */
  customDragHandle: PropTypes.bool,
  /**
     * Whether the container has interactive children and should have `role="group"` instead of `"button"`.
     * Setting this flag ensures your drag & drop container is keyboard and screen reader accessible.
     */
  hasInteractiveChildren: PropTypes.bool,
  /**
     * Whether the item is currently in a position to be removed
     */
  isRemovable: PropTypes.bool,
  /**
     * Whether the currently dragged item is cloned into a portal in the body. This settings will
     * ensure that drag & drop still works as expected within stacking contexts (e.g. within `EuiFlyout`,
     * `EuiModal` and `EuiPopover`).
     *
     * Make sure to apply styles directly to the Draggable content as relative styling from an outside
     * scope might not be applied when the content is placed in a portal as the DOM structure changes.
     */
  usePortal: PropTypes.bool,
  /**
     * Adds padding to the draggable item
     */
  spacing: PropTypes.any,
  style: PropTypes.any,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiDraggable.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/drag_and_drop/draggable.tsx',
    description: '',
    displayName: 'EuiDraggable',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: true,
        type: {
          name: 'ReactElement | DraggableChildrenFn'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      customDragHandle: {
        defaultValue: {
          value: 'false'
        },
        description: 'Whether the `children` will provide and set up its own drag handle',
        name: 'customDragHandle',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasInteractiveChildren: {
        defaultValue: {
          value: 'false'
        },
        description: 'Whether the container has interactive children and should have `role="group"` instead of `"button"`.\n' + 'Setting this flag ensures your drag & drop container is keyboard and screen reader accessible.',
        name: 'hasInteractiveChildren',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isRemovable: {
        defaultValue: {
          value: 'false'
        },
        description: 'Whether the item is currently in a position to be removed',
        name: 'isRemovable',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      usePortal: {
        defaultValue: {
          value: 'false'
        },
        description: 'Whether the currently dragged item is cloned into a portal in the body. This settings will\n' + 'ensure that drag & drop still works as expected within stacking contexts (e.g. within `EuiFlyout`,\n' + '`EuiModal` and `EuiPopover`).\n' + '\n' + 'Make sure to apply styles directly to the Draggable content as relative styling from an outside\n' + 'scope might not be applied when the content is placed in a portal as the DOM structure changes.',
        name: 'usePortal',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      spacing: {
        defaultValue: {
          value: 'none'
        },
        description: 'Adds padding to the draggable item',
        name: 'spacing',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/drag_and_drop/draggable.tsx',
          name: 'EuiDraggableProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      draggableId: {
        defaultValue: null,
        description: '',
        name: 'draggableId',
        parent: {
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      isDragDisabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isDragDisabled',
        parent: {
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disableInteractiveElementBlocking: {
        defaultValue: null,
        description: '',
        name: 'disableInteractiveElementBlocking',
        parent: {
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      shouldRespectForcePress: {
        defaultValue: null,
        description: '',
        name: 'shouldRespectForcePress',
        parent: {
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        },
        declarations: [{
          fileName: 'eui/node_modules/@hello-pangea/dnd/dist/dnd.d.ts',
          name: 'DraggableProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiDraggableProps', 'CommonProps']
  };
} catch (e) {}