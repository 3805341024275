import "core-js/modules/es.array.map.js";
import PropTypes from "prop-types";
import "core-js/modules/es.object.keys.js";
import React from 'react';
import { EuiCode, EuiBasicTable, EuiSpacer } from '../../../../../src';

// @ts-ignore Importing from JS
import { useJsonVars } from '../_json/_get_json_vars';
import { EuiThemeFontBase } from '../_props';
import { getPropsFromComponent } from '../../../services/props/get_props';
import { ThemeExample } from '../_components/_theme_example';
import { getDescription, getDescriptionSmall } from '../../../services/props/get_description';
export var euiFontMixins = {
  euiFont: {
    description: 'Base font reset including family, weight, letter-spacing, and kerning.'
  },
  euiCodeFont: {
    description: 'Supplies the code font family and resets letter-spacing.'
  },
  euiText: {
    description: 'Base reset for text color and weight.'
  },
  'euiTitle($size)': {
    description: "Accepts a `$size` parameter of `'xxxs'` to `'l'` and sets appropriate font-size, weight, color, and line-height. It also ensures long words wrap lines."
  },
  'fontSize($size)': {
    description: 'Accepts a `$size` parameter of a pixel value and returns the appropriate `font-size` property in `rem` units.',
    sample: ___EmotionJSX("div", {
      className: 'guideSass__mixin--fontSize'
    }, "The quick brown fox")
  }
};
export var FontSass = function FontSass() {
  var values = useJsonVars();
  var baseProps = getPropsFromComponent(EuiThemeFontBase);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiFontFamily"),
    description: getDescription(baseProps.family),
    example: values.euiFontFamily,
    snippet: 'font-family: $euiFontFamily;',
    snippetLanguage: "scss"
  }), ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiCodeFontFamily"),
    description: getDescription(baseProps.familyCode),
    example: ___EmotionJSX("p", {
      className: "guideSass__fontFamily--code"
    }, values.euiCodeFontFamily),
    snippet: 'font-family: $euiCodeFontFamily;',
    snippetLanguage: "scss"
  }), ___EmotionJSX(ThemeExample, {
    title: 'Mixins',
    type: null,
    description: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, "EUI also provides mixins for applying a full set of font-based resets from these global variables such as", ' ', ___EmotionJSX(EuiCode, null, "font-family"), ", ", ___EmotionJSX(EuiCode, null, "font-weight"), ", and ", ___EmotionJSX(EuiCode, null, "letter-spacing"), ".")),
    example: ___EmotionJSX("p", {
      className: "guideSass__fontFamily--code"
    }, "The quick brown fox"),
    snippet: "@include euiCodeFont;",
    snippetLanguage: "scss"
  }));
};
export var FontValuesSass = function FontValuesSass() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EuiBasicTable, {
    items: Object.keys(euiFontMixins).map(function (mixin) {
      return {
        id: mixin,
        mixin: "@include ".concat(mixin),
        sample: euiFontMixins[mixin].sample
      };
    }),
    columns: [{
      field: 'mixin',
      name: 'Mixin',
      render: function render(mixin, item) {
        return ___EmotionJSX("div", null, ___EmotionJSX(EuiCode, null, mixin), ___EmotionJSX(EuiSpacer, {
          size: "s"
        }), getDescriptionSmall(euiFontMixins[item.id]));
      },
      mobileOptions: {
        header: false,
        width: '100%'
      }
    }, {
      field: 'sample',
      name: 'Sample',
      render: function render(sample, item) {
        return sample || ___EmotionJSX("div", {
          className: "guideSass__mixin--".concat(item.id === 'euiTitle($size)' ? 'euiTitle' : item.id)
        }, "The quick brown fox");
      }
    }]
  }));
};
export var euiFontWeights = ['euiFontWeightLight', 'euiFontWeightRegular', 'euiFontWeightMedium', 'euiFontWeightSemiBold', 'euiFontWeightBold'];
export var FontWeightSass = ({
  description
}) => {
  const values = useJsonVars();
  return <>
      <ThemeExample title={<code>$euiFontWeight[Weight]</code>} description={description} example={<div style={{
      fontWeight: values.euiFontWeightBold
    }}>
            I am proper bold
          </div>} snippet={'font-weight: $euiFontWeightBold;'} snippetLanguage="scss" />
    </>;
};
FontWeightSass.propTypes = {
  description: PropTypes.node
};
export const FontWeightValuesSass = () => {
  const values = useJsonVars();
  return <>
      <EuiBasicTable items={euiFontWeights.map(function (weight) {
      return {
        id: weight,
        token: `$${weight}`,
        value: values[weight]
      };
    })} columns={[{
      field: 'token',
      name: 'Token',
      render: variable => <EuiCode>{variable}</EuiCode>,
      mobileOptions: {
        header: false,
        width: '100%'
      }
    }, {
      field: 'sample',
      name: 'Sample',
      render: (sample, item) => <p style={{
        fontWeight: item.value
      }}>The quick brown fox</p>
    }, {
      field: 'value',
      name: 'Value',
      width: '60px',
      align: 'right',
      render: value => <small>
                <code>{value}</code>
              </small>
    }]} />
    </>;
};
const euiFontSizes = ['euiFontSizeXS', 'euiFontSizeS', 'euiFontSizeM', 'euiFontSizeL', 'euiFontSizeXL', 'euiFontSizeXXL'];
export const FontScaleSass = () => {
  return <>
      <ThemeExample title={'Mixins'} type={null} description={<p>
            It is recommended to use these as a mixin to automatically apply
            line-height as well as size.
          </p>} example={<p className="guideSass__fontSizeL">The quick brown fox</p>} snippet="@include euiFontSizeL;" snippetLanguage="scss" />
    </>;
};
export const FontScaleValuesSass = () => {
  const values = useJsonVars();
  return <>
      <EuiBasicTable items={euiFontSizes.map(function (size, index) {
      return {
        id: size,
        token: `$${size}`,
        mixin: `@include ${size}`,
        value: values[size],
        index
      };
    })} columns={[{
      field: 'token',
      name: 'Token',
      render: variable => <EuiCode>{variable}</EuiCode>
    }, {
      field: 'mixin',
      name: 'Mixin',
      render: mixin => <EuiCode language="css">{mixin}</EuiCode>
    }, {
      field: 'sample',
      name: 'Sample',
      render: (sample, item) => <div className={`guideSass__fontSize--${item.id}`}>
                The quick brown fox
              </div>,
      mobileOptions: {
        width: '50%'
      }
    }, {
      field: 'value',
      name: 'Value',
      width: '60px',
      align: 'right',
      render: value => <small>
                <code>{value}</code>
              </small>
    }]} />
    </>;
};
try {
  FontWeightSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/typography/_typography_sass.tsx',
    description: '',
    displayName: 'FontWeightSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}