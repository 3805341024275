import React from 'react';
import PropTypes from "prop-types";
export var EuiTreeViewNode = () => <div />;
EuiTreeViewNode.propTypes = {
  /** An array of EuiTreeViewNodes to render as children
     */
  children: PropTypes.arrayOf(PropTypes.shape({
    /** An array of EuiTreeViewNodes to render as children
       */
    children: PropTypes.arrayOf(PropTypes.any.isRequired),
    /** The readable label for the item
       */
    label: PropTypes.node.isRequired,
    /** A unique ID
       */
    id: PropTypes.string.isRequired,
    /** An icon to use on the left of the label
       */
    icon: PropTypes.element,
    /** Display a different icon when the item is expanded.
      For instance, an open folder or a down arrow
      */
    iconWhenExpanded: PropTypes.element,
    /** Use an empty icon to keep items without an icon
      lined up with their siblings
      */
    useEmptyIcon: PropTypes.bool,
    /** Whether or not the item is expanded.
       */
    isExpanded: PropTypes.bool,
    /** Optional class to throw on the node
       */
    className: PropTypes.string,
    /** Function to call when the item is clicked.
       The open state of the item will always be toggled.
       */
    callback: PropTypes.func
  }).isRequired),
  /** The readable label for the item
     */
  label: PropTypes.node.isRequired,
  /** A unique ID
     */
  id: PropTypes.string.isRequired,
  /** An icon to use on the left of the label
     */
  icon: PropTypes.element,
  /** Display a different icon when the item is expanded.
    For instance, an open folder or a down arrow
    */
  iconWhenExpanded: PropTypes.element,
  /** Use an empty icon to keep items without an icon
    lined up with their siblings
    */
  useEmptyIcon: PropTypes.bool,
  /** Whether or not the item is expanded.
     */
  isExpanded: PropTypes.bool,
  /** Optional class to throw on the node
     */
  className: PropTypes.string,
  /** Function to call when the item is clicked.
     The open state of the item will always be toggled.
     */
  callback: PropTypes.func
};
try {
  EuiTreeViewNode.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/tree_view/tree_view_props.tsx',
    description: '',
    displayName: 'EuiTreeViewNode',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: 'An array of EuiTreeViewNodes to render as children',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'Node[]'
        }
      },
      label: {
        defaultValue: null,
        description: 'The readable label for the item',
        name: 'label',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: true,
        type: {
          name: 'React.ReactNode'
        }
      },
      id: {
        defaultValue: null,
        description: 'A unique ID',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      icon: {
        defaultValue: null,
        description: 'An icon to use on the left of the label',
        name: 'icon',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'React.ReactElement'
        }
      },
      iconWhenExpanded: {
        defaultValue: null,
        description: 'Display a different icon when the item is expanded.\n' + 'For instance, an open folder or a down arrow',
        name: 'iconWhenExpanded',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'React.ReactElement'
        }
      },
      useEmptyIcon: {
        defaultValue: null,
        description: 'Use an empty icon to keep items without an icon\n' + 'lined up with their siblings',
        name: 'useEmptyIcon',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isExpanded: {
        defaultValue: null,
        description: 'Whether or not the item is expanded.',
        name: 'isExpanded',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: 'Optional class to throw on the node',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      callback: {
        defaultValue: null,
        description: 'Function to call when the item is clicked.\n' + 'The open state of the item will always be toggled.',
        name: 'callback',
        parent: {
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        },
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view.tsx',
          name: 'Node'
        }],
        required: false,
        type: {
          name: '() => string'
        }
      }
    },
    extendedInterfaces: ['Node']
  };
} catch (e) {}