/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import { useEuiTheme } from '../../../services';
import { EuiI18n } from '../../i18n';
import { EuiText } from '../../text';
import { EuiLoadingSpinner } from '../../loading';
export var EuiAccordionChildrenLoading = ({
  isLoadingMessage
}) => {
  const {
    euiTheme
  } = useEuiTheme();
  return <>
      <EuiLoadingSpinner className="euiAccordion__spinner" css={{
      marginInlineEnd: euiTheme.size.xs
    }} />
      <EuiText size="s">
        <p>
          {isLoadingMessage !== true ? isLoadingMessage : <EuiI18n token="euiAccordionChildrenLoading.message" default="Loading" />}
        </p>
      </EuiText>
    </>;
};
try {
  EuiAccordionChildrenLoading.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accordion/accordion_children/accordion_children_loading.tsx',
    description: '',
    displayName: 'EuiAccordionChildrenLoading',
    methods: [],
    props: {
      isLoadingMessage: {
        defaultValue: null,
        description: 'Choose whether the loading message replaces the content. Customize the message by passing a node',
        name: 'isLoadingMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accordion/accordion.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}