import React from 'react';
export var EuiSelectableOptionProps = function EuiSelectableOptionProps() {
  return ___EmotionJSX("div", null);
};
export var EuiSelectableOptionsList = function EuiSelectableOptionsList() {
  return ___EmotionJSX("div", null);
};
export var EuiSelectableSearchProps = function EuiSelectableSearchProps() {
  return ___EmotionJSX("div", null);
};
export var Options = function Options() {
  return ___EmotionJSX("div", null);
};
export var MetaData = () => <div />;
try {
  EuiSelectableOptionProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/selectable/props.tsx',
    description: '',
    displayName: 'EuiSelectableOptionProps',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      label: {
        defaultValue: null,
        description: 'Visible label of option.\n' + 'Must be unique across items if `key` is not supplied',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      searchableLabel: {
        defaultValue: null,
        description: 'Optionally change the searchable term by passing a different string other than the `label`.\n' + 'Best used when creating a custom `optionRender` to separate the label from metadata but allowing to search on both',
        name: 'searchableLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      key: {
        defaultValue: null,
        description: 'Must be unique across items.\n' + 'Will be used to match options instead of `label`',
        name: 'key',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      checked: {
        defaultValue: null,
        description: 'Leave `undefined` to indicate not selected. Pass a string of\n' + "'on' to indicate inclusion, 'off' to indicate exclusion,\n" + "or 'mixed' to indicate inclusion for some.",
        name: 'checked',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isGroupLabel: {
        defaultValue: null,
        description: 'Optional `boolean`.\n' + 'Set to `true` to indicate object is just a grouping label, not a selectable item',
        name: 'isGroupLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Node to add between the selection icon and the label',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      append: {
        defaultValue: null,
        description: 'Node to add to the far right of the item',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(optionIndex: number) => void'
        }
      },
      id: {
        defaultValue: null,
        description: 'Disallow `id` from being set.\n' + 'Option item `id`s are coordinated at a higher level for a11y reasons.',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'never'
        }
      },
      data: {
        defaultValue: null,
        description: 'Option data to pass through to the `renderOptions` element.\n' + 'Bypass `EuiSelectableItem` and avoid DOM attribute warnings.',
        name: 'data',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ [key: string]: any; }'
        }
      },
      textWrap: {
        defaultValue: {
          value: "'truncate'"
        },
        description: 'How to handle long text within the item.\n' + 'Wrapping only works if `isVirtualization` is false.',
        name: 'textWrap',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"truncate" | "wrap"',
          value: [{
            value: '"truncate"'
          }, {
            value: '"wrap"'
          }]
        }
      },
      truncationProps: {
        defaultValue: null,
        description: 'If textWrap is set to `truncate`, you can pass a custom truncation configuration\n' + 'that accepts any [EuiTextTruncate](/#/utilities/text-truncation) prop except for\n' + '`text` and `children`.\n' + '\n' + 'Note: when searching, custom truncation props are ignored. The highlighted search\n' + 'text will always take precedence.',
        name: 'truncationProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiTextTruncateProps, "children" | "text">>'
        }
      },
      toolTipContent: {
        defaultValue: null,
        description: 'Optional custom tooltip content for the button',
        name: 'toolTipContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: 'Optional props to pass to the underlying **[EuiToolTip](/#/display/tooltip)**',
        name: 'toolTipProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiToolTipProps, "content" | "children">>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiFieldSearchProps', 'InputHTMLAttributes', 'EuiSelectableTemplateSitewideMetaData']
  };
} catch (e) {}
try {
  EuiSelectableOptionsList.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/selectable/props.tsx',
    description: '',
    displayName: 'EuiSelectableOptionsList',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      activeOptionIndex: {
        defaultValue: null,
        description: 'The index of the option to be highlighted as pseudo-focused;\n' + 'Good for use when only one selection is allowed and needing to open\n' + 'directly to that option',
        name: 'activeOptionIndex',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      showIcons: {
        defaultValue: null,
        description: 'Show the check/cross selection indicator icons',
        name: 'showIcons',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      singleSelection: {
        defaultValue: null,
        description: '',
        name: 'singleSelection',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "always"'
        }
      },
      windowProps: {
        defaultValue: null,
        description: 'Any props to send specifically to the react-window `FixedSizeList`',
        name: 'windowProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<ListProps<any>>'
        }
      },
      bordered: {
        defaultValue: null,
        description: 'Adds a border around the list to indicate the bounds;\n' + 'Useful when the list scrolls, otherwise use your own container',
        name: 'bordered',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onFocusBadge: {
        defaultValue: null,
        description: 'When enabled by setting to either `true` or passing custom text,\n' + 'shows a hollow badge as an append (far right) when the item is focused.\n' + 'The default content when `true` is `↩ to select/deselect/include/exclude`',
        name: 'onFocusBadge',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | EuiBadgeProps'
        }
      },
      paddingSize: {
        defaultValue: null,
        description: 'Padding for the list items.',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }]
        }
      },
      textWrap: {
        defaultValue: null,
        description: 'How to handle long text within the item.\n' + 'Wrapping only works if virtualization is off.',
        name: 'textWrap',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"truncate" | "wrap"',
          value: [{
            value: '"truncate"'
          }, {
            value: '"wrap"'
          }]
        }
      },
      truncationProps: {
        defaultValue: null,
        description: 'If textWrap is set to `truncate`, you can pass a custom truncation configuration\n' + 'that accepts any [EuiTextTruncate](/#/utilities/text-truncation) prop except for\n' + '`text` and `children`.\n' + '\n' + 'Note: when searching, custom truncation props are ignored. The highlighted search\n' + 'text will always take precedence.',
        name: 'truncationProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiTextTruncateProps, "children" | "text">>'
        }
      },
      rowHeight: {
        defaultValue: null,
        description: 'The height of each option in pixels. Defaults to `32`.\n' + 'Has no effect if `isVirtualized=false`.',
        name: 'rowHeight',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      isVirtualized: {
        defaultValue: null,
        description: 'Use virtualized rendering for list items with `react-window`.\n' + "Sets each row's height to the value of `rowHeight`.",
        name: 'isVirtualized',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_list/selectable_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiFieldSearchProps', 'InputHTMLAttributes', 'EuiSelectableTemplateSitewideMetaData']
  };
} catch (e) {}
try {
  EuiSelectableSearchProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/selectable/props.tsx',
    description: '',
    displayName: 'EuiSelectableSearchProps',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'when `true` creates a shorter height input',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: '(node: HTMLInputElement) => void'
        }
      },
      placeholder: {
        defaultValue: null,
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isClearable: {
        defaultValue: null,
        description: 'Shows a button that quickly clears any input',
        name: 'isClearable',
        parent: {
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/field_search/field_search.tsx',
          name: 'EuiFieldSearchProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'Passes back (searchValue, matchingOptions)',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_search/selectable_search.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(searchValue: string, matchingOptions: any[]) => void'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiFieldSearchProps', 'InputHTMLAttributes', 'EuiSelectableTemplateSitewideMetaData']
  };
} catch (e) {}
try {
  Options.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/selectable/props.tsx',
    description: '',
    displayName: 'Options',
    methods: [],
    props: {
      icon: {
        defaultValue: null,
        description: 'Displayed on the left (`prepend`).\n' + "Object of `EuiIconProps` for display of the solution/application's logo",
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiIconProps'
        }
      },
      avatar: {
        defaultValue: null,
        description: 'Displayed on the right (`append`).\n' + 'Object of `EuiAvatarProps` for display of the space (default) or user',
        name: 'avatar',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiAvatarProps'
        }
      },
      meta: {
        defaultValue: null,
        description: 'An array of inline #MetaData displayed beneath the label and separated by bullets.',
        name: 'meta',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiSelectableTemplateSitewideMetaData[]'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      label: {
        defaultValue: null,
        description: 'Visible label of option.\n' + 'Must be unique across items if `key` is not supplied',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      searchableLabel: {
        defaultValue: null,
        description: 'Optionally change the searchable term by passing a different string other than the `label`.\n' + 'Best used when creating a custom `optionRender` to separate the label from metadata but allowing to search on both',
        name: 'searchableLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      key: {
        defaultValue: null,
        description: 'Must be unique across items.\n' + 'Will be used to match options instead of `label`',
        name: 'key',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      checked: {
        defaultValue: null,
        description: 'Leave `undefined` to indicate not selected. Pass a string of\n' + "'on' to indicate inclusion, 'off' to indicate exclusion,\n" + "or 'mixed' to indicate inclusion for some.",
        name: 'checked',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isGroupLabel: {
        defaultValue: null,
        description: 'Optional `boolean`.\n' + 'Set to `true` to indicate object is just a grouping label, not a selectable item',
        name: 'isGroupLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Node to add between the selection icon and the label',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      append: {
        defaultValue: null,
        description: 'Node to add to the far right of the item',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      ref: {
        defaultValue: null,
        description: '',
        name: 'ref',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(optionIndex: number) => void'
        }
      },
      id: {
        defaultValue: null,
        description: 'Disallow `id` from being set.\n' + 'Option item `id`s are coordinated at a higher level for a11y reasons.',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'never'
        }
      },
      data: {
        defaultValue: null,
        description: 'Option data to pass through to the `renderOptions` element.\n' + 'Bypass `EuiSelectableItem` and avoid DOM attribute warnings.',
        name: 'data',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ [key: string]: any; }'
        }
      },
      textWrap: {
        defaultValue: {
          value: "'truncate'"
        },
        description: 'How to handle long text within the item.\n' + 'Wrapping only works if `isVirtualization` is false.',
        name: 'textWrap',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"truncate" | "wrap"',
          value: [{
            value: '"truncate"'
          }, {
            value: '"wrap"'
          }]
        }
      },
      truncationProps: {
        defaultValue: null,
        description: 'If textWrap is set to `truncate`, you can pass a custom truncation configuration\n' + 'that accepts any [EuiTextTruncate](/#/utilities/text-truncation) prop except for\n' + '`text` and `children`.\n' + '\n' + 'Note: when searching, custom truncation props are ignored. The highlighted search\n' + 'text will always take precedence.',
        name: 'truncationProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiTextTruncateProps, "children" | "text">>'
        }
      },
      toolTipContent: {
        defaultValue: null,
        description: 'Optional custom tooltip content for the button',
        name: 'toolTipContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: 'Optional props to pass to the underlying **[EuiToolTip](/#/display/tooltip)**',
        name: 'toolTipProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable_option.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiToolTipProps, "content" | "children">>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiFieldSearchProps', 'InputHTMLAttributes', 'EuiSelectableTemplateSitewideMetaData']
  };
} catch (e) {}
try {
  MetaData.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/selectable/props.tsx',
    description: '',
    displayName: 'MetaData',
    methods: [],
    props: {
      text: {
        defaultValue: null,
        description: 'Required to display the metadata',
        name: 'text',
        parent: {
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'EuiSelectableTemplateSitewideMetaData'
        },
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'EuiSelectableTemplateSitewideMetaData'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      type: {
        defaultValue: null,
        description: "Styles the metadata according to Elastic's schema.\n" + "Can be one of 'application', 'deployment', 'article', 'case', 'platform',\n" + 'or a custom string to associate with your own schema.\n' + 'Appends the string to the class name as `euiSelectableTemplateSitewide__optionMeta--[type]`',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'EuiSelectableTemplateSitewideMetaData'
        },
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'EuiSelectableTemplateSitewideMetaData'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      highlightSearchString: {
        defaultValue: null,
        description: 'Will wrap the meta tag in EuiHighlight to mark the portions that match the search text',
        name: 'highlightSearchString',
        parent: {
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'EuiSelectableTemplateSitewideMetaData'
        },
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option.tsx',
          name: 'EuiSelectableTemplateSitewideMetaData'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiFieldSearchProps', 'InputHTMLAttributes', 'EuiSelectableTemplateSitewideMetaData']
  };
} catch (e) {}