/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, useLatest } from '../../services';
import { useResizeObserver } from '../observer/resize_observer';
import { EuiResizableContainerContextProvider } from './context';
import { euiResizableButtonWithControls } from './resizable_button';
import { euiResizablePanelWithControls, getModeType } from './resizable_panel';
import { useContainerCallbacks, getPosition } from './helpers';
import { euiResizableContainerStyles as styles } from './resizable_container.styles';
var initialState = {
  isDragging: false,
  currentResizerPos: -1,
  prevPanelId: null,
  nextPanelId: null,
  containerSize: 1,
  panels: {},
  resizers: {}
};
export var EuiResizableContainer = ({
  direction = 'horizontal',
  children,
  className,
  onPanelWidthChange,
  onToggleCollapsed,
  onResizeStart,
  onResizeEnd,
  ...rest
}) => {
  // Note: It's important to memoize consumer callbacks to prevent our own functions
  // from reinstantiating unnecessarily & causing window event listeners to call stale closures
  const onResizeEndRef = useLatest(onResizeEnd);
  const onResizeStartRef = useLatest(onResizeStart);
  const containerRef = useRef(null);
  const isHorizontal = direction === 'horizontal';
  const classes = classNames('euiResizableContainer', className);
  const cssStyles = [styles.euiResizableContainer, styles[direction]];
  const [actions, reducerState] = useContainerCallbacks({
    initialState: {
      ...initialState,
      isHorizontal
    },
    containerRef,
    onPanelWidthChange
  });
  const containerSize = useResizeObserver(containerRef.current, isHorizontal ? 'width' : 'height');
  const initialize = useCallback(() => {
    actions.initContainer(isHorizontal);
  }, [actions, isHorizontal]);
  useEffect(() => {
    if (containerSize.width > 0 && containerSize.height > 0) {
      initialize();
    }
  }, [initialize, containerSize]);
  const resizeContext = useRef({});
  const resizeEnd = useCallback(() => {
    onResizeEndRef.current?.();
    resizeContext.current = {};
  }, [onResizeEndRef]);
  const resizeStart = useCallback((trigger, keyMoveDirection) => {
    // If another resize starts while the previous one is still in progress
    // (e.g. user presses opposite arrow to change direction while the first
    // is still held down, or user presses an arrow while dragging with the
    // mouse), we want to signal the end of the previous resize first.
    if (resizeContext.current.trigger) {
      resizeEnd();
    }
    onResizeStartRef.current?.(trigger);
    resizeContext.current = {
      trigger,
      keyMoveDirection
    };
  }, [onResizeStartRef, resizeEnd]);
  const onMouseDown = useCallback(event => {
    const currentTarget = event.currentTarget;
    const prevPanel = currentTarget.previousElementSibling;
    const nextPanel = currentTarget.nextElementSibling;
    if (!prevPanel || !nextPanel) return;
    const prevPanelId = prevPanel.id;
    const nextPanelId = nextPanel.id;
    const position = getPosition(event, isHorizontal);
    resizeStart('pointer');
    actions.dragStart({
      position,
      prevPanelId,
      nextPanelId
    });

    // Window event listeners instead of React events are used to continue
    // detecting movement even if the user's mouse leaves the container

    const onMouseMove = event => {
      const position = getPosition(event, isHorizontal);
      actions.dragMove({
        position,
        prevPanelId,
        nextPanelId
      });
    };
    const onMouseUp = () => {
      if (resizeContext.current.trigger === 'pointer') {
        resizeEnd();
      }
      actions.reset();
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchmove', onMouseMove);
      window.removeEventListener('touchend', onMouseUp);
    };
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchmove', onMouseMove);
    window.addEventListener('touchend', onMouseUp);
  }, [actions, isHorizontal, resizeStart, resizeEnd]);
  const getKeyMoveDirection = useCallback(key => {
    let direction = null;
    if (isHorizontal && key === keys.ARROW_LEFT || !isHorizontal && key === keys.ARROW_UP) {
      direction = 'backward';
    } else if (isHorizontal && key === keys.ARROW_RIGHT || !isHorizontal && key === keys.ARROW_DOWN) {
      direction = 'forward';
    }
    return direction;
  }, [isHorizontal]);
  const onKeyDown = useCallback(event => {
    const {
      key,
      currentTarget
    } = event;
    const direction = getKeyMoveDirection(key);
    const prevPanelId = currentTarget.previousElementSibling.id;
    const nextPanelId = currentTarget.nextElementSibling.id;
    if (direction && prevPanelId && nextPanelId) {
      if (!event.repeat) {
        resizeStart('key', direction);
      }
      event.preventDefault();
      actions.keyMove({
        direction,
        prevPanelId,
        nextPanelId
      });
    }
  }, [actions, getKeyMoveDirection, resizeStart]);
  const onKeyUp = useCallback(({
    key
  }) => {
    // We only want to signal the end of a resize if the key that was released
    // is the same as the one that started the resize. This prevents the end
    // of a resize if the user presses one arrow key, then presses the opposite
    // arrow key to change direction, then releases the first arrow key.
    if (resizeContext.current.trigger === 'key' && resizeContext.current.keyMoveDirection === getKeyMoveDirection(key)) {
      resizeEnd();
    }
  }, [getKeyMoveDirection, resizeEnd]);
  const onBlur = useCallback(() => {
    if (resizeContext.current.trigger === 'key') {
      resizeEnd();
    }
    actions.resizerBlur();
  }, [actions, resizeEnd]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const EuiResizableButton = useCallback(euiResizableButtonWithControls({
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onTouchStart: onMouseDown,
    onFocus: actions.resizerFocus,
    onBlur,
    isHorizontal,
    registration: {
      register: actions.registerResizer,
      deregister: actions.deregisterResizer
    }
  }), [actions, isHorizontal]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const EuiResizablePanel = useCallback(euiResizablePanelWithControls({
    isHorizontal,
    registration: {
      register: actions.registerPanel,
      deregister: actions.deregisterPanel
    },
    onToggleCollapsed,
    onToggleCollapsedInternal: actions.togglePanel
  }), [actions, isHorizontal]);
  const render = () => {
    const DEFAULT = 'custom';
    const content = children(EuiResizablePanel, EuiResizableButton, {
      togglePanel: actions.togglePanel
    });
    const modes = React.isValidElement(content) ? content.props.children.map(el => getModeType(el.props.mode) || DEFAULT) : null;
    if (modes && (['collapsible', 'main'].every(i => modes.includes(i)) || modes.every(i => i === DEFAULT))) {
      return content;
    } else {
      throw new Error('Both `collapsible` and `main` mode panels are required.');
    }
  };
  return <EuiResizableContainerContextProvider registry={{
    panels: reducerState.panels,
    resizers: reducerState.resizers
  }}>
      <div css={cssStyles} className={classes} ref={containerRef} {...rest}>
        {render()}
      </div>
    </EuiResizableContainerContextProvider>;
};
EuiResizableContainer.propTypes = {
  /**
     * Specify the container direction
     */
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
  /**
     * Pure function which accepts Panel and Resizer components in arguments
     * and returns a component tree
     */
  children: PropTypes.func.isRequired,
  /**
     * Pure function which accepts an object where keys are IDs of panels, which sizes were changed,
     * and values are actual sizes in percents
     */
  onPanelWidthChange: PropTypes.func,
  onToggleCollapsed: PropTypes.func,
  /**
     * Called when resizing starts
     */
  onResizeStart: PropTypes.func,
  /**
     * Called when resizing ends
     */
  onResizeEnd: PropTypes.func,
  style: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiResizableContainer.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/resizable_container/resizable_container.tsx',
    description: '',
    displayName: 'EuiResizableContainer',
    methods: [],
    props: {
      direction: {
        defaultValue: {
          value: 'horizontal'
        },
        description: 'Specify the container direction',
        name: 'direction',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"horizontal" | "vertical"',
          value: [{
            value: '"horizontal"'
          }, {
            value: '"vertical"'
          }]
        }
      },
      children: {
        defaultValue: null,
        description: 'Pure function which accepts Panel and Resizer components in arguments\n' + 'and returns a component tree',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        }],
        required: true,
        type: {
          name: '(Panel: ComponentType<EuiResizablePanelProps>, Resizer: ComponentType<EuiResizableButtonProps>, actions: Partial<...>) => ReactNode'
        }
      },
      onPanelWidthChange: {
        defaultValue: null,
        description: 'Pure function which accepts an object where keys are IDs of panels, which sizes were changed,\n' + 'and values are actual sizes in percents',
        name: 'onPanelWidthChange',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        }],
        required: false,
        type: {
          name: '({}: { [key: string]: number; }) => void'
        }
      },
      onToggleCollapsed: {
        defaultValue: null,
        description: '',
        name: 'onToggleCollapsed',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        }],
        required: false,
        type: {
          name: 'ToggleCollapseCallback'
        }
      },
      onResizeStart: {
        defaultValue: null,
        description: 'Called when resizing starts',
        name: 'onResizeStart',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        }],
        required: false,
        type: {
          name: '(trigger: ResizeTrigger) => void'
        }
      },
      onResizeEnd: {
        defaultValue: null,
        description: 'Called when resizing ends',
        name: 'onResizeEnd',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/resizable_container/resizable_container.tsx',
          name: 'EuiResizableContainerProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiResizableContainerProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}