/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { EuiEmptyPrompt } from '../../empty_prompt';
import { EuiPageSection } from '../../page/page_section';
export var _EuiPageEmptyPrompt = ({
  children,
  alignment = 'center',
  restrictWidth = false,
  paddingSize = 'l',
  grow = true,
  panelled,
  color,
  ...rest
}) => {
  /**
   * If panelled = true, then either the section or empty prompt must be plain;
   * If color is anything but plain, then it must be the section that is plain;
   * If panelled = true, but color is undefined, then default to the empty prompt being plain;
   */

  let sectionColor;
  let emptyPromptColor;
  if (panelled && color === undefined) {
    sectionColor = 'plain';
    emptyPromptColor = 'subdued';
  } else if (panelled && color !== 'plain') {
    sectionColor = 'plain';
    emptyPromptColor = color;
  } else {
    sectionColor = 'transparent';
    emptyPromptColor = color || 'plain';
  }
  return <EuiPageSection paddingSize={paddingSize} color={sectionColor} grow={grow} restrictWidth={restrictWidth} alignment={alignment}>
      <EuiEmptyPrompt
    // @ts-expect-error Hasn't been updated to use all the new values yet
    paddingSize={paddingSize} color={emptyPromptColor} body={children} {...rest} />
    </EuiPageSection>;
};
_EuiPageEmptyPrompt.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Sets the max-width of the page,
     * set to `true` to use the default size of `1200px`,
     * set to `false` to not restrict the width,
     * set to a number for a custom width in px,
     * set to a string for a custom width in custom measurement.
     */
  restrictWidth: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
       * Background color of the section;
       * Usually a lightened form of the brand colors
       */
  color: PropTypes.any,
  /**
       * Padding for all four sides
       */
  paddingSize: PropTypes.any,
  /**
       * Horizontal and/or vertical alignment of the section contents
       */
  alignment: PropTypes.any,
  /**
       * When true the panel will grow in height to fill container if parent is a flex group
       */
  grow: PropTypes.bool,
  /**
       * Passed down to the div wrapper of the section contents
       */
  contentProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
       * Sets which HTML element to render.
       */
  component: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.elementType.isRequired]),
  panelled: PropTypes.bool
};
try {
  _EuiPageEmptyPrompt.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/page_template/empty_prompt/page_empty_prompt.tsx',
    description: '',
    displayName: '_EuiPageEmptyPrompt',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: 'Background color of the section;\n' + 'Usually a lightened form of the brand colors\n' + 'Background color of the panel;\n' + 'Usually a lightened form of the brand colors',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"transparent" | "accent" | "primary" | "success" | "warning" | "danger" | "subdued" | "plain"',
          value: [{
            value: '"transparent"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"plain"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      contentProps: {
        defaultValue: null,
        description: 'Passed down to the div wrapper of the section contents',
        name: 'contentProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement>'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'l'
        },
        description: 'Padding for all four sides',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      alignment: {
        defaultValue: {
          value: 'center'
        },
        description: 'Horizontal and/or vertical alignment of the section contents',
        name: 'alignment',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "top" | "horizontalCenter"',
          value: [{
            value: '"center"'
          }, {
            value: '"top"'
          }, {
            value: '"horizontalCenter"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: 'When true the panel will grow in height to fill container if parent is a flex group',
        name: 'grow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      component: {
        defaultValue: null,
        description: 'Sets which HTML element to render.',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/page_section/page_section.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ComponentType<{}> | keyof IntrinsicElements'
        }
      },
      restrictWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the max-width of the page,\n' + 'set to `true` to use the default size of `1200px`,\n' + 'set to `false` to not restrict the width,\n' + 'set to a number for a custom width in px,\n' + 'set to a string for a custom width in custom measurement.',
        name: 'restrictWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page/_restrict_width.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number | boolean'
        }
      },
      title: {
        defaultValue: null,
        description: 'Requires passing a single element that gets wrapped in an EuiTitle.\n' + 'Recommendation is a heading, preferrably an `<h2>` if in its own section',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      body: {
        defaultValue: null,
        description: 'Gets wrapped in a subdued EuiText block.\n' + 'Recommendation is to pass typical text elements like `<p>`',
        name: 'body',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      footer: {
        defaultValue: null,
        description: 'Optionally provide a footer. Accepts any combination of elements.',
        name: 'footer',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      iconType: {
        defaultValue: null,
        description: '',
        name: 'iconType',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      iconColor: {
        defaultValue: null,
        description: 'Color for `iconType` when passed as an `IconType`',
        name: 'iconColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      layout: {
        defaultValue: null,
        description: 'Sets the layout. When `horizontal` the icon goes to the right column.',
        name: 'layout',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"horizontal" | "vertical"',
          value: [{
            value: '"horizontal"'
          }, {
            value: '"vertical"'
          }]
        }
      },
      titleSize: {
        defaultValue: null,
        description: 'Choose from one of the `EuiTitle.size` options',
        name: 'titleSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      icon: {
        defaultValue: null,
        description: 'Custom icon replacing the one generated by `iconType`',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      hasBorder: {
        defaultValue: null,
        description: 'Adds a slight 1px border on all edges.\n' + 'Only works when `color="plain | transparent"`',
        name: 'hasBorder',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasShadow: {
        defaultValue: null,
        description: 'Adds a medium shadow to the panel;\nOnly works when `color="plain"`',
        name: 'hasShadow',
        parent: {
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/panel/panel.tsx',
          name: '_EuiPanelProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      actions: {
        defaultValue: null,
        description: 'Pass a single or an array of actions (buttons) that get stacked at the bottom.\n' + 'Recommendation is to pass the primary action first and secondary actions as empty buttons',
        name: 'actions',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/empty_prompt/empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      panelled: {
        defaultValue: null,
        description: '',
        name: 'panelled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/page_template/empty_prompt/page_empty_prompt.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', '_EuiPanelProps']
  };
} catch (e) {}