const _excluded = ["children", "className", "search", "strict", "highlightAll", "hasScreenReaderHelpText"];
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import { EuiMark } from '../mark';
import { HighlightAll } from './_highlight_all';
import { HighlightFirst } from './_highlight_first';
export var EuiHighlight = function (_ref) {
  var children = _ref.children,
    className = _ref.className,
    search = _ref.search,
    _ref$strict = _ref.strict,
    strict = _ref$strict === void 0 ? false : _ref$strict,
    _ref$highlightAll = _ref.highlightAll,
    highlightAll = _ref$highlightAll === void 0 ? false : _ref$highlightAll,
    _ref$hasScreenReaderH = _ref.hasScreenReaderHelpText,
    hasScreenReaderHelpText = _ref$hasScreenReaderH === void 0 ? true : _ref$hasScreenReaderH,
    rest = _objectWithoutProperties(_ref, _excluded);
  var hasSearch = search && search.length > 0;
  var HighlightComponent = useMemo(function () {
    var Component = ({
      children
    }) => <EuiMark hasScreenReaderHelpText={hasScreenReaderHelpText}>
        {children}
      </EuiMark>;
    Component.propTypes = {
      children: PropTypes.string.isRequired
    };
    Component.displayName = '_HighlightComponent';
    return Component;
  }, [hasScreenReaderHelpText]);
  return <span className={className} {...rest}>
      {children && hasSearch ? highlightAll ? <HighlightAll searchValue={search} searchSubject={children} isStrict={strict} highlightComponent={HighlightComponent} /> : <HighlightFirst searchValue={search} searchSubject={children} isStrict={strict} highlightComponent={HighlightComponent} /> : children}
    </span>;
};
EuiHighlight.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * string to highlight as this component's content
       */
  children: PropTypes.string.isRequired,
  /**
       * What to search for.
       *
       * Allows passing an array of strings (searching by multiple separate
       * words or phrases) **only** if `highlightAll` is also set to `true`.
       */
  search: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]).isRequired,
  /**
       * Should the search be strict or not
       */
  strict: PropTypes.bool,
  /**
       * Should highlight all matches
       */
  highlightAll: PropTypes.bool
};
try {
  EuiHighlight.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/highlight/highlight.tsx',
    description: '',
    displayName: 'EuiHighlight',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "string to highlight as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      hasScreenReaderHelpText: {
        defaultValue: {
          value: 'true'
        },
        description: 'Set to `false` to remove the CSS :before and :after\n' + 'screen reader helper text',
        name: 'hasScreenReaderHelpText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/mark/mark.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      search: {
        defaultValue: null,
        description: 'What to search for.\n' + '\n' + 'Allows passing an array of strings (searching by multiple separate\n' + 'words or phrases) **only** if `highlightAll` is also set to `true`.',
        name: 'search',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string | string[]'
        }
      },
      strict: {
        defaultValue: {
          value: 'false'
        },
        description: 'Should the search be strict or not',
        name: 'strict',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      highlightAll: {
        defaultValue: {
          value: 'false'
        },
        description: 'Should highlight all matches',
        name: 'highlightAll',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}