/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiScreenReaderOnly } from '../../accessibility';
import { euiFormLegendStyles } from './form_legend.styles';
export var EuiFormLegend = ({
  children,
  className,
  display = 'visible',
  compressed,
  ...rest
}) => {
  const isLegendHidden = display === 'hidden';
  const styles = useEuiMemoizedStyles(euiFormLegendStyles);
  const cssStyles = [styles.euiFormLegend, !isLegendHidden && (compressed ? styles.compressed : styles.uncompressed)];
  const classes = classNames('euiFormLegend', {
    'euiFormLegend-isHidden': isLegendHidden
  }, className);
  return <legend css={cssStyles} className={classes} {...rest}>
      {isLegendHidden ? <EuiScreenReaderOnly>
          <span>{children}</span>
        </EuiScreenReaderOnly> : children}
    </legend>;
};
EuiFormLegend.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * ReactNode to render as this component's content
       */
  children: PropTypes.node.isRequired,
  /**
       * For a hidden legend that is still visible to the screen reader, set to 'hidden'
       */
  display: PropTypes.oneOf(["hidden", "visible"]),
  compressed: PropTypes.bool
};
try {
  EuiFormLegend.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/form_fieldset/form_legend.tsx',
    description: '',
    displayName: 'EuiFormLegend',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/form/form_fieldset/form_legend.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      display: {
        defaultValue: {
          value: 'visible'
        },
        description: "For a hidden legend that is still visible to the screen reader, set to 'hidden'",
        name: 'display',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_fieldset/form_legend.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"hidden" | "visible"',
          value: [{
            value: '"hidden"'
          }, {
            value: '"visible"'
          }]
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_fieldset/form_legend.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}