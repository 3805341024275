/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, useEuiMemoizedStyles } from '../../services';
import { useEuiTableIsResponsive } from './mobile/responsive_context';
import { euiTableRowStyles } from './table_row.styles';
export var EuiTableRow = ({
  children,
  className,
  hasSelection,
  isSelected,
  isSelectable,
  hasActions,
  isExpandedRow,
  isExpandable,
  onClick,
  ...rest
}) => {
  const isResponsive = useEuiTableIsResponsive();
  const styles = useEuiMemoizedStyles(euiTableRowStyles);
  const cssStyles = isResponsive ? [styles.euiTableRow, styles.mobile.mobile, isSelected && styles.mobile.selected, isExpandedRow && styles.mobile.expanded, (hasActions === true || isExpandable || isExpandedRow) && styles.mobile.hasRightColumn, hasSelection && styles.mobile.hasLeftColumn] : [styles.euiTableRow, styles.desktop.desktop, isSelected && styles.desktop.selected, isExpandedRow && styles.desktop.expanded, onClick && styles.desktop.clickable, isExpandedRow && hasSelection && styles.desktop.checkboxOffset];
  const classes = classNames('euiTableRow', className, {
    'euiTableRow-isSelectable': isSelectable,
    'euiTableRow-isSelected': isSelected,
    'euiTableRow-hasActions': hasActions,
    'euiTableRow-isExpandedRow': isExpandedRow,
    'euiTableRow-isExpandable': isExpandable,
    'euiTableRow-isClickable': onClick
  });
  if (!onClick) {
    return <tr css={cssStyles} className={classes} {...rest}>
        {children}
      </tr>;
  }
  const onKeyDown = event => {
    // Prevent a scroll from occurring if the user has hit space.
    if (event.key === keys.SPACE) event.preventDefault();
  };
  const onKeyUp = event => {
    // Support keyboard accessibility by emulating mouse click on ENTER or SPACE keypress.
    if (event.key === keys.ENTER || event.key === keys.SPACE) {
      onClick(event);
    }
  };
  return <tr css={cssStyles} className={classes} onClick={onClick} onKeyDown={onKeyDown} onKeyUp={onKeyUp} tabIndex={0} {...rest}>
      {children}
    </tr>;
};
EuiTableRow.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Indicates if the table has a single column of checkboxes for selecting
     * rows (used for mobile styling)
     */
  hasSelection: PropTypes.bool,
  /**
     * Indicates that the current row's checkbox is selectable / not disabled
     */
  isSelectable: PropTypes.bool,
  /**
     * Indicates the current row has been selected
     */
  isSelected: PropTypes.bool,
  /**
     * Indicates if the table has a dedicated column for actions
     * (used for mobile styling and desktop action hover behavior)
     */
  hasActions: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf(["custom"])]),
  /**
     * Indicates if the row will have an expanded row
     */
  isExpandable: PropTypes.bool,
  /**
     * Indicates if the row will be the expanded row
     */
  isExpandedRow: PropTypes.bool,
  onClick: PropTypes.any
};
try {
  EuiTableRow.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/table_row.tsx',
    description: '',
    displayName: 'EuiTableRow',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      hasSelection: {
        defaultValue: null,
        description: 'Indicates if the table has a single column of checkboxes for selecting\n' + 'rows (used for mobile styling)',
        name: 'hasSelection',
        parent: {
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSelectable: {
        defaultValue: null,
        description: "Indicates that the current row's checkbox is selectable / not disabled",
        name: 'isSelectable',
        parent: {
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Indicates the current row has been selected',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasActions: {
        defaultValue: null,
        description: 'Indicates if the table has a dedicated column for actions\n' + '(used for mobile styling and desktop action hover behavior)',
        name: 'hasActions',
        parent: {
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        }],
        required: false,
        type: {
          name: 'boolean | "custom"'
        }
      },
      isExpandable: {
        defaultValue: null,
        description: 'Indicates if the row will have an expanded row',
        name: 'isExpandable',
        parent: {
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isExpandedRow: {
        defaultValue: null,
        description: 'Indicates if the row will be the expanded row',
        name: 'isExpandedRow',
        parent: {
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/table_row.tsx',
          name: 'EuiTableRowProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiTableRowProps']
  };
} catch (e) {}