/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useEuiTheme } from '../../../services';
import { euiRangeLabelStyles } from './range_label.styles';
export var EuiRangeLabel = ({
  children,
  disabled,
  side = 'max'
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiRangeLabelStyles(euiTheme);
  const cssStyles = [styles.euiRangeLabel, styles[side], disabled && styles.isDisabled];
  return <label className="euiRangeLabel" css={cssStyles}>
      {children}
    </label>;
};
EuiRangeLabel.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  /**
     * @default 'max'
     */
  side: PropTypes.oneOf(["min", "max"])
};
try {
  EuiRangeLabel.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_label.tsx',
    description: '',
    displayName: 'EuiRangeLabel',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/form/range/range_label.tsx',
          name: 'EuiRangeLabelProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_label.tsx',
          name: 'EuiRangeLabelProps'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      disabled: {
        defaultValue: null,
        description: 'Disables both the range track and any input(s)',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      side: {
        defaultValue: {
          value: 'max'
        },
        description: '',
        name: 'side',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"max" | "min"',
          value: [{
            value: '"max"'
          }, {
            value: '"min"'
          }]
        }
      }
    },
    extendedInterfaces: ['_SharedRangeInputProps']
  };
} catch (e) {}