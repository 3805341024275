import React from 'react';
import PropTypes from "prop-types";
export var EuiKeyPadMenuCheckableProps = () => <div />;
EuiKeyPadMenuCheckableProps.propTypes = {
  /**
       * Rendered within a `legend` to label the `fieldset`.
       * To create a visually hidden legend, use `ariaLegend`
       */
  legend: PropTypes.node,
  /**
       * Pass through props to a `EuiFormLabel` component, except for `type`
       */
  legendProps: PropTypes.any,
  /**
       * Custom aria-attribute for creating a *visually hidden* legend.
       * To create a visible legend, use `legend`
       */
  ariaLegend: PropTypes.string
};
try {
  EuiKeyPadMenuCheckableProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/key_pad_menu/props.tsx',
    description: '',
    displayName: 'EuiKeyPadMenuCheckableProps',
    methods: [],
    props: {
      legend: {
        defaultValue: null,
        description: 'Rendered within a `legend` to label the `fieldset`.\n' + 'To create a visually hidden legend, use `ariaLegend`',
        name: 'legend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/key_pad_menu/key_pad_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      legendProps: {
        defaultValue: null,
        description: 'Pass through props to a `EuiFormLabel` component, except for `type`',
        name: 'legendProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/key_pad_menu/key_pad_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Omit<_EuiFormLegendProps, "type">'
        }
      },
      ariaLegend: {
        defaultValue: null,
        description: 'Custom aria-attribute for creating a *visually hidden* legend.\n' + 'To create a visible legend, use `legend`',
        name: 'ariaLegend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/key_pad_menu/key_pad_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}