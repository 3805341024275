/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import classNames from 'classnames';
import PropTypes from "prop-types";
import React from 'react';
import { EuiStepHorizontal } from './step_horizontal';
import { euiStepsHorizontalStyles } from './steps_horizontal.styles';
export var SIZES = ['xs', 's', 'm'];
export var EuiStepsHorizontal = ({
  className,
  steps,
  size = 'm',
  ...rest
}) => {
  const classes = classNames('euiStepsHorizontal', className);
  const styles = euiStepsHorizontalStyles();
  const cssStyles = styles.euiStepsHorizontal;
  const cssItemStyles = styles.euiStepsHorizontal__item;
  return <ol className={classes} css={cssStyles} {...rest}>
      {steps.map((stepProps, index) => {
      return <li key={index} className="euiStepsHorizontal__item" css={cssItemStyles} aria-current={stepProps.status === 'current' ? 'step' : undefined}>
            <EuiStepHorizontal step={index + 1} size={size} {...stepProps} />
          </li>;
    })}
    </ol>;
};
EuiStepsHorizontal.propTypes = {
  /**
     * An array of `EuiStepHorizontal` objects excluding the `step` prop
     */
  steps: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  size: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiStepsHorizontal.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/steps/steps_horizontal.tsx',
    description: '',
    displayName: 'EuiStepsHorizontal',
    methods: [],
    props: {
      steps: {
        defaultValue: null,
        description: 'An array of `EuiStepHorizontal` objects excluding the `step` prop',
        name: 'steps',
        parent: {
          fileName: 'eui/src/components/steps/steps_horizontal.tsx',
          name: 'EuiStepsHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/steps_horizontal.tsx',
          name: 'EuiStepsHorizontalProps'
        }],
        required: true,
        type: {
          name: 'Omit<EuiStepHorizontalProps, "step">[]'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/steps/steps_horizontal.tsx',
          name: 'EuiStepsHorizontalProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/steps_horizontal.tsx',
          name: 'EuiStepsHorizontalProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiStepsHorizontalProps', 'OlHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}