/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { EuiFilterButton } from '../../filter_group';
import { Query } from '../query';
export class FieldValueToggleGroupFilter extends Component {
  resolveDisplay(config, query, item) {
    const clause = query.getSimpleFieldClause(config.field, item.value);
    if (clause) {
      if (Query.isMust(clause)) {
        return {
          active: true,
          name: item.name
        };
      }
      return {
        active: true,
        name: item.negatedName ? item.negatedName : `Not ${item.name}`
      };
    }
    return {
      active: false,
      name: item.name
    };
  }
  valueChanged(item, active) {
    const {
      field
    } = this.props.config;
    const {
      value,
      operator
    } = item;
    const query = active ? this.props.query.removeSimpleFieldClauses(field) : this.props.query.removeSimpleFieldClauses(field).addSimpleFieldValue(field, value, true, operator);
    this.props.onChange(query);
  }
  render() {
    const {
      config,
      query
    } = this.props;
    return config.items.map((item, index) => {
      const {
        active,
        name
      } = this.resolveDisplay(config, query, item);
      const onClick = () => {
        this.valueChanged(item, active);
      };
      const key = `field_value_toggle_filter_item_${index}`;
      const isLastItem = index === config.items.length - 1;
      return <EuiFilterButton key={key} onClick={onClick} hasActiveFilters={active} aria-pressed={!!active} withNext={!isLastItem}>
          {name}
        </EuiFilterButton>;
    });
  }
}
FieldValueToggleGroupFilter.propTypes = {
  index: PropTypes.number.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(["field_value_toggle_group"]).isRequired,
    field: PropTypes.string.isRequired,
    /**
       * See #FieldValueToggleGroupFilterItemType
       */
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.bool.isRequired]).isRequired,
      name: PropTypes.string.isRequired,
      negatedName: PropTypes.string,
      operator: PropTypes.oneOf(["eq", "exact", "gt", "gte", "lt", "lte"])
    }).isRequired).isRequired,
    available: PropTypes.func
  }).isRequired,
  query: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};
try {
  FieldValueToggleGroupFilter.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
    description: '',
    displayName: 'FieldValueToggleGroupFilter',
    methods: [],
    props: {
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      config: {
        defaultValue: null,
        description: '',
        name: 'config',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: 'FieldValueToggleGroupFilterConfigType'
        }
      },
      query: {
        defaultValue: null,
        description: '',
        name: 'query',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: 'Query'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        },
        declarations: [{
          fileName: 'eui/src/components/search_bar/filters/field_value_toggle_group_filter.tsx',
          name: 'FieldValueToggleGroupFilterProps'
        }],
        required: true,
        type: {
          name: '(value: Query) => void'
        }
      }
    },
    extendedInterfaces: ['FieldValueToggleGroupFilterProps']
  };
} catch (e) {}