/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { cloneElementWithCss } from '../../../services';
import { euiScreenReaderOnlyStyles as styles } from './screen_reader_only.styles';
export var EuiScreenReaderOnly = ({
  children,
  className,
  showOnFocus
}) => {
  const classes = classNames(className, children.props.className);
  const props = useMemo(() => ({
    className: classes.length ? classes : undefined,
    css: showOnFocus ? styles['euiScreenReaderOnly-showOnFocus'] : styles.euiScreenReaderOnly
  }), [classes, showOnFocus]);
  return cloneElementWithCss(children, props);
};
EuiScreenReaderOnly.propTypes = {
  /**
     * ReactElement to render as this component's content
     */
  children: PropTypes.element.isRequired,
  /**
     * For keyboard navigation, force content to display visually upon focus/focus-within.
     */
  showOnFocus: PropTypes.bool,
  className: PropTypes.string
};
try {
  EuiScreenReaderOnly.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accessibility/screen_reader_only/screen_reader_only.tsx',
    description: '',
    displayName: 'EuiScreenReaderOnly',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactElement to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_only/screen_reader_only.tsx',
          name: 'EuiScreenReaderOnlyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_only/screen_reader_only.tsx',
          name: 'EuiScreenReaderOnlyProps'
        }],
        required: true,
        type: {
          name: 'ReactElement'
        }
      },
      showOnFocus: {
        defaultValue: null,
        description: 'For keyboard navigation, force content to display visually upon focus/focus-within.',
        name: 'showOnFocus',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_only/screen_reader_only.tsx',
          name: 'EuiScreenReaderOnlyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_only/screen_reader_only.tsx',
          name: 'EuiScreenReaderOnlyProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/accessibility/screen_reader_only/screen_reader_only.tsx',
          name: 'EuiScreenReaderOnlyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/screen_reader_only/screen_reader_only.tsx',
          name: 'EuiScreenReaderOnlyProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['EuiScreenReaderOnlyProps']
  };
} catch (e) {}