/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component, createRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, htmlIdGenerator } from '../../services';
import { EuiLoadingSpinner } from '../loading';
import { EuiSpacer } from '../spacer';
import { EuiScreenReaderLive, EuiScreenReaderOnly } from '../accessibility';
import { EuiI18n } from '../i18n';
import { EuiSelectableSearch } from './selectable_search';
import { EuiSelectableMessage } from './selectable_message';
import { EuiSelectableList } from './selectable_list';
import { createPartialStringEqualityOptionMatcher, getMatchingOptions } from './matching_options';
import { euiSelectableStyles as styles } from './selectable.styles';

// The `searchable` prop has significant implications for a11y.
// When present, we effectively change from adhering
// to the ARIA `listbox` spec (https://www.w3.org/TR/wai-aria-practices-1.2/#Listbox)
// to the ARIA `combobox` spec (https://www.w3.org/TR/wai-aria-practices-1.2/#combobox)
// and (re)implement all relevant attributes and keyboard interactions.
// Take note of logic that relies on `searchable` to ensure that any
// modifications remain in alignment.
//
// `searchProps` can only be specified when `searchable` is true

export class EuiSelectable extends Component {
  static defaultProps = {
    options: [],
    singleSelection: false,
    searchable: false,
    isPreFiltered: false,
    optionMatcher: createPartialStringEqualityOptionMatcher()
  };
  inputRef = null;
  containerRef = createRef();
  optionsListRef = createRef();
  preventOnFocus = false;
  rootId;
  messageContentId;
  listId;
  constructor(props) {
    super(props);
    this.rootId = props.id ? suffix => `${props.id}${suffix ? `_${suffix}` : ''}` : htmlIdGenerator();
    this.listId = this.rootId('listbox');
    this.messageContentId = this.rootId('messageContent');
    const {
      options,
      singleSelection,
      isPreFiltered,
      searchProps
    } = props;
    const initialSearchValue = searchProps?.value || String(searchProps?.defaultValue || '');
    const visibleOptions = getMatchingOptions({
      options,
      searchValue: initialSearchValue,
      isPreFiltered: !!isPreFiltered,
      selectedOptions: [],
      optionMatcher: props.optionMatcher
    });
    searchProps?.onChange?.(initialSearchValue, visibleOptions);

    // ensure that the currently selected single option is active if it is in the visibleOptions
    const selectedOptions = options.filter(option => option.checked);
    let activeOptionIndex;
    if (singleSelection && selectedOptions.length === 1) {
      if (visibleOptions.includes(selectedOptions[0])) {
        activeOptionIndex = visibleOptions.indexOf(selectedOptions[0]);
      }
    }
    this.state = {
      activeOptionIndex,
      searchValue: initialSearchValue,
      visibleOptions,
      isFocused: false
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      options,
      isPreFiltered,
      searchProps,
      optionMatcher
    } = nextProps;
    const {
      activeOptionIndex,
      searchValue
    } = prevState;
    const stateUpdate = {
      searchValue,
      activeOptionIndex
    };
    if (searchProps?.value != null && searchProps.value !== searchValue) {
      stateUpdate.searchValue = searchProps.value;
    }
    stateUpdate.visibleOptions = getMatchingOptions({
      options,
      searchValue: stateUpdate.searchValue ?? '',
      isPreFiltered: !!isPreFiltered,
      selectedOptions: [],
      optionMatcher: optionMatcher
    });
    if (activeOptionIndex != null && activeOptionIndex >= stateUpdate.visibleOptions.length) {
      stateUpdate.activeOptionIndex = -1;
    }
    return stateUpdate;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeOptionIndex !== this.state.activeOptionIndex) {
      const activeOption = this.state.activeOptionIndex != null ? this.state.visibleOptions[this.state.activeOptionIndex] : null;
      this.props.onActiveOptionChange?.(activeOption);
    }
  }
  isFocusOnSearchOrListBox = target => {
    const searchHasFocus = this.props.searchable && target === this.inputRef;
    const listBox = this.optionsListRef.current?.listBoxRef?.parentElement;
    const listBoxContainsFocus = target instanceof Node && listBox?.contains(target);
    const listBoxHasFocus = target === listBox || listBoxContainsFocus;
    return searchHasFocus || listBoxHasFocus;
  };
  onMouseDown = () => {
    // Bypass onFocus when a click event originates from this.containerRef.
    // Prevents onFocus from scrolling away from a clicked option and negating the selection event.
    // https://github.com/elastic/eui/issues/4147
    this.preventOnFocus = true;
  };
  onFocus = event => {
    if (this.preventOnFocus) {
      this.preventOnFocus = false;
      return;
    }
    if (!this.state.visibleOptions.length || this.state.activeOptionIndex != null) {
      return;
    }
    if (event && !this.isFocusOnSearchOrListBox(event.target)) {
      return;
    }
    const firstSelected = this.state.visibleOptions.findIndex(option => option.checked && !option.disabled && !option.isGroupLabel);
    if (firstSelected > -1) {
      this.setState({
        activeOptionIndex: firstSelected,
        isFocused: true
      });
    } else {
      this.setState({
        activeOptionIndex: this.state.visibleOptions.findIndex(option => !option.disabled && !option.isGroupLabel),
        isFocused: true
      });
    }
  };
  onKeyDown = event => {
    const optionsList = this.optionsListRef.current;

    // Check if the user is interacting with something other than the
    // searchbox or selection list. If so, the user may be attempting to
    // interact with the search clear button or a totally custom button,
    // and listbox keyboard navigation/selection should not be triggered.
    if (!this.isFocusOnSearchOrListBox(event.target)) {
      this.setState({
        activeOptionIndex: undefined,
        isFocused: false
      });
      return;
    }
    switch (event.key) {
      case keys.ARROW_UP:
        event.preventDefault();
        event.stopPropagation();
        this.incrementActiveOptionIndex(-1);
        break;
      case keys.ARROW_DOWN:
        event.preventDefault();
        event.stopPropagation();
        this.incrementActiveOptionIndex(1);
        break;

      // For non-searchable instances, SPACE interaction should align with
      // the user expectation of selection toggling (e.g., input[type=checkbox]).
      // ENTER is also a valid selection mechanism in this case.
      case keys.ENTER:
      case keys.SPACE:
        if (this.props.searchable) {
          // For searchable instances, SPACE is reserved as a character for filtering
          // via the input box, and as such only ENTER will toggle selection.
          if (event.target === this.inputRef && event.key === keys.SPACE) {
            return;
          }
        }
        event.preventDefault();
        event.stopPropagation();
        if (this.state.activeOptionIndex != null && optionsList) {
          event.persist(); // NOTE: This is needed for React v16 backwards compatibility
          optionsList.onAddOrRemoveOption(this.state.visibleOptions[this.state.activeOptionIndex], event);
        }
        break;
      case keys.ALT:
      case keys.SHIFT:
      case keys.CTRL:
      case keys.META:
        break;
      default:
        this.setState({
          activeOptionIndex: undefined
        }, this.onFocus);
        break;
    }
  };
  incrementActiveOptionIndex = amount => {
    // If there are no options available, do nothing.
    if (!this.state.visibleOptions.length) {
      return;
    }
    this.setState(({
      activeOptionIndex,
      visibleOptions
    }) => {
      let nextActiveOptionIndex;
      if (activeOptionIndex == null) {
        // If this is the beginning of the user's keyboard navigation of the menu, then we'll focus
        // either the first or last item.
        nextActiveOptionIndex = amount < 0 ? visibleOptions.length - 1 : 0;
      } else {
        nextActiveOptionIndex = activeOptionIndex + amount;
        if (nextActiveOptionIndex < 0) {
          nextActiveOptionIndex = visibleOptions.length - 1;
        } else if (nextActiveOptionIndex === visibleOptions.length) {
          nextActiveOptionIndex = 0;
        }
      }

      // Group titles and disabled options are included in option list but are not selectable
      const direction = amount > 0 ? 1 : -1;
      while (visibleOptions[nextActiveOptionIndex].isGroupLabel || visibleOptions[nextActiveOptionIndex].disabled) {
        nextActiveOptionIndex = nextActiveOptionIndex + direction;
        if (nextActiveOptionIndex < 0) {
          nextActiveOptionIndex = visibleOptions.length - 1;
        } else if (nextActiveOptionIndex === visibleOptions.length) {
          nextActiveOptionIndex = 0;
        }
      }
      return {
        activeOptionIndex: nextActiveOptionIndex
      };
    });
  };
  onSearchChange = (searchValue, visibleOptions) => {
    this.setState({
      searchValue,
      visibleOptions,
      activeOptionIndex: undefined
    }, () => {
      if (this.state.isFocused) {
        this.onFocus();
      }
    });
    this.props.searchProps?.onChange?.(searchValue, visibleOptions);
  };
  onContainerBlur = e => {
    // Ignore blur events when moving from search to option to avoid activeOptionIndex conflicts
    if (this.isFocusOnSearchOrListBox(e.relatedTarget)) {
      return;
    }
    this.setState({
      activeOptionIndex: undefined,
      isFocused: false
    });
  };
  onOptionClick = (options, event, clickedOption) => {
    const {
      isPreFiltered,
      onChange,
      optionMatcher
    } = this.props;
    const {
      searchValue
    } = this.state;
    const visibleOptions = getMatchingOptions({
      options,
      searchValue: searchValue ?? '',
      isPreFiltered: !!isPreFiltered,
      selectedOptions: [],
      optionMatcher: optionMatcher
    });
    this.setState({
      visibleOptions
    });
    if (onChange) {
      onChange(options, event, clickedOption);
    }
  };
  scrollToItem = (index, align) => {
    this.optionsListRef.current?.listRef?.scrollToItem(index, align);
  };
  makeOptionId = index => index != null ? `${this.listId}_option-${index}` : '';
  render() {
    const {
      children,
      className,
      options,
      onChange,
      onActiveOptionChange,
      searchable,
      searchProps,
      singleSelection,
      isLoading,
      listProps,
      renderOption,
      height,
      allowExclusions,
      'aria-label': ariaLabel,
      'aria-describedby': ariaDescribedby,
      loadingMessage,
      noMatchesMessage,
      emptyMessage,
      errorMessage,
      selectableScreenReaderText,
      isPreFiltered,
      optionMatcher,
      ...rest
    } = this.props;
    const {
      searchValue,
      visibleOptions,
      activeOptionIndex
    } = this.state;

    // Some messy destructuring here to remove aria-label/describedby from searchProps and listProps
    // Made messier by some TS requirements
    // The aria attributes are then used in getAccessibleName() to place them where they need to go
    const unknownAccessibleName = {
      'aria-label': undefined,
      'aria-describedby': undefined
    };
    const {
      'aria-label': searchAriaLabel,
      'aria-describedby': searchAriaDescribedby,
      onChange: propsOnChange,
      defaultValue,
      // Because we control the underlying EuiFieldSearch value state with state.searchValue, we cannot pass a defaultValue prop without a React error
      inputRef,
      // We need to store the inputRef before passing it back to consuming applications
      ...cleanedSearchProps
    } = searchProps || unknownAccessibleName;
    const {
      'aria-label': listAriaLabel,
      'aria-describedby': listAriaDescribedby,
      isVirtualized,
      rowHeight,
      ...cleanedListProps
    } = listProps || unknownAccessibleName;
    let virtualizedProps;
    if (isVirtualized === false) {
      virtualizedProps = {
        isVirtualized
      };
    } else if (rowHeight != null) {
      virtualizedProps = {
        rowHeight
      };
    }
    const classes = classNames('euiSelectable', className);
    const cssStyles = [styles.euiSelectable, height === 'full' && styles.fullHeight];

    /** Create message content that replaces the list if no options are available (yet) */
    let messageContent;
    if (errorMessage != null) {
      messageContent = typeof errorMessage === 'string' ? <p>{errorMessage}</p> : errorMessage;
    } else if (isLoading) {
      if (loadingMessage === undefined || typeof loadingMessage === 'string') {
        messageContent = <>
            <EuiLoadingSpinner size="m" />
            <EuiSpacer size="xs" />
            <p>
              {loadingMessage || <EuiI18n token="euiSelectable.loadingOptions" default="Loading options" />}
            </p>
          </>;
      } else {
        messageContent = React.cloneElement(loadingMessage, {
          id: this.messageContentId,
          ...loadingMessage.props
        });
      }
    } else if (searchValue && visibleOptions.length === 0) {
      if (noMatchesMessage === undefined || typeof noMatchesMessage === 'string') {
        messageContent = <p>
            {noMatchesMessage || <EuiI18n token="euiSelectable.noMatchingOptions" default="{searchValue} doesn't match any options" values={{
            searchValue: <strong>{searchValue}</strong>
          }} />}
          </p>;
      } else {
        messageContent = React.cloneElement(noMatchesMessage, {
          id: this.messageContentId,
          ...noMatchesMessage.props
        });
      }
    } else if (!options.length) {
      if (emptyMessage === undefined || typeof emptyMessage === 'string') {
        messageContent = <p>
            {emptyMessage || <EuiI18n token="euiSelectable.noAvailableOptions" default="No options available" />}
          </p>;
      } else {
        messageContent = React.cloneElement(emptyMessage, {
          id: this.messageContentId,
          ...emptyMessage.props
        });
      }
    }

    /**
     * There are lots of ways to add an accessible name
     * Usually we want the same name for the input and the listbox (which is added by aria-label/describedby)
     * But you can always override it using searchProps or listProps
     * This finds the correct name to use
     *
     * TODO: This doesn't handle being labelled (<label for="idOfInput">)
     */
    const getAccessibleName = (props, messageContentId) => {
      if (props && props['aria-label']) {
        return {
          'aria-label': props['aria-label']
        };
      }
      const messageContentIdString = messageContentId ? ` ${messageContentId}` : '';
      if (props && props['aria-describedby']) {
        return {
          'aria-describedby': `${props['aria-describedby']}${messageContentIdString}`
        };
      }
      if (ariaLabel) {
        return {
          'aria-label': ariaLabel
        };
      }
      if (ariaDescribedby) {
        return {
          'aria-describedby': `${ariaDescribedby}${messageContentIdString}`
        };
      }
      return {};
    };
    const searchAccessibleName = getAccessibleName(searchProps, this.messageContentId);
    const searchHasAccessibleName = Boolean(Object.keys(searchAccessibleName).length);
    const search = searchable ? <EuiI18n tokens={['euiSelectable.screenReaderInstructions', 'euiSelectable.placeholderName']} defaults={['Use the Up and Down arrow keys to move focus over options. Press Enter to select. Press Escape to collapse options.', 'Filter options']}>
        {([screenReaderInstructions, placeholderName]) => <>
            <EuiSelectableSearch aria-describedby={listAriaDescribedbyId} key="listSearch" options={options} value={searchValue} onChange={this.onSearchChange} listId={this.optionsListRef.current ? this.listId : undefined} // Only pass the listId if it exists on the page
        aria-activedescendant={this.makeOptionId(activeOptionIndex)} // the current faux-focused option
        placeholder={placeholderName} isPreFiltered={!!isPreFiltered} optionMatcher={optionMatcher} inputRef={node => {
          this.inputRef = node;
          searchProps?.inputRef?.(node);
        }} {...searchHasAccessibleName ? searchAccessibleName : {
          'aria-label': placeholderName
        }} {...cleanedSearchProps} />

            <EuiScreenReaderOnly>
              <p id={listAriaDescribedbyId}>
                {selectableScreenReaderText} {screenReaderInstructions}
              </p>
            </EuiScreenReaderOnly>
          </>}
      </EuiI18n> : undefined;
    const resultsLength = visibleOptions.filter(option => !option.disabled).length;
    const listScreenReaderStatus = searchable && <EuiI18n token="euiSelectable.searchResults" default={({
      resultsLength
    }) => `${resultsLength} result${resultsLength === 1 ? '' : 's'} available`} values={{
      resultsLength
    }} />;
    const listAriaDescribedbyId = this.rootId('instructions');
    const listAccessibleName = getAccessibleName(listProps, listAriaDescribedbyId);
    const listHasAccessibleName = Boolean(Object.keys(listAccessibleName).length);
    const list = <EuiI18n token="euiSelectable.placeholderName" default="Filter options">
        {placeholderName => <>
            {searchable && <EuiScreenReaderLive isActive={messageContent != null || activeOptionIndex != null}>
                {messageContent || listScreenReaderStatus}
              </EuiScreenReaderLive>}

            {messageContent ? <EuiSelectableMessage data-test-subj="euiSelectableMessage" id={this.messageContentId} bordered={listProps && listProps.bordered}>
                {messageContent}
              </EuiSelectableMessage> : <EuiSelectableList data-test-subj="euiSelectableList" key="list" options={options} visibleOptions={visibleOptions} searchValue={searchValue} isPreFiltered={isPreFiltered} activeOptionIndex={activeOptionIndex} setActiveOptionIndex={(index, cb) => {
          this.setState({
            activeOptionIndex: index
          }, cb);
        }} onOptionClick={this.onOptionClick} singleSelection={singleSelection} ref={this.optionsListRef} renderOption={renderOption} height={height} allowExclusions={allowExclusions} searchable={searchable} makeOptionId={this.makeOptionId} listId={this.listId} {...listHasAccessibleName ? listAccessibleName : searchable && {
          'aria-label': placeholderName
        }} {...cleanedListProps} {...virtualizedProps} />}
          </>}
      </EuiI18n>;
    return <div ref={this.containerRef} css={cssStyles} className={classes} onKeyDown={this.onKeyDown} onBlur={this.onContainerBlur} onFocus={this.onFocus} onMouseDown={this.onMouseDown} {...rest}>
        {children && children(list, search)}
      </div>;
  }
}
EuiSelectable.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Hooks up a search box to filter the list (boolean)
       */
  searchable: PropTypes.oneOfType([PropTypes.oneOf([false]).isRequired, PropTypes.oneOf([true]).isRequired]).isRequired,
  /**
       * Passes props down to the `EuiFieldSearch`.
       * See #EuiSelectableSearchProps
       */
  searchProps: PropTypes.any,
  /**
       * Function that takes the `list` node and then
       * the `search` node (if `searchable` is applied)
       */
  children: PropTypes.func,
  /**
       * Array of EuiSelectableOption objects. See #EuiSelectableOptionProps
       */
  options: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Optional `boolean`.
       * Set to `true` to indicate object is just a grouping label, not a selectable item
       */
    isGroupLabel: PropTypes.oneOfType([PropTypes.oneOf([true]).isRequired, PropTypes.oneOf([false])]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    /**
       * Visible label of option.
       * Must be unique across items if `key` is not supplied
       */
    label: PropTypes.string,
    /**
       * Optionally change the searchable term by passing a different string other than the `label`.
       * Best used when creating a custom `optionRender` to separate the label from metadata but allowing to search on both
       */
    searchableLabel: PropTypes.string,
    /**
       * Must be unique across items.
       * Will be used to match options instead of `label`
       */
    key: PropTypes.string,
    /**
       * Leave `undefined` to indicate not selected. Pass a string of
       * 'on' to indicate inclusion, 'off' to indicate exclusion,
       * or 'mixed' to indicate inclusion for some.
       */
    checked: PropTypes.any,
    disabled: PropTypes.bool,
    /**
       * Node to add between the selection icon and the label
       */
    prepend: PropTypes.node,
    /**
       * Node to add to the far right of the item
       */
    append: PropTypes.node,
    ref: PropTypes.func,
    /**
       * Option data to pass through to the `renderOptions` element.
       * Bypass `EuiSelectableItem` and avoid DOM attribute warnings.
       */
    data: PropTypes.shape({}),
    /**
       * How to handle long text within the item.
       * Wrapping only works if `isVirtualization` is false.
       * @default 'truncate'
       */
    textWrap: PropTypes.oneOf(["truncate", "wrap"]),
    /**
       * If textWrap is set to `truncate`, you can pass a custom truncation configuration
       * that accepts any [EuiTextTruncate](/#/utilities/text-truncation) prop except for
       * `text` and `children`.
       *
       * Note: when searching, custom truncation props are ignored. The highlighted search
       * text will always take precedence.
       */
    truncationProps: PropTypes.any,
    /**
       * Optional custom tooltip content for the button
       */
    toolTipContent: PropTypes.node,
    /**
       * Optional props to pass to the underlying **[EuiToolTip](/#/display/tooltip)**
       */
    toolTipProps: PropTypes.any
  }).isRequired).isRequired,
  /**
       * Passes back the altered `options` array with selected options having `checked: 'on'`.
       * Also passes back the React click/keyboard event as a second argument,
       * and the option that triggered the onChange event as a third argument.
       */
  onChange: PropTypes.func,
  /**
       * Passes back the current active option whenever the user changes the currently
       * highlighted option via keyboard navigation or searching.
       */
  onActiveOptionChange: PropTypes.func,
  /**
       * Sets the single selection policy of
       * `false`: allows multiple selection
       * `true`: only allows one selection
       * `always`: can and must have only one selection
       */
  singleSelection: PropTypes.oneOfType([PropTypes.oneOf(["always"]), PropTypes.bool.isRequired]),
  /**
       * Allows marking options as `checked='off'` as well as `'on'`
       */
  allowExclusions: PropTypes.bool,
  /**
       * Show an loading indicator while you load and hook up your data
       */
  isLoading: PropTypes.bool,
  /**
       * Sets the max height in pixels or pass `full` to allow
       * the whole group to fill the height of its container and
       * allows the list grow as well
       */
  height: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf(["full"])]),
  /**
       * See #EuiSelectableOptionsList
       */
  listProps: PropTypes.any,
  /**
       * Custom render function for each option.
       * Returns `(option, searchValue)`
       */
  renderOption: PropTypes.func,
  /**
       * Customize the loading message. Pass a string to simply change the text,
       * or a node to replace the whole content.
       */
  loadingMessage: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),
  /**
       * Customize the no matches message. Pass a string to simply change the text,
       * or a node to replace the whole content.
       */
  noMatchesMessage: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),
  /**
       * Customize the empty message. Pass a string to simply change the text,
       * or a node to replace the whole content.
       */
  emptyMessage: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),
  /**
       * Add an error message.
       * The message will be shown when the value is not `null` or `undefined`.
       * Pass a string to simply change the text, or a node to replace the whole content.
       *
       * `errorMessage={hasErrors ? 'My error message' : null}`
       */
  errorMessage: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  /**
       * Control whether or not options get filtered internally (i.e., whether filtering is
       * handled by EUI or by you, the consumer).
       * If set to `true`, all passed `options` will be displayed regardless of the user's
       * search input.
       *
       * Additionally allows passing a configuration object which enables turning off
       * search highlighting if needed.
       *
       * @default false
       */
  isPreFiltered: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    highlightSearch: PropTypes.bool
  }).isRequired]),
  /**
       * Optional screen reader instructions to announce upon focus/interaction. This text is read out
       * after the `EuiSelectable` label and a brief pause, but before the default keyboard instructions for
       * interacting with a selectable are read out.
       */
  selectableScreenReaderText: PropTypes.string,
  /**
       * Optional custom option matcher function
       *
       * @example
       * const exactEqualityMatcher: EuiSelectableOptionMatcher = ({ option, searchValue }) => {
       *   return option.label === searchValue;
       * }
       */
  optionMatcher: PropTypes.func
};
try {
  EuiSelectable.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/selectable/selectable.tsx',
    description: '',
    displayName: 'EuiSelectable',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      searchable: {
        defaultValue: {
          value: 'false'
        },
        description: 'Hooks up a search box to filter the list (boolean)',
        name: 'searchable',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      searchProps: {
        defaultValue: null,
        description: 'Passes props down to the `EuiFieldSearch`.\n' + 'See #EuiSelectableSearchProps',
        name: 'searchProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<EuiSelectableSearchProps<T>>'
        }
      },
      children: {
        defaultValue: null,
        description: 'Function that takes the `list` node and then\n' + 'the `search` node (if `searchable` is applied)',
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(list: ReactElement<typeof EuiSelectableList | FunctionComponent<EuiSelectableMessageProps>, string | JSXElementConstructor<any>>, search: ReactElement<...>) => ReactNode'
        }
      },
      options: {
        defaultValue: {
          value: '[]'
        },
        description: 'Array of EuiSelectableOption objects. See #EuiSelectableOptionProps',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ExclusiveUnion<EuiSelectableGroupLabelOption<T>, EuiSelectableLIOption<T>>[]'
        }
      },
      onChange: {
        defaultValue: null,
        description: "Passes back the altered `options` array with selected options having `checked: 'on'`.\n" + 'Also passes back the React click/keyboard event as a second argument,\n' + 'and the option that triggered the onChange event as a third argument.',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(options: ExclusiveUnion<EuiSelectableGroupLabelOption<T>, EuiSelectableLIOption<T>>[], event: EuiSelectableOnChangeEvent, changedOption: ExclusiveUnion<...>) => void'
        }
      },
      onActiveOptionChange: {
        defaultValue: null,
        description: 'Passes back the current active option whenever the user changes the currently\n' + 'highlighted option via keyboard navigation or searching.',
        name: 'onActiveOptionChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(option: (DisambiguateSet<EuiSelectableGroupLabelOption<{}>, EuiSelectableLIOption<{}>> & CommonProps & { ...; } & HTMLAttributes<...>) | (DisambiguateSet<...> & ... 2 more ... & { ...; })) => void'
        }
      },
      singleSelection: {
        defaultValue: {
          value: 'false'
        },
        description: 'Sets the single selection policy of\n' + '`false`: allows multiple selection\n' + '`true`: only allows one selection\n' + '`always`: can and must have only one selection',
        name: 'singleSelection',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | "always"'
        }
      },
      allowExclusions: {
        defaultValue: null,
        description: "Allows marking options as `checked='off'` as well as `'on'`",
        name: 'allowExclusions',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Show an loading indicator while you load and hook up your data',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      height: {
        defaultValue: null,
        description: 'Sets the max height in pixels or pass `full` to allow\n' + 'the whole group to fill the height of its container and\n' + 'allows the list grow as well',
        name: 'height',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number | "full"'
        }
      },
      listProps: {
        defaultValue: null,
        description: 'See #EuiSelectableOptionsList',
        name: 'listProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiSelectableOptionsListPropsWithDefaults'
        }
      },
      renderOption: {
        defaultValue: null,
        description: 'Custom render function for each option.\n' + 'Returns `(option, searchValue)`',
        name: 'renderOption',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(option: ExclusiveUnion<EuiSelectableGroupLabelOption<T>, EuiSelectableLIOption<T>>, searchValue: string) => ReactNode'
        }
      },
      loadingMessage: {
        defaultValue: null,
        description: 'Customize the loading message. Pass a string to simply change the text,\n' + 'or a node to replace the whole content.',
        name: 'loadingMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | ReactElement'
        }
      },
      noMatchesMessage: {
        defaultValue: null,
        description: 'Customize the no matches message. Pass a string to simply change the text,\n' + 'or a node to replace the whole content.',
        name: 'noMatchesMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | ReactElement'
        }
      },
      emptyMessage: {
        defaultValue: null,
        description: 'Customize the empty message. Pass a string to simply change the text,\n' + 'or a node to replace the whole content.',
        name: 'emptyMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | ReactElement'
        }
      },
      errorMessage: {
        defaultValue: null,
        description: 'Add an error message.\n' + 'The message will be shown when the value is not `null` or `undefined`.\n' + 'Pass a string to simply change the text, or a node to replace the whole content.\n' + '\n' + "`errorMessage={hasErrors ? 'My error message' : null}`",
        name: 'errorMessage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | ReactElement'
        }
      },
      isPreFiltered: {
        defaultValue: {
          value: 'false'
        },
        description: 'Control whether or not options get filtered internally (i.e., whether filtering is\n' + 'handled by EUI or by you, the consumer).\n' + "If set to `true`, all passed `options` will be displayed regardless of the user's\n" + 'search input.\n' + '\n' + 'Additionally allows passing a configuration object which enables turning off\n' + 'search highlighting if needed.',
        name: 'isPreFiltered',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | { highlightSearch?: boolean; }'
        }
      },
      selectableScreenReaderText: {
        defaultValue: null,
        description: 'Optional screen reader instructions to announce upon focus/interaction. This text is read out\n' + 'after the `EuiSelectable` label and a brief pause, but before the default keyboard instructions for\n' + 'interacting with a selectable are read out.',
        name: 'selectableScreenReaderText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      optionMatcher: {
        defaultValue: {
          value: 'createPartialStringEqualityOptionMatcher()'
        },
        description: 'Optional custom option matcher function\n' + '@example const exactEqualityMatcher: EuiSelectableOptionMatcher = ({ option, searchValue }) => {\n' + '  return option.label === searchValue;\n' + '}',
        name: 'optionMatcher',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/selectable/selectable.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiSelectableOptionMatcher<T>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}