/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { euiHorizontalRuleStyles } from './horizontal_rule.styles';
export var SIZES = ['full', 'half', 'quarter'];
export var MARGINS = ['none', 'xs', 's', 'm', 'l', 'xl', 'xxl'];
export var EuiHorizontalRule = ({
  className,
  size = 'full',
  margin = 'l',
  ...rest
}) => {
  const classes = classNames('euiHorizontalRule', className);
  const styles = useEuiMemoizedStyles(euiHorizontalRuleStyles);
  const cssStyles = [styles.euiHorizontalRule, styles[size], styles[margin]];
  return <hr css={cssStyles} className={classes} {...rest} />;
};
EuiHorizontalRule.propTypes = {
  /**
     * Defines the width of the HR.
     */
  size: PropTypes.any,
  margin: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiHorizontalRule.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/horizontal_rule/horizontal_rule.tsx',
    description: '',
    displayName: 'EuiHorizontalRule',
    methods: [],
    props: {
      size: {
        defaultValue: {
          value: 'full'
        },
        description: 'Defines the width of the HR.',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/horizontal_rule/horizontal_rule.tsx',
          name: 'EuiHorizontalRuleProps'
        },
        declarations: [{
          fileName: 'eui/src/components/horizontal_rule/horizontal_rule.tsx',
          name: 'EuiHorizontalRuleProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"full" | "half" | "quarter"',
          value: [{
            value: '"full"'
          }, {
            value: '"half"'
          }, {
            value: '"quarter"'
          }]
        }
      },
      margin: {
        defaultValue: {
          value: 'l'
        },
        description: '',
        name: 'margin',
        parent: {
          fileName: 'eui/src/components/horizontal_rule/horizontal_rule.tsx',
          name: 'EuiHorizontalRuleProps'
        },
        declarations: [{
          fileName: 'eui/src/components/horizontal_rule/horizontal_rule.tsx',
          name: 'EuiHorizontalRuleProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl" | "xxl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }, {
            value: '"xxl"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiHorizontalRuleProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}