/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from "prop-types";
export var UseGeneratedHtmlIdProps = ({
  // They're unused in this mock component, but these props must be destructured for our
  // props generator to correctly read the comment docblocks associated with them
  prefix,
  suffix,
  conditionalId
}) => <div />;
UseGeneratedHtmlIdProps.propTypes = {
  /**
     * Optional prefix to prepend to the generated ID
     */
  prefix: PropTypes.string,
  /**
     * Optional suffix to append to the generated ID
     */
  suffix: PropTypes.string,
  /**
     * Optional conditional ID to use instead of a randomly generated ID.
     * Typically used by EUI components where IDs can be passed in as custom props
     */
  conditionalId: PropTypes.string
};
try {
  UseGeneratedHtmlIdProps.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/html_id_generator/use_generated_html_id_props.tsx',
    description: '',
    displayName: 'UseGeneratedHtmlIdProps',
    methods: [],
    props: {
      prefix: {
        defaultValue: null,
        description: 'Optional prefix to prepend to the generated ID',
        name: 'prefix',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/services/accessibility/html_id_generator.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      suffix: {
        defaultValue: null,
        description: 'Optional suffix to append to the generated ID',
        name: 'suffix',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/services/accessibility/html_id_generator.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      conditionalId: {
        defaultValue: null,
        description: 'Optional conditional ID to use instead of a randomly generated ID.\n' + 'Typically used by EUI components where IDs can be passed in as custom props',
        name: 'conditionalId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/services/accessibility/html_id_generator.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}