/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import escapeRegExp from 'lodash/escapeRegExp';
/**
 * Internal subcomponent with logic for highlighting all occurrences
 * of a search value within a subject
 *
 * Uses regex rather than indexOf/while loops for easier dev maintainability
 */
export var HighlightAll = ({
  searchSubject,
  searchValue: _searchValue,
  isStrict,
  highlightComponent: HighlightComponent = 'mark'
}) => {
  const searchValue = useMemo(() => {
    return Array.isArray(_searchValue) ? _searchValue.map(escapeRegExp).join('|') : escapeRegExp(_searchValue);
  }, [_searchValue]);
  const chunks = useMemo(() => {
    const regex = new RegExp(searchValue, isStrict ? 'g' : 'gi');
    const matches = [...searchSubject.matchAll(regex)].map(match => ({
      start: match.index || 0,
      end: (match.index || 0) + match[0].length
    }));
    return fillInChunks(matches, searchSubject.length);
  }, [searchValue, searchSubject, isStrict]);
  return <>
      {chunks.map(chunk => {
      const {
        end,
        highlight,
        start
      } = chunk;
      const value = searchSubject.substring(start, end);
      return highlight ? <HighlightComponent key={start}>{value}</HighlightComponent> : value;
    })}
    </>;
};

/**
 * Chunk utility
 */
HighlightAll.propTypes = {
  searchValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]).isRequired,
  searchSubject: PropTypes.string.isRequired,
  isStrict: PropTypes.bool.isRequired,
  highlightComponent: PropTypes.any
};
const fillInChunks = (chunksToHighlight, totalLength) => {
  const allChunks = [];
  const append = (start, end, highlight) => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight
      });
    }
  };
  if (chunksToHighlight.length === 0) {
    append(0, totalLength, false);
  } else {
    let lastIndex = 0;
    chunksToHighlight.forEach(chunk => {
      append(lastIndex, chunk.start, false);
      append(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, false);
  }
  return allChunks;
};
try {
  HighlightAll.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/highlight/_highlight_all.tsx',
    description: 'Internal subcomponent with logic for highlighting all occurrences\n' + 'of a search value within a subject\n' + '\n' + 'Uses regex rather than indexOf/while loops for easier dev maintainability',
    displayName: 'HighlightAll',
    methods: [],
    props: {
      searchValue: {
        defaultValue: null,
        description: '',
        name: 'searchValue',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string | string[]'
        }
      },
      searchSubject: {
        defaultValue: null,
        description: '',
        name: 'searchSubject',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode & string'
        }
      },
      isStrict: {
        defaultValue: null,
        description: '',
        name: 'isStrict',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      highlightComponent: {
        defaultValue: null,
        description: '',
        name: 'highlightComponent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/highlight/highlight.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ElementType<any, keyof IntrinsicElements>'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}