import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useRouteMatch } from 'react-router-dom';
import { EuiImage, EuiButton, EuiSpacer, EuiSwitch, EuiFlexGroup, EuiFlexItem, EuiHeader, EuiButtonGroup, EuiHeaderLinks, EuiHeaderSectionItem, EuiText } from '../../../../src';
import { useIsWithinBreakpoints } from '../../../../src/services';
import { useExitPath } from '../../services/routing/routing';
import { GuideSection } from '../../components/guide_section/guide_section';
import contentSvg from '../../images/content.svg';
import contentCenterSvg from '../../images/content_center.svg';
import sideNavSvg from '../../images/side_nav.svg';
import singleSvg from '../../images/single.svg';
import CustomTemplateExample from './page_template';
var CustomTemplateExampleSource = require('!!raw-loader!./page_template');
import { GuideSectionTypes } from '../../components/guide_section/guide_section_types';
var ExitFullscreenDemoButton = function ExitFullscreenDemoButton() {
  var exitPath = useExitPath();
  return ___EmotionJSX(EuiButton, {
    fill: true,
    href: exitPath,
    iconType: "exit"
  }, "Exit fullscreen");
};
export var PageDemo = ({
  fullscreen,
  props,
  slug,
  show = {
    sidebar: false
  },
  toggle = {
    pageHeader: false,
    restrictedWidth: false,
    sidebar: false,
    sidebarSticky: false,
    border: false,
    panelledSwitch: false,
    panelledGroup: false
  },
  template: Template = CustomTemplateExample,
  source = CustomTemplateExampleSource
}) => {
  const {
    path
  } = useRouteMatch();
  const isMobileSize = useIsWithinBreakpoints(['xs', 's']);
  const [showHeader, setShowHeader] = useState(true);
  const [showPanelled, setShowPanelled] = useState(toggle.panelledGroup ? undefined : true);
  const [showSidebar, setShowSidebar] = useState(Boolean(show.sidebar));
  const [showSidebarSticky, setShowSidebarSticky] = useState(fullscreen || false);

  // Restrict width combos
  const [restrictWidth, setRestrictWidth] = useState(true);
  const [bottomBorder, setBottomBorder] = useState(undefined);
  const button = fullscreen ? <ExitFullscreenDemoButton /> : <EuiButton fill href={`#${path}/${slug}`}>
      Go fullscreen
    </EuiButton>;
  const sideNav = <EuiImage size={isMobileSize ? 'original' : 'fullWidth'} alt="Fake side nav list" url={isMobileSize ? singleSvg : sideNavSvg} />;
  const content = <>
      <EuiImage size={'fullWidth'} alt="Fake paragraph" url={contentSvg} />
      <EuiSpacer />
      <EuiImage size="fullWidth" alt="Fake paragraph" url={contentSvg} />
    </>;
  const _emptyPrompt = show.emptyPrompt ? <EuiImage size={'l'} alt="Fake paragraph" url={contentCenterSvg} /> : undefined;
  const _bottomBar = show.bottomBar ? <EuiFlexGroup alignItems="center" justifyContent="spaceBetween" responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiText size="s">
          <p>10 selected</p>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton color="text">Share</EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup> : undefined;
  const pageHeaderProps = showHeader ? {
    iconType: 'logoElastic',
    pageTitle: 'Page title',
    rightSideItems: [button],
    description: toggle.restrictedWidth ? <>{`Restricting the width to ${restrictWidth}.`}</> : undefined,
    tabs: show.tabs ? [{
      label: 'Tab 1',
      isSelected: true
    }, {
      label: 'Tab 2'
    }] : undefined
  } : undefined;
  const controls = <EuiFlexGroup alignItems="center">
      {toggle.restrictedWidth && <EuiFlexItem grow={false}>
          <div>
            Restrict width:&emsp;
            <EuiButtonGroup options={[{
          id: 'radioTrue',
          label: 'true'
        }, {
          id: 'radioFalse',
          label: 'false'
        }, {
          id: 'radioPercent',
          label: '75%'
        }]} idSelected={restrictWidth === true ? 'radioTrue' : restrictWidth === false ? 'radioFalse' : 'radioPercent'} onChange={id => setRestrictWidth(id === 'radioTrue' ? true : id === 'radioFalse' ? false : '75%')} name="restrictedWidthGroup" legend={'Restricted width'} buttonSize="compressed" color="primary" />
          </div>
        </EuiFlexItem>}
      {toggle.border && <EuiFlexItem grow={false}>
          <div>
            Bottom border:&emsp;
            <EuiButtonGroup options={[{
          id: 'borderTrue',
          label: 'true'
        }, {
          id: 'borderFalse',
          label: 'false'
        }, {
          id: 'borderExtended',
          label: 'extended'
        }]} idSelected={bottomBorder === true || bottomBorder === undefined ? 'borderTrue' : bottomBorder === false ? 'borderFalse' : 'borderExtended'} onChange={id => {
          switch (id) {
            case 'borderExtended':
              setBottomBorder('extended');
              break;
            case 'borderFalse':
              setBottomBorder(false);
              break;
            default:
              setBottomBorder(true);
              break;
          }
        }} name="bottomBorderGroup" legend={'Bottom border'} buttonSize="compressed" color="primary" />
          </div>
        </EuiFlexItem>}
      {toggle.pageHeader && <EuiFlexItem grow={false}>
          <EuiSwitch label="Page header" checked={showHeader} onChange={() => setShowHeader(showing => !showing)} compressed />
        </EuiFlexItem>}
      {toggle.sidebar && <EuiFlexItem grow={false}>
          <EuiSwitch label="Sidebar" checked={showSidebar} onChange={() => setShowSidebar(showing => !showing)} compressed />
        </EuiFlexItem>}
      {toggle.sidebarSticky && <EuiFlexItem grow={false}>
          <EuiSwitch label="Sticky sidebar" checked={showSidebarSticky} onChange={() => setShowSidebarSticky(showing => !showing)} compressed />
        </EuiFlexItem>}
      {toggle.panelledSwitch && <EuiFlexItem grow={false}>
          <EuiSwitch label="Panelled" checked={!!showPanelled} onChange={() => setShowPanelled(showing => !showing)} compressed />
        </EuiFlexItem>}
      {toggle.panelledGroup && <EuiFlexItem grow={false}>
          <div>
            Panelled:&emsp;
            <EuiButtonGroup options={[{
          id: 'radioTrue',
          label: 'true'
        }, {
          id: 'radioFalse',
          label: 'false'
        }, {
          id: 'radioUndefined',
          label: 'undefined'
        }]} idSelected={showPanelled === true ? 'radioTrue' : showPanelled === false ? 'radioFalse' : 'radioUndefined'} onChange={id => setShowPanelled(id === 'radioTrue' ? true : id === 'radioFalse' ? false : undefined)} name="panelledGroup" legend="Panelled" buttonSize="compressed" color="primary" />
          </div>
        </EuiFlexItem>}
    </EuiFlexGroup>;
  const render = <Template button={button} content={content} sidebar={showSidebar ? sideNav : undefined} bottomBar={_bottomBar} header={pageHeaderProps} panelled={showPanelled} restrictWidth={restrictWidth} bottomBorder={bottomBorder} emptyPrompt={_emptyPrompt} sidebarSticky={showSidebarSticky} grow={fullscreen ? true : false} offset={fullscreen ? undefined : 0} />;
  return fullscreen ? <>
      <EuiHeader theme="dark" position="fixed" style={{
      color: 'white'
    }}>
        <EuiHeaderSectionItem>
          <EuiHeaderLinks popoverProps={{
          panelPaddingSize: 'l'
        }}>
            {controls}
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeader>
      {render}
    </> : <GuideSection nested demoPanelProps={{
    paddingSize: 'none',
    style: {
      overflow: 'hidden'
    }
  }} demo={<div className={'guideDemo__highlightLayout'}>{render}</div>} source={[{
    type: GuideSectionTypes.TSX,
    code: source
  }]} props={props} exampleToggles={controls} />;
};
PageDemo.propTypes = {
  fullscreen: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  show: PropTypes.shape({
    bottomBar: PropTypes.bool,
    emptyPrompt: PropTypes.bool,
    tabs: PropTypes.bool,
    sidebar: PropTypes.bool
  }),
  toggle: PropTypes.shape({
    pageHeader: PropTypes.bool,
    restrictedWidth: PropTypes.bool,
    sidebar: PropTypes.bool,
    sidebarSticky: PropTypes.bool,
    border: PropTypes.bool,
    // If a sidebar is present, a basic switch can be used
    panelledSwitch: PropTypes.bool,
    // If a sidebar is not present, `undefined` matters - use a group instead
    panelledGroup: PropTypes.bool
  }),
  template: PropTypes.any,
  source: PropTypes.any
};
try {
  PageDemo.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/page_template/_page_demo.tsx',
    description: '',
    displayName: 'PageDemo',
    methods: [],
    props: {
      props: {
        defaultValue: null,
        description: '',
        name: 'props',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'object'
        }
      },
      fullscreen: {
        defaultValue: null,
        description: '',
        name: 'fullscreen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/page_template/_page_demo.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      slug: {
        defaultValue: null,
        description: '',
        name: 'slug',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/page_template/_page_demo.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      show: {
        defaultValue: {
          value: '{\n    sidebar: false,\n  }'
        },
        description: '',
        name: 'show',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/page_template/_page_demo.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'showing'
        }
      },
      toggle: {
        defaultValue: {
          value: '{\n' + '    pageHeader: false,\n' + '    restrictedWidth: false,\n' + '    sidebar: false,\n' + '    sidebarSticky: false,\n' + '    border: false,\n' + '    panelledSwitch: false,\n' + '    panelledGroup: false,\n' + '  }'
        },
        description: '',
        name: 'toggle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/page_template/_page_demo.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'toggles'
        }
      },
      template: {
        defaultValue: null,
        description: '',
        name: 'template',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/page_template/_page_demo.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      source: {
        defaultValue: {
          value: "require('!!raw-loader!./page_template')"
        },
        description: '',
        name: 'source',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/page_template/_page_demo.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      }
    },
    extendedInterfaces: ['GuideSectionProps']
  };
} catch (e) {}