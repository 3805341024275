/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useCombinedRefs, useEuiMemoizedStyles } from '../../../services';
import { useEuiI18n } from '../../i18n';
import { EuiButtonIcon } from '../../button';
import { EuiFormControlLayout } from '../form_control_layout';
import { EuiValidatableControl } from '../validatable_control';
import { useFormContext } from '../eui_form_context';
import { euiFieldPasswordStyles } from './field_password.styles';
export var EuiFieldPassword = props => {
  const {
    defaultFullWidth
  } = useFormContext();
  const {
    className,
    id,
    name,
    placeholder,
    value,
    disabled,
    isInvalid,
    fullWidth = defaultFullWidth,
    isLoading = false,
    compressed = false,
    inputRef: _inputRef,
    prepend,
    append,
    type = 'password',
    dualToggleProps,
    ...rest
  } = props;

  // Set the initial input type to `password` if they want dual
  const [inputType, setInputType] = useState(type === 'dual' ? 'password' : type);

  // Setup toggle aria-label
  const [showPasswordLabel, maskPasswordLabel] = useEuiI18n(['euiFieldPassword.showPassword', 'euiFieldPassword.maskPassword'], ['Show password as plain text. Note: this will visually expose your password on the screen.', 'Mask password']);

  // Setup the inputRef to auto-focus when toggling visibility
  const [inputRef, _setInputRef] = useState(null);
  const setInputRef = useCombinedRefs([_setInputRef, _inputRef]);
  const handleToggle = useCallback((event, isVisible) => {
    setInputType(isVisible ? 'password' : 'text');
    inputRef?.focus();
    dualToggleProps?.onClick?.(event);
  }, [inputRef, dualToggleProps]);

  // Add a toggling button to switch between `password` and `input` if consumer wants `dual`
  // https://www.w3schools.com/howto/howto_js_toggle_password.asp
  const visibilityToggle = useMemo(() => {
    if (type === 'dual') {
      const isVisible = inputType === 'text';
      return <EuiButtonIcon iconType={isVisible ? 'eyeClosed' : 'eye'} aria-label={isVisible ? maskPasswordLabel : showPasswordLabel} title={isVisible ? maskPasswordLabel : showPasswordLabel} disabled={disabled} {...dualToggleProps} onClick={e => handleToggle(e, isVisible)} />;
    }
  }, [type, inputType, maskPasswordLabel, showPasswordLabel, dualToggleProps, handleToggle, disabled]);
  const finalAppend = useMemo(() => {
    if (!visibilityToggle) return append;
    if (!append) return visibilityToggle;

    // Convert any `append` elements to an array so the visibility
    // toggle can be added to it
    const appendAsArray = append ? Array.isArray(append) ? append : [append] : [];
    return [...appendAsArray, visibilityToggle];
  }, [append, visibilityToggle]);
  const classes = classNames('euiFieldPassword', {
    'euiFieldPassword-isLoading': isLoading
  }, className);
  const styles = useEuiMemoizedStyles(euiFieldPasswordStyles);
  const cssStyles = [styles.euiFieldPassword, compressed ? styles.compressed : styles.uncompressed, fullWidth ? styles.fullWidth : styles.formWidth, (finalAppend || prepend) && styles.inGroup, type === 'dual' && styles.withToggle];
  return <EuiFormControlLayout icon="lock" fullWidth={fullWidth} isLoading={isLoading} isInvalid={isInvalid} isDisabled={disabled} compressed={compressed} prepend={prepend} append={finalAppend}>
      <EuiValidatableControl isInvalid={isInvalid}>
        <input type={inputType} id={id} name={name} placeholder={placeholder} className={classes} css={cssStyles} value={value} disabled={disabled} ref={setInputRef} {...rest} />
      </EuiValidatableControl>
    </EuiFormControlLayout>;
};
EuiFieldPassword.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  isInvalid: PropTypes.bool,
  /**
       * Expand to fill 100% of the parent.
       * Defaults to `fullWidth` prop of `<EuiForm>`.
       * @default false
       */
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  compressed: PropTypes.bool,
  inputRef: PropTypes.any,
  /**
       * Creates an input group with element(s) coming before input.
       * `string` | `ReactElement` or an array of these
       */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
       * Creates an input group with element(s) coming after input.
       * `string` | `ReactElement` or an array of these
       */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  /**
       * Change the `type` of input for manually handling obfuscation.
       * The `dual` option adds the ability to toggle the obfuscation of the input by
       * adding an icon button as the first `append` element
       * @default password
       */
  type: PropTypes.oneOf(["password", "text", "dual"]),
  /**
       * Additional props to apply to the dual toggle. Extends EuiButtonIcon
       */
  dualToggleProps: PropTypes.any
};
try {
  EuiFieldPassword.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/field_password/field_password.tsx',
    description: '',
    displayName: 'EuiFieldPassword',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLInputElement>'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      type: {
        defaultValue: {
          value: 'password'
        },
        description: 'Change the `type` of input for manually handling obfuscation.\n' + 'The `dual` option adds the ability to toggle the obfuscation of the input by\n' + 'adding an icon button as the first `append` element',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "password" | "dual"',
          value: [{
            value: '"text"'
          }, {
            value: '"password"'
          }, {
            value: '"dual"'
          }]
        }
      },
      dualToggleProps: {
        defaultValue: null,
        description: 'Additional props to apply to the dual toggle. Extends EuiButtonIcon',
        name: 'dualToggleProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/field_password/field_password.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<EuiButtonIconPropsForButton>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'InputHTMLAttributes', 'CommonProps']
  };
} catch (e) {}