/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { keys, tabularCopyMarkers, useEuiMemoizedStyles } from '../../../../services';
import { DataGridFocusContext } from '../../utils/focus';
import { HandleInteractiveChildren } from '../cell/focus_utils';
import { euiDataGridHeaderCellWrapperStyles } from './data_grid_header_cell_wrapper.styles';

/**
 * This is a wrapper that handles repeated concerns between control &
 * standard header cells. Most of its shared logic is around focus state/UX,
 * but it also DRY's out certain class/data-test-subj/style attributes
 */
export var EuiDataGridHeaderCellWrapper = ({
  id,
  index,
  visibleColCount,
  width,
  className,
  children,
  hasActionsPopover,
  openActionsPopover,
  'aria-label': ariaLabel,
  ...rest
}) => {
  const classes = classnames('euiDataGridHeaderCell', className);
  const styles = useEuiMemoizedStyles(euiDataGridHeaderCellWrapperStyles);

  // Must be a state and not a ref to trigger a HandleInteractiveChildren rerender
  const [headerEl, setHeaderEl] = useState(null);
  const [renderFocusTrap, setRenderFocusTrap] = useState(false);
  const [interactiveChildren, setInteractiveChildren] = useState([]);
  useEffect(() => {
    // We're checking for interactive children outside of the default actions button
    setRenderFocusTrap(interactiveChildren.length > (hasActionsPopover ? 1 : 0));
  }, [hasActionsPopover, interactiveChildren]);
  const {
    setFocusedCell,
    onFocusUpdate
  } = useContext(DataGridFocusContext);
  const updateCellFocusContext = useCallback(() => {
    setFocusedCell([index, -1]);
  }, [index, setFocusedCell]);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    onFocusUpdate([index, -1], isFocused => {
      setIsFocused(isFocused);
      if (isFocused && headerEl) {
        // Only focus the cell if not already focused on something in the cell
        if (!headerEl.contains(document.activeElement)) {
          headerEl.focus();
        }
      }
    });
  }, [index, onFocusUpdate, headerEl]);

  // For cell headers with only actions, auto-open the actions popover on enter keypress
  const onKeyDown = useCallback(e => {
    if (e.key === keys.ENTER && hasActionsPopover && !renderFocusTrap && e.target === headerEl) {
      openActionsPopover?.();
    }
  }, [hasActionsPopover, openActionsPopover, renderFocusTrap, headerEl]);
  const isLastColumn = index === visibleColCount - 1;
  return <div role="columnheader" ref={setHeaderEl} tabIndex={isFocused ? 0 : -1} onKeyDown={onKeyDown} css={styles.euiDataGridHeaderCell} className={classes} data-test-subj={`dataGridHeaderCell-${id}`} data-gridcell-column-id={id} data-gridcell-column-index={index} data-gridcell-row-index="-1" data-gridcell-visible-row-index="-1" style={width != null ? {
    width: `${width}px`
  } : {}} aria-label={renderFocusTrap ? ariaLabel : undefined} {...rest}>
      <HandleInteractiveChildren cellEl={headerEl} updateCellFocusContext={updateCellFocusContext} renderFocusTrap={renderFocusTrap} onInteractiveChildrenFound={setInteractiveChildren}>
        {typeof children === 'function' ? children(renderFocusTrap) : children}
      </HandleInteractiveChildren>
      {isLastColumn ? tabularCopyMarkers.hiddenNewline : tabularCopyMarkers.hiddenTab}
    </div>;
};
EuiDataGridHeaderCellWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired]).isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  visibleColCount: PropTypes.number.isRequired,
  width: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf([null])]),
  className: PropTypes.string,
  "aria-label": PropTypes.any,
  hasActionsPopover: PropTypes.bool,
  openActionsPopover: PropTypes.func
};
try {
  EuiDataGridHeaderCellWrapper.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/datagrid/body/header/data_grid_header_cell_wrapper.tsx',
    description: 'This is a wrapper that handles repeated concerns between control &\n' + 'standard header cells. Most of its shared logic is around focus state/UX,\n' + "but it also DRY's out certain class/data-test-subj/style attributes",
    displayName: 'EuiDataGridHeaderCellWrapper',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      index: {
        defaultValue: null,
        description: '',
        name: 'index',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      visibleColCount: {
        defaultValue: null,
        description: '',
        name: 'visibleColCount',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      width: {
        defaultValue: null,
        description: '',
        name: 'width',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      hasActionsPopover: {
        defaultValue: null,
        description: '',
        name: 'hasActionsPopover',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      openActionsPopover: {
        defaultValue: null,
        description: '',
        name: 'openActionsPopover',
        parent: {
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        },
        declarations: [{
          fileName: 'eui/src/components/datagrid/data_grid_types.ts',
          name: 'EuiDataGridHeaderCellWrapperProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      }
    },
    extendedInterfaces: ['EuiDataGridHeaderCellWrapperProps']
  };
} catch (e) {}