import React from 'react';
import PropTypes from "prop-types";
import { EuiSpacer, EuiTitle, EuiText, EuiBetaBadge } from '../../../../../src/components';
export var LANGUAGES = ['javascript', 'html'];
export var GuideSectionExampleText = ({
  title,
  id,
  isBeta,
  isNew,
  children,
  wrapText = true
}) => {
  let titleNode;
  if (title) {
    const badge = (isBeta || isNew) && <EuiBetaBadge label={isBeta ? 'Beta' : 'New'} color="accent" size="s" />;
    titleNode = <>
        <EuiTitle>
          <h2 id={id}>
            {title}
            {badge && <>&emsp;{badge}</>}
          </h2>
        </EuiTitle>
        <EuiSpacer size="m" />
      </>;
  }
  let textNode = children;
  if (children && wrapText) {
    textNode = <EuiText>{children}</EuiText>;
  }
  return <>
      {titleNode}
      {textNode}
    </>;
};
GuideSectionExampleText.propTypes = {
  title: PropTypes.node,
  id: PropTypes.string,
  isBeta: PropTypes.bool,
  isNew: PropTypes.bool,
  children: PropTypes.node,
  wrapText: PropTypes.bool
};
try {
  GuideSectionExampleText.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
    description: '',
    displayName: 'GuideSectionExampleText',
    methods: [],
    props: {
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isBeta: {
        defaultValue: null,
        description: '',
        name: 'isBeta',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isNew: {
        defaultValue: null,
        description: '',
        name: 'isNew',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      wrapText: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'wrapText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}