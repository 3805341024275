/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { euiNotificationBadgeStyles } from './badge_notification.styles';
export var COLORS = ['accent', 'subdued', 'success'];
export var SIZES = ['s', 'm'];
export var EuiNotificationBadge = ({
  children,
  className,
  size = 's',
  color = 'accent',
  ...rest
}) => {
  const classes = classNames('euiNotificationBadge', className);
  const styles = useEuiMemoizedStyles(euiNotificationBadgeStyles);
  const cssStyles = [styles.euiNotificationBadge, styles[size], styles[color]];
  return <span css={cssStyles} className={classes} {...rest}>
      {children}
    </span>;
};
EuiNotificationBadge.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  size: PropTypes.any,
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiNotificationBadge.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/badge/notification_badge/badge_notification.tsx',
    description: '',
    displayName: 'EuiNotificationBadge',
    methods: [],
    props: {
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'EuiNotificationBadgeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'EuiNotificationBadgeProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      size: {
        defaultValue: {
          value: 's'
        },
        description: '',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'EuiNotificationBadgeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'EuiNotificationBadgeProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'accent'
        },
        description: '',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'EuiNotificationBadgeProps'
        },
        declarations: [{
          fileName: 'eui/src/components/badge/notification_badge/badge_notification.tsx',
          name: 'EuiNotificationBadgeProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"accent" | "success" | "subdued"',
          value: [{
            value: '"accent"'
          }, {
            value: '"success"'
          }, {
            value: '"subdued"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiNotificationBadgeProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}