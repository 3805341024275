/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiButton } from '../../button';
import { EuiI18n } from '../../i18n';
import { EuiToolTip } from '../../tool_tip';
import { EuiHideFor, EuiShowFor } from '../../responsive';
export class EuiSuperUpdateButton extends Component {
  static defaultProps = {
    needsUpdate: false,
    isLoading: false,
    isDisabled: false,
    showTooltip: false,
    responsive: ['xs', 's'],
    fill: true
  };
  _isMounted = false;
  tooltipTimeout;
  tooltip = null;
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentDidUpdate() {
    if (this.props.showTooltip && this.props.needsUpdate && !this.props.isDisabled && !this.props.isLoading) {
      this.showTooltip();
      this.tooltipTimeout = setTimeout(() => {
        this.hideTooltip();
      }, 2000);
    }
  }
  setTootipRef = node => {
    this.tooltip = node;
  };
  showTooltip = () => {
    if (!this._isMounted || !this.tooltip) {
      return;
    }
    this.tooltip.showToolTip();
  };
  hideTooltip = () => {
    if (!this._isMounted || !this.tooltip) {
      return;
    }
    this.tooltip.hideToolTip();
  };
  render() {
    const {
      children,
      className,
      needsUpdate,
      isLoading,
      isDisabled,
      onClick,
      toolTipProps,
      showTooltip,
      iconOnly,
      responsive: _responsive,
      textProps: restTextProps,
      fill,
      ...rest
    } = this.props;
    // Force responsive for "all" if `iconOnly = true`
    const responsive = iconOnly ? 'all' : _responsive;
    const classes = classNames('euiSuperUpdateButton', className);
    const sharedButtonProps = {
      color: needsUpdate || isLoading ? 'success' : 'primary',
      iconType: needsUpdate || isLoading ? 'kqlFunction' : 'refresh',
      isDisabled: isDisabled,
      onClick: onClick,
      isLoading: isLoading
    };
    const buttonContent = this.renderButtonContent();
    return <EuiToolTip ref={this.setTootipRef} content={this.renderTooltipContent()} position="bottom" {...toolTipProps}>
        <>
          <EuiShowFor sizes={responsive || 'none'}>
            <EuiButton className={classes} minWidth={false} {...sharedButtonProps} fill={fill} textProps={{
            ...restTextProps,
            className: classNames('euiScreenReaderOnly', restTextProps && restTextProps.className)
          }} {...rest}>
              {buttonContent}
            </EuiButton>
          </EuiShowFor>
          <EuiHideFor sizes={responsive || 'none'}>
            <EuiButton className={classes} minWidth={118} {...sharedButtonProps} fill={fill} textProps={restTextProps} {...rest}>
              {buttonContent}
            </EuiButton>
          </EuiHideFor>
        </>
      </EuiToolTip>;
  }
  renderButtonContent() {
    const {
      children,
      isLoading,
      needsUpdate
    } = this.props;
    if (children) {
      return children;
    }
    if (isLoading) {
      return <EuiI18n token="euiSuperUpdateButton.updatingButtonLabel" default="Updating" />;
    }
    if (needsUpdate) {
      return <EuiI18n token="euiSuperUpdateButton.updateButtonLabel" default="Update" />;
    }
    return <EuiI18n token="euiSuperUpdateButton.refreshButtonLabel" default="Refresh" />;
  }
  renderTooltipContent() {
    if (this.props.isDisabled) {
      return <EuiI18n token="euiSuperUpdateButton.cannotUpdateTooltip" default="Cannot update" />;
    }
    if (this.props.needsUpdate && !this.props.isLoading) {
      return <EuiI18n token="euiSuperUpdateButton.clickToApplyTooltip" default="Click to apply" />;
    }
  }
}
EuiSuperUpdateButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  needsUpdate: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  /**
     * Overrides the default button label with a custom React node.
     *
     * When defined, you're responsible for updating the custom label
     * when the data needs updating (the `needsUpdate` prop)
     * or is loading (the `isLoading` prop).
     */
  children: PropTypes.node,
  /**
     * Show the "Click to apply" tooltip
     */
  showTooltip: PropTypes.bool,
  /**
     * Passes props to `EuiToolTip`
     */
  toolTipProps: PropTypes.any,
  /**
     * Returns an IconButton instead
     */
  iconOnly: PropTypes.bool,
  /**
     * Forces state to be `iconOnly` when within provided breakpoints.
     * Remove completely with `false` or provide your own list of breakpoints.
     */
  responsive: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.arrayOf(PropTypes.any.isRequired).isRequired])
};
try {
  EuiSuperUpdateButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
    description: '',
    displayName: 'EuiSuperUpdateButton',
    methods: [],
    props: {
      isDisabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isDisabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      needsUpdate: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'needsUpdate',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      children: {
        defaultValue: null,
        description: 'Overrides the default button label with a custom React node.\n' + '\n' + "When defined, you're responsible for updating the custom label\n" + 'when the data needs updating (the `needsUpdate` prop)\n' + 'or is loading (the `isLoading` prop).',
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      showTooltip: {
        defaultValue: {
          value: 'false'
        },
        description: 'Show the "Click to apply" tooltip',
        name: 'showTooltip',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: 'Passes props to `EuiToolTip`',
        name: 'toolTipProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<EuiToolTipProps>'
        }
      },
      iconOnly: {
        defaultValue: null,
        description: 'Returns an IconButton instead',
        name: 'iconOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      responsive: {
        defaultValue: {
          value: "['xs', 's']"
        },
        description: 'Forces state to be `iconOnly` when within provided breakpoints.\n' + 'Remove completely with `false` or provide your own list of breakpoints.',
        name: 'responsive',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/super_update_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'false | string[]'
        }
      },
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: null,
        description: 'Use size `s` in confined spaces',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      element: {
        defaultValue: null,
        description: '',
        name: 'element',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"a" | "button" | "span"',
          value: [{
            value: '"a"'
          }, {
            value: '"button"'
          }, {
            value: '"span"'
          }]
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: 'Extends the button to 100% width',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minWidth: {
        defaultValue: null,
        description: 'Override the default minimum width',
        name: 'minWidth',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'false | MinWidth<string | number>'
        }
      },
      contentProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the button's content",
        name: 'contentProps',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      iconType: {
        defaultValue: null,
        description: 'Any `type` accepted by EuiIcon',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      iconSide: {
        defaultValue: null,
        description: 'Can only be one side `left` or `right`',
        name: 'iconSide',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ButtonContentIconSide',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      textProps: {
        defaultValue: null,
        description: "Object of props passed to the `<span>` wrapping the content's text/children only (not icon)\n" + '\n' + 'This span wrapper can be removed by passing `textProps={false}`.',
        name: 'textProps',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: "false | (HTMLAttributes<HTMLSpanElement> & CommonProps & { ref?: Ref<HTMLSpanElement>; 'data-text'?: string; })"
        }
      },
      iconSize: {
        defaultValue: null,
        description: '',
        name: 'iconSize',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      fill: {
        defaultValue: {
          value: 'true'
        },
        description: 'Make button a solid color for prominence',
        name: 'fill',
        parent: {
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['BaseProps', 'EuiButtonDisplayCommonProps', 'CommonProps', 'EuiButtonDisplayContentProps']
  };
} catch (e) {}