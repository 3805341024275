/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useCombinedRefs, useEuiMemoizedStyles } from '../../../services';
import { EuiIcon } from '../../icon';
import { euiCheckboxStyles } from './checkbox.styles';
export var EuiCheckbox = ({
  className,
  id,
  checked = false,
  label,
  onChange,
  type,
  disabled = false,
  readOnly = false,
  indeterminate = false,
  inputRef,
  labelProps,
  ...rest
}) => {
  const classes = classNames('euiCheckbox', className);
  const styles = useEuiMemoizedStyles(euiCheckboxStyles);
  const inputStyles = [styles.input.euiCheckbox__square, !!label && styles.input.hasLabel, disabled ? checked || indeterminate ? styles.input.disabled.selected : styles.input.disabled.unselected : checked || indeterminate ? styles.input.enabled.selected : styles.input.enabled.unselected, readOnly && styles.input.readOnly];
  const labelClasses = classNames('euiCheckbox__label', labelProps?.className);
  const labelStyles = [styles.label.euiCheckbox__label, disabled ? styles.label.disabled : styles.label.enabled, readOnly && styles.label.readOnly, labelProps?.css];
  const iconStyles = [styles.input.icon.euiCheckbox__icon, indeterminate ? styles.input.icon.indeterminate : styles.input.icon.check];

  // @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes
  const setIndeterminateState = useCallback(input => {
    if (input) input.indeterminate = indeterminate;
  }, [indeterminate]);
  const refs = useCombinedRefs([inputRef, setIndeterminateState]);
  return <div css={styles.euiCheckbox} className={classes}>
      <div css={inputStyles} className="euiCheckbox__square">
        <EuiIcon css={iconStyles} type={indeterminate ? 'stopFilled' : checked ? 'check' : 'empty'} />
        <input css={styles.input.euiCheckbox__input} className="euiCheckbox__input" type="checkbox" id={id} checked={checked} onChange={onChange} disabled={disabled} readOnly={readOnly} ref={refs} {...rest} />
      </div>

      {label && <label {...labelProps} css={labelStyles} className={labelClasses} htmlFor={id}>
          {label}
        </label>}
    </div>;
};
EuiCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.any.isRequired,
  // overriding to make it required
  inputRef: PropTypes.func,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  /**
     * Object of props passed to the <label/>
     */
  labelProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiCheckbox.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/checkbox/checkbox.tsx',
    description: '',
    displayName: 'EuiCheckbox',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      checked: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'checked',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: true,
        type: {
          name: 'ChangeEventHandler<HTMLInputElement>'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: false,
        type: {
          name: '(element: HTMLInputElement) => void'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      disabled: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      indeterminate: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'indeterminate',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      labelProps: {
        defaultValue: null,
        description: 'Object of props passed to the <label/>',
        name: 'labelProps',
        parent: {
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/checkbox/checkbox.tsx',
          name: 'EuiCheckboxProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & LabelHTMLAttributes<HTMLLabelElement>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiCheckboxProps', 'CommonProps', 'InputHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}