/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import { useEuiTour } from './useEuiTour';
import PropTypes from "prop-types";
export var EuiTour = ({
  children,
  steps,
  initialState
}) => {
  const [stepProps, actions, state] = useEuiTour(steps, initialState);
  return children(stepProps, actions, state);
};
EuiTour.propTypes = {
  children: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  initialState: PropTypes.shape({
    currentTourStep: PropTypes.number.isRequired,
    isTourActive: PropTypes.bool.isRequired,
    tourPopoverWidth: PropTypes.number.isRequired,
    tourSubtitle: PropTypes.string.isRequired
  }).isRequired
};
try {
  EuiTour.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tour/tour.tsx',
    description: '',
    displayName: 'EuiTour',
    methods: [],
    props: {
      steps: {
        defaultValue: null,
        description: '',
        name: 'steps',
        parent: {
          fileName: 'eui/src/components/tour/tour.tsx',
          name: 'EuiTourProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tour/tour.tsx',
          name: 'EuiTourProps'
        }],
        required: true,
        type: {
          name: 'EuiStatelessTourSteps'
        }
      },
      initialState: {
        defaultValue: null,
        description: '',
        name: 'initialState',
        parent: {
          fileName: 'eui/src/components/tour/tour.tsx',
          name: 'EuiTourProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tour/tour.tsx',
          name: 'EuiTourProps'
        }],
        required: true,
        type: {
          name: 'EuiTourState'
        }
      }
    },
    extendedInterfaces: ['EuiTourProps']
  };
} catch (e) {}