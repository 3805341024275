/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo, useEffect } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import range from 'lodash/range';
import { useEuiTheme, isEvenlyDivisibleBy } from '../../../services';
import { EuiRangeLevels } from './range_levels';
import { EuiRangeTicks } from './range_ticks';
import { euiRangeTrackStyles } from './range_track.styles';
export var EuiRangeTrack = ({
  children,
  trackWidth,
  disabled,
  max,
  min,
  step,
  showTicks,
  tickInterval,
  ticks,
  levels,
  onChange,
  value,
  compressed,
  showRange,
  className,
  ...rest
}) => {
  useEffect(() => {
    validateValueIsInStep(max, {
      min,
      max,
      step
    });
  }, [value, min, max, step]);
  const tickSequence = useMemo(() => {
    if (showTicks !== true) return;
    let sequence;
    if (ticks) {
      // If custom values were passed, use those for the sequence
      // But make sure they align with the possible sequence
      sequence = ticks.map(tick => {
        return validateValueIsInStep(tick.value, {
          min,
          max,
          step
        });
      });
    } else {
      // If a custom interval was passed, use those for the sequence
      // But make sure they align with the possible sequence
      const interval = tickInterval || step;

      // Calculate sequence - loop from min to max, creating adding values at each interval
      const sequenceRange = range(min, max, interval);
      // range is non-inclusive of max, so make it inclusive
      if (max % interval === 0 && !sequenceRange.includes(max)) {
        sequenceRange.push(max);
      }
      sequence = sequenceRange.map(tick => {
        return validateValueIsInStep(tick, {
          min,
          max,
          step
        });
      });
    }

    // Error out if there are too many ticks to render
    if (trackWidth && sequence.length) {
      validateTickRenderCount(trackWidth, sequence.length);
    }
    return sequence;
  }, [showTicks, ticks, min, max, tickInterval, step, trackWidth]);
  const euiTheme = useEuiTheme();
  const styles = euiRangeTrackStyles(euiTheme);
  const cssStyles = [styles.euiRangeTrack, disabled && styles.disabled, levels && !!levels.length && styles.hasLevels, showTicks && (tickSequence || ticks) && styles.hasTicks];
  const classes = classNames('euiRangeTrack', className);
  return <div className={classes} css={cssStyles} {...rest}>
      {levels && !!levels.length && <EuiRangeLevels levels={levels} max={max} min={min} showTicks={showTicks} showRange={showRange} trackWidth={trackWidth} />}
      {tickSequence && <EuiRangeTicks disabled={disabled} compressed={compressed} onChange={onChange} ticks={ticks} tickInterval={tickInterval || step} tickSequence={tickSequence} value={value} min={min} max={max} trackWidth={trackWidth} />}
      {children}
    </div>;
};
EuiRangeTrack.propTypes = {
  trackWidth: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired, PropTypes.any.isRequired]),
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  /**
     * The number to increment or decrement between each interval
     * @default 1
     */
  step: PropTypes.number,
  /**
     * Specified ticks at specified values
     */
  ticks: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.node.isRequired,
    accessibleLabel: PropTypes.string
  }).isRequired),
  /**
     * Modifies the number of tick marks and at what interval
     */
  tickInterval: PropTypes.number,
  /**
     * Create colored indicators for certain intervals.
     * An array of #EuiRangeLevel objects
     */
  levels: PropTypes.arrayOf(PropTypes.shape({
    /**
       * Accepts one of `["primary", "success", "warning", "danger"]` or a valid CSS color value.
       */
    color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired)
};
const validateValueIsInStep = (value, {
  min,
  max,
  step
}) => {
  if (value < min) {
    throw new Error(`The value of ${value} is lower than the min value of ${min}.`);
  }
  if (value > max) {
    throw new Error(`The value of ${value} is higher than the max value of ${max}.`);
  }
  // Error out if the value doesn't line up with the sequence of steps
  if (!isEvenlyDivisibleBy(value - min, step !== undefined ? step : 1)) {
    throw new Error(`The value of ${value} is not included in the possible sequence provided by the step of ${step}.`);
  }
  // Return the value if nothing fails
  return value;
};
const validateTickRenderCount = (trackWidth, tickCount) => {
  const tickWidth = trackWidth / tickCount;

  // These widths are guesstimations - it's possible we should use actual label content/widths instead
  const COMFORTABLE_TICK_WIDTH = 20; // Set a warning threshold before throwing
  const MIN_TICK_WIDTH = 5; // If ticks are smaller than this, something's gone seriously wrong and we should throw

  const message = `The number of ticks to render (${tickCount}) is too high for the range width. Ensure all ticks are visible on the page at multiple screen widths, or use EUI's breakpoint hook utilities to reduce the tick interval responsively.`;
  if (tickWidth <= MIN_TICK_WIDTH) {
    throw new Error(message);
  } else if (tickWidth < COMFORTABLE_TICK_WIDTH) {
    console.warn(message);
  }
};
try {
  EuiRangeTrack.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_track.tsx',
    description: '',
    displayName: 'EuiRangeTrack',
    methods: [],
    props: {
      trackWidth: {
        defaultValue: null,
        description: '',
        name: 'trackWidth',
        parent: {
          fileName: 'eui/src/components/form/range/range_track.tsx',
          name: 'EuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_track.tsx',
          name: 'EuiRangeTrackProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/range_track.tsx',
          name: 'EuiRangeTrackProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_track.tsx',
          name: 'EuiRangeTrackProps'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangesValues'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangesValues'
        }],
        required: false,
        type: {
          name: 'string | number | [string | number, string | number]'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number to increment or decrement between each interval',
        name: 'step',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      ticks: {
        defaultValue: null,
        description: 'Specified ticks at specified values',
        name: 'ticks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeTick[]'
        }
      },
      tickInterval: {
        defaultValue: null,
        description: 'Modifies the number of tick marks and at what interval',
        name: 'tickInterval',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      levels: {
        defaultValue: null,
        description: 'Create colored indicators for certain intervals.\n' + 'An array of #EuiRangeLevel objects',
        name: 'levels',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeDataStructures'
        }],
        required: false,
        type: {
          name: 'EuiRangeLevel[]'
        }
      },
      showRange: {
        defaultValue: null,
        description: 'Shows a thick line from min to value',
        name: 'showRange',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: 'Disables both the range track and any input(s)',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'Only impacts ticks rendered by `showTicks` or inputs rendered by `showInput`.',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiRangeTrackProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', '_SharedRangesValues', '_SharedRangeValuesProps', '_SharedRangeDataStructures', '_SharedRangeVisualConfiguration', '_SharedRangeInputProps']
  };
} catch (e) {}