/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { memo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { EuiIcon } from '../icon';
import { euiTreeViewItemStyles } from './tree_view_item.styles';
export var EuiTreeViewItem = memo(({
  id,
  label,
  className,
  children,
  display = 'default',
  icon,
  hasArrow,
  isActive,
  isExpanded,
  buttonRef,
  wrapperProps,
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiTreeViewItemStyles(euiTheme);
  const wrapperClasses = classNames('euiTreeView__node', {
    'euiTreeView__node--expanded': isExpanded
  }, wrapperProps?.className);
  const wrapperStyles = [styles.li.euiTreeView__node, styles.li[display], isExpanded && styles.li.expanded];
  const buttonClasses = classNames('euiTreeView__nodeInner', className, {
    'euiTreeView__node--active': isActive
  });
  const buttonStyles = [styles.button.euiTreeView__nodeInner, styles.button[display]];
  const iconStyles = [styles.icon.euiTreeView__iconWrapper, styles.icon[display]];
  return <li {...wrapperProps} css={wrapperStyles} className={wrapperClasses}>
        <button id={id} css={buttonStyles} className={buttonClasses} aria-expanded={isExpanded} ref={buttonRef} {...rest}>
          {hasArrow && (!!children ? <EuiIcon className="euiTreeView__expansionArrow" size={display === 'compressed' ? 's' : 'm'} type={isExpanded ? 'arrowDown' : 'arrowRight'} /> : <span css={iconStyles} className="euiTreeView__arrowPlaceholder" />)}
          {icon && <span css={iconStyles} className="euiTreeView__iconWrapper">
              {icon}
            </span>}
          <span className="euiTreeView__nodeLabel eui-textTruncate">
            {label}
          </span>
        </button>
        {children}
      </li>;
});
EuiTreeViewItem.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Required for `aria-controls` accessibility
       */
  id: PropTypes.string.isRequired,
  /**
       * The main button content
       */
  label: PropTypes.node.isRequired,
  /**
       * Used to render nested `EuiTreeView`s
       */
  children: PropTypes.node,
  /**
       * Optional icon to render. Pass, e.g., `<EuiIcon />` or `<EuiToken />`
       */
  icon: PropTypes.node,
  /**
       * Renders an arrow if `children` exists. Otherwise renders a blank icon
       */
  hasArrow: PropTypes.bool,
  /**
       * Adds a targetable modifier class
       */
  isActive: PropTypes.bool,
  /**
       * Sets the `aria-expanded` attribute
       */
  isExpanded: PropTypes.bool,
  /**
       * Determines default or compressed display
       */
  display: PropTypes.oneOf(["default", "compressed"]),
  buttonRef: PropTypes.any,
  /**
       * Optional extra props to pass to the wrapping `<li>`
       */
  wrapperProps: PropTypes.any
};
EuiTreeViewItem.displayName = 'EuiTreeViewItem';
try {
  EuiTreeViewItem.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tree_view/tree_view_item.tsx',
    description: '',
    displayName: 'EuiTreeViewItem',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      id: {
        defaultValue: null,
        description: 'Required for `aria-controls` accessibility',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      label: {
        defaultValue: null,
        description: 'The main button content',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      children: {
        defaultValue: null,
        description: 'Used to render nested `EuiTreeView`s',
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      icon: {
        defaultValue: null,
        description: 'Optional icon to render. Pass, e.g., `<EuiIcon />` or `<EuiToken />`',
        name: 'icon',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      hasArrow: {
        defaultValue: null,
        description: 'Renders an arrow if `children` exists. Otherwise renders a blank icon',
        name: 'hasArrow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isActive: {
        defaultValue: null,
        description: 'Adds a targetable modifier class',
        name: 'isActive',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isExpanded: {
        defaultValue: null,
        description: 'Sets the `aria-expanded` attribute',
        name: 'isExpanded',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      display: {
        defaultValue: null,
        description: 'Determines default or compressed display',
        name: 'display',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiTreeViewDisplayOptions',
          value: [{
            value: '"default"'
          }, {
            value: '"compressed"'
          }]
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      wrapperProps: {
        defaultValue: null,
        description: 'Optional extra props to pass to the wrapping `<li>`',
        name: 'wrapperProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tree_view/tree_view_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLLIElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}