/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { createElement, useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiText } from '../text';
import { EuiTitle } from '../title/title';
import { useEuiI18n } from '../i18n';
import { useEuiMemoizedStyles } from '../../services';
import { euiStatStyles, euiStatTitleStyles } from './stat.styles';
export var COLORS = ['default', 'subdued', 'primary', 'success', 'danger', 'accent'];
export var ALIGNMENTS = ['left', 'center', 'right'];
export var EuiStat = ({
  children,
  className,
  description,
  isLoading = false,
  reverse = false,
  textAlign = 'left',
  title,
  titleColor = 'default',
  titleSize = 'l',
  titleElement = 'p',
  descriptionElement = 'p',
  ...rest
}) => {
  const classes = classNames('euiStat', className);
  const styles = useEuiMemoizedStyles(euiStatStyles);
  const cssStyles = [styles.euiStat, styles[textAlign]];
  const loadingStatsAriaLabel = useEuiI18n('euiStat.loadingText', 'Statistic is loading');
  const titleStyles = useEuiMemoizedStyles(euiStatTitleStyles);
  const titleDisplay = useMemo(() => {
    const isNamedTitleColor = COLORS.includes(titleColor);
    const titleCssStyles = [titleStyles.euiStat__title, isNamedTitleColor && titleStyles[titleColor], isLoading && titleStyles.isLoading];
    return <EuiTitle size={titleSize} className="euiStat__title" css={titleCssStyles} aria-label={isLoading ? loadingStatsAriaLabel : undefined}>
        {createElement(titleElement, isNamedTitleColor ? null : {
        style: {
          color: titleColor
        }
      }, isLoading ? '--' : title)}
      </EuiTitle>;
  }, [title, titleElement, titleColor, titleSize, titleStyles, isLoading, loadingStatsAriaLabel]);
  const descriptionDisplay = useMemo(() => <EuiText size="s" className="euiStat__description">
        {createElement(descriptionElement, null, description)}
      </EuiText>, [description, descriptionElement]);
  return <div css={cssStyles} className={classes} {...rest}>
      {!reverse && descriptionDisplay}
      {titleDisplay}
      {reverse && descriptionDisplay}
      {children}
    </div>;
};
EuiStat.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Set the description (label) text
     */
  description: PropTypes.node.isRequired,
  /**
     * Will hide the title with an animation until false
     */
  isLoading: PropTypes.bool,
  /**
     * Flips the order of the description and title
     */
  reverse: PropTypes.bool,
  textAlign: PropTypes.any,
  /**
     * The (value) text
     */
  title: PropTypes.node.isRequired,
  /**
     * The color of the title text
     */
  titleColor: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.string.isRequired]),
  /**
     * Size of the title. See EuiTitle for options ('s', 'm', 'l'... etc)
     */
  titleSize: PropTypes.any,
  /**
     * HTML Element to be used for title
     */
  titleElement: PropTypes.string,
  /**
     * HTML Element to be used for description
     */
  descriptionElement: PropTypes.string
};
try {
  EuiStat.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/stat/stat.tsx',
    description: '',
    displayName: 'EuiStat',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      description: {
        defaultValue: null,
        description: 'Set the description (label) text',
        name: 'description',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: 'Will hide the title with an animation until false',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      reverse: {
        defaultValue: {
          value: 'false'
        },
        description: 'Flips the order of the description and title',
        name: 'reverse',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      textAlign: {
        defaultValue: {
          value: 'left'
        },
        description: '',
        name: 'textAlign',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "left" | "right"',
          value: [{
            value: '"center"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      title: {
        defaultValue: null,
        description: 'The (value) text',
        name: 'title',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      titleColor: {
        defaultValue: {
          value: 'default'
        },
        description: 'The color of the title text',
        name: 'titleColor',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      titleSize: {
        defaultValue: {
          value: 'l'
        },
        description: "Size of the title. See EuiTitle for options ('s', 'm', 'l'... etc)",
        name: 'titleSize',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      titleElement: {
        defaultValue: {
          value: 'p'
        },
        description: 'HTML Element to be used for title',
        name: 'titleElement',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      descriptionElement: {
        defaultValue: {
          value: 'p'
        },
        description: 'HTML Element to be used for description',
        name: 'descriptionElement',
        parent: {
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        },
        declarations: [{
          fileName: 'eui/src/components/stat/stat.tsx',
          name: 'EuiStatProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiStatProps']
  };
} catch (e) {}