function _EMOTION_STRINGIFIED_CSS_ERROR__() {
  return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
}
import PropTypes from "prop-types";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import classNames from 'classnames';
import { EuiIcon } from '../icon';
import { EuiI18n } from '../i18n';
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1v4nm53-EuiTourStepIndicator",
  styles: "display:inline-block;label:EuiTourStepIndicator;"
} : {
  name: "1v4nm53-EuiTourStepIndicator",
  styles: "display:inline-block;label:EuiTourStepIndicator;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var EuiTourStepIndicator = ({
  className,
  number,
  status,
  ...rest
}) => {
  const classes = classNames('euiTourStepIndicator', className);
  let indicatorIcon;
  if (status === 'active') {
    indicatorIcon = <EuiI18n token="euiTourStepIndicator.isActive" default="active">
        {isActive => <EuiIcon type="dot" aria-label={isActive} color="success" aria-current="step" />}
      </EuiI18n>;
  } else if (status === 'complete') {
    indicatorIcon = <EuiI18n token="euiTourStepIndicator.isComplete" default="complete">
        {isComplete => <EuiIcon type="dot" aria-label={isComplete} color="subdued" />}
      </EuiI18n>;
  } else if (status === 'incomplete') {
    indicatorIcon = <EuiI18n token="euiTourStepIndicator.isIncomplete" default="incomplete">
        {isIncomplete => <EuiIcon type="dot" aria-label={isIncomplete} color="subdued" />}
      </EuiI18n>;
  }
  return <EuiI18n token="euiTourStepIndicator.ariaLabel" default="Step {number} {status}" values={{
    status,
    number
  }}>
      {ariaLabel => <li css={_ref} className={classes} aria-label={ariaLabel} {...rest}>
          {indicatorIcon}
        </li>}
    </EuiI18n>;
};
EuiTourStepIndicator.propTypes = {
  number: PropTypes.number.isRequired,
  status: PropTypes.oneOf(["complete", "incomplete", "active"]).isRequired,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTourStepIndicator.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tour/tour_step_indicator.tsx',
    description: '',
    displayName: 'EuiTourStepIndicator',
    methods: [],
    props: {
      number: {
        defaultValue: null,
        description: '',
        name: 'number',
        parent: {
          fileName: 'eui/src/components/tour/tour_step_indicator.tsx',
          name: 'EuiTourStepIndicatorProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step_indicator.tsx',
          name: 'EuiTourStepIndicatorProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      status: {
        defaultValue: null,
        description: '',
        name: 'status',
        parent: {
          fileName: 'eui/src/components/tour/tour_step_indicator.tsx',
          name: 'EuiTourStepIndicatorProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step_indicator.tsx',
          name: 'EuiTourStepIndicatorProps'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: 'EuiTourStepStatus',
          value: [{
            value: '"incomplete"'
          }, {
            value: '"complete"'
          }, {
            value: '"active"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiTourStepIndicatorProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}