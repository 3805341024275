/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useGeneratedHtmlId, useEuiMemoizedStyles } from '../../../services';
import { EuiIcon } from '../../icon';
import { euiSwitchStyles } from './switch.styles';
export var EuiSwitch = ({
  label,
  id,
  checked,
  disabled,
  compressed,
  mini,
  onChange,
  className,
  showLabel = true,
  type = 'button',
  labelProps,
  ...rest
}) => {
  const switchId = useGeneratedHtmlId({
    conditionalId: id
  });
  const labelId = useGeneratedHtmlId({
    conditionalId: labelProps?.id
  });
  const onClick = useCallback(e => {
    if (disabled) {
      return;
    }
    const event = e;
    event.target.checked = !checked;
    onChange(event);
  }, [checked, disabled, onChange]);
  const classes = classNames('euiSwitch', className);
  const labelClasses = classNames('euiSwitch__label', labelProps?.className);
  if (showLabel === false && typeof label !== 'string') {
    console.warn('EuiSwitch `label` must be a string when `showLabel` is false.');
  }
  const size = mini ? 'mini' : compressed ? 'compressed' : 'uncompressed';
  const styles = useEuiMemoizedStyles(euiSwitchStyles);
  const cssStyles = [styles.euiSwitch, disabled ? styles.disabled : styles.enabled];
  const buttonStyles = [styles.button.euiSwitch__button, styles.button[size]];
  const bodyStyles = [styles.body.euiSwitch__body, disabled ? styles.body.disabled[size] : checked ? styles.body.on : styles.body.off];
  const iconsStyles = [styles.icons.euiSwitch__icons, checked ? styles.icons.on : styles.icons.off, disabled ? styles.icons.disabled : styles.icons.enabled];
  const thumbStyles = [styles.thumb.euiSwitch__thumb, checked ? styles.thumb.on[size] : styles.thumb.off, ...(disabled ? [styles.thumb.disabled.disabled, styles.thumb.disabled[size]] : [styles.thumb.enabled.enabled, styles.thumb.enabled[size]])];
  const labelStyles = [styles.label.euiSwitch__label, styles.label[size], disabled && styles.label.disabled, labelProps?.css];
  return <div css={cssStyles} className={classes}>
      <button id={switchId} aria-checked={checked || false} css={buttonStyles} className="euiSwitch__button" role="switch" type={type} disabled={disabled} onClick={onClick} aria-label={showLabel ? undefined : label} aria-labelledby={showLabel ? labelId : undefined} {...rest}>
        <span css={bodyStyles} className="euiSwitch__body">
          {!(compressed || mini) && <span css={iconsStyles} className="euiSwitch__icons">
              <EuiIcon type="check" size="m" />
              <EuiIcon type="cross" size="m" />
            </span>}
        </span>
        <span css={thumbStyles} className="euiSwitch__thumb" />
      </button>

      {showLabel &&
    // <button> + <label> has poor screen reader support.
    // Click handler added to simulate natural, secondary <label> interactivity.
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <span {...labelProps} css={labelStyles} className={labelClasses} id={labelId} onClick={onClick}>
          {label}
        </span>}
    </div>;
};
EuiSwitch.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Whether to render the text label
       */
  showLabel: PropTypes.bool,
  /**
       * Must be a string if `showLabel` prop is false
       */
  label: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  /**
       * Compressed switches are smaller and contain no icon signifiers
       */
  compressed: PropTypes.bool,
  /**
       * Object of props passed to the label's `<span />`
       */
  labelProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /**
       * Mini styling is similar to compressed, but even smaller.
       * It's undocumented because it has very specific uses.
       */
  mini: PropTypes.bool
};
try {
  EuiSwitch.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/switch/switch.tsx',
    description: '',
    displayName: 'EuiSwitch',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      showLabel: {
        defaultValue: {
          value: 'true'
        },
        description: 'Whether to render the text label',
        name: 'showLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      label: {
        defaultValue: null,
        description: 'Must be a string if `showLabel` prop is false',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(event: React.BaseSyntheticEvent<React.MouseEvent<HTMLButtonElement>, HTMLButtonElement, EventTarget & { checked: boolean; }>) => void'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      compressed: {
        defaultValue: null,
        description: 'Compressed switches are smaller and contain no icon signifiers',
        name: 'compressed',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      labelProps: {
        defaultValue: null,
        description: "Object of props passed to the label's `<span />`",
        name: 'labelProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      mini: {
        defaultValue: null,
        description: 'Mini styling is similar to compressed, but even smaller.\n' + "It's undocumented because it has very specific uses.",
        name: 'mini',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/switch/switch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'ButtonHTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}