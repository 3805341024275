/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiContextMenuItem } from '../../context_menu';
export var EuiTableSortMobileItem = ({
  children,
  onSort,
  isSorted,
  isSortAscending,
  className,
  ariaLabel,
  ...rest
}) => {
  let sortIcon = 'empty';
  if (isSorted) {
    sortIcon = isSortAscending ? 'sortUp' : 'sortDown';
  }
  const buttonClasses = classNames('euiTableSortMobileItem', className, {
    'euiTableSortMobileItem-isSorted': isSorted
  });
  const columnTitle = ariaLabel ? ariaLabel : children;
  const statefulAriaLabel = `Sort ${columnTitle} ${isSortAscending ? 'descending' : 'ascending'}`;
  return <EuiContextMenuItem className={buttonClasses} icon={sortIcon} onClick={onSort} aria-label={statefulAriaLabel} {...rest}>
      {children}
    </EuiContextMenuItem>;
};
EuiTableSortMobileItem.propTypes = {
  /**
     * Callback to know when an item has been clicked
     */
  onSort: PropTypes.func,
  /**
     * Indicates current option is the sorted on column
     */
  isSorted: PropTypes.bool,
  /**
     * Indicates which direction the current column is sorted on
     */
  isSortAscending: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiTableSortMobileItem.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/table/mobile/table_sort_mobile_item.tsx',
    description: '',
    displayName: 'EuiTableSortMobileItem',
    methods: [],
    props: {
      onSort: {
        defaultValue: null,
        description: 'Callback to know when an item has been clicked',
        name: 'onSort',
        parent: {
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      isSorted: {
        defaultValue: null,
        description: 'Indicates current option is the sorted on column',
        name: 'isSorted',
        parent: {
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isSortAscending: {
        defaultValue: null,
        description: 'Indicates which direction the current column is sorted on',
        name: 'isSortAscending',
        parent: {
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ariaLabel: {
        defaultValue: null,
        description: '',
        name: 'ariaLabel',
        parent: {
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/table/mobile/table_sort_mobile_item.tsx',
          name: 'EuiTableSortMobileItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiTableSortMobileItemProps', 'CommonProps']
  };
} catch (e) {}